import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Dialog from "@civicplus/preamble-ui/lib/Dialog";
import ErrorStep from "./Components/ErrorStep";
import LanguageStep from "./Language/LanguageStep";
import ReviewStep from "./Components/ReviewStep";
import PhoneNumberStep from "./PhoneNumber/PhoneNumberStep";
import PhoneNumberVerificationStep from "./PhoneNumber/PhoneNumberVerificationStep";
import PhysicalAddressStep from "./PhysicalAddress/PhysicalAddressStep";
import Wizard, { IStepShape, WizardType } from "./Components/Wizard";
import { AppIdsAndPhoneNumbers } from "../../types/Subscription";
import { DocumentType } from "../../types/SearchDocument";
import { isNullOrUndefined } from "../../shared/functions";
import { KonexusUser, UserAccountDetails } from "../../types/Account";
import { Organization } from "../../types/Organization";
import { PhoneNumber, PhoneNumberType, areAllPhonesVerified } from "./PhoneNumber/PhoneNumberUtils";
import { PhysicalAddress } from "./PhysicalAddress/PhysicalAddressList";
import { useApplicationPhoneNumbers } from "../../hooks/useApplicationPhoneNumbers";

const stepperSteps: IStepShape[] = [
    {
        label: "Phone Number",
        completed: false
    },
    {
        label: "Phone Verification",
        completed: false
    },
    {
        label: "Location",
        completed: false
    },
    {
        label: "Language",
        completed: false
    },
    {
        label: "Review Your Information",
        completed: false,
        final: true
    }
];

export interface AlertsSubscriptionDialogProps {
    open: boolean;
    onClose: () => void;
    languages: IKonexusLanguages;
    userAccountDetails: UserAccountDetails;
    reloadAccountDetails?: () => void;
    organization: Organization;
    applicationId: string;
    onSubmit: (signUpData: DataType) => Promise<boolean>;
    documentType?: DocumentType;
    phoneNumbers?: AppIdsAndPhoneNumbers["PhoneNumberDetails"];
    title?: string;
    showDefaultSubscriptionCheck?: boolean;
    setIsDirty?: (value: boolean) => void;
}

export type DataType = {
    phoneNumbers: PhoneNumber[];
    isPhoneVerified?: boolean;
    physicalAddress?: PhysicalAddress;
    subscribeToDefault?: boolean;
    documentType?: DocumentType;
    language?: {
        written?: ILanguageOption;
        spoken?: ILanguageOption;
    };
};

export type StepProps = Omit<WizardType<DataType>, "children">;

export interface ILanguageOption {
    label?: string;
    value: string;
}

export interface IKonexusLanguages {
    spoken: ILanguageOption[];
    written: ILanguageOption[];
}

export const AlertsSubscriptionDialog: React.FC<AlertsSubscriptionDialogProps> = (props) => {
    const {
        open,
        onClose,
        languages,
        userAccountDetails,
        reloadAccountDetails,
        organization,
        applicationId,
        onSubmit,
        documentType = DocumentType.AlertList,
        phoneNumbers,
        title = "Complete your Profile to Subscribe to Alerts",
        showDefaultSubscriptionCheck = true,
        setIsDirty
    } = props;

    const defaultSpokenLanguage = languages.spoken.find((x) => x.value === "en-us");
    const defaultWrittenLanguage = languages.written.find((x) => x.value === "en-us");

    const [signUpData, setSignUpData] = React.useState<DataType>({
        phoneNumbers: [],
        documentType,
        isPhoneVerified: false,
        physicalAddress: undefined,
        language: {
            spoken: {
                value: defaultSpokenLanguage?.value ?? "en",
                label: defaultSpokenLanguage?.label ?? "English"
            },
            written: {
                value: defaultWrittenLanguage?.value ?? "en",
                label: defaultWrittenLanguage?.label ?? "English"
            }
        }
    });

    const phoneNumbersWithVerified = useMemo(
        () =>
            userAccountDetails?.phoneNumbers?.map((phoneNumber) => {
                const isPhoneVerified =
                    phoneNumbers?.find((x) => x.phoneNumber === phoneNumber.konexusPhoneNumber)?.isValidated ?? false;

                return {
                    ...phoneNumber,
                    isPhoneVerified
                };
            }) ?? [],
        [phoneNumbers, userAccountDetails?.phoneNumbers]
    );

    const userAddresses = useMemo(
        () => userAccountDetails?.physicalAddresses ?? [],
        [userAccountDetails?.physicalAddresses]
    );

    const [currentStep, setCurrentStep] = useState(0);
    const [synced, setSynced] = useState<boolean>(false);
    const [steps, setSteps] = useState<IStepShape[]>(JSON.parse(JSON.stringify(stepperSteps)));
    const [, , , reloadPhoneNumbers] = useApplicationPhoneNumbers();
    const initialSignUpDataRef = useRef(signUpData);

    useEffect(() => {
        if (setIsDirty) {
            if (JSON.stringify(initialSignUpDataRef.current) !== JSON.stringify(signUpData)) {
                setIsDirty(true);
            } else {
                setIsDirty(false);
            }
        }
    }, [setIsDirty, signUpData]);

    useEffect(() => {
        const isAllPhoneNumbersVerified = areAllPhonesVerified(signUpData.phoneNumbers);
        // Set Phone Verification step in completed based on phone number selection
        setSteps((prevSteps) => {
            const newSteps = [...prevSteps];
            const step = newSteps.find((x) => x.label === "Phone Verification");
            if (step) {
                step.completed = isAllPhoneNumbersVerified;
            }
            return newSteps;
        });
        // Set isPhoneVerified based on phone number selection
        setSignUpData((prevData) => ({
            ...prevData,
            isPhoneVerified: isAllPhoneNumbersVerified
        }));
    }, [signUpData.phoneNumbers]);

    useEffect(
        function updateDataBasedOnUserDetails() {
            const selectedPhoneNumbers: PhoneNumber[] = [];

            if (phoneNumbersWithVerified) {
                type phoneType = {
                    id: keyof KonexusUser;
                    type: PhoneNumberType;
                };

                const phoneTypes: phoneType[] = [
                    { id: "accountServiceCellPhoneId", type: PhoneNumberType.CellPhone },
                    { id: "accountServiceCellPhoneSecondaryId", type: PhoneNumberType.CellPhoneSecondary },
                    { id: "accountServiceHomePhoneId", type: PhoneNumberType.HomePhone },
                    { id: "accountServiceWorkPhoneId", type: PhoneNumberType.WorkPhone }
                ];

                phoneTypes.forEach(({ id, type }) => {
                    const phoneId = userAccountDetails?.konexusUser?.[id];

                    if (phoneId) {
                        const existingPhone = phoneNumbersWithVerified.find((x) => x.id === phoneId);
                        if (existingPhone) {
                            selectedPhoneNumbers.push({ ...existingPhone, type });
                        }
                    }
                });
            }

            let address: PhysicalAddress | undefined = undefined;

            if (userAccountDetails?.konexusUser?.accountServicePhysicalAddressId) {
                address = userAccountDetails.physicalAddresses.find(
                    (x) => x.id === userAccountDetails.konexusUser?.accountServicePhysicalAddressId
                );
            }

            const spokenLanguage = languages.spoken.find(
                (x) => x.value === (userAccountDetails?.spokenLanguage ?? "en")
            );

            const writtenLanguage = languages.written.find(
                (x) => x.value === (userAccountDetails?.writtenLanguage ?? "en")
            );

            let effectivePhoneNumbers: PhoneNumber[] = [];
            let effectiveAddress: PhysicalAddress | undefined;

            if (selectedPhoneNumbers.length > 0 || address !== undefined || spokenLanguage || writtenLanguage) {
                setSignUpData((prevData) => {
                    effectivePhoneNumbers =
                        selectedPhoneNumbers.length > 0 ? selectedPhoneNumbers : prevData.phoneNumbers;
                    effectiveAddress = address !== undefined ? address : prevData.physicalAddress;

                    const populatedSignUpData = {
                        ...prevData,
                        phoneNumbers: effectivePhoneNumbers,
                        physicalAddress: effectiveAddress,
                        language: {
                            spoken: isNullOrUndefined(spokenLanguage) ? prevData.language?.spoken : spokenLanguage,
                            written: isNullOrUndefined(writtenLanguage) ? prevData.language?.written : writtenLanguage
                        },
                        isPhoneVerified: areAllPhonesVerified(effectivePhoneNumbers)
                    };

                    initialSignUpDataRef.current = populatedSignUpData;

                    return populatedSignUpData;
                });

                setSteps((prevSteps) => {
                    const newSteps = [...prevSteps];
                    const stepLabels = ["Phone Number", "Phone Verification", "Location", "Language"];
                    const stepCompleted = [
                        effectivePhoneNumbers.length > 0,
                        effectivePhoneNumbers.length > 0 &&
                            effectivePhoneNumbers
                                .filter(
                                    (x) =>
                                        x.type === PhoneNumberType.CellPhone ||
                                        x.type === PhoneNumberType.CellPhoneSecondary
                                )
                                .every((x) => x.isPhoneVerified),
                        effectiveAddress !== undefined,
                        !isNullOrUndefined(spokenLanguage) && !isNullOrUndefined(writtenLanguage)
                    ];

                    stepLabels.forEach((label, index) => {
                        const step = newSteps.find((x) => x.label === label);
                        if (step) {
                            step.completed = stepCompleted[index];
                        }
                    });

                    return newSteps;
                });
            }
        },
        [languages, phoneNumbersWithVerified, userAccountDetails]
    );

    const setSignUpDataForStep = useCallback((signUpData: Partial<DataType>) => {
        setSignUpData((currentSignUpData) => {
            return {
                ...currentSignUpData,
                ...signUpData
            };
        });
    }, []);

    const handleOnSubmit = async (signUpData: DataType): Promise<boolean> => {
        const success = await onSubmit(signUpData);
        if (success) {
            reloadPhoneNumbers();
        }
        return success;
    };

    const handleOnClose = () => {
        if (!setIsDirty) {
            reloadPhoneNumbers();
            setSignUpData({
                phoneNumbers: [],
                isPhoneVerified: false,
                physicalAddress: undefined,
                language: {
                    spoken: {
                        value: defaultSpokenLanguage?.value ?? "en",
                        label: defaultSpokenLanguage?.label ?? "English"
                    },
                    written: {
                        value: defaultWrittenLanguage?.value ?? "en",
                        label: defaultWrittenLanguage?.label ?? "English"
                    }
                }
            });

            setCurrentStep(0);
            setSteps(JSON.parse(JSON.stringify(stepperSteps)));
        }
        if (onClose) {
            onClose();
        }
    };

    return (
        <Dialog
            key="subscription-dialog"
            id="subscription-dialog"
            title={title}
            open={open}
            onClose={handleOnClose}
            maxWidth="md"
            fullWidth={true}
        >
            <Wizard<DataType>
                data={signUpData}
                steps={steps}
                setSteps={setSteps}
                setData={setSignUpDataForStep}
                currentStep={currentStep}
                setCurrentStep={setCurrentStep}
            >
                <Wizard.Step>
                    <PhoneNumberStep existingPhoneNumbers={phoneNumbersWithVerified} />
                </Wizard.Step>

                {userAccountDetails?.phoneNumbers ? (
                    <Wizard.Step>
                        <PhoneNumberVerificationStep
                            applicationId={applicationId}
                            organization={organization}
                            konexusUser={userAccountDetails?.konexusUser}
                            reloadAccountDetails={reloadAccountDetails}
                            reloadPhoneNumbers={reloadPhoneNumbers}
                            setSynced={setSynced}
                            synced={synced}
                        />
                    </Wizard.Step>
                ) : (
                    <></>
                )}

                <Wizard.Step>
                    <PhysicalAddressStep existingPhysicalAddresses={userAddresses} />
                </Wizard.Step>

                <Wizard.Step>
                    <LanguageStep availableLanguages={languages} />
                </Wizard.Step>

                <Wizard.Step>
                    <ReviewStep
                        onSubmit={handleOnSubmit}
                        organization={organization}
                        showDefaultSubscriptionCheck={showDefaultSubscriptionCheck}
                    />
                </Wizard.Step>

                <Wizard.Step>
                    <ErrorStep />
                </Wizard.Step>
            </Wizard>
        </Dialog>
    );
};

export default AlertsSubscriptionDialog;
