import { alpha } from "@mui/material/styles";
import { makeStyles } from "@civicplus/preamble-ui/lib/Utilities/ThemeHelper";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "inline-flex",
        position: "relative"
    },
    button: {
        height: "100%",
        flexDirection: "column",
        padding: "0",
        textTransform: "none",
        letterSpacing: "normal",
        border: `1px solid ${alpha(theme.palette.primary.main, 0.5)}`,
        backgroundColor: theme.palette.background.paper,
        borderRadius: theme.shape.borderRadius,
        "&:hover": {
            borderColor: theme.palette.primary.main
        }
    },
    cardIcon: {
        width: "100%",
        color: theme.palette.primary.main,
        margin: 0,
        padding: theme.spacing(2, 2, 0),
        fontSize: theme.spacing(8),
        "& svg": {
            display: "block",
            height: theme.spacing(8)
        }
    },
    content: {
        width: "100%",
        padding: theme.spacing(2),
        display: "flex",
        flexDirection: "column",
        flexShrink: 1,
        overflow: "hidden",
        position: "relative",
        flexGrow: 1,
        textAlign: "left"
    },
    description: {
        flexGrow: 1,
        overflow: "hidden",
        marginTop: theme.spacing(1),
        display: "-webkit-box",
        boxOrient: "vertical",
        lineClamp: 4
    },
    descriptionDashboard: {
        lineClamp: 2
    },
    linkWrapper: {
        marginTop: "auto",
        paddingTop: theme.spacing(2)
    },
    title: {
        marginRight: theme.spacing(6)
    },
    chipsContainer: {
        display: "flex",
        alignItems: "center",
        paddingTop: 12,
        flexWrap: "wrap",
        gap: 5,
        margin: theme.spacing(1, 0, 0),
        overflowX: "auto"
    },
    externalChip: {
        "& svg": {
            marginLeft: theme.spacing(1) + "!important"
        }
    },
    buttonGroup: {
        position: "absolute",
        top: 0,
        right: 0,
        padding: theme.spacing(2)
    }
}));

export default useStyles;
