import React, { useEffect, useRef, useState } from "react";
import ApiService, { ApiError, parseErrorMessage } from "../../../services/apiService";
import Button from "@civicplus/preamble-ui/lib/Button";
import Chip from "../../Chip";
import Close from "@mui/icons-material/Cancel";
import Collapse from "@mui/material/Collapse";
import IconCircleCheck from "@mui/icons-material/CheckCircle";
import Grid from "@civicplus/preamble-ui/lib/Grid";
import TextInput from "@civicplus/preamble-ui/lib/TextInput";
import Typography from "@civicplus/preamble-ui/lib/Typography";
import { formatSecondsToMMSS } from "../../../shared/functions";
import { makeStyles } from "@civicplus/preamble-ui/lib/Utilities/ThemeHelper";
import { Organization } from "../../../types/Organization";
import { PhoneNumber } from "./PhoneNumberUtils";
import { useAuth } from "../../../providers/AuthProvider";
import { useFeatures } from "../../../shared/useFeatureFlags";
import { useSnackbar } from "notistack";
import enhanceWithValidation, { requiredValidation, ValidationResult } from "@civicplus/preamble-ui/lib/Validations";

const EnhancedTextInput = enhanceWithValidation(TextInput);

const useStyles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(2, 0),
        padding: theme.spacing(2),
        border: `1px solid ${theme.palette.divider}`
    },
    timer: {
        userSelect: "none"
    }
}));

export enum VerificationStatus {
    Default = "Default",
    Pending = "Pending",
    Sent = "Sent",
    Verifying = "Verifying",
    Verified = "Verified"
}

export interface PhoneNumberVerificationItemsProps {
    phoneNumber: PhoneNumber;
    organization: Organization;
    applicationId: string;
    setPhoneNumberVerified: (phoneNumber: string) => void;
}

const PhoneNumberVerificationItems: React.FC<PhoneNumberVerificationItemsProps> = (props) => {
    const { phoneNumber, organization, setPhoneNumberVerified } = props;
    const auth = useAuth();
    const classes = useStyles();
    const [flags] = useFeatures();
    const { enqueueSnackbar } = useSnackbar();
    const [oneTimeCode, setOneTimeCode] = useState("");
    const selectedNumber = `+1${phoneNumber?.number}`;
    const [sendVerificationCodeStatus, setSendVerificationCodeStatus] = useState<VerificationStatus>(
        VerificationStatus.Default
    );
    const [seconds, setSeconds] = useState(0);
    const otpInputRef = useRef<{ validate: () => Promise<ValidationResult> }>();

    useEffect(() => {
        let interval: NodeJS.Timeout | undefined;

        if (seconds > 0) {
            interval = setInterval(() => {
                setSeconds((seconds) => seconds - 1);
            }, 1000);
        } else {
            clearInterval(interval);
        }

        return () => clearInterval(interval);
    }, [seconds]);

    const sendVerificationCode = async () => {
        if (!phoneNumber.isPhoneVerified) {
            setSendVerificationCodeStatus(VerificationStatus.Pending);

            try {
                const url = `${organization?.name.toLowerCase()}/notifications/phone/verification/send`;
                await ApiService.post({
                    url: url,
                    authUser: auth.user,
                    requestInit: {
                        headers: {
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify({
                            phoneNumber: selectedNumber,
                            phoneId: phoneNumber.id,
                            type: phoneNumber.type
                        })
                    }
                });

                setSeconds(60);
                setSendVerificationCodeStatus(VerificationStatus.Sent);

                if (flags.phoneVerificationEnabled) {
                    const url = `${organization?.name.toLowerCase()}/notifications/phone/verification/get`;
                    const response = await ApiService.post({
                        url: url,
                        authUser: auth.user,
                        requestInit: {
                            headers: {
                                "Content-Type": "application/json"
                            },
                            body: JSON.stringify({
                                phoneNumber: selectedNumber,
                                phoneId: phoneNumber.id
                            })
                        }
                    });

                    setOneTimeCode(response.toString());
                }
            } catch (error) {
                const message = parseErrorMessage(error as ApiError, "Failed to send verification code.");

                if (message.includes("already verified.")) {
                    setSendVerificationCodeStatus(VerificationStatus.Verified);
                    setPhoneNumberVerified(selectedNumber);
                    enqueueSnackbar("Phone number is already verified.", {
                        variant: "success"
                    });
                    return;
                }

                setSendVerificationCodeStatus(VerificationStatus.Default);
                enqueueSnackbar(message, {
                    variant: "error",
                    persist: true
                });
                setSeconds(0);
            }
        }
    };

    const handlePhoneVerification = async () => {
        try {
            const otpValidationResult = await otpInputRef.current?.validate();
            if (otpValidationResult?.error) {
                return;
            }

            setSendVerificationCodeStatus(VerificationStatus.Verifying);

            const url = `${organization?.name.toLowerCase()}/notifications/phone/verification/verify`;

            await ApiService.post({
                url: url,
                authUser: auth.user,
                requestInit: {
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        phoneNumber: selectedNumber,
                        phoneId: phoneNumber.id,
                        verificationCode: oneTimeCode,
                        type: phoneNumber.type
                    })
                }
            });

            setSendVerificationCodeStatus(VerificationStatus.Verified);

            enqueueSnackbar("Phone number has been verified.", {
                variant: "success"
            });

            setPhoneNumberVerified(selectedNumber);
        } catch (ex) {
            setSendVerificationCodeStatus(VerificationStatus.Default);

            const message = parseErrorMessage(ex as ApiError, "Phone number failed to verify.");
            enqueueSnackbar(message, {
                variant: "error",
                persist: true
            });
        }
    };

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setOneTimeCode(e.target.value);
    };

    const isVerifying =
        sendVerificationCodeStatus === VerificationStatus.Sent ||
        sendVerificationCodeStatus === VerificationStatus.Verifying;

    return (
        <section className={classes.root} key="phone-verification">
            <Grid container={true} spacing={1} alignItems="center">
                <Grid xs={12} sm={6}>
                    <Grid container={true}>
                        <Grid xs={12}>
                            <Typography id="phone-label" component="h3" variant="h6">
                                {phoneNumber?.label}
                            </Typography>
                        </Grid>

                        <Grid xs={12}>
                            <Typography color="textSecondary">{phoneNumber?.number}</Typography>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid xs={12} sm={true}>
                    {phoneNumber.isPhoneVerified ? (
                        <Chip icon={<IconCircleCheck fontSize="small" />} label="Verified" />
                    ) : (
                        <Chip icon={<Close fontSize="small" />} label="Not Verified" />
                    )}
                </Grid>

                {!phoneNumber.isPhoneVerified && (
                    <Grid xs={12} md="auto">
                        <Button
                            id="sendVerificationCode"
                            onClick={sendVerificationCode}
                            disabled={seconds > 0}
                            isLoading={sendVerificationCodeStatus === "Pending"}
                            fullWidth={true}
                        >
                            {sendVerificationCodeStatus === "Sent"
                                ? "Resend Verification Code"
                                : "Send Verification Code"}
                        </Button>

                        <Collapse in={seconds > 0}>
                            <Typography
                                className={classes.timer}
                                variant="caption"
                                color="textSecondary"
                                aria-live="polite"
                            >
                                {`Resend in ${formatSecondsToMMSS(seconds)}`}
                            </Typography>
                        </Collapse>
                    </Grid>
                )}
            </Grid>

            {isVerifying && (
                <Grid sx={{ mt: 2 }} container={true} spacing={2}>
                    <Grid xs={12}>
                        <Typography id="verification-text">
                            {`A code has been sent to ${phoneNumber?.number}. Please enter the code to verify the phone.`}
                        </Typography>
                    </Grid>

                    <Grid xs={12}>
                        <Grid container={true} spacing={2} alignItems="center">
                            <Grid xs={12} md={true}>
                                <EnhancedTextInput
                                    ref={otpInputRef}
                                    id="verification-code"
                                    label="One Time Password"
                                    fullWidth={true}
                                    placeholder="Please type the received password here"
                                    value={oneTimeCode}
                                    onChange={onChange}
                                    margin={false}
                                    required={true}
                                    validations={[requiredValidation]}
                                />
                            </Grid>

                            <Grid xs={12} md="auto">
                                <Button
                                    id="verifyCode"
                                    color="primary"
                                    onClick={handlePhoneVerification}
                                    fullWidth={true}
                                    disabled={sendVerificationCodeStatus === VerificationStatus.Verifying}
                                    isLoading={sendVerificationCodeStatus === VerificationStatus.Verifying}
                                >
                                    Verify Code
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            )}
        </section>
    );
};

export default PhoneNumberVerificationItems;
