import React, { useMemo } from "react";
import AccessibilityMessage from "../AccessibilityMessage";
import Typography from "@civicplus/preamble-ui/lib/Typography";
import useStyles from "./styles";
import { NotificationsService } from "../../services/notificationsService";
import { Organization } from "../../types/Organization";
import { SubscriptionList } from "../../types/Subscription";
import { User as AuthUser } from "oidc-client-ts";
import NotificationsListGroupCard from "./NotificationsListGroupCard";
import { groupNotifications } from "./utils";

export interface NotificationsListProps {
    notifications?: SubscriptionList[];
    organization?: Organization;
    searchText?: string;
    authUser?: AuthUser | null;
    notificationService: NotificationsService;
    handleSubscriptionUpdate: (subscription: SubscriptionList) => void;
}

const NotificationsListGroups: React.FC<NotificationsListProps> = (props) => {
    const classes = useStyles();
    const { notifications, organization, searchText, authUser, handleSubscriptionUpdate } = props;

    const renderedNotifications = useMemo(() => {
        if (notifications === undefined) {
            // mock 3 notifications cards
            return Array.from({ length: 3 }, (_, i) => (
                <div key={`${i}-card-key`} className={classes.card}>
                    <NotificationsListGroupCard
                        id={`${i}-mock-group`}
                        authUser={authUser}
                        groupName=""
                        source=""
                        notifications={Array.from({ length: 5 }, () => ({}) as SubscriptionList)} // mock 5 notifications per card
                        subGroups={[]}
                        organization={organization}
                        handleSubscriptionUpdate={handleSubscriptionUpdate}
                        isLoading={true}
                    />
                </div>
            ));
        } else if (notifications.length === 0) {
            return (
                <div className={classes.card}>
                    <Typography align="center">{searchText ? "No results found" : "No notifications found"}</Typography>
                </div>
            );
        } else {
            if (!organization) {
                return null;
            }

            const groupedNotifications = groupNotifications(
                notifications,
                organization.applications,
                organization?.friendlyName || organization?.name
            );

            return (
                <>
                    {groupedNotifications.map(({ groupName, source, notifications, subGroups }) => (
                        <div key={`${groupName}-card-key`} className={classes.card}>
                            <NotificationsListGroupCard
                                id={`${groupName.replace(/\s+/g, "-").toLowerCase()}`}
                                authUser={authUser}
                                groupName={groupName}
                                source={source}
                                notifications={notifications}
                                subGroups={subGroups}
                                organization={organization}
                                handleSubscriptionUpdate={handleSubscriptionUpdate}
                            />
                        </div>
                    ))}
                </>
            );
        }
    }, [authUser, classes.card, handleSubscriptionUpdate, notifications, organization, searchText]);

    const isLoading = notifications === undefined;

    return (
        <>
            <div id="notifications-list" aria-busy={isLoading}>
                {renderedNotifications}
            </div>

            <AccessibilityMessage
                message={isLoading ? "Loading Subscription Details" : "Subscription Details Loaded"}
            />
        </>
    );
};

export default NotificationsListGroups;
