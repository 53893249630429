import React, { useState, useMemo, useEffect, useContext, useCallback } from "react";
import { Link as RouterLink, useLocation as useRouterLocation, useNavigate, useParams } from "react-router-dom";
import ApiService from "../../services/apiService";
import Button from "@civicplus/preamble-ui/lib/Button";
import ButtonGroup from "@civicplus/preamble-ui/lib/ButtonGroup";
import CloseIcon from "@mui/icons-material/Close";
import clsx from "clsx";
import GlobalSearchInput from "../GlobalSearchInput";
import Page from "@civicplus/preamble-ui/lib/Page";
import PortalOrganizationService from "../../services/portalOrganizationService";
import ViewDashboardIcon from "mdi-material-ui/ViewDashboard";
import useMediaQuery from "@mui/material/useMediaQuery";
import { CppOrganization } from "@civicplus/preamble-ui/lib/Services/OrgService/Types/Organization";
import { NavigationRailButton } from "@civicplus/preamble-ui/lib/NavigationRail";
import { Breakpoint } from "@mui/material/styles";
import { useTheme } from "@civicplus/preamble-ui/lib/Utilities/ThemeHelper";
import { GAcontentSelected, GAlogin, GAuser } from "../../services/googleAnalyticsService";
import { getOrgApplicationNavButtons } from "./NavbarHelper";
import { Organization } from "../../types/Organization";
import { Location, LocationTypes, LocationContext } from "../../types/LocationTypes";
import { MenuItemStaticTypes } from "@civicplus/preamble-ui/lib/MenuItem";
import { SigninPopupArgs } from "oidc-client-ts";
import { setupAcrValue } from "../react-oidc-context";
import { getHasGroupMemberAccess, getIsOwnerOfOrganization, getIsSuperUser } from "../../services/permissionService";
import { getCppOrganizationFromOrganization, isStaffUser, getLocallyStoredOrgName } from "../../shared/functions";
import { useAccountInfo } from "../../hooks/useAccountInfo";
import { useAuth } from "../../providers/AuthProvider";
import { useConfig } from "../../providers/ConfigProvider";
import { useEmbedStore } from "../../stores/embedStore";
import { useFeatures } from "../../shared/useFeatureFlags";
import { useLinks } from "../../stores/linksStore";
import { useLocation as LocationStore } from "../../stores/locationStore";
import { useOrganization } from "../../stores/organizationStore";
import OpenInNewTab from "mdi-material-ui/OpenInNew";
import AlertIcon from "@mui/icons-material/NotificationsActive";
import CashMultipleIcon from "mdi-material-ui/CashMultiple";
import CogIcon from "mdi-material-ui/Cog";
import FormIcon from "mdi-material-ui/FormSelect";
import LegislationIcon from "mdi-material-ui/ScaleBalance";
import MeetingsIcon from "mdi-material-ui/Bank";
import WebIcon from "mdi-material-ui/Web";
import { useStyles } from "./styles";
import { useSnackbar } from "notistack";
import SnackbarProviderProps from "../SnackBar/config";

const ignoredOrgsPendo = ["ui-automation", "Kraken", "civicplus-internal"];

const productNavSections = [
    {
        title: "Portal"
    }
];

interface NavbarProps {
    widthOverride?: Breakpoint;
    children?: React.ReactElement;
    isEmbed: boolean;
    hideNavigation: boolean;
    backgroundColor?: string;
}

const Navbar: React.FC<NavbarProps> = ({ children, isEmbed, hideNavigation, backgroundColor }) => {
    const classes = useStyles({ hideNavigation, backgroundColor });
    const auth = useAuth();
    const config = useConfig();
    const navigate = useNavigate();
    const router = useRouterLocation();
    const [, userAccountInfo] = useAccountInfo();
    const { locationName } = useParams();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [organizationName] = useEmbedStore((state) => [state.organizationName]);

    const [, dispatch] = useContext(LocationContext);
    const [organization, setOrganization] = useOrganization((state) => [state.organization, state.setOrganization]);
    const [currentLocation, userLocations, setUserLocations] = LocationStore((state) => [
        state.currentLocation,
        state.userLocations,
        state.setUserLocations
    ]);

    const [flags, _, orgFlagsLoaded] = useFeatures();
    const [initializedGainsight, setInitializedGainsight] = useState(false);
    const [initializedPendo, setInitializedPendo] = useState(false);
    const [pendoOrgName, setPendoOrgName] = useState(organization?.name);

    const { clearFavorites } = useLinks((state) => ({
        clearFavorites: state.clearFavorites
    }));

    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

    useEffect(() => {
        async function loadRecentOrganization() {
            let fetchOrganization = undefined;

            if (!isEmbed) {
                if (locationName) {
                    fetchOrganization = locationName;
                } else {
                    fetchOrganization = getLocallyStoredOrgName();
                }
            } else {
                fetchOrganization = organizationName;
            }

            if (fetchOrganization && fetchOrganization !== organization?.name && router.pathname !== "/404") {
                try {
                    const org = await ApiService.get({
                        url: `Organization/${fetchOrganization}`,
                        authUser: auth?.user,
                        resetCache: auth?.isAuthenticated && !auth?.isLoading
                    });
                    clearFavorites();
                    setOrganization(org);
                } catch {
                    navigate("/404");
                }
            }
        }

        loadRecentOrganization();
    }, [auth, locationName, navigate, organization?.name, organizationName, setOrganization, router, isEmbed, clearFavorites]);

    const [isSuperUser, setIsSuperUser] = useState<boolean>(false);
    const [hasElevatedAccess, setHasElevatedAccess] = useState<boolean>(false);
    const [activeBtn, setActiveBtn] = useState<number>();
    const [isAdminPage, setIsAdminPage] = useState<boolean>(false);

    const pageOrganization: CppOrganization | undefined = useMemo(() => {
        if (organization !== undefined) {
            return getCppOrganizationFromOrganization(organization);
        }

        return undefined;
    }, [organization]);

    useEffect(() => {
        const fecthAccess = async () => {
            if (!auth.isAuthenticated || !auth.user) {
                return;
            }

            const isSuperUser = getIsSuperUser(auth.user, config.superUserRole);
            if (isSuperUser) {
                setIsSuperUser(true);
                setHasElevatedAccess(true);
                return;
            }

            const isEmptyOrg = JSON.stringify(organization) === JSON.stringify({});

            if (isEmptyOrg || !organization) {
                return;
            }

            const isOwner = getIsOwnerOfOrganization(auth.user, organization);
            if (isOwner) {
                setHasElevatedAccess(true);
                return;
            }

            if (auth && auth.user) {
                const hasGroupAccess = await getHasGroupMemberAccess(auth.user, organization);
                setHasElevatedAccess(hasGroupAccess);
            }
        };

        fecthAccess();
    }, [auth, auth.isAuthenticated, auth.user, organization, config.superUserRole]);

    useEffect(() => {
        const sendUserInfoToGainsight = async () => {
            setInitializedGainsight(true);

            let userRole = "Public Users";

            if (userAccountInfo?.email?.includes("@civicplus.com")) {
                userRole = "CivicPlus Staff";
            } else if (userAccountInfo?.userInOrganization || hasElevatedAccess) {
                userRole = "Admin Users";
            }

            //passing user and account objects:
            // @ts-ignore
            aptrinsic(
                "identify",
                {
                    //User Fields
                    id: userAccountInfo?.userId,
                    email: userAccountInfo?.email,
                    firstName: userAccountInfo?.firstName,
                    lastName: userAccountInfo?.lastName,
                    phone: userAccountInfo?.phoneNumbers[0]?.number,
                    role: userRole
                },
                {
                    //Account Fields
                    id: `Portal-${organization?.name}`,
                    name: organization?.friendlyName
                }
            );
        };

        // @ts-ignore
        if (auth.user && window.aptrinsic?.init && userAccountInfo && organization && !initializedGainsight) {
            sendUserInfoToGainsight();
        }
        // @ts-ignore
    }, [auth.user, organization, window.aptrinsic, userAccountInfo]);

    useEffect(() => {
        const sendUserInfoToPendo = async () => {
            let userRole = "Public Users";
            let portal_is_admin = false;
            let portal_is_public = false;
            const isCivicPlusStaff = userAccountInfo?.email?.includes("@civicplus.com");

            if (isCivicPlusStaff) {
                userRole = "CivicPlus Staff";
            } else if (organization?.userInOrganization || hasElevatedAccess) {
                userRole = "Customer Admin";
                portal_is_admin = true;
            } else {
                userRole = "Public User";
                portal_is_public = true;
            }

            const visitorData = {
                id: userAccountInfo?.userId,
                role: userRole,
                portal_is_admin,
                portal_is_superuser: isSuperUser,
                portal_is_staff: isCivicPlusStaff,
                portal_is_public
            };

            const accountData = {
                id: organization?.id ?? "",
                name: organization?.name,
                org_friendly_name: organization?.friendlyName ?? "",
                is_Portal: true
            };
            if (window.pendo) {
                if (!initializedPendo) {
                    setPendoOrgName(organization?.name);
                    window.pendo.initialize({
                        visitor: visitorData,
                        account: accountData,
                        disablePersistence: true
                    });
                } else if (pendoOrgName && pendoOrgName !== organization?.name) {
                    setPendoOrgName(organization?.name);
                    window.pendo.updateOptions({
                        visitor: visitorData,
                        account: accountData,
                        disablePersistence: true
                    });
                }
            }

            setInitializedPendo(true);
        };

        if (
            auth.user &&
            userAccountInfo &&
            organization &&
            !ignoredOrgsPendo.includes(organization.name.toLowerCase())
        ) {
            sendUserInfoToPendo();
        }
    }, [auth.user, organization, userAccountInfo, isSuperUser]);

    const orgName = organization?.name || currentLocation?.name;

    const buttons = useMemo(() => {
        // If an org is selected
        let orgAppNavBtns: NavigationRailButton[] = [];
        if ((organization || currentLocation) && orgFlagsLoaded) {
            if (organization) {
                orgAppNavBtns = getOrgApplicationNavButtons(
                    organization,
                    RouterLink,
                    flags,
                    classes.navIcon,
                    classes.hideNavOption
                );
            } else if (currentLocation) {
                orgAppNavBtns = getOrgApplicationNavButtons(
                    currentLocation as Organization,
                    RouterLink,
                    flags,
                    classes.navIcon,
                    classes.hideNavOption
                );
            }
            if (!flags.showSettingsNavigation) {
                orgAppNavBtns.push({
                    label: "Dashboard",
                    buttonProps: {
                        id: "Dashboard",
                        to: `/${orgName}/dashboard`,
                        component: RouterLink
                    },
                    icon: <ViewDashboardIcon />
                });

                orgAppNavBtns.push({
                    label: "Alerts & Notifications",
                    buttonProps: {
                        id: "AlertsAndNotifications",
                        to: `/${orgName}/notifications`,
                        component: RouterLink
                    },
                    icon: <AlertIcon />
                });

                orgAppNavBtns.push({
                    label: "Legislation",
                    buttonProps: {
                        id: "Legislation",
                        to: `/${orgName}/legislation`,
                        component: RouterLink
                    },
                    icon: <LegislationIcon />
                });

                orgAppNavBtns.push({
                    label: "Forms",
                    buttonProps: {
                        id: "Forms",
                        to: `/${orgName}/forms`,
                        component: RouterLink
                    },
                    icon: <FormIcon />
                });

                orgAppNavBtns.push({
                    label: "Meetings",
                    buttonProps: {
                        id: "Meetings",
                        to: `/${orgName}/meetings`,
                        component: RouterLink
                    },
                    icon: <MeetingsIcon />
                });

                orgAppNavBtns.push(
                    {
                        label: "Links",
                        buttonProps: {
                            id: "Links",
                            to: `/${orgName}/links`,
                            component: RouterLink
                        },
                        icon: <WebIcon />
                    },
                    {
                        label: "Payments",
                        buttonProps: {
                            id: "Payments",
                            to: `/${orgName}/payments`,
                            component: RouterLink
                        },
                        icon: <CashMultipleIcon />
                    }
                );
            }

            orgAppNavBtns.sort((a, b) =>
                a.label === "Dashboard" || b.label === "Dashboard" ? 2 : a.label > b.label ? 1 : -1
            );

            if (auth.isAuthenticated && hasElevatedAccess) {
                orgAppNavBtns.push({
                    label: "Settings",
                    isAdmin: true,
                    buttonProps: {
                        id: "Settings",
                        ...(isEmbed
                            ? {
                                  target: "_blank",
                                  href: `${config.getPortalBaseUrl()}/${organization?.name}/settings`
                              }
                            : {
                                  to: `/${orgName}/settings`,
                                  component: RouterLink
                              })
                    },
                    icon: <CogIcon />
                });
            }
        } else {
            // Need to add dashboard even when no organization.
            orgAppNavBtns.push({
                label: "Dashboard",
                buttonProps: {
                    id: "Dashboard",
                    to: ``,
                    component: RouterLink
                },
                icon: <ViewDashboardIcon />
            });
        }
        return orgAppNavBtns;
    }, [
        orgName,
        organization,
        currentLocation,
        flags,
        orgFlagsLoaded,
        auth.isAuthenticated,
        hasElevatedAccess,
        isEmbed,
        config,
        classes.navIcon,
        classes.hideNavOption
    ]);

    const onNavChange = useCallback(
        (selected: number | undefined) => {
            if (selected === undefined) return;
            if (selected) setActiveBtn(selected);

            if (!isStaffUser(auth.user)) {
                GAcontentSelected("section", buttons[selected].label, orgName);
            }

            if (router.pathname.includes(buttons[selected]?.label)) {
                setActiveBtn(selected);
            }

            setIsAdminPage(buttons[selected].isAdmin === true);
        },
        [auth.user, buttons, orgName, router.pathname]
    );

    const accountMenuOptions = useMemo(() => {
        type ExtractUnknownType<T> = T extends { component: unknown } ? T : never;

        const menuItems: MenuItemStaticTypes[] = [
            {
                children: "My Account",
                component: "a",
                icon: <OpenInNewTab fontSize="small" data-testid="my-account-external" />,
                iconLocation: "right",
                href: `${config.accountServiceBaseUrl}/personal`,
                target: "_blank",
                onClick: () => {
                    enqueueSnackbar("You may need to refresh your browser to see updated information", {
                        variant: "warning",
                        persist: true,
                        preventDuplicate: true,
                        action: (
                            <ButtonGroup>
                                <Button
                                    variant="outlined"
                                    id="refresh-page-btn"
                                    data-testid="refresh-page-btn"
                                    onClick={() => window.location.reload()}
                                    size="small"
                                >
                                    Refresh
                                </Button>

                                <Button
                                    id="dismiss-message-btn"
                                    data-testid="dismiss-message-btn"
                                    type="icon"
                                    size="small"
                                    onClick={() => closeSnackbar()}
                                    title="Dismiss message"
                                >
                                    <CloseIcon />
                                </Button>
                            </ButtonGroup>
                        )
                    });
                }
            },
            ...(hasElevatedAccess && (organization || currentLocation)
                ? [
                      {
                          children: "Portal Settings",
                          component: RouterLink,
                          to: `/${orgName}/settings`,
                          // to force the Popover to close after clicking the link
                          onClick: () => undefined
                      } as ExtractUnknownType<MenuItemStaticTypes>
                  ]
                : []),
            {
                children: "Sign Out",
                onClick: () => {
                    navigate("/signout");
                }
            }
        ];

        return {
            signoutUrl: "/signout",
            platformBaseUrl: `${config.getIdentityServerBaseUrl()}`,
            show: true,
            includeDefault: false,
            menuItems
        };
    }, [config, hasElevatedAccess, organization, currentLocation, enqueueSnackbar, closeSnackbar, navigate, orgName]);

    const helpMenuOptions = useMemo(() => {
        const menuItems = [
            {
                children: "Portal - Help Center",
                onClick: () => window.open(config.getHelpLink)
            }
        ];

        return {
            menuItems,
            show: true
        };
    }, [config.getHelpLink]);

    const customTopbar = useMemo(() => {
        try {
            localStorage?.removeItem("user_id");
        } catch (e) {
            console.error(e);
        }

        if (router.pathname === "/") {
            return <></>;
        }
        return (
            <>
                <div
                    style={{
                        flexGrow: 1,
                        textAlign: "center"
                    }}
                ></div>

                <div className={classes.searchContainer}>
                    <GlobalSearchInput />
                </div>
            </>
        );
    }, [router.pathname]);

    const authMenuOptions = useMemo(() => {
        return {
            show: !hasElevatedAccess,
            platformBaseUrl: `${config.getIdentityServerBaseUrl()}`,
            returnUrl: organization ? `${config.getPortalBaseUrl()}/${organization?.name}` : config.getPortalBaseUrl(),
            signInButton: {
                onClick: () => {
                    const args = {
                        state: { from: window.location.pathname }
                    } as SigninPopupArgs;
                    auth.signinPopup(setupAcrValue(auth, args, organization?.id));
                }
            }
        };
    }, [auth, config, hasElevatedAccess, organization]);

    const shouldHideOrgPicker = useMemo(() => {
        const isSuperUser = auth.user ? getIsSuperUser(auth.user, config.superUserRole) : false;

        if (isSuperUser) {
            return false;
        }

        return Boolean(organization);
    }, [auth.user, config.superUserRole, organization]);

    const onOrgSelect = useCallback(
        async (org: CppOrganization) => {
            navigate(`/${org.name}/dashboard`);
        },
        [navigate]
    );

    const orgService = useMemo(() => new PortalOrganizationService(auth.user), [auth.user]);

    useEffect(() => {
        if (window.parent && auth.isAuthenticated) {
            window.parent.postMessage({ type: "embededUserSignedIn", user: auth.user }, "*");
        }
    }, [auth.isAuthenticated, auth.user]);

    useEffect(
        function signInSilient() {
            if (!isEmbed && !auth.isAuthenticated) {
                auth.signinSilent().catch((e) => {
                    clearFavorites();
                });
            }
        },
        // Empty array as we only attempt sign in 1 time.
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    useEffect(() => {
        return auth.events.addAccessTokenExpired(() => {
            auth.clearStaleState();
            auth.removeUser();
        });
    }, [auth]);

    useEffect(() => {
        if (auth.isAuthenticated && auth.user && !isStaffUser(auth.user)) {
            GAuser(config.googleAnalyticsKey, auth.user.profile.sub);

            const userEmailStored = localStorage?.getItem("user_id");

            if (!userEmailStored || userEmailStored !== auth.user.profile.sub) {
                try {
                    localStorage?.setItem("user_id", auth.user.profile.sub as string);
                    GAlogin(isEmbed);
                } catch (e) {
                    console.error(e);
                }
            }
        }
    }, [auth.isAuthenticated, auth.user, config.googleAnalyticsKey, isEmbed]);

    useEffect(() => {
        const hasLocationOnPath = router?.pathname && router?.pathname.includes("[locationName]");

        const fetchLocations = async () => {
            const fetchedLocations: Location[] = [];
            auth.isAuthenticated && (await ApiService.get({ url: `location`, resetCache: true, authUser: auth.user }));

            dispatch({ type: LocationTypes.SET_LOCATION, value: fetchedLocations }); // reset value
            setUserLocations(fetchedLocations);

            if (!hasLocationOnPath && !currentLocation) {
                const defaultLoc = fetchedLocations.find((l) => l.default === true);
                if (defaultLoc) {
                    navigate(`/${defaultLoc.city}`);
                }
            }
        };

        if (auth.isAuthenticated && !userLocations) {
            fetchLocations();
        }
    }, [userLocations, dispatch, setUserLocations, currentLocation, auth.isAuthenticated, auth.user, router, navigate]);

    useEffect(() => {
        if (router?.pathname.toLowerCase() === "/") {
            setActiveBtn(0);
        } else if (pageOrganization) {
            const pathnameWithOrgName = router?.pathname
                .toLowerCase()
                ?.replace(pageOrganization.id.toLowerCase(), pageOrganization.name)
                .toLowerCase();

            if (
                pathnameWithOrgName.startsWith(`/${pageOrganization.name}/search`.toLowerCase()) ||
                pathnameWithOrgName.includes("/account")
            ) {
                setActiveBtn(undefined);
                return;
            }

            for (let i = 0; i < buttons.length; i++) {
                const button = buttons[i];
                if (button.buttonProps?.to && pathnameWithOrgName.includes(button.buttonProps.to.toLowerCase())) {
                    setActiveBtn(i);
                    setIsAdminPage(button.isAdmin === true);
                    return;
                }
            }
        }
    }, [buttons, pageOrganization, router.pathname]);

    return (
        <Page
            id="page-portal"
            title="CivicPlus Portal"
            orgServiceSdk={orgService}
            OrgSwitcherProps={{
                showGeolocation: false,
                showRecentOrganizations: true,
                hide: shouldHideOrgPicker,
                superUserRole: config.superUserRole
            }}
            accountMenuOptions={accountMenuOptions}
            helpMenuOptions={helpMenuOptions}
            appSwitcherOptions={{
                show: isDesktop && (organization?.userInOrganization || isSuperUser)
            }}
            authMenuOptions={authMenuOptions}
            customTopbar={customTopbar}
            navigationRailProps={
                hideNavigation
                    ? undefined
                    : {
                          className: clsx(classes.navRail, { [classes.navRailEmbed]: isEmbed }),
                          active: activeBtn,
                          buttons: buttons,
                          xs: 4,
                          onChange: onNavChange,
                          "aria-label": "CivicPlus Portal Navigation"
                      }
            }
            disableSidebar={true}
            disableTopbar={isEmbed}
            currentOrg={pageOrganization}
            specificity={isEmbed ? 3 : undefined}
            cssReset={isEmbed}
            prefix="cp-portal-embed"
            // todo: if we want to disable the app switcher, we still need to pass this prop. Fix this on Preamble
            disableProductList={true}
            productNavSections={productNavSections}
            className={clsx(classes.page, { [classes.pageEmbed]: isEmbed, [classes.pageHideNav]: hideNavigation })}
            onOrgSelect={onOrgSelect}
            type={isAdminPage ? "internal" : "external"}
            showOrgTitle={isSuperUser ? "never" : "desktop"}
            product="residentPortal"
            SnackbarProviderProps={SnackbarProviderProps}
        >
            {children}
        </Page>
    );
};

export default Navbar;
