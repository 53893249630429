import React, { useState } from "react";
import ApiService, { ApiError, parseErrorMessage } from "../../../services/apiService";
import Button from "@civicplus/preamble-ui/lib/Button";
import Checkbox from "@civicplus/preamble-ui/lib/Checkbox";
import enhanceWithValidation, { geoLocationRequireValidation } from "@civicplus/preamble-ui/lib/Validations";
import Grid from "@civicplus/preamble-ui/lib/Grid";
import TextInput from "@civicplus/preamble-ui/lib/TextInput";
import { softMaxLengthValidation } from "../../../shared/customValidation";
import { useAuth } from "../../../providers/AuthProvider";
import { useSnackbar } from "notistack";
import { AddressOrLocation, emptyAddress, USstatePattern, USzipCodePattern } from "./AddressUtils";
import Dialog from "@civicplus/preamble-ui/lib/Dialog";
import { useConfig } from "../../../providers/ConfigProvider";
import { makeStyles } from "@civicplus/preamble-ui/lib/Utilities/ThemeHelper";
import Geolocation from "@civicplus/preamble-ui/lib/Geolocation";
import { isNullOrWhiteSpace } from "@civicplus/preamble-ui/lib/Utilities/StringHelper";
import { PhysicalAddress } from "./PhysicalAddressList";
import Typography from "@civicplus/preamble-ui/lib/Typography";
import { DirtyStateDialog } from "../../DirtyState";

const EnhancedTextInput = enhanceWithValidation(TextInput);
const EnhancedGeolocation = enhanceWithValidation(Geolocation);

const useStyles = makeStyles((theme) => ({
    geoLocation: {
        backgroundColor: theme.palette.background.paper,

        "&:hover": {
            borderColor: theme.palette.primary.main
        }
    }
}));

interface AddAddressDialogProps {
    finishedAddAddressAction: (loading: boolean, newAddress?: PhysicalAddress) => void;
    existingNumberCount: number;
    show: boolean;
}

export type InvalidFields = {
    city: boolean;
    state: boolean;
    zip: boolean;
}

const AddAddressDialog: React.FC<AddAddressDialogProps> = (props) => {
    const { finishedAddAddressAction, existingNumberCount, show } = props;
    const auth = useAuth();
    const classes = useStyles();
    const config = useConfig();
    const { enqueueSnackbar } = useSnackbar();

    const [addressValues, setAddressValues] = useState<AddressOrLocation>({
        ...emptyAddress,
        label: `Address ${existingNumberCount + 1}`
    });

    const [hasInvalidFields, setHasInvalidFields] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isLeavingWithoutSaving, setIsLeavingWithoutSaving] = useState<boolean>(false);
    const [isDirty, setIsDirty] = useState<boolean>(false);

    const allValues = {
        address1: addressValues.address1,
        address2: addressValues.address2,
        city: addressValues.city,
        state: addressValues.state,
        zip: addressValues.zipCode,
        country: addressValues.country
    };

    const geoProps = {
        className: classes.geoLocation,
        id: "geolocation-with-map",
        editor: "Map",
        googleMapsKey: config?.googleMapsKey,
        validateFields: {
            validateAddress1: false,
            validateAddress2: false,
            validateCity: true,
            validateState: true,
            validateZipCode: true
        },
        capitalizeState: true,
        statePattern: addressValues.country?.value === "US" ? USstatePattern : undefined,
        statePatternMessage: "This value does not conform to a U.S. state.", // switch case for international message?
        zipCodePattern: addressValues.country?.value === "US" ? USzipCodePattern : undefined,
        zipCodePatternMessage: "This value does not match a valid U.S. zip code.",
        error: false,
        onClear: () => true,
        onValidation: (invalidFields: InvalidFields) => {
            if (isNullOrWhiteSpace(addressValues.city)) {
                invalidFields.city = true;
            }
            if (invalidFields.city || invalidFields.state || invalidFields.zip) {
                setHasInvalidFields(true);
            } else {
                setHasInvalidFields(false);
            }
        },
        onChange: ({ address1, address2, city, state, zip, latitude, longitude, searchedAddress, country }: any) => {
            setIsDirty(true);
            setAddressValues({
                ...addressValues,
                address1: address1,
                address2: address2,
                city: city,
                state: state,
                zipCode: zip,
                latitude: latitude,
                longitude: longitude,
                fullAddress: addressValues.fullAddress ? addressValues.fullAddress : searchedAddress,
                country: country
            });
        },
        fields: {
            showLabel: false,
            showAddress1: true,
            showAddress2: true,
            showCity: true,
            showState: true,
            showZipCode: true,
            showCountry: true
        },
        value: allValues
    };

    const handleCancel = () => {
        if (isDirty) {
            setIsLeavingWithoutSaving(true);
        } else {
            setAddressValues(emptyAddress);
            finishedAddAddressAction(false);
        }
    };

    const onCustomLabelChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        setIsDirty(true);
        if (e && e.currentTarget) {
            setAddressValues({ ...addressValues, label: e.currentTarget?.value });
        }
    };

    const onShippingAddressChanged = (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        setAddressValues({ ...addressValues, isShippingAddress: checked });
    };

    const handleSubmit = async () => {
        if (!addressValues.city || !addressValues.state || !addressValues.zipCode) {
            enqueueSnackbar("City, State and Zip Code must be valid to continue.", {
                variant: "error",
                persist: true
            });
            return;
        }

        if (hasInvalidFields) {
            enqueueSnackbar("All fields must be valid to continue.", {
                variant: "error",
                persist: true
            });
            return;
        }

        const postBody = {
            address1: addressValues.address1,
            address2: addressValues.address2,
            city: addressValues.city,
            fullAddress: addressValues.fullAddress,
            isShippingAddress: addressValues.isShippingAddress,
            label: addressValues.label,
            latitude: addressValues.latitude,
            longitude: addressValues.longitude,
            addressType: "Building",
            country: addressValues.country?.value,
            countryLabel: addressValues.country?.label,
            governingDistrict: addressValues.state,
            localMunicipality: "",
            postalArea: addressValues.zipCode
        };

        try {
            setIsLoading(true);

            await ApiService.post({
                url: `user/account/physicalAddress`,
                authUser: auth.user,
                requestInit: {
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(postBody)
                }
            })
                .then((response) => {
                    enqueueSnackbar("Address saved successfully!", {
                        variant: "success"
                    });

                    if (response) {
                        setAddressValues((prevAddress) => ({ ...prevAddress, id: response.id }));

                        finishedAddAddressAction(false, response);
                    } else {
                        finishedAddAddressAction(false);
                    }
                    return true;
                })
                .catch((error) => {
                    const message = parseErrorMessage(error as ApiError, "Failed to save a new address.");

                    enqueueSnackbar(message, { variant: "error", persist: true });
                    finishedAddAddressAction(false);
                });
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <Dialog
                open={show}
                onClose={handleCancel}
                title="Add Address/Location"
                actions={[
                    <Button key="save-changes" color="primary" onClick={handleSubmit} isLoading={isLoading}>
                        Save Changes
                    </Button>,
                    <Button key="cancel" data-testid="cancel-add-address" onClick={handleCancel}>
                        Cancel
                    </Button>
                ]}
            >
                <Grid container={true}>
                    <Grid xs={12}>
                        <Typography variant="subtitle1" style={{ margin: "15px 0" }}>
                            Type in your address in the Google Maps field below and select from the drop down as the
                            list populates to choose the correct one. If the selector does not find your address, try
                            typing in your city name or zip code. A location must be selected in order to continue.
                        </Typography>
                        <EnhancedTextInput
                            id="addresslabel"
                            label="Address Label"
                            value={addressValues.label}
                            onChange={onCustomLabelChanged}
                            fullWidth={true}
                            softMaxLength={30}
                            validations={[softMaxLengthValidation]}
                        />
                    </Grid>

                    <EnhancedGeolocation validations={[geoLocationRequireValidation]} {...geoProps} />

                    <Grid container={true}>
                        <Grid xs={12} md="auto">
                            <Checkbox
                                id="isShipping"
                                onChange={onShippingAddressChanged}
                                checked={addressValues.isShippingAddress}
                                size="small"
                                label={`Shipping Address is the same as ${
                                    addressValues.label || emptyAddress.label || "Primary Address or Location"
                                }`}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Dialog>
            <DirtyStateDialog
                warningText="Are you sure you want to discard changes for Address?"
                isOpen={isLeavingWithoutSaving}
                onReturnToContent={() => {
                    setIsLeavingWithoutSaving(false);
                }}
                onDiscardChanges={() => {
                    setIsLeavingWithoutSaving(false);
                    setIsDirty(false);
                    setAddressValues(emptyAddress);
                    finishedAddAddressAction(false);
                }}
            />
        </>
    );
};

export default AddAddressDialog;
