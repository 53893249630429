import React from "react";
import classNames from "classnames";
import Grid from "@civicplus/preamble-ui/lib/Grid";
import Typography from "@civicplus/preamble-ui/lib/Typography";
import { PhysicalAddress } from "./PhysicalAddressList";
import { makeStyles } from "@civicplus/preamble-ui/lib/Utilities/ThemeHelper";

const useStyles = makeStyles((theme) => ({
    card: {
        padding: theme.spacing(2),
        border: `1px solid ${theme.palette.divider}`
    },
    disabled: {
        opacity: theme.palette.action.disabledOpacity
    }
}));

export type PhysicalAddressListItemProps = PhysicalAddress;

export const PhysicalAddressListItem: React.FC<PhysicalAddressListItemProps> = (props) => {
    const { label, id, fullAddress } = props;
    const classes = useStyles();

    return (
        <section className={classNames(classes.card)}>
            <Grid container={true} spacing={2}>
                <Grid xs={8} sm="auto">
                    <Grid container={true} alignItems="center">
                        <Grid>
                            <Typography id={`item-${id}-name`} component="h3" variant="h6">
                                {label}
                            </Typography>
                        </Grid>
                    </Grid>

                    <Typography color="textSecondary">{fullAddress}</Typography>
                </Grid>
            </Grid>
        </section>
    );
};

export default PhysicalAddressListItem;
