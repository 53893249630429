import React from "react";
import ApiService from "./services/apiService";
import ErrorBoundary from "./components/ErrorBoundary";
import Router from "./Router";
import { AuthProviderWrapper } from "./providers/AuthProvider";
import { BrowserRouter, MemoryRouter } from "react-router-dom";
import { ConfigProvider } from "./providers/ConfigProvider";
import { FeatureFlagsProvider } from "./shared/useFeatureFlags";
import { Helmet } from "react-helmet";
import { Provider as StateProvider } from "./types/LocationTypes";
import { UserAccountInfoProvider } from "./hooks/useAccountInfo";
import "./shared/globalStyles.css";
import ProductThemeProvider from "@civicplus/preamble-ui/lib/ProductThemeProvider";
import SnackbarProviderProps from "./components/SnackBar/config";

interface IAppProps {
    isEmbed?: boolean;
    baseUrl?: string;
    organizationName?: string;
    startingPath?: string;
    defaultSection?: string;
    isUserSignedIn?: boolean;
    hideNavigation?: boolean;
}

const App: React.FC<IAppProps> = (
    appProps: IAppProps = { isEmbed: false, isUserSignedIn: false, hideNavigation: false }
) => {
    const { isEmbed, baseUrl } = appProps;

    ApiService.init(baseUrl ?? window.location.origin);

    const ReactRouter = isEmbed ? MemoryRouter : BrowserRouter;
    const IFrameComponent = <iframe style={{ display: "none" }} title="embedSignIn" src={`${baseUrl}/embedSignIn`} />;

    return (
        <div className="App">
            {isEmbed ? (
                IFrameComponent
            ) : (
                <Helmet>
                    <script
                        id="frase-script"
                        data-hash="9f0c666c71794943be2f15c79d2cd02b"
                        src="https://app.frase.io/js/libraries/bot.js"
                        defer={true}
                    />
                    <script type="text/javascript">
                        {`
                        var script = document.querySelector('#frase-script');
                        if(script){
                            script.addEventListener('load', function() {
                                if (window && window.frase) {
                                    const fraseChatbot = window.frase;
                                    const chatbotEl = document.getElementById("frase-widget-container");
                                    fraseChatbot.on("ready", function () {
                                        chatbotEl.classList.add("prmbl-hasNavBarMobile");
                                    });
                                    fraseChatbot.on("frase_sidebar", function () {
                                        chatbotEl.classList.remove("prmbl-hasNavBarMobile");
                                    });
                                    fraseChatbot.on("frase_sidebar_close", function () {
                                        chatbotEl.classList.add("prmbl-hasNavBarMobile");
                                    });
                                    if(fraseChatbot.active === false){
                                        chatbotEl.style.display = "none";
                                    }
                                }
                            });
                        }
                        `}
                    </script>

                    <style type="text/css">
                        {`
                        @media only screen and (max-width: 959px) {
                            .prmbl-hasNavBarMobile {
                                bottom: 110px !important;
                            }
                        }
                        `}
                    </style>
                </Helmet>
            )}

            <ConfigProvider {...appProps}>
                <AuthProviderWrapper>
                    <StateProvider>
                        <FeatureFlagsProvider>
                            <ProductThemeProvider
                                specificity={isEmbed ? 3 : undefined}
                                cssReset={isEmbed}
                                prefix="cp-portal-embed-wrapper"
                                SnackbarProviderProps={SnackbarProviderProps}
                            >
                                <ReactRouter>
                                    <ErrorBoundary>
                                        <UserAccountInfoProvider>
                                            <Router />
                                        </UserAccountInfoProvider>
                                    </ErrorBoundary>
                                </ReactRouter>
                            </ProductThemeProvider>
                        </FeatureFlagsProvider>
                    </StateProvider>
                </AuthProviderWrapper>
            </ConfigProvider>
        </div>
    );
};

export default App;
