import { User as AuthUser } from "oidc-client-ts";
import { IUser } from "@civicplus/preamble-ui/lib/entities/baseEntities";

class ApiServiceClass {
    private initialized = false;
    private baseUrl: string | undefined;

    private requestCache = new Map<string, Promise<any>>();

    public init = (baseUrl: string): void => {
        if (this.initialized) return;
        this.baseUrl = baseUrl;
        this.initialized = true;
    };

    public isInitialized = (): void => {
        if (this.initialized) return;
        throw new Error("Api Service not initialized");
    };

    public get = async ({
        url,
        cache = true,
        resetCache = false,
        authUser,
        controller
    }: {
        url: string;
        cache?: boolean;
        resetCache?: boolean;
        authUser?: AuthUser | IUser | null;
        controller?: AbortController;
    }): Promise<any> => {
        this.isInitialized();
        let userKey = "";
        if (authUser && "profile" in authUser) {
            userKey = (authUser as AuthUser).profile.sub;
        }
        if (authUser && "id" in authUser) {
            userKey = (authUser as IUser).id ?? "";
        }

        const cacheKey = `${url.toLowerCase()}_${userKey}`;
        const cached = this.requestCache.get(cacheKey);

        if (cached && !resetCache) {
            return cached;
        }

        const promise = this.fetch({ url, authUser, controller });

        if (cache) {
            this.requestCache.set(cacheKey, promise);
        }

        return promise;
    };

    public post = async ({
        url,
        requestInit,
        authUser
    }: {
        url: string;
        requestInit?: RequestInit | undefined;
        authUser?: AuthUser | null;
    }): Promise<any> => {
        this.isInitialized();
        const init: RequestInit = requestInit || {};
        init.method = "POST";

        return await this.fetch({ url, requestInit: init, authUser });
    };

    public put = async ({
        url,
        requestInit,
        authUser,
        returnCompleteResponse
    }: {
        url: string;
        requestInit?: RequestInit | undefined;
        authUser?: AuthUser | null;
        returnCompleteResponse?: boolean | undefined;
    }): Promise<any> => {
        this.isInitialized();
        const init: RequestInit = requestInit || {};
        init.method = "PUT";

        const response = await this.fetch({ url, requestInit: init, authUser, returnCompleteResponse });

        if (this.requestCache.has(url.toLowerCase())) {
            this.requestCache.delete(url.toLowerCase());
        }

        return response;
    };

    public patch = async ({
        url,
        requestInit,
        authUser,
        returnCompleteResponse
    }: {
        url: string;
        requestInit?: any | undefined;
        authUser?: AuthUser | null;
        returnCompleteResponse?: boolean | undefined;
    }): Promise<any> => {
        this.isInitialized();
        const init: RequestInit = requestInit || {};
        init.method = "PATCH";

        return await this.fetch({ url, requestInit: init, authUser, returnCompleteResponse });
    };

    public delete = async ({
        url,
        requestInit,
        authUser
    }: {
        url: string;
        requestInit?: RequestInit | undefined;
        authUser?: AuthUser | null;
    }): Promise<any> => {
        this.isInitialized();
        const init: RequestInit = requestInit || {};
        init.method = "DELETE";

        return await this.fetch({ url, requestInit: init, authUser });
    };

    private fetch = async ({
        url,
        requestInit,
        authUser,
        returnCompleteResponse,
        controller
    }: {
        url: string;
        requestInit?: RequestInit | undefined;
        authUser?: AuthUser | IUser | null;
        returnCompleteResponse?: boolean | undefined;
        controller?: AbortController;
    }): Promise<unknown> => {
        this.isInitialized();
        url = `${this.baseUrl}/api/${url}`;

        const init: RequestInit = requestInit || {};
        init.headers = new Headers(init.headers);
        init.headers.set("Access-Control-Allow-Origin", "*");

        const isAuthUser = authUser && "access_token" in authUser;
        const userAccessToken = isAuthUser ? authUser.access_token : authUser?.accessToken;

        if (authUser && userAccessToken) {
            init.headers.set("Authorization", `Bearer ${userAccessToken}`);
        }

        if (controller) {
            init.signal = controller.signal;
        }

        try {
            const result = await fetch(url, init);
            let data = undefined;

            try {
                data = await result.json();
            } catch {
                if (returnCompleteResponse) {
                    data = result;
                } else {
                    data = false;
                }
            }

            if (!result.ok) {
                const error: ApiError = {
                    info: data,
                    status: result.status,
                    message: "An error occured while fetching the data.",
                    name: "ApiError"
                };
                throw error;
            }

            return data;
        } catch (ex) {
            if (ex instanceof DOMException && ex.name === "AbortError") {
                // Rethrow abort errors without logging
                throw ex;
            }
            console.error(ex);
            const error = ex as ApiError;
            throw error;
        }
    };

    public resetCache = () => {
        this.requestCache = new Map<string, Promise<any>>();
    };
}

export interface ApiError extends Error {
    message: string;
    info: string | boolean | ProblemDetails;
    status: number;
}

export interface ErrorsObject {
    [Key: string]: string[];
}

export interface ProblemDetails {
    error: string; // Never used?
    errors?: ErrorsObject;
    exceptionType: string; // Never set?
    status: number;
    title: string;
    detail: string | null;
    type: string;
}

export function parseErrorMessage(error: ApiError, defaultMessage?: string): string {
    const details = error.info as ProblemDetails;
    if (details !== null && details !== undefined) {
        let message = "";
        if (details.detail) {
            message = details.detail;
        }
        if (details.title) {
            message += "\r\n " + details.title;
        }

        if (details.errors) {
            for (const [_, value] of Object.entries(details.errors)) {
                message += "\r\n " + value[0];
            }
        }
        return message;
    }

    return defaultMessage ?? error.message;
}

const ApiService = new ApiServiceClass();

if (process.env.NODE_ENV !== "test") {
    Object.seal(ApiService);
}

export enum JSONPatchOperationType {
    Add = "add",
    Remove = "remove",
    Replace = "replace",
    Copy = "copy",
    Move = "move",
    Test = "test"
}

export interface JSONPatchOperation {
    op: JSONPatchOperationType;
    path: string;
    value: string;
}

export default ApiService;
