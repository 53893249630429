/* eslint-disable @typescript-eslint/no-explicit-any */
import { User } from "oidc-client-ts";
import ApiService from "./apiService";
import { AuthProviderProps } from "../components/react-oidc-context";

export class AppConfig {
    private _organizationServiceBaseUrl = "";
    private _gaKey = "";
    private _notificationsUrl = "";
    private _identityServerBaseUrl = "";
    private _postLogoutRedirectUri = "";
    private _authority = "";
    private _config: AuthProviderProps | null = null;
    private _helpLink = "";
    private _portalBaseUrl = "";
    private _gainsightKey = "";
    private _pendoKey = "";
    private _superUserRole = "";
    private _accountServiceBaseUrl = "";
    private _numberOfPhoneNumbersAllowed = 5;
    private _numberOfAddressesAllowed = 1;
    private _googleMapsKey = "";

    public isInitialized(): boolean {
        return this._config != null;
    }

    initAsync = async (): Promise<void> => {
        if (!this._config) {
            const data = await ApiService.get({ url: "config", cache: true });

            const config: AuthProviderProps = {
                authority: `${data.authority}`,
                client_id: `${data.client}`,
                redirect_uri: `${data.postLoginRedirectUri}`,
                response_type: `${data.responseType}`,
                scope: `${data.scope}`,
                post_logout_redirect_uri: `${data.postLogoutRedirectUri}`,
                automaticSilentRenew: true,
                filterProtocolClaims: true,
                loadUserInfo: true,
                response_mode: "query",
                onSigninCallback: this.onSigninCallback
            };

            this._organizationServiceBaseUrl = data.organizationServiceUrl;
            this._notificationsUrl = data.notificationsUrl;
            this._gaKey = data.gaKey;
            this._identityServerBaseUrl = data.identityServerBaseUrl;
            this._postLogoutRedirectUri = data.postLogoutRedirectUri;
            this._authority = data.authority;
            this._config = config;
            this._helpLink = data.helpCenterLink;
            this._portalBaseUrl = data.portalBaseUrl;
            this._gainsightKey = data.gainsightKey;
            this._pendoKey = data.pendoKey;
            this._superUserRole = data.superUserRoleName;
            this._accountServiceBaseUrl = data.accountServiceBaseUrl;
            this._numberOfPhoneNumbersAllowed = data.numberOfPhoneNumbersAllowed;
            this._numberOfAddressesAllowed = data.numberOfAddressesAllowed;
            this._googleMapsKey = data.googleMapsKey;
        }
    };

    private onSigninCallback = (_user: any): void => {
        const from = _user.state.from;
        const fromEmbed = _user.state.isEmbed;
        if (fromEmbed === true) {
            window.opener.postMessage({ type: "embededUserSignedIn", user: _user }, "*");
            window.close();
        } else {
            window.history.replaceState({}, document.title, from);
            window.location.assign(from);
        }
    };

    getOrgServiceBaseUrl = (): string => {
        return this._organizationServiceBaseUrl;
    };

    public get notificationsUrl(): string {
        return this._notificationsUrl;
    }

    getPortalBaseUrl = (): string => this._portalBaseUrl;

    getIdentityServerBaseUrl = (): string => this._identityServerBaseUrl;

    getPostLogoutRedirectUrl = (): string => this._postLogoutRedirectUri;

    getAuthority = (): string => this._authority;

    getSignoutUrl = (user: User, orgId: string): string => {
        return `${this.getAuthority()}/connect/endsession?post_logout_redirect_uri=${this.getPostLogoutRedirectUrl()}&id_token_hint=${
            user.id_token
        }&state=${orgId}`;
    };

    getResetPasswordUrl = (): string => {
        return `${this.getIdentityServerBaseUrl()}/Identity/Account/Manage/ChangePassword`;
    };

    getSettingsUrl = (): string => {
        return `${this.getIdentityServerBaseUrl()}/Identity/Account/Manage`;
    };

    public get authConfig(): AuthProviderProps {
        return this._config!;
    }

    public get googleAnalyticsKey(): string {
        return this._gaKey;
    }

    public get gainsightKey(): string {
        return this._gainsightKey;
    }

    public get pendoKey(): string {
        return this._pendoKey;
    }

    public get getHelpLink(): string {
        return this._helpLink;
    }

    public get superUserRole(): string {
        return this._superUserRole;
    }

    public get accountServiceBaseUrl(): string {
        return this._accountServiceBaseUrl;
    }

    public get googleMapsKey(): string {
        return this._googleMapsKey;
    }

    public get  allowedPhoneNumbers(): number {
        return this._numberOfPhoneNumbersAllowed;
    }

    public get  allowedAddresses(): number {
        return this._numberOfAddressesAllowed;
    }
}
