import React from "react";
import Card from "@civicplus/preamble-ui/lib/Card";
import { formatDate } from "@civicplus/preamble-ui/lib/Utilities/DateHelper";
import SkeletonLoader from "@civicplus/preamble-ui/lib/SkeletonLoader";
import { makeStyles } from "@civicplus/preamble-ui/lib/Utilities/ThemeHelper";
import PublicationContent from "./publicationContent";
import Link from "../../../../components/link";
import { SearchDocument } from "../../../../types/SearchDocument";
import { ChangesType, LegislationType } from "../types";

const useStyles = makeStyles((theme) => ({
    cardTitle: {
        margin: 0
    }
}));

export type PublicationItemProps = {
    ordinances?: LegislationType[];
    recentChanges?: ChangesType[];
} & Partial<SearchDocument>;

export const PublicationItem: React.FC<PublicationItemProps> = (props) => {
    const { lastModifiedDate, title, url, ordinances, recentChanges } = props;
    const classes = useStyles();
    return (
        <Card
            id={`publication-${title}`}
            margin={0}
            title={
                <Link href={url} target="_blank" color="inherit" id={`publication-link-${title}`}>
                    {title ? title : <SkeletonLoader width={200} />}
                </Link>
            }
            subheader={
                <>
                    Online Content updated on{" "}
                    {lastModifiedDate ? formatDate(lastModifiedDate, "MMMM dd, yyyy") : <SkeletonLoader width={100} />}
                </>
            }
            content={<PublicationContent legislationItems={ordinances} changesItems={recentChanges} />}
            cardHeaderProps={{
                component: "h3",
                className: classes.cardTitle
            }}
        />
    );
};

export default PublicationItem;
