export enum Sections {
    Links = "General",
    Community = "Community",
    Forms = "Forms",
    Notifications = "Notifications",
    Payments = "Payments",
    Services = "Services",
    Support = "Support",

    AcademicEnrollment = "Academic Enrollment",
    AlertsAndMessages = "Alerts and Messages",
    ApplicationsAndPermits = "Applications and Permits",
    Contact = "Contact",
    Donations = "Donations",
    EventRegistration = "Event Registration",
    Featured = "Featured Links",
    Documents = "Documents",
    GetInvolved = "Get Involved",
    Help = "Help",
    NewsAndEvents = "News and Events",
    OnlineRequest = "Online Request",
    ReportAnIssue = "Report an Issue",
    Utilities = "Utilities",
    Various = "Various",
    Meetings = "Meetings"
}

export interface SectionOption {
    label: string;
    value: string | number;
}

export interface SectionObject {
    [key: string]: string;
}

export const FormsSections: SectionObject = Object.freeze({
    Forms: Sections.Forms
});

export const NotificationsSections: SectionObject = Object.freeze({
    Notifications: Sections.Notifications
});

export const PaymentsSections: SectionObject = Object.freeze({
    Payments: Sections.Payments,
    Donations: Sections.Donations,
    Utilities: Sections.Utilities,
    Various: Sections.Various
});

export const CommunitySections: SectionObject = Object.freeze({
    Community: Sections.Community,
    GetInvolved: Sections.GetInvolved,
    NewsAndEvents: Sections.NewsAndEvents,
    ReportAnIssue: Sections.ReportAnIssue
});

export const ServiceSections: SectionObject = Object.freeze({
    Services: Sections.Services,
    AcademicEnrollment: Sections.AcademicEnrollment,
    AlertsAndMessages: Sections.AlertsAndMessages,
    ApplicationsAndPermits: Sections.ApplicationsAndPermits,
    EventRegistration: Sections.EventRegistration,
    Documents: Sections.Documents,
    OnlineRequest: Sections.OnlineRequest
});

export const SupportSections: SectionObject = Object.freeze({
    Support: Sections.Support,
    Contact: Sections.Contact,
    Help: Sections.Help
});

export const LinksSections: SectionObject = Object.freeze({
    Links: Sections.Links
});

export const MeetingsSections: SectionObject = Object.freeze({
    Meetings: Sections.Meetings
});

export const LinksSectionOptions = Object.freeze([
    {
        label: "Links",
        options: Object.keys(LinksSections).map((x: string) => {
            const key = x as keyof typeof LinksSections;
            return { label: LinksSections[key], value: key };
        })
    },
    {
        label: "Payments",
        options: Object.keys(PaymentsSections).map((x: string) => {
            const key = x as keyof typeof PaymentsSections;
            return { label: PaymentsSections[key], value: key };
        })
    },
    {
        label: "Community",
        options: Object.keys(CommunitySections).map((x: string) => {
            const key = x as keyof typeof CommunitySections;
            return { label: CommunitySections[key], value: key };
        })
    },
    {
        label: "Services",
        options: Object.keys(ServiceSections).map((x: string) => {
            const key = x as keyof typeof ServiceSections;
            return { label: ServiceSections[key], value: key };
        })
    },
    {
        label: "Support",
        options: Object.keys(SupportSections).map((x: string) => {
            const key = x as keyof typeof SupportSections;
            return { label: SupportSections[key], value: key };
        })
    }
]);

export const FormsSectionOptions = Object.freeze([
    {
        label: "Forms",
        options: Object.keys(FormsSections).map((x: string) => {
            const key = x as keyof typeof FormsSections;
            return { label: FormsSections[key], value: key };
        })
    }
]);

export const NotificationsSectionOptions = Object.freeze([
    {
        label: "Notifications",
        options: Object.keys(NotificationsSections).map((x: string) => {
            const key = x as keyof typeof NotificationsSections;
            return { label: NotificationsSections[key], value: key };
        })
    }
]);

export const AllSectionOptions = Object.freeze([
    {
        label: "Links",
        options: Object.keys(LinksSections).map((x: string) => {
            const key = x as keyof typeof LinksSections;
            return { label: LinksSections[key], value: key };
        })
    },
    {
        label: "Payments",
        options: Object.keys(PaymentsSections).map((x: string) => {
            const key = x as keyof typeof PaymentsSections;
            return { label: PaymentsSections[key], value: key };
        })
    },
    {
        label: "Forms",
        options: Object.keys(FormsSections).map((x: string) => {
            const key = x as keyof typeof FormsSections;
            return { label: FormsSections[key], value: key };
        })
    },
    {
        label: "Notifications",
        options: Object.keys(NotificationsSections).map((x: string) => {
            const key = x as keyof typeof NotificationsSections;
            return { label: NotificationsSections[key], value: key };
        })
    },
    {
        label: "Meetings",
        options: Object.keys(MeetingsSections).map((x: string) => {
            const key = x as keyof typeof MeetingsSections;
            return { label: MeetingsSections[key], value: key };
        })
    },
    {
        label: "Community",
        options: Object.keys(CommunitySections).map((x: string) => {
            const key = x as keyof typeof CommunitySections;
            return { label: CommunitySections[key], value: key };
        })
    },
    {
        label: "Services",
        options: Object.keys(ServiceSections).map((x: string) => {
            const key = x as keyof typeof ServiceSections;
            return { label: ServiceSections[key], value: key };
        })
    },
    {
        label: "Support",
        options: Object.keys(SupportSections).map((x: string) => {
            const key = x as keyof typeof SupportSections;
            return { label: SupportSections[key], value: key };
        })
    }
]);

// ("Report an Issue") => returns "ReportAnIssue"
export const getSectionKeyFromValue = (value: string): string | undefined => {
    const key = Object.keys(Sections).find((x) => Sections[x as keyof typeof Sections] === value);
    return key;
};
