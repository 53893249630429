import React, { useMemo } from "react";
import Autocomplete, { OptionShape } from "@civicplus/preamble-ui/lib/Autocomplete";
import { ILanguageOption, IKonexusLanguages } from "../AlertsSubscriptionDialog";

export interface LanguageItemsProps {
    written?: ILanguageOption;
    spoken?: ILanguageOption;
    disabled?: boolean;
    onChangeWritten?: (language: OptionShape) => void;
    onChangeSpoken?: (language: OptionShape) => void;
    availableLanguages?: IKonexusLanguages;
}

const LanguageItems: React.FC<LanguageItemsProps> = (props) => {
    const { written, spoken, onChangeWritten, onChangeSpoken, disabled, availableLanguages } = props;

    const convertedWritten = useMemo(() => {
        return {
            value: written?.value,
            label: written?.label ?? written?.value ?? ""
        };
    }, [written]);

    const convertedSpoken = useMemo(() => {
        return {
            value: spoken?.value,
            label: spoken?.label ?? spoken?.value ?? ""
        };
    }, [spoken]);

    const handleChangeWritten = (value: OptionShape | undefined) => {
        if (onChangeWritten && value) onChangeWritten(value);
    };

    const handleChangeSpoken = (value: OptionShape | undefined) => {
        if (onChangeSpoken && value) onChangeSpoken(value);
    };

    return (
        <>
            <Autocomplete
                id="written-language"
                isInDialog={true}
                helperText="The language you prefer to be written in emails."
                label="Preferred Written Language"
                multiSelect={false}
                placeholder="Select a Language"
                value={convertedWritten}
                onChange={handleChangeWritten}
                readOnly={disabled}
                options={availableLanguages?.written.map((language) => ({
                    label: language.label ?? language.value,
                    value: language.value
                }))}
            />

            <Autocomplete
                id="spoken-language"
                isInDialog={true}
                helperText="The language you prefer to be spoken in phone calls."
                label="Preferred Spoken Language"
                multiSelect={false}
                placeholder="Select a Language"
                value={convertedSpoken}
                onChange={handleChangeSpoken}
                readOnly={disabled}
                options={availableLanguages?.spoken.map((language) => ({
                    label: language.label ?? language.value,
                    value: language.value
                }))}
            />
        </>
    );
};

export default LanguageItems;
