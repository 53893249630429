/* eslint-disable @typescript-eslint/no-explicit-any */
import ApiService, { ApiError, parseErrorMessage } from "./apiService";
import { DocumentType, SearchDocument } from "../types/SearchDocument";
import { Organization } from "../types/Organization";
import { SubscriptionList, SubscriptionType, AppIdsAndPhoneNumbers, WeatherChannel } from "../types/Subscription";
import { SubscriptionProfileInformation } from "../types/Account";
import { User as AuthUser } from "oidc-client-ts";

export interface SubscriptionAction {
    aggregateId: string;
    organization: Organization;
    authUser?: AuthUser | null;
    subscriptionType: SubscriptionType;
    subscriptionName: string;
    phoneNumber?: string;
    subscribeToDefault?: boolean;
    documentType?: DocumentType;
    weatherChannels?: WeatherChannel[];
    profileInfo: SubscriptionProfileInformation | null;
}

export class NotificationsService {
    public getSubscriptions = async ({
        orgName,
        authUser,
        orderByQuery,
        documentType,
        controller
    }: {
        orgName?: string;
        authUser?: AuthUser | null;
        orderByQuery?: string;
        documentType: DocumentType;
        controller?: AbortController;
    }): Promise<SubscriptionList[]> => {
        const url = `${orgName?.toLowerCase()}/notifications?type=${documentType}${
            orderByQuery ? `&$orderBy=${orderByQuery}` : ""
        }`;

        const response: { items: SubscriptionList[]; count: number } = await ApiService.get({
            url,
            cache: false,
            authUser,
            controller
        });

        return response.items;
    };

    public getAdminSubcriptions = async ({
        orgName,
        authUser
    }: {
        orgName?: string;
        authUser?: AuthUser | null;
    }): Promise<SearchDocument[]> => {
        const url = `${orgName?.toLowerCase()}/notifications/admin`;

        const response: { items: SearchDocument[]; count: number } = await ApiService.get({
            url,
            cache: false,
            authUser
        });

        return response.items;
    };

    public getSubscriberPhoneNumbers = async ({
        orgName,
        authUser
    }: {
        orgName?: string;
        authUser?: AuthUser | null;
    }): Promise<AppIdsAndPhoneNumbers> => {
        const url = `${orgName?.toLowerCase()}/notifications/phoneNumbers`;

        const response = await ApiService.get({ url, cache: false, authUser });
        return response;
    };

    public updateSubscriberPhoneNumber = async ({
        orgName,
        phoneNumber,
        phoneNumberId,
        authUser
    }: {
        orgName?: string;
        phoneNumber?: string;
        phoneNumberId?: string;
        authUser?: AuthUser | null;
    }): Promise<any> => {
        const url = `${orgName?.toLowerCase()}/notifications/phoneNumber`;

        try {
            return await ApiService.put({
                url,
                authUser,
                requestInit: {
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        phoneNumber,
                        phoneNumberId
                    })
                }
            });
        } catch (e) {
            const message = parseErrorMessage(e as ApiError, `Error while trying to update subscriber phone number`);
            console.error(message, e);

            return {
                success: false,
                error: message
            };
        }
    };

    public checkWeatherAlertsEnabledForOrg = async ({
        orgName,
        authUser
    }: {
        orgName: string;
        authUser?: AuthUser | null;
    }): Promise<boolean> => {
        const url = `${orgName.toLowerCase()}/notifications/checkWeatherAlertsEnabledForOrg`;
        const response = await ApiService.get({ url, cache: false, authUser });

        return response;
    };

    public subscribeUserToList = async ({
        aggregateId,
        organization,
        authUser,
        subscriptionType,
        subscriptionName,
        phoneNumber,
        subscribeToDefault,
        documentType,
        weatherChannels,
        profileInfo
    }: SubscriptionAction): Promise<any> => {
        const url = `${organization.name.toLowerCase()}/notifications/${encodeURIComponent(aggregateId)}`;

        try {
            return await ApiService.post({
                url,
                authUser,
                requestInit: {
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        isEmailChange: false,
                        email: authUser?.profile.name || "",
                        type: subscriptionType,
                        phoneNumber: phoneNumber === null ? "" : `${phoneNumber}`,
                        profileInfo,
                        subscribeToDefault,
                        documentType,
                        weatherChannels
                    })
                }
            });
        } catch (e) {
            const message = parseErrorMessage(
                e as ApiError,
                `Error while trying to subscribe to list ${subscriptionName}`
            );
            console.error(message, e);

            return {
                success: false,
                error: message
            };
        }
    };

    public unsubscribeUserFromList = async ({
        aggregateId,
        organization,
        authUser,
        subscriptionType,
        subscriptionName,
        phoneNumber,
        documentType,
        weatherChannels
    }: SubscriptionAction): Promise<any> => {
        const url = `${organization.name.toLowerCase()}/notifications/${encodeURIComponent(aggregateId)}/unsubscribe`;

        try {
            return await ApiService.put({
                url,
                authUser,
                returnCompleteResponse: true,
                requestInit: {
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        isEmailChange: false,
                        email: authUser?.profile.name || "",
                        type: subscriptionType,
                        phoneNumber,
                        documentType,
                        weatherChannels
                    })
                }
            });
        } catch (e) {
            const message = parseErrorMessage(
                e as ApiError,
                `Error while trying to unsubscribe from list ${subscriptionName}`
            );
            console.error(message, e);

            return {
                success: false,
                error: message
            };
        }
    };

    public unsubscribeFromAllLists = async ({
        organization,
        authUser,
        subscriptionType,
        documentType,
        weatherChannels
    }: {
        organization: Organization;
        authUser?: AuthUser | null;
        subscriptionType: SubscriptionType;
        documentType: DocumentType;
        weatherChannels?: WeatherChannel[];
    }): Promise<any> => {
        const url = `${organization.name.toLowerCase()}/notifications/unsubscribeFromAll`;

        try {
            return await ApiService.put({
                url,
                authUser,
                returnCompleteResponse: true,
                requestInit: {
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        isEmailChange: false,
                        email: authUser?.profile.name || "",
                        type: subscriptionType,
                        documentType: documentType,
                        weatherChannels: weatherChannels
                    })
                }
            });
        } catch (e) {
            const message = parseErrorMessage(e as ApiError, `Error while trying to unsubscribe from all lists`);
            console.error(`Error while trying to unsubscribe user ${authUser?.profile.name} from all lists`);

            return {
                success: false,
                error: message
            };
        }
    };

    public subscribeToAllLists = async ({
        organization,
        authUser,
        documentType,
        weatherChannels,
        profileInfo
    }: SubscriptionAction): Promise<{ success: boolean; error?: string }> => {
        const url = `${organization.name.toLowerCase()}/notifications/subscribe/all`;

        try {
            const response = await ApiService.put({
                url,
                requestInit: {
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        documentType,
                        weatherChannels,
                        profileInfo
                    })
                },
                authUser,
                returnCompleteResponse: true
            });
            return {
                success: response.ok
            };
        } catch (e) {
            const message = parseErrorMessage(e as ApiError, `Error while trying to subscribe to all lists`);
            return {
                success: false,
                error: message
            };
        }
    };

    public updateUserPreferences = async ({
        organization,
        authUser,
        cellPhoneId,
        cellPhoneSecondaryId,
        homePhoneId,
        workPhoneId,
        physicalAddressId,
        writtenLanguageOption,
        spokenLanguageOption
    }: {
        organization: Organization;
        authUser?: AuthUser | null;
        cellPhoneId?: string;
        cellPhoneSecondaryId?: string;
        homePhoneId?: string;
        workPhoneId?: string;
        physicalAddressId?: string;
        writtenLanguageOption?: string;
        spokenLanguageOption?: string;
    }): Promise<any> => {
        const userId = authUser?.profile?.sub || "";
        const url = `${organization?.name.toLowerCase()}/user/${encodeURIComponent(userId)}/konexus-preferences`;

        try {
            return await ApiService.post({
                url,
                requestInit: {
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        email: authUser?.profile.name || "",
                        cellPhoneId,
                        cellPhoneSecondaryId,
                        homePhoneId,
                        workPhoneId,
                        physicalAddressId,
                        writtenLanguageOption,
                        spokenLanguageOption
                    })
                },
                authUser
            });
        } catch (e) {
            const message = parseErrorMessage(
                e as ApiError,
                `Error while trying to update the user contact preferences for Konexus`
            );
            console.error(message, e);
            return {
                success: false,
                error: message
            };
        }
    };

    public getNotificationsAvailability = async (orgName: string): Promise<any> => {
        const url = `${orgName.toLowerCase()}/notifications/availability`;

        try {
            return await ApiService.get({
                url
            });
        } catch (e) {
            const message = parseErrorMessage(
                e as ApiError,
                `Error while trying to get the notifications type availability`
            );
            console.error(message, e);
            return {
                success: false,
                error: message
            };
        }
    };
}
