import React, { useCallback } from "react";
import Button from "@civicplus/preamble-ui/lib/Button";
import Dialog from "@civicplus/preamble-ui/lib/Dialog";

interface SimpleDialogProps {
    title: string;
    children: React.ReactNode;
    isOpen: boolean;
    onClose: () => void;
    primaryAction: (() => Promise<void>) | (() => void);
    primaryBtnText: string;
}

export const SimpleDialog: React.FC<SimpleDialogProps> = ({
    title,
    children,
    isOpen,
    onClose,
    primaryAction,
    primaryBtnText
}) => {
    const [primaryIsLoading, setPrimaryIsLoading] = React.useState<boolean>(false);

    const onCloseDialog = useCallback(() => {
        if (onClose) {
            onClose();
        }
    }, [onClose]);

    const onPrimaryAction = useCallback(async () => {
        setPrimaryIsLoading(true);
        if (primaryAction) {
            await primaryAction();
        }
        setPrimaryIsLoading(false);
    }, [primaryAction, setPrimaryIsLoading]);

    return (
        <Dialog
            open={isOpen}
            onClose={onCloseDialog}
            title={title}
            actions={[
                <Button
                    color="primary"
                    onClick={onPrimaryAction}
                    key={`action-${title}-dialog`}
                    isLoading={primaryIsLoading}
                >
                    {primaryBtnText}
                </Button>,

                <Button onClick={onCloseDialog} key={`cancel-${title}-dialog`}>
                    Cancel
                </Button>
            ]}
        >
            {children}
        </Dialog>
    );
};
