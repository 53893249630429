import React, { useMemo } from "react";
import { useConfig } from "./ConfigProvider";
import { AuthContextProps, AuthProvider, useAuth as oidcUseAuth } from "../components/react-oidc-context";

export function AuthProviderWrapper({ children }: { children: React.ReactNode }): JSX.Element {
    const config = useConfig();
    return <AuthProvider {...config.authConfig}>{children}</AuthProvider>;
}

export function useAuth(): AuthContextProps {
    const useAuth = oidcUseAuth();
    return useMemo(() => {
        return { ...useAuth };
    }, [useAuth]);
}
