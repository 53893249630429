import React from "react";
import { makeStyles, PreambleTheme } from "@civicplus/preamble-ui/lib/Utilities/ThemeHelper";
import Typography from "@civicplus/preamble-ui/lib/Typography";
import Button from "@civicplus/preamble-ui/lib/Button";
import Alert from "@civicplus/preamble-ui/lib/Alert";
import { useSnackbar, SnackbarContent, CustomContentProps } from "notistack";
import CloseIcon from "@mui/icons-material/Close";
import ButtonGroup from "@civicplus/preamble-ui/lib/ButtonGroup";

const useStyles = makeStyles((theme: PreambleTheme) => ({
    root: {
        maxWidth: "500px"
    },
    actionRoot: {
        gap: theme.spacing(1),
        justifyContent: "space-between",
        display: "flex",
        alignItems: "center"
    },
    message: {
        overflow: "hidden",
        padding: 0
    }
}));

type SuccessProps = CustomContentProps;

const SuccessVariant = React.forwardRef<HTMLDivElement, SuccessProps>((props, ref) => {
    const { id, message, action } = props;

    const { closeSnackbar } = useSnackbar();
    const classes = useStyles();

    const handleDismiss = React.useCallback(() => {
        closeSnackbar(id);
    }, [id, closeSnackbar]);

    return (
        <SnackbarContent ref={ref}>
            <Alert
                severity="success"
                classes={{
                    root: classes.root,
                    message: classes.message
                }}
            >
                <div className={classes.actionRoot}>
                    <Typography variant="body1" sx={{ lineHeight: 1.2, paddingY: 1, flex: 1 }}>
                        {message}
                    </Typography>
                    <ButtonGroup>
                        {typeof action === "function" ? (
                            action(id)
                        ) : typeof action === "object" ? (
                            action
                        ) : (
                            <Button
                                data-testid="dismiss-message-btn"
                                title="Dismiss message"
                                type="icon"
                                onClick={handleDismiss}
                            >
                                <CloseIcon fontSize="small" />
                            </Button>
                        )}
                    </ButtonGroup>
                </div>
            </Alert>
        </SnackbarContent>
    );
});

SuccessVariant.displayName = "SuccessVariant";

export default SuccessVariant;
