import React, { useMemo } from "react";
import Button from "@civicplus/preamble-ui/lib/Button";
import ChipPortal from "../Chip";
import clsx from "clsx";
import Grid from "@civicplus/preamble-ui/lib/Grid";
import Icon from "@mdi/react";
import List from "@civicplus/preamble-ui/lib/List/List";
import ListItem from "@civicplus/preamble-ui/lib/ListItem";
import Typography from "@civicplus/preamble-ui/lib/Typography";
import VideocamIcon from "@mui/icons-material/Videocam";
import { CivicClerkEvent } from "../../types/CivicClerkEvent";
import { formatDate } from "@civicplus/preamble-ui/lib/Utilities/DateHelper";
import { LoginNoticeDialog } from "../Authentication/LoginNoticeDialog";
import { makeStyles } from "@civicplus/preamble-ui/lib/Utilities/ThemeHelper";
import { mdiBookmark, mdiBookmarkOutline, mdiCalendarClock, mdiMapMarker } from "@mdi/js";
import { RemoveFavoriteDialog } from "../Favorites/RemoveFavoriteDialog";
import { SearchDocument } from "../../types/SearchDocument";
import { useFavorites } from "../../shared/useFavorites";
import EventFilesPopover from "./EventFilesPopover";

const useStyles = makeStyles((theme) => ({
    chipsContainer: {
        display: "flex",
        flexWrap: "wrap",
        gap: 5,
        margin: theme.spacing(1, 0, 0),
        overflowX: "auto"
    },
    externalChip: {
        "& svg": {
            marginLeft: theme.spacing(1) + "!important"
        }
    },
    featured: {
        position: "relative",
        "&:after": {
            content: '""',
            position: "absolute",
            left: 0,
            width: 5,
            height: "100%",
            backgroundColor: theme.palette.primary.main
        }
    },
    listing: {
        margin: theme.spacing(2, 0)
    },
    icon: {
        color: theme.palette.grey[400],
        fontSize: theme.typography.subtitle1.fontSize
    },
    title: {
        display: "flex",
        alignItems: "center",
        gap: theme.spacing(1),
        margin: "0px 0px 4px",
        fontWeight: 600
    },
    locationIcon: { margin: theme.spacing(0, 1, 0, 0) },
    smallIcon: { margin: "4px 0px 0px 0px" },
    disabled: { color: theme.palette.text.disabled }
}));

export interface EventsListProps {
    /**
     * Array of SearchDocuments to display in list.
     */
    events: SearchDocument[];
}

const EventsList = React.forwardRef<HTMLUListElement, EventsListProps>(({ events }, ref) => {
    const classes = useStyles();
    const [
        { eventsFavoriteMap, loginNoticeOpen, unfavoriteDialogSettings },
        {
            handleCloseLoginNotice,
            handleCloseUnfavoriteNotice,
            handleConfirmUnfavorite,
            handleLogin,
            handleFavoriteClick
        }
    ] = useFavorites();

    const renderedEventRows = useMemo(() => {
        if (events && events.length === 0) {
            return [
                <ListItem
                    key="empty-list"
                    ListItemTextProps={{
                        primary: <Typography align="center">Sorry, no results found</Typography>
                    }}
                />
            ];
        }

        return (
            events &&
            events.map((item: SearchDocument) => {
                const event = item.item as CivicClerkEvent;
                const eventLocation = event.eventLocation;
                const hasMedia = event.liveIsCurrentlyStreaming || event.hasMedia || event.externalMediaUrl;

                const renderedDateTime = item.startDate ? formatDate(item.startDate, "MM/dd/yyyy h:mm aa") : undefined;

                item.isFavorite = eventsFavoriteMap.get(item.id) !== undefined;

                return (
                    <ListItem
                        key={item.id}
                        id={`listItem-${event.id}`}
                        data-testid={`listItem-${event.id}`}
                        divider={true}
                        button={true}
                        onClick={() => window.open(item.url, "_blank")}
                        className={clsx({ [classes.featured]: item.isFeatured })}
                        ListItemTextProps={{
                            primary: (
                                <div data-testid={`title-${item.id}`} className={classes.title}>
                                    <span>{item.title}</span>
                                </div>
                            ),
                            secondary: (
                                <Grid container={true}>
                                    <Grid>
                                        <Grid container={true} spacing={1}>
                                            {item.startDate && (
                                                <>
                                                    <Grid>
                                                        <Icon
                                                            path={mdiCalendarClock}
                                                            size={0.75}
                                                            className={clsx(classes.smallIcon, classes.disabled)}
                                                        />
                                                    </Grid>

                                                    <Grid style={{ padding: "7px 3px", marginRight: 10 }}>
                                                        {renderedDateTime}
                                                    </Grid>
                                                </>
                                            )}

                                            {eventLocation && (
                                                <>
                                                    <Grid>
                                                        {eventLocation?.address1 ? (
                                                            <Icon
                                                                path={mdiMapMarker}
                                                                size={0.75}
                                                                className={clsx(classes.smallIcon, classes.disabled)}
                                                            />
                                                        ) : null}
                                                    </Grid>

                                                    <Grid style={{ padding: "7px 3px", marginLeft: "-4px" }}>
                                                        {eventLocation?.address1 ? `${eventLocation.address1} ` : ""}
                                                        {eventLocation?.address2 ? `${eventLocation.address2} ` : ""}
                                                        {eventLocation?.city ? `${eventLocation.city}, ` : ""}
                                                        {eventLocation?.state ? `${eventLocation.state} ` : ""}
                                                        {eventLocation?.zipCode ? `${eventLocation.zipCode}` : ""}
                                                    </Grid>
                                                </>
                                            )}
                                        </Grid>
                                    </Grid>

                                    <Grid xs={12}>
                                        <Typography color="textPrimary">{item.description}</Typography>
                                    </Grid>

                                    <Grid xs={12}>
                                        <div className={classes.chipsContainer}>
                                            {item.tags?.map((tag) => <ChipPortal label={tag} key={tag} size="small" />)}
                                        </div>
                                    </Grid>
                                </Grid>
                            )
                        }}
                        itemSecondaryAction={
                            <Grid container={true} justifyContent="center" alignItems="center" spacing={1}>
                                <Grid>
                                    <Button
                                        size="small"
                                        type="icon"
                                        disabled={!hasMedia}
                                        stopPropagation={true}
                                        id={`media-btn-${event.id}`}
                                        data-testid={`media-btn-${event.id}`}
                                        title={hasMedia ? "Go To Meeting Media" : "No Meeting Media Available"}
                                        onClick={() => {
                                            const mediaUrl = item.url.replace("/overview", "/media");
                                            window.open(mediaUrl, "_blank");
                                        }}
                                    >
                                        <VideocamIcon
                                            aria-hidden={true}
                                            color={hasMedia ? "inherit" : "disabled"}
                                        ></VideocamIcon>
                                    </Button>
                                </Grid>

                                <Grid>
                                    <EventFilesPopover event={item} />
                                </Grid>

                                <Grid>
                                    <Button
                                        id={`bookmark-icon-btn-${item.id}`}
                                        data-testid={`bookmark-icon-btn-${item.id}`}
                                        size="small"
                                        type="icon"
                                        stopPropagation={true}
                                        onClick={(e: React.MouseEvent<HTMLElement>) => {
                                            handleFavoriteClick(e, item);
                                        }}
                                        title={`${
                                            item.isFavorite ? `Remove ${item.title} from` : `Add ${item.title} to`
                                        } my bookmarks`}
                                        // aria-selected={item.isFavorite}
                                        aria-pressed={item.isFavorite}
                                    >
                                        <Icon
                                            path={item.isFavorite ? mdiBookmark : mdiBookmarkOutline}
                                            size={1}
                                            aria-hidden="true"
                                            data-testid={
                                                item.isFavorite
                                                    ? `${item.id}-remove-favorite`
                                                    : `${item.id}-add-favorite`
                                            }
                                        />
                                    </Button>
                                </Grid>
                            </Grid>
                        }
                    />
                );
            })
        );
    }, [classes, eventsFavoriteMap, events, handleFavoriteClick]);

    return (
        <>
            <List
                className={classes.listing}
                ref={ref}
                id="events-list"
                aria-label="Events by date"
                wrapperStyles={true}
            >
                {renderedEventRows}
            </List>

            <LoginNoticeDialog isOpen={loginNoticeOpen} onClose={handleCloseLoginNotice} handleLogin={handleLogin} />

            <RemoveFavoriteDialog
                isOpen={unfavoriteDialogSettings.open}
                handleConfirm={handleConfirmUnfavorite}
                onClose={handleCloseUnfavoriteNotice}
            />
        </>
    );
});

EventsList.displayName = "EventsList";

export default EventsList;
