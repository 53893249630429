import React, { useState, useRef } from "react";
import ApiService, { ApiError, parseErrorMessage } from "../../../services/apiService";
import Button from "@civicplus/preamble-ui/lib/Button";
import ButtonGroup from "@civicplus/preamble-ui/lib/ButtonGroup";
import Checkbox from "@civicplus/preamble-ui/lib/Checkbox";
import enhanceWithValidation, { requiredValidation, ValidationResult } from "@civicplus/preamble-ui/lib/Validations";
import Grid from "@civicplus/preamble-ui/lib/Grid";
import PhoneNumberInput from "@civicplus/preamble-ui/lib/PhoneNumberInput";
import TextInput from "@civicplus/preamble-ui/lib/TextInput";
import useGlobalStyles from "../../../shared/globalStyles";
import { softMaxLengthValidation } from "../../../shared/customValidation";
import { useAuth } from "../../../providers/AuthProvider";
import { useSnackbar } from "notistack";
import { phoneNumberMask } from "./customValidation";
import FormGroup from "@civicplus/preamble-ui/lib/FormGroup";
import FormControl from "@civicplus/preamble-ui/lib/FormControl";
import FormLabel from "@civicplus/preamble-ui/lib/FormLabel";
import FormHelperText from "@civicplus/preamble-ui/lib/ErrorHandlingFormHelperText";
import {
    emptyPhone,
    isValidPhoneNumberValidation,
    PhoneNumber,
    cleanUpPhoneNumber,
    removeCountryCode
} from "./PhoneNumberUtils";

const EnhancedTextInput = enhanceWithValidation(TextInput);
const EnhancedPhoneNumberInput = enhanceWithValidation(PhoneNumberInput);
const EnhancedFormControl = enhanceWithValidation(FormControl);

interface PhoneNumberFormProps {
    finishedAddPhoneNumberAction: (isLoading: boolean, newNumber?: PhoneNumber) => void;
    existingNumberCount: number;
}

const PhoneNumberForm: React.FC<PhoneNumberFormProps> = (props) => {
    const { finishedAddPhoneNumberAction, existingNumberCount } = props;
    const globalClasses = useGlobalStyles();
    const auth = useAuth();
    const { enqueueSnackbar } = useSnackbar();

    const [phoneNumber, setPhoneNumber] = useState<PhoneNumber>({
        ...emptyPhone,
        label: `Phone Number ${existingNumberCount + 1}`,
        isDefaultNumber: existingNumberCount === 0
    });
    const [isLoading, setIsLoading] = useState(false);
    const phoneLabelRef = useRef<{ validate: () => ValidationResult }>();
    const phoneRef = useRef<{ validate: () => ValidationResult }>();
    const communicationOptionsRef = useRef<{ validate: () => ValidationResult }>();
    const [checkBoxError, setCheckBoxError] = useState(false);

    const handleSaveChanges = (key: string) => (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        let value = checked === undefined ? e.currentTarget?.value : checked;
        if (key === "number") value = cleanUpPhoneNumber(e.currentTarget?.value);
        setPhoneNumber((prevPhone) => ({ ...prevPhone, [key]: value }));
    };

    const handleCancel = () => {
        setPhoneNumber(emptyPhone);
        finishedAddPhoneNumberAction(false, undefined);
    };

    const handleSubmit = async () => {
        const validationResults: ValidationResult[] = await Promise.all([
            phoneRef.current!.validate(),
            phoneLabelRef.current!.validate(),
            communicationOptionsRef.current!.validate()
        ]);

        if (validationResults.some((x) => x.error)) {
            setCheckBoxError(validationResults[2]?.error ? true : false);
            return;
        }

        finishedAddPhoneNumberAction(true, undefined);

        try {
            setIsLoading(true);
            const promiseResult = await ApiService.post({
                url: "user/account/phoneNumber",
                authUser: auth.user,
                requestInit: {
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        number: removeCountryCode(phoneNumber.number),
                        allowSms: phoneNumber.allowSms,
                        allowVoice: phoneNumber.allowVoice,
                        countryCode: phoneNumber.countryCode,
                        label: phoneNumber.label,
                        isDefaultNumber: phoneNumber.isDefaultNumber
                    })
                }
            });
            enqueueSnackbar("Phone number saved successfully!", {
                variant: "success"
            });

            setPhoneNumber((prevPhone) => ({ ...prevPhone, id: promiseResult.id }));
            finishedAddPhoneNumberAction(false, promiseResult);
        } catch (error) {
            const message = parseErrorMessage(error as ApiError, "Failed to save a new phone number.");
            enqueueSnackbar(message, {
                variant: "error",
                persist: true
            });
            finishedAddPhoneNumberAction(false, undefined);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Grid container={true} sx={{ my: 2 }} spacing={2}>
            <Grid xs={12} md="auto">
                <EnhancedFormControl
                    ref={communicationOptionsRef}
                    required={true}
                    validations={[requiredValidation]}
                    value={phoneNumber.allowSms || phoneNumber.allowVoice ? "true" : undefined}
                    margin="none"
                >
                    <FormLabel sx={{ color: "primary.main", fontSize: "caption.fontSize", fontWeight: "bold" }}>
                        Communication Options
                    </FormLabel>
                    <FormGroup row={true}>
                        <Checkbox
                            id="allowSms"
                            label="SMS / text messages"
                            onChange={handleSaveChanges("allowSms")}
                            checked={phoneNumber.allowSms}
                            size="small"
                        />

                        <Checkbox
                            id="allowVoice"
                            label="Voice calls"
                            onChange={handleSaveChanges("allowVoice")}
                            checked={phoneNumber?.allowVoice}
                            size="small"
                        />
                    </FormGroup>
                    <FormHelperText
                        error={Boolean(checkBoxError)}
                        errorMessage="You must select at least one communication option."
                    />
                </EnhancedFormControl>
            </Grid>

            <Grid xs={12}>
                <EnhancedTextInput
                    ref={phoneLabelRef}
                    id="phoneNumberLabel"
                    label="Phone Number Label"
                    value={phoneNumber.label}
                    onChange={handleSaveChanges("label")}
                    fullWidth={true}
                    softMaxLength={30}
                    validations={[softMaxLengthValidation]}
                    margin={false}
                />
            </Grid>

            <Grid xs={12}>
                <EnhancedPhoneNumberInput
                    ref={phoneRef}
                    id="phoneNumber"
                    label="Phone Number"
                    value={phoneNumber.number}
                    onChange={handleSaveChanges("number")}
                    fullWidth={true}
                    required={true}
                    maxLength={10}
                    autoComplete="tel-local"
                    placeholder="+1 (000) 000-0000"
                    maskTemplate={phoneNumberMask}
                    regExpPattern={"^\\+1\\s\\([1-9]\\d{2}\\)\\s\\d{3}-\\d{4}"}
                    validations={[requiredValidation, isValidPhoneNumberValidation]}
                    margin={false}
                />
            </Grid>

            <Grid md={6} xs={12} className={globalClasses.alignedCenter}>
                <Checkbox
                    id="isDefaultPhoneNumber"
                    label="This is my Default Phone Number"
                    size="small"
                    color="primary"
                    onChange={handleSaveChanges("isDefaultNumber")}
                    disabled={existingNumberCount === 0}
                    checked={existingNumberCount === 0 && phoneNumber.id === "" ? true : phoneNumber.isDefaultNumber}
                />
            </Grid>

            <Grid md={6} xs={12} className={globalClasses.justifiedFlexEnd}>
                <ButtonGroup id="saveAndCancel">
                    <Button color="primary" onClick={handleSubmit} disabled={isLoading} isLoading={isLoading}>
                        Save Changes
                    </Button>

                    <Button data-testid="cancel-add-phone" onClick={handleCancel}>
                        Cancel
                    </Button>
                </ButtonGroup>
            </Grid>
        </Grid>
    );
};

export default PhoneNumberForm;
