import { useCallback, useMemo, useState } from "react";
import { LinkState, useLinks } from "../stores/linksStore";
import { SearchDocument } from "../types/SearchDocument";
import { useAuth } from "../providers/AuthProvider";
import { GetSectionFromDocumentType, Link, convertSearchDocumentToLink } from "../types/Link";
import { OrganizationState, useOrganization } from "../stores/organizationStore";
import { setupAcrValue } from "../components/react-oidc-context";
import { SigninPopupArgs } from "oidc-client-ts";
import { useSnackbar } from "notistack";

export interface FavoritesContextState {
    favoriteMap: Map<string, Link>;
    eventsFavoriteMap: Map<string, Link>;
    formsFavoriteMap: Map<string, Link>;
    linksFavoriteMap: Map<string, Link>;
    subscriptionListsFavoriteMap: Map<string, Link>;
    loginNoticeOpen: boolean;
    unfavoriteDialogSettings: UnfavoriteDialogSettingState;
}

export interface UnfavoriteDialogSettingState {
    open: boolean;
    link: SearchDocument | null;
}

export interface FavoritesContextActions {
    handleCloseLoginNotice: () => void;
    handleCloseUnfavoriteNotice: () => void;
    handleConfirmUnfavorite: () => Promise<void>;
    handleFavoriteClick: (e: React.MouseEvent, link: SearchDocument) => Promise<void>;
    handleLogin: () => void;
    setUnfavoriteDialogSettings: (state: UnfavoriteDialogSettingState) => void;
    getAllFavorites: () => void;
}

export function useFavorites(): [FavoritesContextState, FavoritesContextActions] {
    const { enqueueSnackbar } = useSnackbar();
    const auth = useAuth();

    const [organization] = useOrganization((state: OrganizationState) => [state.organization]);
    const {
        fetchFavorites,
        favorites,
        favoriteEvents,
        favoriteForms,
        favoriteLinks,
        favoriteSubscriptionLists,
        setFavoriteLink,
        setLinkToFavorite
    } = useLinks((state: LinkState) => ({
        fetchLinks: state.fetchLinks,
        fetchLinksStatus: state.fetchLinksStatus,
        fetchFavorites: state.fetchFavorites,
        favorites: state.favorites,
        favoriteEvents: state.favoriteEvents,
        favoriteForms: state.favoriteForms,
        favoriteLinks: state.favoriteLinks,
        favoriteSubscriptionLists: state.favoriteSubscriptionLists,
        setFavoriteLink: state.setFavoriteLink,
        setLinkToFavorite: state.setLinkToFavorite
    }));

    const [loginNoticeOpen, setLoginNoticeOpen] = useState(false);
    const [unfavoriteDialogSettings, setUnfavoriteDialogSettings] = useState<UnfavoriteDialogSettingState>({
        open: false,
        link: null
    });

    const getAllFavorites = useCallback(() => {
        if (!auth.isAuthenticated) {
            return;
        }

        const fetchAll = async () => {
            if (auth.user) {
                await fetchFavorites(auth.user, enqueueSnackbar, organization?.id);
            }
        };

        fetchAll();
    }, [auth.isAuthenticated, auth.user, fetchFavorites, enqueueSnackbar, organization?.id]);

    const handleFavoriteClick = useCallback(
        async (e: React.MouseEvent, link: SearchDocument) => {
            e.preventDefault();
            const isSignedIn = auth.isAuthenticated;

            if (isSignedIn) {
                if (link.isFavorite) {
                    setUnfavoriteDialogSettings({ open: true, link: link });
                } else if (auth.user) {
                    await setFavoriteLink(
                        link.id,
                        convertSearchDocumentToLink(link, GetSectionFromDocumentType(link.type)),
                        true,
                        auth.user,
                        enqueueSnackbar,
                        organization?.id
                    );
                }
            } else if (!isSignedIn && link.id) {
                setLinkToFavorite(convertSearchDocumentToLink(link, GetSectionFromDocumentType(link.type)));
                setLoginNoticeOpen(true);
            }
        },
        [auth.isAuthenticated, auth.user, enqueueSnackbar, setFavoriteLink, setLinkToFavorite, organization]
    );

    const handleConfirmUnfavorite = async () => {
        if (!unfavoriteDialogSettings.link || !auth.user) {
            return;
        }

        const document = unfavoriteDialogSettings.link as SearchDocument;

        await setFavoriteLink(
            document.id,
            convertSearchDocumentToLink(document, GetSectionFromDocumentType(document.type)),
            false,
            auth.user,
            enqueueSnackbar,
            organization?.id
        );

        setUnfavoriteDialogSettings({ open: false, link: null });
    };

    const handleLogin = () => {
        const args = {
            state: { from: window.location.pathname }
        } as SigninPopupArgs;
        auth.signinPopup(setupAcrValue(auth, args, organization?.id));
        setLoginNoticeOpen(false);
    };

    const handleCloseLoginNotice = () => {
        setLoginNoticeOpen(false);
        setLinkToFavorite(undefined);
    };

    const handleCloseUnfavoriteNotice = () => {
        setUnfavoriteDialogSettings({ open: false, link: null });
    };

    const favoriteMap = useMemo(() => {
        return new Map(
            favorites.map((f) => {
                return [f.aggregateId ?? f.id, f];
            })
        );
    }, [favorites]);

    const eventsFavoriteMap = useMemo(() => {
        return new Map(
            favoriteEvents.map((f) => {
                return [f.aggregateId ?? f.id, f];
            })
        );
    }, [favoriteEvents]);

    const formsFavoriteMap = useMemo(() => {
        return new Map(
            favoriteForms.map((f) => {
                return [f.aggregateId ?? f.id, f];
            })
        );
    }, [favoriteForms]);

    const linksFavoriteMap = useMemo(() => {
        return new Map(
            favoriteLinks.map((f) => {
                return [f.aggregateId ?? f.id, f];
            })
        );
    }, [favoriteLinks]);

    const subscriptionListsFavoriteMap = useMemo(() => {
        return new Map(
            favoriteSubscriptionLists.map((f) => {
                return [f.aggregateId ?? f.id, f];
            })
        );
    }, [favoriteSubscriptionLists]);

    return [
        {
            favoriteMap,
            eventsFavoriteMap,
            formsFavoriteMap,
            linksFavoriteMap,
            subscriptionListsFavoriteMap,
            loginNoticeOpen,
            unfavoriteDialogSettings
        },
        {
            handleCloseLoginNotice,
            handleCloseUnfavoriteNotice,
            handleConfirmUnfavorite,
            handleFavoriteClick,
            handleLogin,
            setUnfavoriteDialogSettings,
            getAllFavorites
        }
    ];
}
