import React, { useEffect, useState } from "react";
import Avatar from "@civicplus/preamble-ui/lib/Avatar";
import Button from "@civicplus/preamble-ui/lib/Button";
import CloseIcon from "@mui/icons-material/Close";
import Menu from "@civicplus/preamble-ui/lib/Menu";
import OpenInNewTabIcon from "mdi-material-ui/OpenInNew";
import LogoutIcon from "mdi-material-ui/Logout";
import SkeletonLoader from "@civicplus/preamble-ui/lib/SkeletonLoader";
import { Grid } from "@civicplus/preamble-ui/lib/Grid";
import GlobalSearchInput from "../GlobalSearchInput";
import { useAuth } from "../../providers/AuthProvider";
import { useConfig } from "../../providers/ConfigProvider";
import { useOrganization } from "../../stores/organizationStore";
import { useEmbedStore } from "../../stores/embedStore";
import { useSnackbar } from "notistack";

interface EmbedTopbarProps {
    signInPending?: boolean;
}
declare global {
    interface Document {
        embedSignOut: {
            postMessage: (message: any, targetOrigin: string, transfer?: Transferable[]) => void;
        };
    }
}

const EmbedTopbar: React.FC<EmbedTopbarProps> = ({ signInPending = true }) => {
    const auth = useAuth();
    const { user: authUser, isAuthenticated } = auth;
    const [organization] = useOrganization((state) => [state.organization]);
    const config = useConfig();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [signingOut, setSigningOut] = useState(false);
    const [baseUrl] = useEmbedStore((state) => [state.baseUrl]);

    const profile = authUser ? authUser.profile : undefined;
    const fullName = profile ? `${profile.given_name} ${profile.family_name}` : "User";

    useEffect(() => {
        return auth.events.addAccessTokenExpired(() => {
            auth.clearStaleState();
            auth.removeUser();
        });
    }, [auth]);

    const handleWarning = () => {
        enqueueSnackbar("You may need to refresh your browser to see updated information", {
            variant: "warning",
            persist: true,
            preventDuplicate: true,
            action: (
                <>
                    <Button
                        variant="outlined"
                        id="refresh-page-btn"
                        data-testid="refresh-page-btn"
                        onClick={() => window.location.reload()}
                    >
                        Refresh
                    </Button>

                    <Button
                        id="dismiss-message-btn"
                        data-testid="dismiss-message-btn"
                        type="icon"
                        size="small"
                        style={{ marginLeft: 15 }}
                        onClick={() => closeSnackbar()}
                        title="Dismiss message"
                    >
                        <CloseIcon />
                    </Button>
                </>
            )
        });
    };

    const handleSignOut = async () => {
        document.embedSignOut.postMessage({ type: "embededUserSignOutRequest" }, "*");
        setSigningOut(true);
    };

    useEffect(() => {
        function crossDomainUserSignIn(event: any) {
            if (event.origin === baseUrl) {
                if (event.data.type === "embededUserSignOutSuccessful") {
                    auth.removeUser();
                    auth.clearStaleState();
                    setSigningOut(false);
                } else if (event.data.type === "embededUserSignOutFailed") {
                    setSigningOut(false);
                }
            }
        }

        window.addEventListener("message", crossDomainUserSignIn);

        return () => {
            window.removeEventListener("message", crossDomainUserSignIn);
        };
    }, [baseUrl, auth]);

    return (
        <Grid
            id="embed-topbar"
            container={true}
            spacing={3}
            alignItems="center"
            alignContent="flex-end"
            justifyContent="flex-end"
        >
            <iframe
                style={{ display: "none" }}
                name="embedSignOut"
                title="embedSignOut"
                src={`${baseUrl}/embedSignOut`}
            />
            <Grid xs={true} md={6}>
                <GlobalSearchInput />
            </Grid>

            {signInPending || isAuthenticated || signingOut ? (
                <Grid xs="auto">
                    {signInPending || signingOut ? <SkeletonLoader height={25} width={25} circle={true} /> : null}
                    {isAuthenticated && !signInPending && !signingOut ? (
                        <Menu
                            id="account-menu"
                            title="My Account"
                            icon={
                                <Avatar
                                    id="embed-account-avatar"
                                    name={fullName}
                                    email={authUser?.profile.name}
                                    size="small"
                                />
                            }
                            items={[
                                {
                                    children: "Account Settings",
                                    href: `${config.accountServiceBaseUrl}/personal`,
                                    target: "_blank",
                                    component: "a",
                                    onClick: handleWarning,
                                    icon: <OpenInNewTabIcon fontSize="small" />,
                                    iconLocation: "right"
                                },
                                {
                                    children: "Portal Settings",
                                    href: `${config.getPortalBaseUrl()}/${organization?.name}/settings`,
                                    target: "_blank",
                                    component: "a",
                                    icon: <OpenInNewTabIcon fontSize="small" />,
                                    iconLocation: "right"
                                },
                                {
                                    children: "Sign Out",
                                    onClick: handleSignOut,
                                    icon: <LogoutIcon fontSize="small" />,
                                    iconLocation: "right"
                                }
                            ]}
                        />
                    ) : null}
                </Grid>
            ) : null}
        </Grid>
    );
};

export default EmbedTopbar;
