import React from "react";
import Checkbox from "@civicplus/preamble-ui/lib/Checkbox";
import classNames from "classnames";
import Close from "@mui/icons-material/Close";
import IconCircleCheck from "@civicplus/preamble-ui/lib/Icons/IconCircleCheck";
import Grid from "@civicplus/preamble-ui/lib/Grid";
import MuiChip from "@mui/material/Chip";
import Typography from "@civicplus/preamble-ui/lib/Typography";
import { Dictionary } from "../../../types/Content";
import { makeStyles } from "@civicplus/preamble-ui/lib/Utilities/ThemeHelper";
import { PhoneNumber, PhoneNumberType } from "./PhoneNumberUtils";

const useStyles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(2, 0)
    },
    card: {
        padding: theme.spacing(2),
        border: `1px solid ${theme.palette.divider}`
    },
    flexGrow: {
        flexGrow: 1
    },
    disabled: {
        opacity: theme.palette.action.disabledOpacity
    },
    disableOpacity: {
        "& .prmbl-formControlLabel span": {
            color: "inherit"
        }
    },
    chipRoot: {
        color: theme.palette.primary.main + "!important",
        lineHeight: "1.75",
        backgroundColor: "inherit !important",
        borderRadius: 5,
        margin: 2
    }
}));

const phoneNumberFriendlyType: Dictionary = {
    [PhoneNumberType.CellPhone]: "Cell Phone",
    [PhoneNumberType.CellPhoneSecondary]: "Cell Phone 2/Pager",
    [PhoneNumberType.HomePhone]: "Home Phone",
    [PhoneNumberType.WorkPhone]: "Work Phone"
};

export interface PhoneNumberListItemProps {
    phoneNumber: PhoneNumber;
}

const PhoneNumberListItem: React.FC<PhoneNumberListItemProps> = (props) => {
    const { phoneNumber } = props;
    const classes = useStyles();
    const isCellPhone =
        phoneNumber.type === PhoneNumberType.CellPhone || phoneNumber.type === PhoneNumberType.CellPhoneSecondary;

    return (
        <section className={classNames(classes.card)} key={phoneNumber.id}>
            <Grid container={true} spacing={2}>
                <Grid xs={isCellPhone ? 6 : 8} sm={isCellPhone ? 6 : "auto"}>
                    <Grid container={true} alignItems="center">
                        <Grid xs={12}>
                            <Typography id={`item-${phoneNumber.id}-name`} component="h3" variant="h6">
                                {phoneNumber.label} •{" "}
                                {phoneNumber.type in phoneNumberFriendlyType
                                    ? phoneNumberFriendlyType[phoneNumber.type]
                                    : phoneNumber.type}
                            </Typography>
                        </Grid>

                        <Grid xs={12}>
                            <Typography component="p" variant="body1" color="textSecondary" display="inline">
                                {phoneNumber.number}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>

                {isCellPhone && (
                    <Grid xs={6} md={6}>
                        <Typography component="span" variant="body1" color="textSecondary" display="inline">
                            {phoneNumber.isPhoneVerified ? (
                                <IconCircleCheck style={{ verticalAlign: "middle" }} />
                            ) : (
                                <Close color="disabled" style={{ verticalAlign: "middle" }} />
                            )}
                            <span style={{ verticalAlign: "middle" }}> Verified</span>
                        </Typography>
                    </Grid>
                )}
            </Grid>

            <div>
                <Checkbox
                    id={`${phoneNumber.id}-allowSms`}
                    name="allowSms"
                    label="Supports SMS / text messages"
                    size="small"
                    checked={phoneNumber.allowSms}
                    disabled={true}
                />

                <Checkbox
                    id={`${phoneNumber.id}-allowVoice`}
                    name="allowVoice"
                    label="Supports voice calls"
                    size="small"
                    checked={phoneNumber.allowVoice}
                    disabled={true}
                />
            </div>

            {phoneNumber.isDefaultNumber ? (
                <Grid container={true} style={{ margin: "8px 0 0" }}>
                    <Grid>
                        <MuiChip
                            classes={{ avatar: classes.chipRoot }}
                            label="Default Phone Number"
                            variant="outlined"
                            color="primary"
                            size="small"
                        />
                    </Grid>
                </Grid>
            ) : null}
        </section>
    );
};

export default PhoneNumberListItem;
