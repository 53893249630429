import React, { useMemo } from "react";
import Chip from "@civicplus/preamble-ui/lib/Chip";
import ChipPortal from "../Chip";
import clsx from "clsx";
import Grid from "@civicplus/preamble-ui/lib/Grid";
import Icon from "@mdi/react";
import List from "@civicplus/preamble-ui/lib/List";
import ListItem from "@civicplus/preamble-ui/lib/ListItem";
import SkeletonLoader from "@civicplus/preamble-ui/lib/SkeletonLoader";
import Typography from "@civicplus/preamble-ui/lib/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { makeStyles, useTheme } from "@civicplus/preamble-ui/lib/Utilities/ThemeHelper";
import { mdiOpenInNew } from "@mdi/js";
import { NotificationGroupType } from "./utils";
import { NotificationsService } from "../../services/notificationsService";
import { NotificationToggle } from "./NotificationToggle";
import { ProductType } from "../../types/ProductType";
import { Organization } from "../../types/Organization";
import { SubscriptionList } from "../../types/Subscription";
import { User as AuthUser } from "oidc-client-ts";

const useStyles = makeStyles((theme) => ({
    toggle: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        gap: theme.spacing(1),
        [theme.breakpoints.up("md")]: {
            justifyContent: "center"
        },
        [theme.breakpoints.down("md")]: {
            flexDirection: "column"
        }
    },
    alignEnd: {
        justifyContent: "flex-end"
    },
    chipsContainer: {
        display: "flex",
        flexWrap: "wrap",
        gap: 5,
        margin: theme.spacing(1, 0, 0),
        overflowX: "auto"
    },
    featured: {
        position: "relative",
        "&:after": {
            content: '""',
            position: "absolute",
            left: 0,
            top: 0,
            width: 5,
            height: "100%",
            backgroundColor: theme.palette.primary.main
        }
    },
    mobileListButton: {
        paddingRight: theme.spacing(2) + "!important"
    },
    mobileToggle: {
        display: "flex",
        position: "relative",
        transform: "none",
        justifyContent: "center"
    },
    mobileSubscribeLabel: {
        fontWeight: theme.typography.fontWeightBold as number,
        margin: theme.spacing(0, 0, 2)
    },
    titleWrapper: {
        marginTop: theme.spacing(1)
    },
    listTitle: {
        fontWeight: theme.typography.fontWeightBold as number
    },
    gutterBottom: {
        marginBottom: theme.spacing(1)
    },
    listItem: {
        border: `1px solid ${theme.palette.divider}`,
        borderTop: "none"
    },
    fistListItem: {
        borderTop: `1px solid ${theme.palette.divider}`
    },
    childList: {
        display: "block",
        paddingRight: 0 + " !important",
        paddingLeft: theme.spacing(4),
        [theme.breakpoints.down("md")]: {
            paddingLeft: 0
        }
    },
    chip: {
        backgroundColor: theme.palette.background.default,
        "& span": {
            color: theme.palette.text.primary
        }
    },
    externalChip: {
        "& svg": {
            marginLeft: theme.spacing(1) + "!important"
        }
    }
}));

export type NotificationsGroupMap = {
    groupName: string;
    source: string;
    notifications: SubscriptionList[];
    subGroups: NotificationsGroupMap[];
};

interface GroupCardProps extends NotificationsGroupMap {
    id: string;
    authUser?: AuthUser | null;
    organization?: Organization;
    handleSubscriptionUpdate: (subscription: SubscriptionList) => void;
    isLoading?: boolean;
}

const NotificationsListGroupCard: React.FC<GroupCardProps> = (props) => {
    const {
        id,
        groupName,
        source,
        notifications,
        subGroups,
        authUser,
        organization,
        handleSubscriptionUpdate,
        isLoading
    } = props;
    const theme = useTheme();
    const classes = useStyles();
    const isMobileView = useMediaQuery(theme.breakpoints.down("md"));
    const notificationService = useMemo(() => new NotificationsService(), []);

    const isWebsite =
        source === ProductType.CivicEngageCentral ||
        source === ProductType.CivicEngageEvolve ||
        source === ProductType.CivicEngageOpen;

    const isExternalLink = groupName === NotificationGroupType.ExternalLinks;

    return (
        <>
            <Grid
                className={clsx(classes.titleWrapper, { [classes.gutterBottom]: notifications.length > 0 })}
                container={true}
                justifyContent="space-between"
            >
                <Grid xs={12} md="auto">
                    <Typography
                        className={classes.listTitle}
                        variant="h6"
                        id={`${id}-group-title`}
                        component={subGroups.length > 0 ? "h2" : "h3"}
                    >
                        {isLoading ? <SkeletonLoader width={400} /> : groupName}
                    </Typography>
                </Grid>

                {!isLoading && isWebsite && (
                    <Grid xs="auto">
                        <Chip className={classes.chip} label="Municipal Website" />
                    </Grid>
                )}
            </Grid>

            <List id={`${id}-group-list`} aria-describedby={`${id}-group-title`}>
                {notifications.map((notification: SubscriptionList, index: number) => (
                    <ListItem
                        key={`${id}-${index}-${notification.id}`}
                        className={clsx({ [classes.mobileListButton]: isMobileView })}
                        classes={{
                            container: clsx(classes.listItem, {
                                [classes.fistListItem]: index === 0,
                                [classes.featured]: notification.isFeatured
                            })
                        }}
                        ListItemTextProps={{
                            primary: (
                                <div>
                                    <Typography>
                                        {isLoading ? <SkeletonLoader width={300} /> : notification.name}
                                    </Typography>

                                    <Typography variant="body2" color="textSecondary">
                                        {isLoading ? <SkeletonLoader width={200} /> : notification.description}
                                    </Typography>

                                    {Number(notification.tags?.length) > 0 || isExternalLink ? (
                                        <div className={classes.chipsContainer}>
                                            {notification.tags?.map((tag) => (
                                                <ChipPortal label={tag} key={tag} size="small" />
                                            ))}

                                            {isExternalLink && 
                                                <ChipPortal 
                                                    label="External" 
                                                    size="small" 
                                                    icon={<Icon path={mdiOpenInNew} size={0.75} aria-hidden="true" />}
                                                    className={classes.externalChip}
                                                />}
                                        </div>
                                    ) : null}
                                </div>
                            )
                        }}
                        itemSecondaryAction={
                            isLoading || !organization || isExternalLink ? null : (
                                <div className={clsx(classes.toggle, classes.alignEnd)}>
                                    <NotificationToggle
                                        toggleType="email"
                                        organization={organization}
                                        authUser={authUser}
                                        subscription={notification}
                                        notificationService={notificationService}
                                        emailSubscription={notification.emailSubscription ?? false}
                                        smsSubscription={notification.smsSubscription ?? false}
                                        handlePendingSubscription={true}
                                        onChangeComplete={(success: boolean) => {
                                            if (success) {
                                                notification.emailSubscription = !notification.emailSubscription;
                                                handleSubscriptionUpdate(notification);
                                            }
                                        }}
                                    />

                                    <NotificationToggle
                                        toggleType="sms"
                                        organization={organization}
                                        authUser={authUser}
                                        subscription={notification}
                                        notificationService={notificationService}
                                        emailSubscription={notification.emailSubscription ?? false}
                                        smsSubscription={notification.smsSubscription ?? false}
                                        handlePendingSubscription={true}
                                        onChangeComplete={(success: boolean) => {
                                            if (success) {
                                                notification.smsSubscription = !notification.smsSubscription;
                                                handleSubscriptionUpdate(notification);
                                            }
                                        }}
                                    />

                                    {isMobileView ? (
                                        <Typography className={classes.mobileSubscribeLabel}>Subscribe</Typography>
                                    ) : null}
                                </div>
                            )
                        }
                        ListItemSecondaryActionProps={{
                            className: isMobileView ? classes.mobileToggle : undefined
                        }}
                        {...(isExternalLink ? { href: notification.url, target: "_blank", button: true } : {})}
                    />
                ))}

                {subGroups.map(({ groupName, source, notifications, subGroups }) => (
                    <ListItem
                        key={`${groupName.replace(/\s+/g, "-").toLowerCase()}-card-key`}
                        className={classes.childList}
                    >
                        <NotificationsListGroupCard
                            id={`${groupName.replace(/\s+/g, "-").toLowerCase()}`}
                            groupName={groupName}
                            source={source}
                            notifications={notifications}
                            subGroups={subGroups}
                            authUser={authUser}
                            organization={organization}
                            handleSubscriptionUpdate={handleSubscriptionUpdate}
                        />
                    </ListItem>
                ))}
            </List>
        </>
    );
};

export default NotificationsListGroupCard;
