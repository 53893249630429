import React, { useState, useCallback, useMemo, useEffect } from "react";
import AccessibilityMessage from "../../AccessibilityMessage";
import ApiService from "../../../services/apiService";
import Button from "@civicplus/preamble-ui/lib/Button";
import DashboardZone from "../DashboardZone";
import Link from "@civicplus/preamble-ui/lib/Link";
import LinkCard, { LinkCardProps } from "../../LinkCard/LinkCard";
import Loader from "@civicplus/preamble-ui/lib/Loader";
import Grid from "@civicplus/preamble-ui/lib/Grid";
import Typography from "@civicplus/preamble-ui/lib/Typography";
import useStyles from "../styles";
import { CreatePublicLinkDialog } from "../../CreateNewLinkDialog/CreatePublicLinkDialog";
import { DocumentType } from "../../../types/SearchDocument";
import { Link as LinkEntity, PublicLink } from "../../../types/Link";
import { mdiWeb } from "@mdi/js";
import { NavigationOption, Organization } from "../../../types/Organization";
import { orderByProperty } from "../../../shared/functions";
import { Sections } from "../../../types/Sections";
import { shallow } from "zustand/shallow";
import { useFavorites } from "../../../shared/useFavorites";
import { useLinks } from "../../../stores/linksStore";
import { useNavigate } from "react-router-dom";
import { usePrompt } from "../../../shared/useCustomPrompt";
import { User as AuthUser } from "oidc-client-ts";
import { useSnackbar } from "notistack";

interface LinksDashboardProps {
    /**
     * The current user session
     */
    authUser: AuthUser | null | undefined;
    /**
     * The current organization
     */
    organization?: Organization;
}

const LinksDashboard: React.FC<LinksDashboardProps> = ({ authUser, organization }) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const [allLinks, setAllLinks] = useState<LinkCardProps[]>([]);
    const [isDirty, setIsDirty] = useState<boolean>(false);
    const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);

    const {
        organizationLinks,
        fetchSpecificFavorites,
        favoriteLinks,
        fetchLinksStatus,
        loadedAllFavorites,
        publicLinks,
        fetchPublicLinks,
        clearPublicLinks,
        fetchLinks
    } = useLinks(
        (state) => ({
            organizationLinks: state.links,
            fetchSpecificFavorites: state.fetchSpecificFavorites,
            favoriteLinks: state.favoriteLinks,
            fetchLinksStatus: state.fetchLinksStatus,
            loadedAllFavorites: state.loadedAllFavorites,
            publicLinks: state.publicLinks,
            fetchPublicLinks: state.fetchPublicLinks,
            clearPublicLinks: state.clearPublicLinks,
            fetchLinks: state.fetchLinks
        }),
        shallow
    );

    const [sortedPublicLinks, setSortedPublicLinks] = useState<PublicLink[]>(publicLinks ?? []);

    const [{ linksFavoriteMap }] = useFavorites();

    useEffect(() => {
        clearPublicLinks();
    }, [clearPublicLinks]);

    useEffect(() => {
        if (!authUser) {
            return;
        }

        const getLinkFavorites = async () => {
            await fetchSpecificFavorites(authUser, DocumentType.Link, enqueueSnackbar, organization?.id);
        };

        if (authUser && !loadedAllFavorites.links) {
            getLinkFavorites();
        }
    }, [authUser, fetchSpecificFavorites, loadedAllFavorites, enqueueSnackbar, organization?.id]);

    useEffect(() => {
        const getLinks = async () => {
            if (organization === undefined) {
                return;
            }

            await fetchLinks(organization.name, false);
        };
        getLinks();
    }, [organization, fetchLinks]);

    const featuredLinks = useMemo(() => {
        return orderByProperty(
            organizationLinks.filter((o: LinkEntity) => o.isFeatured === true && o.documentType === DocumentType.Link),
            "lastModified",
            true
        );
    }, [organizationLinks]);

    const sortedByDateLinks = useMemo(() => {
        return orderByProperty(
            organizationLinks.filter((o: LinkEntity) => o.documentType === DocumentType.Link),
            "lastModified",
            true
        );
    }, [organizationLinks]);

    const getPublicLinks = useCallback(async () => {
        if (!publicLinks && authUser) {
            await fetchPublicLinks(authUser);
        }

        if (publicLinks) {
            const sortedPublicLinks = [...orderByProperty(publicLinks, "lastModified", true)];

            sortedPublicLinks.map((pl) => (pl.aggregateId = `public-link-${pl.id}`));

            setSortedPublicLinks(sortedPublicLinks);
        }
    }, [authUser, publicLinks, fetchPublicLinks]);

    const onEditDialogClose = useCallback(() => {
        getPublicLinks();
    }, [getPublicLinks]);

    const onCreateDialogClose = useCallback(() => {
        setIsCreateDialogOpen(false);
        setIsDirty(false);
    }, []);

    const onCreateDialogSave = useCallback(() => {
        setIsCreateDialogOpen(false);
        setIsDirty(false);
        getPublicLinks();
    }, [getPublicLinks]);

    const onDeleteDialogClose = useCallback(() => {
        getPublicLinks();
        clearPublicLinks();
    }, [getPublicLinks, clearPublicLinks]);

    const onDeleteDialogAction = useCallback(
        async (id: string, title: string) => {
            if (!id) {
                console.error("No link to delete");
                return;
            }

            try {
                await ApiService.delete({
                    url: `publicLinks/${id}`,
                    authUser: authUser
                });

                onDeleteDialogClose();

                enqueueSnackbar(`"${title}" has been deleted.`, {
                    variant: "success"
                });

                getPublicLinks();
            } catch (ex) {
                console.error(ex);
                enqueueSnackbar("An error occured while deleting the link.", {
                    variant: "error",
                    persist: true
                });
            }
        },
        [enqueueSnackbar, onDeleteDialogClose, authUser, getPublicLinks]
    );

    useEffect(() => {
        if (!authUser) {
            return;
        }

        getPublicLinks();
    }, [authUser, getPublicLinks, publicLinks]);

    useEffect(
        function getDashboardLinks() {
            let joinedLinks = [];

            sortedPublicLinks.length > 0 &&
                sortedPublicLinks.forEach((l: PublicLink) => {
                    l.onDeleteAction = onDeleteDialogAction;
                    l.onModifyAction = onEditDialogClose;
                    l.onDeleteClose = onDeleteDialogClose;
                    l.onModifyClose = onEditDialogClose;
                    l.href = l.url;
                });

            if (authUser && favoriteLinks !== undefined) {
                const favoriteLinksOrdered = favoriteLinks ? orderByProperty(favoriteLinks, "lastModified", true) : [];
                joinedLinks = [...favoriteLinksOrdered, ...sortedPublicLinks];
            } else {
                const tempLinks = [...featuredLinks, ...sortedByDateLinks];
                joinedLinks = tempLinks.slice(0, 10);
            }

            const currentLinks: LinkCardProps[] = [];

            if (joinedLinks.length > 0) {
                joinedLinks.forEach((link) => {
                    if (!currentLinks.find((c) => c.aggregateId === link.aggregateId)) {
                        currentLinks.push({
                            id: link.id?.toString(),
                            aggregateId: link.aggregateId,
                            title: link.title,
                            description: link.description ?? " ",
                            href: link.url,
                            icon: link.icon,
                            section: Sections.Links,
                            created: link.created,
                            productType: link.productType,
                            additionalInformation: link.additionalInformation,
                            organizationId: link.organizationId,
                            isFeatured: link.isFeatured ?? false,
                            favorite: linksFavoriteMap.get(link.aggregateId) !== undefined,
                            tags: link.tags,
                            fullLink: link,
                            /* ↓ Public Links needed props ↓ */
                            userId: link?.userId,
                            onDeleteAction: link?.onDeleteAction,
                            onModifyAction: link?.onModifyAction,
                            onDeleteClose: link?.onDeleteClose,
                            onModifyClose: link?.onModifyClose
                        });
                    }
                });
            }

            setAllLinks(authUser && favoriteLinks !== undefined ? currentLinks : currentLinks.slice(0, 3));
        },
        [
            authUser,
            favoriteLinks,
            featuredLinks,
            linksFavoriteMap,
            sortedByDateLinks,
            sortedPublicLinks,
            onDeleteDialogAction,
            onEditDialogClose,
            onDeleteDialogClose
        ]
    );

    const zoneButtons: React.ReactNode[] | undefined = useMemo(() => {
        const buttons = [];

        if (authUser) {
            buttons.push(
                <Button
                    id="create-public-link-btn"
                    color="default"
                    className={classes.newLinkButton}
                    onClick={() => {
                        setIsCreateDialogOpen(true);
                        setIsDirty(true);
                    }}
                >
                    + New Link
                </Button>
            );
        }

        buttons.push(
            <Button id="view-all-links-btn" color="default" onClick={() => navigate(`/${organization?.name}/links`)}>
                View all links
            </Button>
        );

        return buttons;
    }, [authUser, navigate, organization?.name, classes.newLinkButton]);

    const zoneContent: React.ReactElement = useMemo(() => {
        if (authUser && favoriteLinks?.length === 0 && publicLinks?.length === 0) {
            return (
                <div className={classes.emptyZone}>
                    <Typography component="h3" variant="h5">
                        You have not saved any Links
                    </Typography>

                    <Typography>
                        <Link
                            href={
                                window.location.href.substring(0, window.location.href.lastIndexOf("/") + 1) + "links"
                            }
                            key="view-list-links"
                            underline="always"
                        >
                            View Link Lists
                        </Link>{" "}
                        and use the bookmark action on any Link to
                        <br /> save to your Dashboard for future reference.
                    </Typography>
                </div>
            );
        }

        if (!authUser && allLinks.length === 0) {
            return (
                <div className={classes.emptyZone}>
                    <Typography component="h3" variant="h5">
                        {organization?.name || "This organization"} does not currently have any available links.
                    </Typography>
                </div>
            );
        }

        return (
            <Grid container={true} spacing={2} role="list" aria-labelledby="links-dashboard-container">
                {allLinks.map((link) => {
                    return (
                        <LinkCard
                            role="listitem"
                            id={`${link.id}`}
                            key={`${link.id}-title`}
                            linksBackgroundColor="#FFF"
                            orgName={organization?.name}
                            organizationId={organization?.id}
                            userId={link?.userId}
                            isOnLinksDashboard={true}
                            {...link}
                        />
                    );
                })}
            </Grid>
        );
    }, [authUser, organization, favoriteLinks, publicLinks, allLinks, classes.emptyZone]);

    usePrompt("Changes you made may not be saved", isDirty);

    return (
        <>
            <DashboardZone icon={mdiWeb} title="Links" buttons={zoneButtons} navigationType={NavigationOption.Links}>
                <section id="links-dashboard-list">
                    {fetchLinksStatus !== "Complete" ? <Loader verticallyCenter={true} /> : <>{zoneContent} </>}

                    <AccessibilityMessage
                        message={fetchLinksStatus !== "Complete" ? "Loading links" : "Dashboard links loaded"}
                    />
                </section>
            </DashboardZone>

            <CreatePublicLinkDialog
                isOpen={isCreateDialogOpen}
                onClose={onCreateDialogClose}
                onSave={onCreateDialogSave}
                userId={authUser?.id_token || ""}
                modifying={false}
            />
        </>
    );
};

export default LinksDashboard;
