import React, { useEffect, useMemo, useState } from "react";
import Layout from "@civicplus/preamble-ui/lib/Layout";
import Loader from "@civicplus/preamble-ui/lib/Loader";
import Tabs from "@civicplus/preamble-ui/lib/Tabs";
import useStyles from "./styles";
import { AuthContextProps } from "../../components/react-oidc-context";
import { Breakpoint } from "@mui/material/styles";
import { getCurrentTabFromTabName, toggleDisplayFraseChatbot } from "../../shared/functions";
import { getHasGroupMemberAccess, isSuperUserOrOrganizationOwner } from "../../services/permissionService";
import { Organization } from "../../types/Organization";
import { tabsData } from "./settingsData";
import { useAuth } from "../../providers/AuthProvider";
import { useConfig } from "../../providers/ConfigProvider";
import { useEmbedStore } from "../../stores/embedStore";
import { useFeatures } from "../../shared/useFeatureFlags";
import { useNavigate, useParams } from "react-router-dom";
import { useOrganization } from "../../stores/organizationStore";
import { User } from "oidc-client-ts";

interface SettingsProps {
    auth: AuthContextProps;
    org: Organization;
    isSuperUserOrOrganizationOwner: boolean;
    widthOverride?: Breakpoint;
    currentTab?: number;
}

export const SettingsAccessWrapper: React.FC = () => {
    const auth = useAuth();
    const config = useConfig();
    const navigate = useNavigate();
    const { isLoading: isLoadingSession, user } = auth;

    const [organization] = useOrganization((state) => [state.organization]);
    const [hasPermissionsAccess, setHasPermissionsAccess] = useState(false);
    const [hasAccess, setHasAccess] = useState<boolean | undefined>(undefined);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        async function checkAccess() {
            const fetchGroupAccess = async (user: User, organization: Organization) => {
                const hasGroupAccess = await getHasGroupMemberAccess(user, organization);
                setHasAccess(hasGroupAccess);
            };

            if (user && organization) {
                if (isSuperUserOrOrganizationOwner(user, organization, config.superUserRole)) {
                    setHasAccess(true);
                    setHasPermissionsAccess(true);
                } else {
                    await fetchGroupAccess(user, organization);
                }
            } else if (!user && organization) {
                setHasAccess(false);
            }
        }

        if (!isLoadingSession && organization) {
            checkAccess();
            setIsLoading(false);
        }
    }, [organization, organization?.name, user, hasAccess, isLoadingSession, config]);

    if (isLoading || hasAccess === undefined) {
        return <Loader verticallyCenter={true} />;
    }

    if (hasAccess !== undefined && hasAccess === false) {
        navigate("/access-denied");
        return null;
    }

    if (organization) {
        return <Settings auth={auth} org={organization} isSuperUserOrOrganizationOwner={hasPermissionsAccess} />;
    }

    return <Loader verticallyCenter={true} />;
};

export const Settings: React.FC<SettingsProps> = ({ auth, org, isSuperUserOrOrganizationOwner }) => {
    const { tabName } = useParams();
    const classes = useStyles();
    const [flags, isFlagLoading] = useFeatures();
    const isEmbed = useEmbedStore((state) => state.isInitialized);

    const [currentSettingsTab, setCurrentSettingsTab] = useOrganization((state) => [
        state.currentSettingsTab,
        state.setCurrentSettingsTab
    ]);

    useEffect(() => {
        if (isFlagLoading) {
            return;
        }

        const tabIndex = getCurrentTabFromTabName(tabName);

        setCurrentSettingsTab(tabIndex);
    }, [tabName, flags, setCurrentSettingsTab, isFlagLoading]);

    useEffect(() => {
        toggleDisplayFraseChatbot(org, isEmbed || false, true);
    }, [org, isEmbed]);

    const tabsContents = useMemo(() => {
        if (isFlagLoading) {
            return [];
        }

        return tabsData
            .filter((tab) => !tab.flag || flags[tab.flag.name] === tab.flag.value) // Keep tabs that have flag enabled
            .filter((tab) => isSuperUserOrOrganizationOwner || tab.label !== "Permissions") // If the user is not a super user or organization owner, remove the permissions tab
            .map((tab) => ({
                label: tab.label,
                contents: React.createElement(tab.component, {
                    org,
                    auth,
                    tabName: tab.label,
                    itemName: tab.itemName,
                    ...tab.customProps
                })
            }));
    }, [auth, flags, isSuperUserOrOrganizationOwner, org, isFlagLoading]);

    const handleTabChange = (_e: React.SyntheticEvent, value: number) => {
        if (!tabsContents) {
            return;
        }

        setCurrentSettingsTab(value);
        const url = window.location.pathname.split("/");
        let newUrl = `/${url[1]}/${url[2]}`;

        const tabName = tabsContents[value].label.toLowerCase();

        newUrl = newUrl.concat(`/${tabName}`);

        window.history.replaceState({}, "", newUrl);
    };

    return (
        <Layout
            TitlebarProps={{
                id: "settings-titlebar",
                title: "Settings"
            }}
        >
            {tabsContents.length > 0 ? (
                <Tabs
                    id="settings-tabs"
                    value={currentSettingsTab}
                    onChange={handleTabChange}
                    tabContents={tabsContents}
                    tabsContentRenderingVariant="currentTab"
                    color="default"
                    className={classes.tabRoot}
                    variant="scrollable"
                />
            ) : (
                <Loader data-testid="loading" />
            )}
        </Layout>
    );
};

export default SettingsAccessWrapper;
