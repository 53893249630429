import { ProductType } from "./ProductType";
import { SearchDocument } from "./SearchDocument";
import { Dictionary } from "./Content";
import { DocumentType } from "../types/SearchDocument";

export interface Subscriber {
    email: string;
    firstName: string;
    id: string;
    isValidated: boolean;
    lastModified: string;
    lastName: string;
    phoneNumber: string;
}

export enum SubscriptionType {
    Email = "Email",
    Sms = "Sms",
    EmailAndSms = "EmailAndSms",
    None = "None"
}

export enum MissingConfiguration {
    None = "None",
    Account = "Account",
    SmsOnly = "SmsOnly"
}

export enum WeatherChannel {
    Text = "Text",
    Email = "Email",
    VoiceHome = "VoiceHome",
    VoiceCell = "VoiceCell"
}

export interface SubscriptionList {
    aggregateId: string;
    availableSubscriptionType: SubscriptionType;
    created?: string;
    description: string;
    id: number;
    internalOnly: boolean;
    lastModified?: string;
    missingConfiguration: MissingConfiguration;
    name: string;
    organizationId: string;
    documentType: DocumentType;
    productType: ProductType;
    emailSubscription?: boolean;
    smsSubscription?: boolean;
    homeSubscription?: boolean;
    cellSubscription?: boolean;
    url: string;
    additionalProducts: ProductType[];
    isFeatured: boolean;
    isHidden: boolean;
    additionalInformation?: Dictionary[];
    tags?: string[];
    externalTags?: string[];
    customTags?: string[];
    applicationId: string;
    supportedChannels?: WeatherChannel[];
    item?: SubscriptionList | unknown;
}

export interface AppIdsAndPhoneNumbers {
    [id: string]: PhoneNumberDetails[];
}

export interface PhoneNumberDetails {
    phoneNumber: string;
    isValidated: boolean;
}

export const convertSearchDocumentToSubscriptionList = (searchDocument: SearchDocument): SubscriptionList => {
    const internalOnlyProperty = searchDocument.additionalIndexedProperties?.find((a) => a.key === "InternalOnly");
    const isInternalOnly =
        (searchDocument.additionalIndexedProperties &&
            internalOnlyProperty &&
            internalOnlyProperty.value.toLowerCase() === "true") ||
        false;

    return {
        aggregateId: searchDocument.id,
        id: parseInt(searchDocument.itemId),
        organizationId: searchDocument.organizationId,
        name: searchDocument.title,
        description: searchDocument.description,
        created: searchDocument.createdDate.toString(),
        url: searchDocument.url,
        documentType: searchDocument.type as unknown as DocumentType,
        productType: searchDocument.productType,
        availableSubscriptionType: (searchDocument.item as SubscriptionList).availableSubscriptionType,
        internalOnly: isInternalOnly,
        missingConfiguration: MissingConfiguration.None,
        additionalProducts: (searchDocument.item as SubscriptionList).additionalProducts,
        isFeatured: searchDocument.isFeatured,
        isHidden: searchDocument.isHidden,
        additionalInformation: searchDocument.additionalIndexedProperties,
        applicationId: searchDocument.applicationId,
        item: searchDocument.item
    };
};
