import React from "react";
import List from "@civicplus/preamble-ui/lib/List";
import ListItem from "@civicplus/preamble-ui/lib/ListItem";
import Typography from "@civicplus/preamble-ui/lib/Typography";
import { makeStyles } from "@civicplus/preamble-ui/lib/Utilities/ThemeHelper";
import OpenInNewTab from "@mui/icons-material/OpenInNew";
import TablePagination from "@civicplus/preamble-ui/lib/TablePagination";
import SkeletonLoader from "@civicplus/preamble-ui/lib/SkeletonLoader";
import Chip from "../../../../components/Chip";
import { ChangesType } from "../types";

const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: theme.spacing(1)
    },
    list: {
        marginTop: theme.spacing(1),
        width: "100%"
    },
    externalLink: {
        marginTop: theme.spacing(1)
    }
}));

export interface RecentChangesTabProps {
    items?: Array<ChangesType>;
}

export const RecentChangesTab: React.FC<RecentChangesTabProps> = (props) => {
    const { items } = props;
    const classes = useStyles();
    const [currentPage, setCurrentPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const paginatedItems = items?.slice(currentPage * rowsPerPage, currentPage * rowsPerPage + rowsPerPage);
    const handleChangePage = async (
        _event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
        newPage: number
    ) => {
        setCurrentPage(newPage);
    };

    const handleChangeRowsPerPage = async (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10) || 10);
    };

    return (
        <div className={classes.root}>
            <Typography>
                The information displayed below spans the most recent updates, changes made later can be found on this
                publication’s library.
            </Typography>
            <List id="recent-changes-list" wrapperStyles={true} className={classes.list}>
                {paginatedItems === undefined ? (
                    Array.from({ length: 5 }, (_, i) => (
                        <ListItem
                            key={`skeleton-${i}`}
                            ListItemTextProps={{
                                primary: <SkeletonLoader width={300} />,
                                secondary: (
                                    <>
                                        <div>
                                            <SkeletonLoader width={100} />
                                        </div>
                                        <div>
                                            <SkeletonLoader width={100} />
                                        </div>
                                    </>
                                )
                            }}
                            divider={true}
                            button={false}
                        />
                    ))
                ) : paginatedItems.length === 0 ? (
                    <ListItem
                        key="empty-list"
                        ListItemTextProps={{
                            primary: <Typography align="center">Sorry, no results found</Typography>
                        }}
                    />
                ) : (
                    paginatedItems.map((item) => (
                        <ListItem
                            key={item.nodeId}
                            aria-label={`Open ${item.title} in a new tab`}
                            ListItemTextProps={{
                                primary: item.title,
                                secondary: (
                                    <>
                                        <div>{item.compareStatus.toUpperCase()}</div>
                                        <div className={classes.externalLink}>
                                            <Chip label="External" avatar={<OpenInNewTab />} size="small" />
                                        </div>
                                    </>
                                )
                            }}
                            button={true}
                            href={item.url}
                            target="_blank"
                            divider={true}
                        />
                    ))
                )}
            </List>
            {items && items.length > 0 && (
                <TablePagination
                    id="changes-results-pagination"
                    component="div"
                    count={items.length}
                    page={currentPage}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            )}
        </div>
    );
};
export default RecentChangesTab;
