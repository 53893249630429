import React from "react";
import Chip from "../../../../components/Chip";
import List from "@civicplus/preamble-ui/lib/List";
import ListItem from "@civicplus/preamble-ui/lib/ListItem";
import OpenInNewTab from "@mui/icons-material/OpenInNew";
import SkeletonLoader from "@civicplus/preamble-ui/lib/SkeletonLoader";
import TablePagination from "@civicplus/preamble-ui/lib/TablePagination";
import Typography from "@civicplus/preamble-ui/lib/Typography";
import { formatDate } from "@civicplus/preamble-ui/lib/Utilities/DateHelper";
import { LegislationType } from "../types";
import { makeStyles } from "@civicplus/preamble-ui/lib/Utilities/ThemeHelper";
import { visuallyHidden } from '@mui/utils';

const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: theme.spacing(1)
    },
    list: {
        marginTop: theme.spacing(1),
        width: "100%"
    },
    listItem: {
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing(1)
    },
    externalLink: {
        marginTop: theme.spacing(1)
    },
    title: {
        "& span": {
            fontSize: theme.typography.body2.fontSize,
            color: theme.palette.text.secondary
        }
    }
}));

export interface LegislationTabProps {
    items?: Array<LegislationType>;
}

export const LegislationTab: React.FC<LegislationTabProps> = (props) => {
    const { items } = props;
    const classes = useStyles();
    const [currentPage, setCurrentPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const paginatedItems = items?.slice(currentPage * rowsPerPage, currentPage * rowsPerPage + rowsPerPage);
    const handleChangePage = async (
        _event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
        newPage: number
    ) => {
        setCurrentPage(newPage);
    };

    const handleChangeRowsPerPage = async (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10) || 10);
    };

    return (
        <div className={classes.root}>
            <Typography>
                The listing below includes all legislation received by Municipal Code since the last update (printed or
                electronic) to the Code of Ordinances. This legislation has been enacted, but has not yet been codified.
                The information displayed below spans the most recent items, ordinances passed later can be found on
                this publication’s library.
            </Typography>

            <List id="ordinances-list" wrapperStyles={true} className={classes.list}>
                {paginatedItems === undefined ? (
                    Array.from({ length: 5 }, (_, i) => (
                        <ListItem
                            key={`skeleton-${i}`}
                            divider={true}
                            button={false}
                            ListItemTextProps={{
                                primary: <SkeletonLoader width={300} />,
                                secondary: (
                                    <>
                                        <div>
                                            <SkeletonLoader width={500} />
                                        </div>
                                        <div>
                                            <SkeletonLoader width={100} />
                                        </div>
                                    </>
                                )
                            }}
                        />
                    ))
                ) : paginatedItems.length === 0 ? (
                    <ListItem
                        key="empty-list"
                        ListItemTextProps={{
                            primary: <Typography align="center">Sorry, no results found</Typography>
                        }}
                    />
                ) : (
                    paginatedItems.map((item) => (
                        <ListItem
                            key={item.id}
                            href={item.url}
                            button={true}
                            divider={true}
                            target="_blank"
                            ListItemTextProps={{
                                primary: (
                                    <div className={classes.listItem}>
                                        <div className={classes.title}>
                                            <Typography>
                                                {item.title}{" "}
                                            </Typography>

                                            <span>
                                                — Adopted{" "}
                                                <span aria-hidden={true}>
                                                    {formatDate(item.adoptionDate, "MM/dd/yyyy")}
                                                </span>
                                                <Typography style={visuallyHidden}>
                                                    {formatDate(item.adoptionDate, "MMMM do, yyyy")}
                                                </Typography>
                                            </span>
                                        </div>

                                        <Typography variant="body2">
                                            {item.description}
                                        </Typography>

                                        <div>
                                            <Chip label="External" avatar={<OpenInNewTab />} size="small" />
                                        </div>
                                    </div>
                                )
                            }}
                        />
                    ))
                )}
            </List>

            {items && items.length > 0 && (
                <TablePagination
                    id="legislation-results-pagination"
                    component="div"
                    count={items.length}
                    page={currentPage}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            )}
        </div>
    );
};

export default LegislationTab;
