import React, { useState, useEffect, useMemo } from "react";
import Loader from "@civicplus/preamble-ui/lib/Loader/Loader";
import { AppConfig } from "../services/appConfig";
import { useEmbedStore } from "../stores/embedStore";

interface IConfigProps {
    children: React.ReactNode;
    isEmbed?: boolean;
    baseUrl?: string;
    organizationName?: string;
    defaultSection?: string;
    startingPath?: string;
    isUserSignedIn?: boolean;
    hideNavigation?: boolean;
    backgroundColor?: string;
}

const ConfigContext = React.createContext<AppConfig>(null!);

export function ConfigProvider(props: IConfigProps): JSX.Element {
    const [config, setConfig] = useState<AppConfig>({} as AppConfig);
    const embedStore = useEmbedStore();

    const {
        children,
        isEmbed = false,
        baseUrl,
        organizationName,
        defaultSection,
        startingPath,
        isUserSignedIn = false,
        hideNavigation = false,
        backgroundColor = undefined
    } = props;

    useEffect(() => {
        async function init() {
            const manager = new AppConfig();

            if (!manager.isInitialized()) {
                await manager.initAsync();
            }

            setConfig(manager);
        }

        init();

        if (isEmbed && !embedStore.isInitialized) {
            embedStore.initEmbedStore({
                organizationName,
                baseUrl,
                defaultSection,
                startingPath,
                isUserSignedIn,
                hideNavigation,
                backgroundColor
            });
        }
    }, []);

    return config.isInitialized === undefined ? (
        <Loader verticallyCenter={true} />
    ) : (
        <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>
    );
}

export function useConfig(): AppConfig {
    const configContext = React.useContext(ConfigContext);
    return useMemo(() => {
        return configContext;
    }, [configContext]);
}
