import React from "react";
import MuiChip, { ChipProps } from "@mui/material/Chip";
import { makeStyles, PreambleTheme } from "@civicplus/preamble-ui/lib/Utilities/ThemeHelper";

const useStyles = makeStyles((theme: PreambleTheme) => ({
    linkIcon: {
        width: theme.spacing(2) + "!important",
        backgroundColor: "inherit!important",
        color: theme.palette.primary.main + "!important",
        marginLeft: theme.spacing(1) + "!important"
    },
    transformTextNone: {
        textTransform: "none"
    }
}));

const Chip: React.FC<ChipProps> = (props) => {
    const classes = useStyles();

    return (
        <MuiChip
            variant="outlined"
            color="primary"
            classes={{ avatar: classes.linkIcon, root: classes.transformTextNone }} 
            {...props}
        />
    );
};

export default Chip;
