import { ValidationResult, errorMessage } from "@civicplus/preamble-ui/lib/Validations";

interface softMaxLengthValidationProps {
    errorMessages: { [key: string]: string };
    softMaxLength: number;
    label?: string;
}

export const softMaxLengthValidation = (value: string, props: softMaxLengthValidationProps) => {
    const validationName = "SoftMaxLength";
    const defaultErrorMessage = `${props.label ?? "Input"} length must be under ${props.softMaxLength} characters`;

    const isValid = value ? value.length < props.softMaxLength : true;

    const result = {
        error: !isValid,
        message: errorMessage(validationName, props.errorMessages, defaultErrorMessage),
        validationName: validationName
    };
    return result.error ? Promise.reject(result) : Promise.resolve(result);
};

export const isValidUrlValidation = (value: string): Promise<ValidationResult> => {
    let isValid = false;
    try {
        new URL(value);
        isValid = true;
    } catch {
        isValid = false;
    }

    const result = {
        error: !isValid,
        message: "You must insert a valid URL.",
        validationName: "isValidUrlValidation"
    };
    return result.error ? Promise.reject(result) : Promise.resolve(result);
};

export const isValidPhoneNumberValidation = (value: string): Promise<ValidationResult> => {
    const isValid = (value && RegExp(/^\d{10}$/).test(value)) || value === "";

    const result = {
        error: !isValid,
        message: "Phone Number must be 10 digits.",
        validationName: "isValidPhoneNumberValidation"
    };
    return result.error ? Promise.reject(result) : Promise.resolve(result);
};
