import React, { useState, useMemo, useCallback, useEffect } from "react";
import AccessibilityMessage from "../../AccessibilityMessage";
import { mdiBellAlert } from "@mdi/js";
import AlertList from "../../AlertList/AlertList";
import Link from "@civicplus/preamble-ui/lib/Link";
import Loader from "@civicplus/preamble-ui/lib/Loader";
import Typography from "@civicplus/preamble-ui/lib/Typography";
import useStyles from "../styles";
import { DocumentType } from "../../../types/SearchDocument";
import { KonexusAlertListItem } from "../../../types/Alerts";
import { LoginNoticeDialog } from "../../Authentication/LoginNoticeDialog";
import { NavigationOption, Organization } from "../../../types/Organization";
import { SubscriptionList } from "../../../types/Subscription";
import { useFavorites } from "../../../shared/useFavorites";
import { useNavigate } from "react-router-dom";
import { useOrganization } from "../../../stores/organizationStore";
import { User as AuthUser } from "oidc-client-ts";
import DashboardZone from "../DashboardZone";

const NUMBER_OF_ALERTS_TO_DISPLAY = 3;

interface AlertsDashboardProps {
    /**
     * The current user session
     */
    authUser: AuthUser | null | undefined;
    /**
     * The current organization
     */
    organization: Organization;
    /**
     * Array of organization subscriptions with user subscription info.
     */
    subscriptions: SubscriptionList[];
}

const AlertsDashboard: React.FC<AlertsDashboardProps> = ({ authUser, organization, subscriptions }) => {
    const classes = useStyles();
    const navigate = useNavigate();

    const [alertsToDisplay, setAlertsToDisplay] = useState<SubscriptionList[]>([]);
    const [{ loginNoticeOpen }, { handleCloseLoginNotice, handleLogin }] = useFavorites();
    const [fetchNotificationsStatus, setOrgSubscriptionsList, availableNotifications] = useOrganization((state) => [
        state.fetchNotificationsStatus,
        state.setOrgSubscriptionsList,
        state.availableNotifications
    ]);

    const alertSubscriptions = useMemo(
        () => subscriptions?.filter((subscription) => subscription.documentType === DocumentType.AlertList),
        [subscriptions]
    );

    const subscribedAlerts = useMemo(
        () =>
            alertSubscriptions?.filter(
                (s: SubscriptionList) => s.emailSubscription === true || s.smsSubscription === true
            ),
        [alertSubscriptions]
    );

    const retryActionLocalStorage = Object.keys({ ...localStorage }).find((s) => s.includes("retrySubscriptionAction"));

    useEffect(() => {
        if (alertSubscriptions?.length === 0) {
            return;
        }

        const featuredAlerts = alertSubscriptions?.filter((s: SubscriptionList) => s.isFeatured === true);

        let joinedAlerts: SubscriptionList[] = [];

        if (authUser && subscribedAlerts?.length > 0 && !retryActionLocalStorage) {
            joinedAlerts = [...subscribedAlerts];
        } else {
            let tempItems: SubscriptionList[] = [];

            if (featuredAlerts?.length > 0) {
                tempItems = [...featuredAlerts];
            }

            tempItems = alertSubscriptions ? [...tempItems, ...alertSubscriptions] : tempItems;

            joinedAlerts = tempItems.slice(0, 10);
        }

        const currentAlerts: SubscriptionList[] = [];

        if (joinedAlerts.length > 0) {
            joinedAlerts.forEach((alert) => {
                if (!currentAlerts.find((c) => c.aggregateId === alert.aggregateId)) {
                    currentAlerts.push(alert);
                }
            });
        }

        setAlertsToDisplay(
            authUser && subscribedAlerts?.length > 0
                ? currentAlerts
                : currentAlerts.slice(0, NUMBER_OF_ALERTS_TO_DISPLAY)
        );
    }, [alertSubscriptions, subscribedAlerts, authUser, retryActionLocalStorage]);

    const handleSubscriptionUpdate = useCallback(
        (subscription: SubscriptionList, subscribedToDefaultGroups: boolean) => {
            // update all the featured subscription list
            if (subscribedToDefaultGroups) {
                const subscriptionUpdate = subscriptions.map((sub) => {
                    if (
                        (sub.item as KonexusAlertListItem).defaultGroup &&
                        sub.documentType === DocumentType.AlertList
                    ) {
                        sub.smsSubscription = !sub.smsSubscription;
                        sub.emailSubscription = !sub.emailSubscription;
                    }
                    return sub;
                });

                setOrgSubscriptionsList(subscriptionUpdate);
            } else {
                const thisSubscription = subscriptions.find((sub) => sub.aggregateId === subscription.aggregateId);

                if (thisSubscription) {
                    thisSubscription.smsSubscription = subscription.smsSubscription;
                }

                // create a new array. this is for places where are using React's useMemo since it does a shallow comparison and otherwise it won't re-render
                const subscriptionListUpdated = [...subscriptions];
                setOrgSubscriptionsList(subscriptionListUpdated);
            }
        },
        [setOrgSubscriptionsList, subscriptions]
    );

    const zoneContent: React.ReactElement = useMemo(() => {
        if (alertsToDisplay.length === 0) {
            return (
                <Typography className={classes.emptyMessage}>
                    At present, <b>{organization?.name || "This organization"}</b> does not have have any available
                    Alerts.
                </Typography>
            );
        }

        if (authUser && subscribedAlerts?.length === 0 && !retryActionLocalStorage) {
            return (
                <div className={classes.emptyZone}>
                    <Typography component="h3" variant="h5">
                        You have not subscribed to any Alerts
                    </Typography>

                    <Typography>
                        <Link
                            key="view-all-alerts"
                            onClick={() => navigate(`/${organization?.name}/notifications?tab=alerts`)}
                            underline="always"
                        >
                            View the Alerts List
                        </Link>{" "}
                        and use the subscription toggles to
                        <br /> subscribe and manage notifications to say informed.
                    </Typography>
                </div>
            );
        }

        return (
            <AlertList
                alerts={alertsToDisplay}
                handleSubscriptionUpdate={handleSubscriptionUpdate}
                organization={organization}
            />
        );
    }, [
        classes,
        navigate,
        authUser,
        organization,
        alertsToDisplay,
        subscribedAlerts,
        retryActionLocalStorage,
        handleSubscriptionUpdate
    ]);

    if (availableNotifications !== undefined && availableNotifications[DocumentType.AlertList] === false) {
        return null;
    }

    return (
        <>
            <DashboardZone
                icon={mdiBellAlert}
                title="Alerts"
                url={`/${organization.name}/notifications?tab=alerts`}
                navigationType={NavigationOption.AlertsAndNotifications}
            >
                <section id="alerts-dashboard-list">
                    {fetchNotificationsStatus !== "Complete" ? <Loader verticallyCenter={true} /> : <>{zoneContent}</>}
                    <AccessibilityMessage
                        message={fetchNotificationsStatus !== "Complete" ? "Loading Alerts" : "Dashboard Alerts loaded"}
                    />
                </section>
            </DashboardZone>

            <LoginNoticeDialog isOpen={loginNoticeOpen} onClose={handleCloseLoginNotice} handleLogin={handleLogin} />
        </>
    );
};

export default AlertsDashboard;
