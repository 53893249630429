import {
    ApplicationsSettings,
    LinkSettings,
    NavigationSettings,
    Permissions,
    SettingsSection
} from "../../components/Settings";
import { FeatureFlag } from "../../shared/useFeatureFlags";
import { DocumentType } from "../../types/SearchDocument";

type TabProps = {
    label: string;
    itemName: string;
    component: React.FC<any>;
    flag?: { name: FeatureFlag; value: boolean };
    customProps?: { [key: string]: boolean | DocumentType | DocumentType[] };
};

export const tabsData: TabProps[] = [
    { label: "Links", itemName: "Link", component: LinkSettings },
    {
        label: "Forms",
        itemName: "Form",
        component: SettingsSection,
        customProps: { type: DocumentType.Form, showFeatureOption: true, showNewLink: true }
    },
    {
        label: "Notifications",
        itemName: "Notification",
        component: SettingsSection,
        customProps: { type: DocumentType.SubscriptionList, showFeatureOption: true, showNewLink: true }
    },
    {
        label: "Meetings",
        itemName: "Meeting",
        component: SettingsSection,
        customProps: { type: DocumentType.Event, showFeatureOption: true, showNewLink: true }
    },
    {
        label: "Alerts",
        itemName: "Alert",
        component: SettingsSection,
        customProps: { type: DocumentType.AlertList, showFeatureOption: true, showNewLink: false }
    },
    {
        label: "Legislation",
        itemName: "Publication",
        component: SettingsSection,
        customProps: {
            type: DocumentType.Publication,
            searchMultipleDocumentTypes: [DocumentType.Publication, DocumentType.PublicationDocument],
            showFeatureOption: true,
            showNewLink: false
        }
    },
    {
        label: "Applications",
        itemName: "Application",
        flag: { name: FeatureFlag.ShowSettingsNavigation, value: false },
        component: ApplicationsSettings
    },
    {
        label: "Navigation",
        itemName: "Navigation",
        flag: { name: FeatureFlag.ShowSettingsNavigation, value: true },
        component: NavigationSettings
    },
    { label: "Permissions", itemName: "Permission", component: Permissions }
];
