import React, { createContext, useCallback, useContext, useEffect, useState } from "react";
import ApiService from "../services/apiService";
import { useAuth } from "../providers/AuthProvider";
import { useOrganization } from "../stores/organizationStore";
import { UserAccountDetails } from "../types/Account";
import { PhoneNumber } from "../components/AlertsSubscriptionDialog/PhoneNumber/PhoneNumberUtils";

export const UserAccountInfoContext = createContext<
    [boolean, UserAccountDetails | undefined, () => void, (newPhones: PhoneNumber[]) => void] | undefined
>(undefined);

export function UserAccountInfoProvider({ children }: { children: React.ReactNode }): React.ReactElement {
    const auth = useAuth();
    const [organization] = useOrganization((state) => [state.organization]);
    const [userAccountInfo, setUserAccountInfo] = useState<UserAccountDetails | undefined>(undefined);
    const [isLoading, setIsLoading] = useState(true);
    const [reloadAccountInfo, setReloadAccountInfo] = useState<boolean>(false);

    const reloadData = useCallback(() => {
        setReloadAccountInfo((prev) => {
            return !prev;
        });
    }, []);

    const updateAccountInfoPhoneNumbers = useCallback((newPhones: PhoneNumber[]) => {
        setUserAccountInfo((prevState) => {
            return {
                ...(prevState as UserAccountDetails),
                phoneNumbers: newPhones,
                phoneNumbersUpdated: true
            };
        });
    }, []);

    useEffect(() => {
        let isCancelled = false;
        setIsLoading(true);

        try {
            const fetchUserAccountInfo = async () => {
                const urlOrgPart = `${organization?.name ? organization.name + "/" : ""}`;
                const user: UserAccountDetails = await ApiService.get({
                    url: `${urlOrgPart}user/account`,
                    cache: false,
                    authUser: auth.user
                });

                if (!isCancelled) {
                    setUserAccountInfo(user);
                    setIsLoading(false);
                }
            };

            if (!auth.isLoading && auth.isAuthenticated) {
                fetchUserAccountInfo();
            }
        } catch {
            setIsLoading(false);
        }

        return () => {
            isCancelled = true;
        };
    }, [auth.isLoading, auth.isAuthenticated, auth.user, organization, reloadAccountInfo]);

    return (
        <UserAccountInfoContext.Provider
            value={[isLoading, userAccountInfo, reloadData, updateAccountInfoPhoneNumbers]}
        >
            {children}
        </UserAccountInfoContext.Provider>
    );
}

export function useAccountInfo(): [
    boolean,
    UserAccountDetails | undefined,
    () => void,
    (newPhones: PhoneNumber[]) => void
] {
    const value = useContext(UserAccountInfoContext);

    if (value === undefined) {
        throw Error(
            "`useAccountInfo` must be used inside of a `<UserAccountInfoProvider>...</UserAccountInfoProvider>`"
        );
    }

    return value;
}
