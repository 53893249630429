import React, { useState, useEffect } from "react";
import Typography from "@civicplus/preamble-ui/lib/Typography";
import { visuallyHidden } from '@mui/utils';

interface MessageProps {
    message: string;
}

const AccessibilityMessage: React.FC<MessageProps> = ({ message }) => {
    const [visibleMessage, setVisibleMessage] = useState<string>("");

    useEffect(() => {
        // Clear the previous message first to ensure the screen reader detects a change
        setVisibleMessage("");

        const timer = setTimeout(() => {
            setVisibleMessage(message);
        }, 100); // delay in milliseconds

        return () => clearTimeout(timer);
    }, [message]);

    return (
        <Typography style={visuallyHidden} role="status" aria-live="assertive">
            {visibleMessage}
        </Typography>
    );
};

export default AccessibilityMessage;
