import React, { MouseEventHandler, useMemo } from "react";
import AlertIcon from "@mui/icons-material/NotificationsActive";
import AlertToggle from "./AlertToggle";
import Button from "@civicplus/preamble-ui/lib/Button";
import ChipPortal from "./Chip";
import classNames from "classnames";
import clsx from "clsx";
import Grid from "@civicplus/preamble-ui/lib/Grid";
import Icon from "@mdi/react";
import languages from "../shared/languages";
import ListItem from "@civicplus/preamble-ui/lib/ListItem";
import Typography from "@civicplus/preamble-ui/lib/Typography";
import { convertSearchDocumentToSubscriptionList, SubscriptionList } from "../types/Subscription";
import { DocumentType, SearchDocument } from "../types/SearchDocument";
import { default as FormIcon } from "mdi-material-ui/FormSelect";
import { default as LinkIcon } from "mdi-material-ui/Link";
import { default as MeetingsIcon } from "mdi-material-ui/Bank";
import { default as OpenInNewTab } from "mdi-material-ui/OpenInNew";
import { default as SubscriptionIcon } from "mdi-material-ui/FormatListBulleted";
import { Link, LinkUtilities } from "../types/Link";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { mdiBookmark, mdiBookmarkOutline, mdiCalendarClock } from "@mdi/js";
import { NotificationsService } from "../services/notificationsService";
import { NotificationToggle } from "../components/Notifications/NotificationToggle";
import { formatDate } from "@civicplus/preamble-ui/lib/Utilities/DateHelper";
import { Organization } from "../types/Organization";
import { useNavigate } from "react-router-dom";
import { User as AuthUser } from "oidc-client-ts";
import { useAccountInfo } from "../hooks/useAccountInfo";
import { useOrganization } from "../stores/organizationStore";
import { shallow } from "zustand/shallow";
import { ProductType } from "../types/ProductType";

const useStyles = makeStyles((theme: Theme) => ({
    chipsContainer: {
        display: "flex",
        flexWrap: "wrap",
        gap: 5,
        margin: theme.spacing(1, 0, 0),
        overflowX: "auto"
    },
    root: {
        padding: theme.spacing(2),
        overflow: "hidden",
        marginTop: 25
    },
    bookmarkButton: {
        margin: theme.spacing(1)
    },
    disabled: { color: theme.palette.text.disabled },
    externalLink: {
        margin: "-.15em .15em"
    },
    listItem: {
        alignItems: "center",
        display: "flex",
        textTransform: "none"
    },
    pagination: {
        justifyContent: "center",
        padding: "10px"
    },
    paginationToolbar: {
        flexDirection: "row"
    },
    smallIcon: { margin: "4px 0px 0px 0px" },
    togglesSections: {
        alignItems: "center",
        display: "flex",
        justifyContent: "space-between",
        minWidth: 180
    },
    active: {
        position: "relative",
        "&:after": {
            content: '""',
            position: "absolute",
            left: 0,
            width: 5,
            height: "100%",
            backgroundColor: theme.palette.primary.main
        }
    },
    toggle: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        gap: theme.spacing(1),
        [theme.breakpoints.up("md")]: {
            justifyContent: "center"
        }
    },
    alignEnd: {
        justifyContent: "flex-end"
    }
}));

interface PortalListItemsProps {
    /**
     * The current user session.
     */
    authUser: AuthUser | null | undefined;
    /**
     * The current organization
     */
    organization: Organization;

    /**
     * Array of user's subscriptions.
     */
    subscriptionList: SubscriptionList[];

    /**
     * Items to render
     */
    items: SearchDocument[];

    /**
     * True if app is embeded
     */
    isEmbed: boolean;

    /**
     * User Favorites Map
     */
    favorites: Map<string, Link>;

    /**
     * True to check local storage and handle any pending subscriptions.
     */
    handlePendingSubscriptions: boolean;

    /**
     * Favorite Click Handler
     * @param e Event
     * @param link Document to Favorite
     * @returns Promise
     */
    handleFavoriteClick: (e: React.MouseEvent, link: SearchDocument) => Promise<void>;
}

const ItemIcon: Record<string, React.ReactElement> = {
    [DocumentType.AlertList.toString()]: <AlertIcon />,
    [DocumentType.SubscriptionList.toString()]: <SubscriptionIcon />,
    [DocumentType.Form.toString()]: <FormIcon />,
    [DocumentType.Event.toString()]: <MeetingsIcon />
};

export const PortalListItems: React.FC<PortalListItemsProps> = ({
    authUser,
    organization,
    subscriptionList,
    items,
    isEmbed,
    favorites,
    handlePendingSubscriptions,
    handleFavoriteClick
}) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const notificationService = useMemo(() => new NotificationsService(), []) as NotificationsService;
    const [, userAccountInfo, reloadAccountInfo] = useAccountInfo();
    const [setOrgSubscriptionsList] = useOrganization((state) => [state.setOrgSubscriptionsList], shallow);

    const listItems = items.map((item) => {
        const { url, isExternal } = LinkUtilities.navigateUrl(
            item.productType,
            organization.name,
            item.itemId,
            item.url,
            undefined,
            isEmbed
        );

        item.isFavorite = favorites.get(item.id) !== undefined;

        const startDate =
            item.type === DocumentType.Event && item.startDate
                ? formatDate(item.startDate, "MM/dd/yyyy h:mm aa")
                : undefined;

        return (
            <ListItem
                itemAvatar={ItemIcon[item.type.toString()] || <LinkIcon />}
                data-testid={`listItem-${item.id}`}
                key={item.id}
                button={true}
                className={(classes.listItem, item.isFeatured ? classes.active : "")}
                divider={true}
                ListItemTextProps={{
                    primary: (
                        <div data-testid={`title-${item.id}`}>
                            <Typography>
                                {item.title}
                            </Typography>

                            {isExternal ? (
                                <OpenInNewTab
                                    id={`${item.id}-open-in-new-tab`}
                                    className={classes.externalLink}
                                    aria-label="Open in new tab"
                                    data-testid={`${item.id}-open-in-new-tab`}
                                />
                            ) : undefined}
                        </div>
                    ),
                    secondary: (
                        <div data-testid={`description-${item.id}`}>
                            <Typography variant="body2">
                                {item.description}
                            </Typography>

                            {startDate && (
                                <Grid container={true} spacing={1}>
                                    <Grid>
                                        <Icon
                                            path={mdiCalendarClock}
                                            size={0.75}
                                            className={clsx(classes.smallIcon, classes.disabled)}
                                        />
                                    </Grid>

                                    <Grid xs={11} style={{ padding: "7px 3px" }}>
                                        {startDate}
                                    </Grid>
                                </Grid>
                            )}

                            {item.tags.length > 0 && (
                                <div className={classes.chipsContainer}>
                                    {item.tags.map((tag, index) => (
                                        <ChipPortal
                                            data-testid={`${item.id}-tag-${index}`}
                                            label={tag}
                                            key={tag}
                                            size="small"
                                        />
                                    ))}
                                </div>
                            )}
                        </div>
                    ),
                    onClick: (): MouseEventHandler | undefined => {
                        if (isExternal) {
                            window.open(url);
                        } else {
                            navigate(url);
                        }
                        return undefined;
                    }
                }}
                itemSecondaryAction={
                    <>
                        {{
                            [DocumentType.AlertList.toString()]: (
                                <div className={classNames(classes.toggle, classes.alignEnd)}>
                                    <AlertToggle
                                        id={`${item.id}-alert-toggle`}
                                        subscribed={
                                            subscriptionList.find((s) => s.aggregateId === item.id)?.smsSubscription ??
                                            false
                                        }
                                        organization={organization}
                                        subscription={convertSearchDocumentToSubscriptionList(item)}
                                        notificationService={notificationService}
                                        documentType={DocumentType.AlertList}
                                        languages={languages}
                                        userAccountDetails={userAccountInfo}
                                        reloadAccountDetails={reloadAccountInfo}
                                        handlePendingSubscription={handlePendingSubscriptions}
                                        onChangeComplete={(success: boolean, subscribedToDefaultGroups: boolean) => {
                                            if (success) {
                                                // update all the featured subscription list
                                                if (subscribedToDefaultGroups) {
                                                    const subscriptionUpdate = subscriptionList.map((s) => {
                                                        if (s.isFeatured && s.documentType === DocumentType.AlertList) {
                                                            s.smsSubscription = !s.smsSubscription;
                                                            s.emailSubscription = !s.emailSubscription;
                                                        }
                                                        return s;
                                                    });
                                                    setOrgSubscriptionsList(subscriptionUpdate);
                                                } else {
                                                    const subscription = subscriptionList.find(
                                                        (s) => s.aggregateId === item.id
                                                    );
                                                    if (subscription) {
                                                        subscription.smsSubscription = !subscription.smsSubscription;
                                                        subscription.emailSubscription =
                                                            !subscription.emailSubscription;
                                                        // create a new array. this is for places where are using React's useMemo since it does a shallow comparison and otherwise it won't re-render
                                                        const subscriptionListUpdated = [...subscriptionList];
                                                        setOrgSubscriptionsList(subscriptionListUpdated);
                                                    }
                                                }
                                            }
                                        }}
                                    />
                                </div>
                            ),
                            [DocumentType.SubscriptionList.toString()]: item.productType !==
                                ProductType.CivicPlusPortal && (
                                <div className={classes.togglesSections}>
                                    <NotificationToggle
                                        toggleType="email"
                                        organization={organization}
                                        authUser={authUser}
                                        subscription={convertSearchDocumentToSubscriptionList(item)}
                                        notificationService={notificationService}
                                        handlePendingSubscription={handlePendingSubscriptions}
                                        emailSubscription={
                                            subscriptionList.find((s) => s.aggregateId === item.id)
                                                ?.emailSubscription ?? false
                                        }
                                        smsSubscription={
                                            subscriptionList.find((s) => s.aggregateId === item.id)?.smsSubscription ??
                                            false
                                        }
                                    />

                                    <NotificationToggle
                                        toggleType="sms"
                                        organization={organization}
                                        authUser={authUser}
                                        subscription={convertSearchDocumentToSubscriptionList(item)}
                                        notificationService={notificationService}
                                        handlePendingSubscription={handlePendingSubscriptions}
                                        emailSubscription={
                                            subscriptionList.find((s) => s.aggregateId === item.id)
                                                ?.emailSubscription ?? false
                                        }
                                        smsSubscription={
                                            subscriptionList.find((s) => s.aggregateId === item.id)?.smsSubscription ??
                                            false
                                        }
                                    />
                                </div>
                            )
                        }[item.type.toString()] || (
                            <Button
                                id={`bookmark-icon-btn-${item.id}`}
                                data-testid={`bookmark-icon-btn-${item.id}`}
                                size="small"
                                type="icon"
                                stopPropagation={true}
                                onClick={(e: React.MouseEvent<HTMLElement>) => {
                                    handleFavoriteClick(e, item);
                                }}
                                title={`${
                                    item.isFavorite ? `Remove ${item.title} from` : `Add ${item.title} to`
                                } my bookmarks`}
                                aria-pressed={item.isFavorite}
                                aria-label={`${item.isFeatured ? (item.isFavorite ? "Remove Featured Bookmark" : "Add Featured Bookmark") : null}`}
                            >
                                <Icon
                                    path={item.isFavorite ? mdiBookmark : mdiBookmarkOutline}
                                    size={1}
                                    aria-hidden="true"
                                    data-testid={
                                        item.isFavorite ? `${item.id}-remove-favorite` : `${item.id}-add-favorite`
                                    }
                                />
                            </Button>
                        )}
                    </>
                }
                {...{ openInNewWindow: isExternal }}
            />
        );
    });

    return <>{listItems}</>;
};

export default PortalListItems;
