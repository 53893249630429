import React, { useState, useMemo, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AccessibilityMessage from "../../AccessibilityMessage";
import Loader from "@civicplus/preamble-ui/lib/Loader";
import Link from "@civicplus/preamble-ui/lib/Link";
import Typography from "@civicplus/preamble-ui/lib/Typography";
import { shallow } from "zustand/shallow";
import { mdiFormSelect } from "@mdi/js";
import { NavigationOption, Organization, PagedResult } from "../../../types/Organization";
import { useLinks } from "../../../stores/linksStore";
import { useFavorites } from "../../../shared/useFavorites";
import { DocumentType, SearchDocument } from "../../../types/SearchDocument";
import ApiService from "../../../services/apiService";
import { User as AuthUser } from "oidc-client-ts";
import FormsList from "../../Forms/FormsList";
import useStyles from "../styles";
import DashboardZone from "../DashboardZone";
import { useSnackbar } from "notistack";

interface FormsDashboardProps {
    /**
     * The current user session.
     */
    authUser: AuthUser | null | undefined;
    /**
     * The current organization
     */
    organization?: Organization;
}

const FormsDashboard: React.FC<FormsDashboardProps> = ({ authUser, organization }) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [forms, setForms] = useState<PagedResult<SearchDocument>>({
        items: [],
        count: 0
    });

    const { fetchSpecificFavorites, favoriteForms, loadedAllFavorites } = useLinks(
        (state) => ({
            fetchSpecificFavorites: state.fetchSpecificFavorites,
            favoriteForms: state.favoriteForms,
            loadedAllFavorites: state.loadedAllFavorites
        }),
        shallow
    );

    const [{ formsFavoriteMap }] = useFavorites();

    useEffect(() => {
        if (!authUser) {
            return;
        }

        const getFormFavorites = async () => {
            await fetchSpecificFavorites(authUser, DocumentType.Form, enqueueSnackbar, organization?.id);
        };

        if (authUser && !loadedAllFavorites?.forms) {
            getFormFavorites();
        }
    }, [authUser, fetchSpecificFavorites, loadedAllFavorites, enqueueSnackbar, organization?.id]);

    useEffect(() => {
        async function fetchForms() {
            const allForms: PagedResult<SearchDocument> = await ApiService.get({
                url: `${organization?.name}/search?$top=1000&$orderby=isFeatured desc,lastModifiedDate desc&documentType=Form`,
                cache: false,
                authUser: authUser
            });

            setForms(allForms);
            setIsLoading(false);
        }
        fetchForms();
    }, [authUser, organization?.name]);

    const bookmarkedForms = useMemo(() => {
        if (authUser && loadedAllFavorites?.forms && favoriteForms && favoriteForms.length > 0) {
            return forms.items.filter((x) => formsFavoriteMap.get(x.id) !== undefined);
        }
        return forms.items.slice(0, 3);
    }, [forms, authUser, loadedAllFavorites, formsFavoriteMap, favoriteForms]);

    const zoneContent: React.ReactElement = useMemo(() => {
        if (forms.items.length === 0) {
            return (
                <Typography className={classes.emptyMessage}>
                    At present, <b>{organization?.name || "This organization"}</b> does not have any available forms.
                </Typography>
            );
        }

        if (authUser && favoriteForms?.length === 0) {
            return (
                <div className={classes.emptyZone}>
                    <Typography component="h3" variant="h5">
                        You have not saved any Forms
                    </Typography>

                    <Typography>
                        <Link
                            onClick={() => navigate(`/${organization?.name}/forms`)}
                            key="view-list-forms"
                            underline="always"
                        >
                            View Forms Lists
                        </Link>{" "}
                        and use the bookmark action on any Form to
                        <br /> save to your Dashboard for future reference.
                    </Typography>
                </div>
            );
        }

        return <FormsList forms={bookmarkedForms} orgName={organization?.name} />;
    }, [
        favoriteForms,
        forms.items,
        authUser,
        bookmarkedForms,
        organization?.name,
        navigate,
        classes.emptyMessage,
        classes.emptyZone
    ]);

    return (
        <DashboardZone
            icon={mdiFormSelect}
            title="Forms"
            url={`/${organization?.name}/forms`}
            navigationType={NavigationOption.Forms}
        >
            <section id="forms-dashboard-list">
                {isLoading ? <Loader verticallyCenter={true} /> : <>{zoneContent} </>}
                <AccessibilityMessage message={isLoading ? "Loading forms" : "Dashboard forms loaded"} />
            </section>
        </DashboardZone>
    );
};

export default FormsDashboard;
