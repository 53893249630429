import { makeStyles } from "@civicplus/preamble-ui/lib/Utilities/ThemeHelper";

const useStyles = makeStyles(() => ({
    gridTextWrapper: {
        display: "grid",
        alignItems: "center",
        paddingRight: "0px !important"
    },
    gridIconWrapper: {
        paddingRight: "35px !important"
    }
}));

export default useStyles;
