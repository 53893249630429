import React, { useState, useMemo, useEffect } from "react";
import Alert from "@civicplus/preamble-ui/lib/Alert";
import Collapse from "@mui/material/Collapse";
import Link from "@civicplus/preamble-ui/lib/Link";
import { checkIfPageIsHidden } from "../shared/functions";
import { isSuperUserOrOrganizationOwner, getHasGroupMemberAccess } from "../services/permissionService";
import { makeStyles } from "@civicplus/preamble-ui/lib/Utilities/ThemeHelper";
import { NavigationOption } from "../types/Organization";
import { useAuth } from "../providers/AuthProvider";
import { useConfig } from "../providers/ConfigProvider";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import { useOrganization } from "../stores/organizationStore";

const useStyles = makeStyles(() => ({
    root: {
        margin: "5px 0 15px"
    }
}));

interface HiddenPageAlertProps {
    navOption: NavigationOption;
}

const HiddenPageAlert: React.FC<HiddenPageAlertProps> = ({ navOption }) => {
    const classes = useStyles();
    const auth = useAuth();
    const config = useConfig();
    const navigate = useNavigate();
    const organization = useOrganization((state) => state.organization);

    const [isOpen, setIsOpen] = useState<boolean>(false);

    const isHidden = useMemo(() => {
        const hidden = checkIfPageIsHidden(navOption, organization?.navigation);
        setIsOpen(hidden);

        return hidden;
    }, [organization, navOption]);

    useEffect(() => {
        async function verifyHidden() {
            const hasGroupAccess = auth.user ? await getHasGroupMemberAccess(auth.user, organization!) : false;

            if (
                !auth.user ||
                (auth.user &&
                    !isSuperUserOrOrganizationOwner(auth.user, organization!, config.superUserRole) &&
                    !hasGroupAccess)
            ) {
                navigate(`/${organization?.name}/dashboard`);
            }
        }

        if (isHidden && organization) {
            verifyHidden();
        }
    }, [isHidden, auth.user, organization, config, navigate]);

    if (!isHidden) {
        return null;
    }

    return (
        <div className={classes.root}>
            <Collapse in={isOpen}>
                <Alert id="hidden-page-alert" alignAction="center" severity="warning" onClose={() => setIsOpen(false)}>
                    This section is hidden from Public users. You can update this setting in{" "}
                    <Link
                        underline="always"
                        component={RouterLink}
                        style={{ cursor: "pointer" }}
                        key="redirect-to-settings-navigation"
                        to={`/${organization?.name}/settings/navigation`}
                    >
                        Portal Navigation settings.
                    </Link>{" "}
                </Alert>
            </Collapse>
        </div>
    );
};

export default HiddenPageAlert;
