/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import CollapsableHeader from "./CollapsibleHeader/CollapsibleHeader";
import LinkCard, { LinkCardProps } from "./LinkCard/LinkCard";
import Grid from "@civicplus/preamble-ui/lib/Grid";
import Link from "@civicplus/preamble-ui/lib/Link";
import Typography from "@civicplus/preamble-ui/lib/Typography";
import { Link as PortalLink } from "../types/Link";

const useStyles = makeStyles((theme: Theme) => ({
    title: {
        marginBottom: theme.spacing(2)
    },
    linkTitle: {
        color: theme.palette.text.primary,
        textDecoration: "none",
        "&:hover": {
            textDecoration: "underline !important"
        }
    },
    section: {
        marginBottom: theme.spacing(3)
    },
    list: {
        marginTop: theme.spacing(2)
    }
}));

export interface LinkSectionProps extends React.HTMLProps<HTMLDivElement> {
    /**
     * The title to display for the link section
     */
    title: string;
    /**
     * The links to render for this section
     */
    links: LinkCardProps[];
    /**
     * Link's Organization name
     */
    orgName?: string;
    /**
     * Link's Organization id
     */
    organizationId?: string;
    /**
     * Links background color
     */
    linksBackgroundColor?: string;
    /**
     * Check if LinkSection is on Dashboard page
     */
    isOnDashboard?: boolean;

    /**
     * User Favorites
     */
    favorites: Map<string, PortalLink>;
}

const LinkSection: React.FC<LinkSectionProps> = (props) => {
    const classes = useStyles(props);
    const { title, links, id, linksBackgroundColor, orgName, organizationId, isOnDashboard, favorites, ...otherProps } =
        props;

    // If there are no links, don't render anything.
    if (links.length === 0) return null;

    return (
        <>
            {isOnDashboard ? (
                <section id={`linkSection-${btoa(title)}`} {...otherProps} className={classes.section}>
                    {title && (
                        <Typography id={`${id}-title`} variant="h5" component="h2" className={classes.title}>
                            {isOnDashboard ? (
                                <Link
                                    to={`/${orgName}`}
                                    component={RouterLink}
                                    underline="none"
                                    className={classes.linkTitle}
                                >
                                    {title}
                                </Link>
                            ) : (
                                title
                            )}
                        </Typography>
                    )}

                    <Grid container={true} spacing={2} role="list" aria-labelledby={`${id}-title`}>
                        {links.map((link) => {
                            return (
                                <LinkCard
                                    id={`${id}-${link.id}`}
                                    key={`${id}-${link.id}-title`}
                                    linksBackgroundColor={linksBackgroundColor}
                                    orgName={orgName}
                                    organizationId={organizationId}
                                    {...link}
                                />
                            );
                        })}
                    </Grid>
                </section>
            ) : (
                <section id={`linkSection-${btoa(title)}`} {...otherProps} className={classes.section}>
                    <CollapsableHeader title={title} expandedDefault={true} id={`${id}-title`}>
                        <Grid
                            container={true}
                            spacing={2}
                            role="list"
                            aria-labelledby={`${id}-title`}
                            className={classes.list}
                        >
                            {links.map((link) => {
                                link.favorite = link.aggregateId
                                    ? favorites.get(link.aggregateId) !== undefined
                                    : false;
                                return (
                                    <LinkCard
                                        role="listitem"
                                        id={`${id}-${link.id}`}
                                        key={`${id}-${link.id}-title`}
                                        linksBackgroundColor={linksBackgroundColor}
                                        orgName={orgName}
                                        organizationId={organizationId}
                                        {...link}
                                    />
                                );
                            })}
                        </Grid>
                    </CollapsableHeader>
                </section>
            )}
        </>
    );
};

export default LinkSection;
