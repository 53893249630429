import { makeStyles } from "@civicplus/preamble-ui/lib/Utilities/ThemeHelper";

const useStyles = makeStyles((theme) => ({
    banner: {
        height: 200,

        // Needed for the Next Image layout="fill"
        position: "relative",

        // Go beyond the margins with the banner
        margin: theme.spacing(-4, -4, 0, -4),
        width: `calc(100% + ${theme.spacing(8)}px)`,
        [theme.breakpoints.up("md")]: {
            width: `calc(100% + ${theme.spacing(16)}px)`,
            margin: theme.spacing(-8, -8, 0, -8)
        }
    },
    bannerEmbed: {
        // Go beyond the margins with the banner
        margin: theme.spacing(0, -5, 0, -5),
        width: `calc(100% + ${theme.spacing(10)}px)`
    },
    logoWrapper: {
        marginTop: theme.spacing(-16),
        width: 200,
        height: 200,
        background: theme.palette.background.paper,
        borderRadius: theme.shape.borderRadius,
        boxShadow: theme.shadows[4],
        zIndex: 1,
        flexShrink: 0,

        // On mobile, center the logo
        [theme.breakpoints.down("md")]: {
            marginLeft: "auto",
            marginRight: "auto"
        }
    },
    logo: {
        // Needed for the Next Image layout="contain"
        position: "relative",

        // Add spacing around the logo
        margin: theme.spacing(2),
        height: `calc(100% - ${theme.spacing(4)}px)`,

        // Center the logo
        alignItems: "center",
        display: "flex"
    },
    tabRoot: {
        "& .MuiTab-root": {
            minWidth: 140,
            [theme.breakpoints.down("sm")]: {
                minWidth: 110,
            }
        }
    },
    filterSection: {
        marginBottom: 10,
        "& .prmbl-range-component": {
            width: "35%"
        },
        "& #dateInputFilter-range-group": {
            justifyContent: "end"
        },
        "& #dateInputFilter-form-control": {
            marginTop: 7
        },
        "@media (max-width: 600px)": {
            "& .prmbl-range-component": {
                width: "45%"
            }
        }
    },
    searchInput: {
        margin: "10px 20px",
        width: "90%",
        "@media (max-width: 600px)": {
            width: "92%"
        }
    },
    filterBtnIcon: {
        padding: "0.8rem 0.5rem",
        marginLeft: 20,
        "& span": {
            "& svg": {
                width: "1.8rem",
                height: "1.8rem"
            }
        }
    },
    filterBtnItem: {
        order: 4,
        "@media (max-width: 600px)": {
            order: 2
        }
    },
    searchInputItem: {
        order: 2,
        "@media (max-width: 600px)": {
            order: 3
        }
    },
    dateFilterItem: {
        order: 1,
        "@media (max-width: 600px)": {
            order: 3
        }
    },
    submitFilterItem: {
        display: "flex",
        justifyContent: "center",
        order: 3,
        "@media (max-width: 600px)": {
            order: 1
        }
    },
    logoHeaderSection: {
        display: "flex",
        [theme.breakpoints.down("md")]: {
            flexDirection: "column"
        }
    },
    heading: {
        marginTop: theme.spacing(4),
        marginLeft: theme.spacing(4),
        [theme.breakpoints.down("md")]: {
            textAlign: "center",
            marginLeft: 0
        }
    },
    contentHeader: {
        margin: theme.spacing(2, 0, 3, 0)
    },
    contentHeaderNews: {
        margin: theme.spacing(0, 0, 3, 0)
    },
    seeMore: {
        borderBottom: `1px solid ${theme.palette.divider}`,
        display: "flex",
        flexDirection: "row-reverse",
        margin: theme.spacing(1, 0, 0, 0)
    },
    newsBackground: {
        padding: theme.spacing(2),
        borderRadius: theme.shape.borderRadius,
        boxShadow: theme.shadows[2]
    },
    newsList: {
        "& .prmbl-listItem": {
            padding: theme.spacing(0.5, 0)
        }
    },
    newsHeadline: {
        marginTop: theme.spacing(0)
    },
    newsText: {
        paddingBottom: theme.spacing(2),
        borderBottom: `1px solid ${theme.palette.divider}`
    },
    eventListItem: {
        listStyleType: "none",
        marginBottom: theme.spacing(0.5),
        paddingBottom: theme.spacing(1)
    },
    eventList: {
        padding: 0,
        listStyleType: "none"
    },
    eventListItemTile: {
        padding: theme.spacing(1),
        backgroundColor: theme.palette.background.paper,
        borderRadius: theme.shape.borderRadius,
        boxShadow: theme.shadows[1],
        height: "fit-content",
        alignSelf: "center"
    },
    eventListItemDate: {
        borderRadius: theme.shape.borderRadius,
        boxShadow: theme.shadows[1],
        padding: theme.spacing(2.5, 1),
        justifyContent: "center",
        background: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        [theme.breakpoints.down("md")]: {
            marginBottom: theme.spacing(1)
        }
    },
    eventListItemNameWrapper: {
        padding: theme.spacing(0.5, 2, 1, 2),
        alignItems: "center"
    },
    eventListItemName: {
        fontSize: "1rem",
        lineHeight: 1.5,
        fontWeight: 400
    },
    eventListItemMonth: {
        padding: theme.spacing(0, 0.5),
        alignItems: "center",
        alignSelf: "center"
    },
    eventListItemDay: {
        padding: theme.spacing(0, 0.5),
        alignItems: "center"
    },
    trendingContentButtonList: {
        listStyleType: "none",
        padding: 0,
        height: "100%"
    },
    trendingContentButton: {
        padding: "0",
        alignItems: "stretch",
        textTransform: "none",
        letterSpacing: "normal",
        backgroundColor: theme.palette.background.paper,
        borderRadius: theme.shape.borderRadius,
        justifyContent: "flex-start",
        height: "100%",
        boxShadow: theme.shadows[3]
    },
    figure: {
        background: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        margin: 0,
        padding: theme.spacing(2, 6),
        display: "flex",
        alignItems: "center",
        borderRadius: theme.shape.borderRadius,
        position: "relative",
        overflow: "hidden",
        flexShrink: 0,
        fontSize: theme.spacing(4)
    },
    right: {
        padding: theme.spacing(0, 2),
        display: "flex",
        flexDirection: "column",
        flexShrink: 1,
        overflow: "hidden",
        position: "relative",
        flexGrow: 1,
        textAlign: "left",
        justifyContent: "center"
    },
    hasImage: {
        minWidth: 130
    },
    searchBox: {
        marginLeft: "auto",
        marginRight: 0
    },
    listItem: {
        display: "flex",
        alignItems: "center",
        textTransform: "none"
    },
    pagination: {
        justifyContent: "center",
        padding: "10px"
    },
    paginationToolbar: {
        flexDirection: "row"
    },
    externalLink: {
        margin: "-.15em .15em"
    },
    bookmarkButton: {
        margin: theme.spacing(1)
    },
    togglesSections: {
        display: "flex",
        alignItems: "center",
        minWidth: 180,
        justifyContent: "space-between"
    }
}));

export default useStyles;
