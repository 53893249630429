import { OptionShape } from "@civicplus/preamble-ui/lib/Autocomplete";

export interface AddressOrLocation {
    id: string | null;
    address1: string;
    address2: string;
    city: string;
    county: string;
    country: OptionShape | null;
    label: string;
    fullAddress: string;
    googleMaps: string;
    state: string;
    zipCode: string;
    latitude: number;
    longitude: number;
    isShippingAddress: boolean;
}

export interface InternationalAddress extends AddressOrLocation {
    governingDistrict: string;
    postalArea: string;
    countryLabel: string | null;
}

export const emptyAddress: AddressOrLocation = {
    id: "",
    label: "Address 1",
    googleMaps: "",
    address1: "",
    address2: "",
    city: "",
    fullAddress: "",
    state: "",
    zipCode: "",
    county: "",
    country: { value: "US", label: "United States" },
    latitude: -180,
    longitude: 180,
    isShippingAddress: false
};

export const USstatePattern =
    "^((A[LKSZR])|(C[AOT])|(D[EC])|(F[ML])|(G[AU])|(HI)|(I[DLNA])|(K[SY])|(LA)|(M[EHDAINSOPT])|(N/A)|(N[EVHJMYCD])|(O[HKR])|(P[WAR])|(RI)|(S[CD])|(T[NX])|(UT)|(V[TIA])|(W[AVIY]))$";

export const USzipCodePattern = "^\\d{5}([\\-]\\d{4})?$";
