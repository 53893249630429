import React from "react";
import Button from "@civicplus/preamble-ui/lib/Button";
import ErrorMessage from "@civicplus/preamble-ui/lib/ErrorMessage";
import { StepProps } from "..";

const ErrorStep: React.FC<StepProps> = (props) => {
    const { resetStep } = props;

    return (
        <div>
            <ErrorMessage id="error" title="Uh oh!" align="center">
                There was an error processing your request. Please try again later.
            </ErrorMessage>

            <Button onClick={resetStep}>Try again</Button>
        </div>
    );
};

export default ErrorStep;
