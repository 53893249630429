import React, {
    Children,
    cloneElement,
    ReactElement,
    useRef,
    Dispatch,
    SetStateAction,
    useCallback,
    useMemo
} from "react";
import Stepper from "@civicplus/preamble-ui/lib/Stepper";
import Grid from "@civicplus/preamble-ui/lib/Grid";

export type WizardType<T = Record<string, string | number>> = {
    children: ReactElement;
    isActive?: boolean;
    previousStep?: () => void;
    nextStep?: () => void;
    resetStep?: () => void;
    setData?: Dispatch<SetStateAction<Partial<T>>>;
    setCurrentStep?: Dispatch<SetStateAction<number>>;
    setCompleted?: Dispatch<SetStateAction<boolean>>;
    isError?: () => void;
    data?: T;
    allStepsCompleted?: boolean;
};

export interface IStepShape {
    label: string;
    completed?: boolean;
    disabled?: boolean;
    final?: boolean;
}

export type WizardStepType = WizardType & {
    index?: number;
    setCompleted?: (active: boolean, index: number) => void;
};

const WizardStep = React.memo((props: WizardStepType): JSX.Element => {
    const { children, isActive, index, setCompleted, ...otherProps } = props;
    const handleComplete = useCallback(
        (active: boolean) => {
            if (setCompleted) {
                setCompleted(active, index || 0);
            }
        },
        [index, setCompleted]
    );
    if (!isActive) return <></>;
    // TODO: use a custom hook instead of sending all these props to the children
    return cloneElement(children, { ...otherProps, setCompleted: handleComplete });
});

WizardStep.displayName = "WizardStep";

interface WizardProps<T> {
    children: ReactElement[];
    steps: IStepShape[];
    setSteps: Dispatch<SetStateAction<IStepShape[]>>;
    currentStep: number;
    setCurrentStep: Dispatch<SetStateAction<number>>;
    data: T;
    setData?: (stepSignUpData: Partial<T>) => void;
}

// TODO: port this component to Preamble
function Wizard<T>(props: WizardProps<T>): JSX.Element {
    const { children, data = {}, steps, setSteps, currentStep, setCurrentStep, setData } = props;

    const wizardRef = useRef<HTMLDivElement>(null);

    const previousStep = () => {
        setCurrentStep((previousCurrentStep) => previousCurrentStep - 1);
        if (wizardRef.current) window.scrollTo(0, wizardRef.current.offsetTop);
    };

    const nextStep = () => {
        setCurrentStep((previousCurrentStep) => previousCurrentStep + 1);
        if (wizardRef.current) window.scrollTo(0, wizardRef.current.offsetTop);
    };

    const resetStep = () => {
        setCurrentStep(0);
        if (wizardRef.current) window.scrollTo(0, wizardRef.current.offsetTop);
    };

    const setCompleted = useCallback(
        (completed: boolean, stepNumber: number) => {
            setSteps((prevSteps) =>
                prevSteps.map((step, key) => ({ ...step, completed: key === stepNumber ? completed : step.completed }))
            );
        },
        [setSteps]
    );

    const isError = () => {
        setCurrentStep(children.length - 1);
    };

    const setActiveStep = (step: number) => {
        setCurrentStep(step);
        if (wizardRef.current) window.scrollTo(0, wizardRef.current.offsetTop);
    };

    const allStepsCompleted = useMemo(
        () => steps.filter((x) => x.completed && !x.final).length === steps.filter((x) => !x.final).length,
        [steps]
    );

    return (
        <Grid container={true} gap={4} className="wizard">
            {steps && (
                <Grid xs={12}>
                    <Stepper
                        steps={steps}
                        activeStep={currentStep}
                        setActiveStep={setActiveStep}
                        nonLinear={true}
                        orientation="responsive"
                    />
                </Grid>
            )}
            <Grid xs={12} ref={wizardRef}>
                {Children.map(children, (child, index) =>
                    cloneElement(child, {
                        index,
                        isActive: index === currentStep,
                        data: data,
                        setData: setData,
                        previousStep: previousStep,
                        nextStep: nextStep,
                        resetStep: resetStep,
                        setCurrentStep,
                        setCompleted: setCompleted,
                        isError: isError,
                        allStepsCompleted: allStepsCompleted
                    })
                )}
            </Grid>
        </Grid>
    );
}

Wizard.Step = WizardStep;

export default Wizard;
