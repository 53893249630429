import React from "react";
import { SimpleDialog } from "../SimpleDialog/SimpleDialog";
import Typography from "@civicplus/preamble-ui/lib/Typography";

interface RemoveFavoriteDialogProps {
    isOpen: boolean;
    handleConfirm: () => void;
    onClose: () => void;
}
export const RemoveFavoriteDialog: React.FC<RemoveFavoriteDialogProps> = (props: RemoveFavoriteDialogProps) => {
    return (
        <SimpleDialog
            isOpen={props.isOpen}
            title="Remove Bookmark"
            primaryBtnText="Confirm Removal"
            primaryAction={props.handleConfirm}
            onClose={props.onClose}
        >
            <Typography>Are you sure you want to remove this bookmark?</Typography>
        </SimpleDialog>
    );
};
