import { createWithEqualityFn } from "zustand/traditional";
import { devtools } from "zustand/middleware";
import { Location } from "../types/Location";
import { Organization } from "../types/Organization";

export interface LocationState {
    currentLocation?: Location | Organization;
    setCurrentLocation: (location?: Location | Organization) => void;
    currentLocationName?: string;
    setCurrentLocationName: (locationName?: string) => void;
    userLocations?: Location[];
    setUserLocations: (locations?: Location[]) => void;
}

export const useLocation = createWithEqualityFn<LocationState>()(
    devtools(
        (set, get) => ({
            currentLocation: undefined,
            setCurrentLocation: (location) => set({ currentLocation: location }),

            currentLocationName: undefined,
            setCurrentLocationName: (locationName) => set({ currentLocationName: locationName }),

            userLocations: undefined,
            setUserLocations: (locations) => set({ userLocations: locations })
        }),
        { name: "locationStore" }
    )
);
