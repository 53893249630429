import React from "react";
import ButtonNavigation from "../Components/ButtonNavigation";
import LanguageItems from "./LanguageItems";
import Typography from "@civicplus/preamble-ui/lib/Typography";
import { IKonexusLanguages, StepProps } from "..";
import { OptionShape } from "@civicplus/preamble-ui/lib/Autocomplete";

interface ILanguageStepProps extends StepProps {
    availableLanguages: IKonexusLanguages;
}

const LanguageStep: React.FC<ILanguageStepProps> = (props) => {
    const { previousStep, nextStep, data, setData, setCompleted, availableLanguages } = props;

    if (!data) throw new Error("data is required");
    if (!nextStep) throw new Error("nextStep is required");
    if (!previousStep) throw new Error("previousStep is required");
    if (!setData) throw new Error("setData is required");
    if (!setCompleted) throw new Error("setCompleted is required");

    const handleSpokenChange = (spoken: OptionShape) => {
        setData({
            language: { ...data.language, spoken: { value: spoken.value as string, label: spoken.label as string } }
        });

        if (data?.language?.written) {
            setCompleted(true);
        } else {
            setCompleted(false);
        }
    };

    const handleWrittenChange = (written: OptionShape) => {
        setData({
            language: { ...data.language, written: { value: written.value as string, label: written.label as string } }
        });

        if (data?.language?.spoken) {
            setCompleted(true);
        } else {
            setCompleted(false);
        }
    };

    const onNextStep = () => {
        if (data?.language?.spoken && data.language.written) {
            setCompleted(true);
        }

        nextStep();
    };

    return (
        <>
            <Typography variant="h5">Preferred Language</Typography>

            <Typography variant="subtitle1">
                Select your preferred languages for voice calls and messages below.
            </Typography>

            <LanguageItems
                spoken={data.language?.spoken}
                written={data.language?.written}
                onChangeSpoken={handleSpokenChange}
                onChangeWritten={handleWrittenChange}
                availableLanguages={availableLanguages}
            />

            <ButtonNavigation
                previousStep={previousStep}
                nextStep={onNextStep}
                previousAriaLabel="Location Selection"
                nextAriaLabel="Information Confirmation"
            />
        </>
    );
};

export default LanguageStep;
