import React from "react";
import ErrorPage from "@civicplus/preamble-ui/lib/ErrorPage";

class ErrorBoundary extends React.Component<any, { hasError: boolean }> {
    constructor(props: any) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error: any) {
        return { hasError: true };
    }

    componentDidCatch(error: any, errorInfo: any) {
        console.error(error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            return (
                <ErrorPage id="error-boundary" title="Oops! Something went wrong!" severity="error">
                    Some technical problem has prevented this page from loading.
                </ErrorPage>
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
