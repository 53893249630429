import React, { useCallback, useEffect, useRef, useState } from "react";
import Button from "@civicplus/preamble-ui/lib/Button";
import ButtonGroup from "@civicplus/preamble-ui/lib/ButtonGroup";
import Checkbox from "@civicplus/preamble-ui/lib/Checkbox";
import { defaultToolbarButtons } from "@civicplus/preamble-ui/lib/RichText/RichTextConstants";
import Grid from "@civicplus/preamble-ui/lib/Grid";
import Layout from "@civicplus/preamble-ui/lib/Layout";
import Link from "@civicplus/preamble-ui/lib/Link";
import Loader from "@civicplus/preamble-ui/lib/Loader";
import RichText from "@civicplus/preamble-ui/lib/RichText";
import TextInput from "@civicplus/preamble-ui/lib/TextInput";
import { useNavigate, useParams, Link as RouterLink } from "react-router-dom";
import { makeStyles } from "@civicplus/preamble-ui/lib/Utilities/ThemeHelper";
import enhanceWithValidation, {
    maxLengthValidation,
    requiredValidation,
    ValidationResult
} from "@civicplus/preamble-ui/lib/Validations";
import { useAuth } from "../../providers/AuthProvider";
import ApiService, { ApiError, parseErrorMessage } from "../../services/apiService";
import { getHasGroupMemberAccess, isSuperUserOrOrganizationOwner } from "../../services/permissionService";
import { useOrganization } from "../../stores/organizationStore";
import { DirtyStateDialog } from "../../components/DirtyState";
import { Organization, OrgApplicationDto } from "../../types/Organization";
import { ProductType } from "../../types/ProductType";
import { User } from "oidc-client-ts";
import { toggleDisplayFraseChatbot, getCustomOrDefaultLabel } from "../../shared/functions";
import { defaultApplicationLabels } from "../../shared/constants";
import { useEmbedStore } from "../../stores/embedStore";
import { useConfig } from "../../providers/ConfigProvider";
import { useSnackbar } from "notistack";
const EnhancedTextInput = enhanceWithValidation(TextInput);

interface EditEnhancedApplicationProps {
    org: Organization;
    application: OrgApplicationDto;
    user: User | null | undefined;
}

const useStyles = makeStyles(() => ({
    title: {
        marginBottom: 20
    },
    btnsContainer: {
        marginTop: 20
    }
}));

export const getCustomLabel = (application: OrgApplicationDto): string => {
    let customLabel = "";

    switch (application.productType) {
        case ProductType.CivicClerk:
            customLabel = getCustomOrDefaultLabel(application, defaultApplicationLabels[ProductType.CivicClerk]);
            break;
        case ProductType.CivicReady:
            customLabel = getCustomOrDefaultLabel(application, defaultApplicationLabels[ProductType.CivicReady]);
            break;
        case ProductType.CivicRec:
            customLabel = getCustomOrDefaultLabel(application, defaultApplicationLabels[ProductType.CivicRec]);
            break;
        case ProductType.MunicodeNEXT:
            customLabel = getCustomOrDefaultLabel(application, defaultApplicationLabels[ProductType.MunicodeNEXT]);
            break;
        case ProductType.NextRequest:
            customLabel = getCustomOrDefaultLabel(application, defaultApplicationLabels[ProductType.NextRequest]);
            break;
        case ProductType.SeeClickFix:
            customLabel = getCustomOrDefaultLabel(application, defaultApplicationLabels[ProductType.SeeClickFix]);
            break;
        case ProductType.CivicGov:
            customLabel = getCustomOrDefaultLabel(application, "Permits & Inspections");
            break;
    }

    return customLabel;
};

export const EditEnhancedApplicationWrapper: React.FC = () => {
    const { isLoading: isLoadingSession, user } = useAuth();
    const { applicationId } = useParams();
    const navigate = useNavigate();
    const [hasAccess, setHasAccess] = useState<boolean | undefined>(undefined);
    const [organization, orgApplications] = useOrganization((state) => [state.organization, state.orgApplications]);
    const config = useConfig();

    useEffect(() => {
        async function checkAccess() {
            const fetchGroupAccess = async (user: User, organization: Organization) => {
                const hasGroupAccess = await getHasGroupMemberAccess(user, organization);
                setHasAccess(hasGroupAccess);
            };

            if (user && organization) {
                if (isSuperUserOrOrganizationOwner(user, organization, config.superUserRole)) {
                    setHasAccess(true);
                } else {
                    await fetchGroupAccess(user, organization);
                }
            } else if (!user && organization) {
                setHasAccess(false);
            }
        }

        if (!isLoadingSession && organization) {
            checkAccess();
        }
    }, [organization, organization?.name, user, hasAccess, isLoadingSession, config]);

    if (isLoadingSession || organization === undefined || hasAccess === undefined) {
        return <Loader verticallyCenter={true} />;
    }

    if (hasAccess !== undefined && hasAccess === false) {
        navigate("/access-denied");
        return null;
    }

    const currentApplication = orgApplications?.find((o) => o.id === applicationId);

    if (organization && currentApplication) {
        return <EditEnhancedApplication org={organization} application={currentApplication} user={user} />;
    }

    return <Loader verticallyCenter={true} />;
};

export const EditEnhancedApplication: React.FC<EditEnhancedApplicationProps> = (props) => {
    const { org, application, user } = props;

    const classes = useStyles();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const [additionalInfo, setAdditionalInfo] = useState<string | undefined>(application?.additionalProductInformation);
    const [customLabel, setCustomLabel] = useState<string | undefined>();
    const [isActive, setIsActive] = useState<boolean>(application?.isActive ?? false);
    const [isDirty, setIsDirty] = useState<boolean>(false);
    const isEmbed = useEmbedStore((state) => state.isInitialized);
    const [isLeavingWithoutSaving, setIsLeavingWithoutSaving] = useState<boolean>(false);
    const labelRef = useRef<{ validate: () => ValidationResult }>();

    const [orgApplications, setOrgApplications] = useOrganization((state) => [
        state.orgApplications,
        state.setOrgApplications
    ]);

    useEffect(() => {
        toggleDisplayFraseChatbot(org, isEmbed || false);
    }, [org, isEmbed]);

    useEffect(() => {
        if (
            isActive !== application?.isActive ||
            (customLabel !== application?.customLabel &&
                customLabel &&
                !Object.values(defaultApplicationLabels).includes(customLabel)) ||
            additionalInfo !== application?.additionalProductInformation
        ) {
            setIsDirty(true);
        }
    }, [isActive, application, customLabel, additionalInfo]);

    useEffect(() => {
        if (application) {
            const customLabel = getCustomLabel(application);
            setCustomLabel(customLabel);
        }
    }, [application]);

    const cancelEdit = useCallback(() => {
        if (isDirty) {
            setIsLeavingWithoutSaving(true);
        } else {
            navigate("..");
        }
    }, [isDirty, navigate]);

    const updateEnhancedApplication = useCallback(async () => {
        const validationResults: ValidationResult[] = await Promise.all([labelRef.current!.validate()]);

        if (validationResults.some((x) => x.error)) {
            return;
        } else {
            const updateOrgApplications = ({ enhancedAppId }: { enhancedAppId: string }) => {
                if (orgApplications) {
                    const index = orgApplications.findIndex((o) => o.id === enhancedAppId);

                    if (index >= 0) {
                        orgApplications[index].additionalProductInformation = additionalInfo;
                        orgApplications[index].customLabel = customLabel;
                        orgApplications[index].isActive = isActive;

                        setOrgApplications([...orgApplications]);
                    }
                }
            };

            try {
                await ApiService.put({
                    url: `${org.name}/enhancedApplications/${application?.id}`,
                    authUser: user,
                    requestInit: {
                        headers: {
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify({
                            additionalProductInformation: additionalInfo,
                            customLabel,
                            isActive
                        })
                    }
                });

                enqueueSnackbar("Application settings saved.", {
                    variant: "success"
                });

                if (application) {
                    updateOrgApplications({ enhancedAppId: application.id });
                    setIsDirty(false);
                }
            } catch (ex: any) {
                console.error(ex);

                const errorMessage = parseErrorMessage(
                    ex as ApiError,
                    "An error occured while updating the application."
                );

                enqueueSnackbar(errorMessage, {
                    variant: "error",
                    persist: true
                });
            }
        }
    }, [
        additionalInfo,
        application,
        customLabel,
        enqueueSnackbar,
        isActive,
        org.name,
        orgApplications,
        setOrgApplications,
        user
    ]);

    const defaultButtons = JSON.parse(JSON.stringify(defaultToolbarButtons));

    const customizedButtons = {
        ...defaultButtons,
        moreRich: {
            align: "left",
            buttonsVisible: 2,
            buttons: ["insertLink", "specialCharacters"]
        }
    };

    /*
        After the Preamble update (16.0.0), the Froala editor styling issues are fixed,
        but there are still some weird behaviors happening. This code below prevents the
        duplication of the RichText/FroalaEditor component to appear on the page.
    */
    const froalaEditors = document.getElementsByClassName("fr-box fr-basic fr-top");

    if (froalaEditors.length > 1) {
        froalaEditors[0].remove();
    }

    return (
        <Layout
            TitlebarProps={{
                id: "title",
                title: `Edit "${getCustomLabel(application)}" Application`,
                className: classes.title,
                breadcrumbs: [
                    <Link key="settings" to="../" component={RouterLink}>
                        Settings
                    </Link>
                ]
            }}
            StickyRightBarProps={{
                id: "sticky-right-bar-edit-enhancedapplication",
                rightBar: (
                    <Grid container={true} spacing={2}>
                        <Grid xs={12}>
                            <ButtonGroup layout="stacked">
                                <Button
                                    color="primary"
                                    onClick={updateEnhancedApplication}
                                    fullWidth={true}
                                    id="update-app-button"
                                    data-testid="update-app-button"
                                    key="update-app-button"
                                >
                                    Update
                                </Button>

                                <Button
                                    onClick={cancelEdit}
                                    fullWidth={true}
                                    data-testid="cancel-app-button"
                                    key="cancel-app-button"
                                >
                                    Cancel
                                </Button>
                            </ButtonGroup>
                        </Grid>

                        <Grid xs={12}>
                            <Checkbox
                                checked={isActive}
                                id={`checkbox-public-uri-${application?.publicUri}`}
                                color="primary"
                                label="Visible to public"
                                onChange={() => {
                                    setIsActive(!isActive);
                                }}
                            />
                        </Grid>
                    </Grid>
                )
            }}
        >
            <Grid xs={12}>
                <TextInput value={application?.name} fullWidth={true} disabled={true} label="Product Name" />
            </Grid>

            <Grid xs={12}>
                <EnhancedTextInput
                    id="edit-app-custom-label"
                    label="Label"
                    ref={labelRef}
                    value={customLabel}
                    maxLength={25}
                    fullWidth={true}
                    required={true}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const newValue = e.target.value !== "" ? e.target.value : undefined;
                        setCustomLabel(newValue);
                    }}
                    validations={[maxLengthValidation, requiredValidation]}
                />
            </Grid>

            <Grid xs={12}>
                <TextInput
                    value={application?.publicUri}
                    fullWidth={true}
                    disabled={true}
                    helperText="Public URIs are configured in the CivicPlus Organization Service."
                    label="Public URI"
                />
            </Grid>

            <Grid xs={12}>
                <RichText
                    id="richTextFroala"
                    label="Additional Product Information"
                    value={additionalInfo}
                    toolbarButtons={customizedButtons}
                    config={{
                        attribution: false,
                        charCounterCount: true,
                        countHTMLCharacters: true,
                        charCounterMax: 2500
                    }}
                    onChange={(e: any) => {
                        setAdditionalInfo(e.target.value);
                    }}
                />
            </Grid>

            <DirtyStateDialog
                isOpen={isLeavingWithoutSaving}
                warningText="Are you sure you want to discard changes for this application?"
                onDiscardChanges={() => {
                    setIsLeavingWithoutSaving(false);
                    navigate("..");
                }}
                onReturnToContent={() => {
                    setIsLeavingWithoutSaving(false);
                }}
            />
        </Layout>
    );
};
