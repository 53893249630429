import { OptionShape } from "@civicplus/preamble-ui/lib/Autocomplete";
import { SubscriptionList, SubscriptionType } from "../../../../../types/Subscription";
import { getProductTypeLabels } from "../../../../../shared/functions";
import { ProductType } from "../../../../../types/ProductType";
import { NotificationGroupType } from "../../../../../components/Notifications/utils";

export function filterByName(items: SubscriptionList[], searchText: string): SubscriptionList[] {
    return items.filter((item) => item.name.toLowerCase().includes(searchText.toLowerCase()));
}

export function filterBySubscription(items: SubscriptionList[], subscriptions: OptionShape[]): SubscriptionList[] {
    return items.filter((item) =>
        subscriptions.some((f) => (f.value === SubscriptionType.Email ? item.emailSubscription : item.smsSubscription))
    );
}

export function filterByFeatured(items: SubscriptionList[], featured: OptionShape[]): SubscriptionList[] {
    return items.filter((item) => featured.some((f) => (f.value === "Featured" ? item.isFeatured : !item.isFeatured)));
}

export function filterByTags(items: SubscriptionList[], tags: OptionShape[]): SubscriptionList[] {
    return items.filter((item) => tags.some((f) => item.tags?.includes(f.label as string)));
}

export function filterBySource(
    items: SubscriptionList[],
    sources: OptionShape[],
    WebsiteProductTypes: string[]
): SubscriptionList[] {
    return items.filter((item) =>
        sources.some((f) => {
            const productTypeLabel = getProductTypeLabels(item.productType);
            const additionalProductType = getProductType(f.label.toString());

            return f.value === "Website"
                ? WebsiteProductTypes.includes(item.productType)
                : productTypeLabel === f.value ||
                      (additionalProductType && item.additionalProducts.includes(additionalProductType));
        })
    );
}

export function getProductType(productName: string): ProductType | undefined {
    switch (productName) {
        case NotificationGroupType.AgendasMeetings:
            return ProductType.CivicClerk;
        case "Web Open":
            return ProductType.CivicEngageOpen;
        case "Web Evolve":
            return ProductType.HCMS;
        default:
            return undefined;
    }
}

export function generateQueryString(params: { [key: string]: unknown[] }): string {
    return Object.entries(params)
        .flatMap(([key, values]) => values.map((value) => `${key}=${encodeURIComponent((value as string).toString())}`))
        .join("&");
}
