import React from "react";
import ErrorPage from "@civicplus/preamble-ui/lib/ErrorPage";

const Page404: React.FC = () => {
    return (
        <ErrorPage title="The page you're looking for couldn't be found." severity="warning" id="not-found-error">
            <></>
        </ErrorPage>
    );
};

export default Page404;
