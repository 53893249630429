import React, { lazy, Suspense } from "react";
import AccessDenied from "./pages/AccessDenied";
import Dashboard from "./pages/Dashboard";
import EmbedSignIn from "./pages/EmbedSignIn";
import EmbedSignOut from "./pages/EmbedSignOut";
import Layout from "./components/Layout";
import Loader from "@civicplus/preamble-ui/lib/Loader";
import NotificationsAndAlertsPage from "./pages/location/notificationsAndAlerts/notificationsAndAlerts";
import OptimizeForm from "./pages/location/forms/optimize/OptimizeForm";
import OptimizePaymentReceipt from "./pages/location/forms/optimize/OptimizePaymentReceipt";
import Page404 from "./pages/404";
import * as Pages from "./pages/location";
import { AccountRedirect } from "./pages/AccountRedirect";
import { ApplicationPhoneNumbersProvider } from "./hooks/useApplicationPhoneNumbers";
import { Route, Routes, Navigate, Outlet } from "react-router-dom";
import { SignIn, SignOut, SignInCallback, SignOutCallback } from "./components/Authentication";
import { WeatherAlertsEnabledProvider } from "./hooks/useCheckWeatherAlertsEnabledForOrg";

const Settings = lazy(() => import("./pages/location/settings"));

const Router: React.FC = () => {
    return (
        <Routes>
            <Route path="/embedSignIn" element={<EmbedSignIn />} />
            <Route path="/embedSignOut" element={<EmbedSignOut />} />
            <Route path="/signin" element={<SignIn />} />
            <Route path="/signout" element={<SignOut />} />
            <Route path="/CivicPlusPlatform">
                <Route path="SignInCallback" element={<SignInCallback />} />
                <Route path="SignOutCallback" element={<SignOutCallback />} />
            </Route>
            <Route
                path="/"
                element={
                    <Layout>
                        <Outlet />
                    </Layout>
                }
            >
                <Route
                    index={true}
                    element={
                        <ApplicationPhoneNumbersProvider>
                            <Dashboard />
                        </ApplicationPhoneNumbersProvider>
                    }
                />

                <Route path=":locationName">
                    <Route index={true} element={<Navigate to="dashboard" />} />
                    <Route path="meetings" element={<Pages.Events />} />
                    <Route path="legislation" element={<Pages.Legislation />} />
                    <Route path="content/:tag" element={<Pages.TaggedContent />} />

                    <Route
                        element={
                            <ApplicationPhoneNumbersProvider>
                                <Outlet />
                            </ApplicationPhoneNumbersProvider>
                        }
                    >
                        <Route path="dashboard" element={<Dashboard />} />
                        <Route path="search/:query" element={<Pages.Search />} />
                    </Route>

                    <Route path="forms">
                        <Route index={true} element={<Pages.Forms />} />
                        <Route path="optimize">
                            <Route path=":formId" element={<OptimizeForm />} />
                            <Route path=":formId/payment-receipt" element={<OptimizePaymentReceipt />} />
                        </Route>
                    </Route>

                    <Route
                        element={
                            <WeatherAlertsEnabledProvider>
                                <Outlet />
                            </WeatherAlertsEnabledProvider>
                        }
                    >
                        <Route
                            path="notifications"
                            element={
                                <ApplicationPhoneNumbersProvider>
                                    <NotificationsAndAlertsPage />
                                </ApplicationPhoneNumbersProvider>
                            }
                        />
                        <Route
                            path="settings"
                            element={
                                <Suspense fallback={<Loader verticallyCenter={true} />}>
                                    <Outlet />
                                </Suspense>
                            }
                        >
                            <Route index={true} element={<Settings />} />
                            <Route path=":tabName" element={<Settings />} />
                            <Route path="applications/:applicationId" element={<Pages.EditEnhancedApplication />} />
                        </Route>
                    </Route>

                    <Route path="embedded">
                        <Route path="civicGov" element={<Pages.CivicGovEmbed />} />
                        <Route path="civicRec" element={<Pages.CivicRecEmbed />} />
                        <Route path="civicService" element={<Pages.CivicServiceEmbed />} />
                        <Route path="nextRequest" element={<Pages.NextRequestEmbed />} />
                    </Route>

                    {/* Redirects */}
                    <Route path="subscriptions" element={<Navigate to="../notifications" />} />
                    <Route path="alerts" element={<Navigate to="../notifications" />} />

                    {/* Redirects */}
                    <Route path=":sectionName" element={<Pages.Section />} />
                </Route>

                <Route path="/account" element={<AccountRedirect />} />
                <Route path="/access-denied" element={<AccessDenied />} />
                <Route path="/404" element={<Page404 />} />
                <Route path="*" element={<Page404 />} />
            </Route>
        </Routes>
    );
};

export default Router;
