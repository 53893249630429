import React, { createContext, useContext, useEffect, useState } from "react";
import ApiService from "../services/apiService";
import { useAuth } from "../providers/AuthProvider";
import { useOrganization } from "../stores/organizationStore";

export enum FeatureFlag {
    CivicEngageCentralSubscriptions = "civicEngageCentralSubscriptionsEnabled",
    PhoneVerification = "phoneVerificationEnabled",
    ShowSettingsNavigation = "showSettingsNavigation",
    ShowNotificationsGrouped = "showNotificationsGrouped"
}

export type FeatureFlags = {
    [FeatureFlag.CivicEngageCentralSubscriptions]: boolean;
    [FeatureFlag.PhoneVerification]: boolean;
    [FeatureFlag.ShowSettingsNavigation]: boolean;
    [FeatureFlag.ShowNotificationsGrouped]: boolean;
};

const DefaultFeatureFlags: FeatureFlags = {
    [FeatureFlag.CivicEngageCentralSubscriptions]: true,
    [FeatureFlag.PhoneVerification]: false,
    [FeatureFlag.ShowSettingsNavigation]: false,
    [FeatureFlag.ShowNotificationsGrouped]: false
};

const FeatureFlagsContext = createContext<[FeatureFlags, boolean, boolean]>([DefaultFeatureFlags, true, false]);

export function FeatureFlagsProvider({ children }: { children: React.ReactNode }): React.ReactElement {
    const auth = useAuth();
    const [organization] = useOrganization((state) => [state.organization]);
    const [flags, setFlags] = useState(DefaultFeatureFlags);
    const [isLoading, setIsLoading] = useState(true);
    const [organizationFlagsLoaded, setOrganizationFlagsLoaded] = useState(false);

    useEffect(() => {
        let isCancelled = false;

        const fetchFeatureFlags = async (organization: string) => {
            setIsLoading(true);

            const featureflags = await ApiService.get({
                url: `featureflags/${organization}`,
                authUser: auth.user,
                cache: true
            });

            if (!isCancelled) {
                setFlags(featureflags);
                setOrganizationFlagsLoaded(true);
                setIsLoading(false);
            }
        };

        if (organization) {
            fetchFeatureFlags(organization.name);
        }

        return () => {
            isCancelled = true;
        };
    }, [auth.user, organization]);

    return (
        <FeatureFlagsContext.Provider value={[flags, isLoading, organizationFlagsLoaded]}>
            {children}
        </FeatureFlagsContext.Provider>
    );
}

export function useFeatures(): [FeatureFlags, boolean, boolean] {
    return useContext(FeatureFlagsContext);
}
