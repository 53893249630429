import React, { useState } from "react";
import Badge from "@mui/material/Badge";
import Button from "@civicplus/preamble-ui/lib/Button";
import CloseIcon from "@mui/icons-material/Close";
import FilterListIcon from "@mui/icons-material/FilterList";
import Grid from "@civicplus/preamble-ui/lib/Grid";
import Popover from "@civicplus/preamble-ui/lib/Popover";
import Typography from "@civicplus/preamble-ui/lib/Typography";
import { makeStyles } from "@civicplus/preamble-ui/lib/Utilities/ThemeHelper";

const useStyles = makeStyles((theme) => ({
    filterPopover: {
        minWidth: "min(450px, 90%)",
        padding: theme.spacing(2),
        boxShadow: theme.shadows[1]
    },
    filterTitleWrapper: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    filterTitle: {
        display: "flex",
        alignItems: "center",
        gap: theme.spacing(1)
    }
}));

export interface FilterButtonProps {
    /**
     * The id of the element that the popover should be associated with
     */
    id: string;
    /**
     * The children to render in the popover
     */
    children: React.ReactNode;
    /**
     * The number of active filters
     */
    activeFilters?: number;
    /**
     * Callback to reset filters
     */
    onFilterReset?: () => void;
}

export const FilterButton: React.FC<FilterButtonProps> = (props) => {
    const { id, children, activeFilters, onFilterReset } = props;
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const open = Boolean(anchorEl);
    const classes = useStyles(props);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);

    const handleFilterReset = () => {
        if (onFilterReset) {
            onFilterReset();
        }
    };

    return (
        <>
            <Button
                id={id}
                onClick={handleClick}
                type="icon"
                title="Search Filters"
                size="small"
                aria-label="Open the search filters"
                aria-describedby={`${id}-popover`}
                aria-owns={anchorEl ? "mouse-over-popover" : undefined}
                aria-haspopup="true"
                aria-expanded={Boolean(anchorEl)}
            >
                <Badge badgeContent={activeFilters} color="primary" data-testid="filterButton" overlap="rectangular">
                    <FilterListIcon fontSize="small" />
                </Badge>
            </Button>

            <Popover
                open={open}
                id={`${id}-popover`}
                anchorEl={anchorEl}
                data-testid="filterPopover"
                className={classes.filterPopover}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right"
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right"
                }}
            >
                <Grid container={true} spacing={2} direction="column">
                    <Grid xs={12} className={classes.filterTitleWrapper}>
                        <div className={classes.filterTitle}>
                            <Typography variant="subtitle1">FILTERS</Typography>

                            {onFilterReset && (
                                <Button
                                    id={`${id}-resetFilters`}
                                    onClick={handleFilterReset}
                                    variant="text"
                                    size="small"
                                    color="primary"
                                >
                                    RESET
                                </Button>
                            )}
                        </div>

                        <Button onClick={handleClose} type="icon" size="small" aria-label="Close Search Filters">
                            <CloseIcon />
                        </Button>
                    </Grid>

                    <Grid xs={12}>{children}</Grid>
                </Grid>
            </Popover>
        </>
    );
};

export default FilterButton;
