import React from "react";
import { useAuth } from "../../providers/AuthProvider";
import { useConfig } from "../../providers/ConfigProvider";

export const SignOut: React.FC = () => {
    const auth = useAuth();
    const config = useConfig();

    if (auth.isAuthenticated && !auth.isLoading) {
        auth.removeUser();
        const logoutUrl = config.getSignoutUrl(auth.user!, "");
        window.location.assign(logoutUrl);
    }

    return <></>;
};
