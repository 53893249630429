import { ValidationResult, errorMessage } from "@civicplus/preamble-ui/lib/Validations";

interface softMaxLengthValidationProps {
    errorMessages: string;
    softMaxLength: number;
    label?: string;
}

export const softMaxLengthValidation = (value: string, props: softMaxLengthValidationProps) => {
    const validationName = "SoftMaxLength";
    const defaultErrorMessage = `${props.label ?? "Input"} length must be under ${props.softMaxLength} characters`;

    const isValid = value ? value.length < props.softMaxLength : true;

    const errormsg = { key: props.errorMessages };

    const result = {
        error: !isValid,
        message: errorMessage(validationName, errormsg, defaultErrorMessage),
        validationName: validationName
    };
    return result.error ? Promise.reject(result) : Promise.resolve(result);
};

export const isValidPhoneNumberValidation = (value: string): Promise<ValidationResult> => {
    const isValid = (value && RegExp(/^\d{10}$/).test(value)) || value === "";

    const result = {
        error: !isValid,
        message: "Phone Number must be 10 digits.",
        validationName: "isValidPhoneNumberValidation"
    };
    return result.error ? Promise.reject(result) : Promise.resolve(result);
};
// prettier-ignore
export const phoneNumberMask = [
    "+","1"," ","(", /[1-9]/,/\d/,/\d/, ")"," ",/\d/, /\d/,/\d/,"-",/\d/,/\d/,/\d/,/\d/
];
