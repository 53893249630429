import React, { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useAuth } from "../providers/AuthProvider";
import { useOrganization } from "../stores/organizationStore";
import { NotificationsService } from "../services/notificationsService";
import { isNullOrUndefined } from "../shared/functions";

const WeatherAlertsEnabledContext = createContext<[boolean, boolean] | undefined>(undefined);

export function WeatherAlertsEnabledProvider({ children }: { children: React.ReactNode }): React.ReactElement {
    const auth = useAuth();
    const [organization] = useOrganization((state) => [state.organization]);
    const notificationService = useMemo(() => new NotificationsService(), []);
    const [isLoading, setIsLoading] = useState(true);
    const [weatherAlertsEnabled, setWeatherAlertsEnabled] = useState(false);

    useEffect(() => {
        let isCancelled = false;
        try {
            const checkWeatherAlertsEnabledForOrg = async () => {
                setIsLoading(true);
                const orgName = organization?.name ? organization.name : "";
                const response = await notificationService.checkWeatherAlertsEnabledForOrg({
                    orgName: orgName,
                    authUser: auth.user
                });

                if (!isCancelled && !isNullOrUndefined(response)) {
                    setWeatherAlertsEnabled(response);
                    setIsLoading(false);
                }
            };

            if (!auth.isLoading && organization !== undefined) {
                checkWeatherAlertsEnabledForOrg();
            }
        } catch {
            setIsLoading(false);
        }

        return () => {
            isCancelled = true;
        };
    }, [auth.isLoading, auth.user, organization, notificationService]);

    return (
        <WeatherAlertsEnabledContext.Provider value={[isLoading, weatherAlertsEnabled]}>
            {children}
        </WeatherAlertsEnabledContext.Provider>
    );
}

export function useCheckWeatherAlertsEnabledForOrg(): [boolean, boolean] {
    const value = useContext(WeatherAlertsEnabledContext);

    if (value === undefined) {
        throw Error(
            "`useCheckWeatherAlertsEnabledForOrg` must be used inside of a `<WeatherAlertsEnabledProvider>...</WeatherAlertsEnabledProvider>`"
        );
    }

    return value;
}
