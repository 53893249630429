import React, { useState, useEffect } from "react";
import AccessibilityMessage from "../../AccessibilityMessage";
import ApiService from "../../../services/apiService";
import Accordion from "@civicplus/preamble-ui/lib/Accordion";
import List from "@civicplus/preamble-ui/lib/List/List";
import ListItem from "@civicplus/preamble-ui/lib/ListItem";
import Loader from "@civicplus/preamble-ui/lib/Loader";
import PortalListItems from "../../PortalListItems";
import StarIcon from "@mui/icons-material/StarBorderOutlined";
import Typography from "@civicplus/preamble-ui/lib/Typography";
import { LoginNoticeDialog } from "../../Authentication/LoginNoticeDialog";
import { makeStyles } from "@civicplus/preamble-ui/lib/Utilities/ThemeHelper";
import { Organization } from "../../../types/Organization";
import { RemoveFavoriteDialog } from "../../Favorites/RemoveFavoriteDialog";
import { SearchDocument } from "../../../types/SearchDocument";
import { SubscriptionList } from "../../../types/Subscription";
import { useFavorites } from "../../../shared/useFavorites";
import { useEmbedStore } from "../../../stores/embedStore";
import { useOrganization } from "../../../stores/organizationStore";
import { User as AuthUser } from "oidc-client-ts";

const useStyles = makeStyles((theme) => ({
    root: {
        borderRadius: theme.shape.borderRadius
    },
    list: {
        margin: theme.spacing(1, 0, 0, 0),
        width: "100%"
    },
    heading: {
        display: "flex",
        gap: theme.spacing(2),
        alignItems: "center"
    },
    title: {
        fontWeight: theme.typography.fontWeightBold as "bold"
    },
    expansionSummary: {
        margin: theme.spacing(2, 0) + " !important"
    }
}));

interface FeaturedDashboardProps {
    /**
     * The current user session.
     */
    authUser: AuthUser | null | undefined;
    /**
     * The current organization
     */
    organization: Organization;

    /**
     * Array of organization subscriptions with user subscription info set.
     */
    subscriptions: SubscriptionList[];
}

export const FeaturedDashboard: React.FC<FeaturedDashboardProps> = ({ authUser, organization, subscriptions }) => {
    const classes = useStyles();
    const isEmbed = useEmbedStore((state) => state.isInitialized);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [items, setItems] = useState<Array<SearchDocument>>();
    const [expanded, setExpanded] = useState(false);

    const handleCollapse = () => setExpanded(!expanded);

    const [fetchNotificationsStatus] = useOrganization((state) => [state.fetchNotificationsStatus]);

    const [
        { favoriteMap: favorites, loginNoticeOpen, unfavoriteDialogSettings },
        {
            handleCloseLoginNotice,
            handleConfirmUnfavorite,
            handleFavoriteClick,
            handleLogin,
            setUnfavoriteDialogSettings
        }
    ] = useFavorites();

    useEffect(() => {
        async function fetchItems() {
            const featured: Array<SearchDocument> = await ApiService.get({
                url: `${organization.name}/dashboard/featured`,
                cache: false,
                authUser: authUser
            });
            setItems(featured);
        }
        fetchItems();
    }, [authUser, organization?.name]);

    useEffect(() => {
        if (fetchNotificationsStatus === "Complete" && items !== undefined) {
            setIsLoading(false);
        }
    }, [fetchNotificationsStatus, items]);

    return (
        <>
            <Accordion
                id="viewFeatured-title"
                className={classes.root}
                expanded={expanded}
                disabled={isLoading}
                onChange={() => handleCollapse()}
                expandSummary={
                    <div className={classes.heading}>
                        {isLoading ? <Loader /> : <StarIcon color="primary" fontSize="large" />}
                        <div>
                            <Typography variant="h5" component="h2" className={classes.title}>
                                Featured Content from {organization?.friendlyName || organization?.name}
                            </Typography>

                            <Typography variant="body1" component="h3">
                                View content prioritized by the organization
                            </Typography>

                            <AccessibilityMessage
                                message={isLoading ? "Loading featured items" : "Featured items loaded"}
                            />
                        </div>
                    </div>
                }
                expandDetails={
                    <List id="featured-list" wrapperStyles={true} className={classes.list}>
                        {!items || items.length === 0 ? (
                            <ListItem
                                key="empty-list"
                                ListItemTextProps={{
                                    primary: <Typography align="center">Sorry, no results found</Typography>
                                }}
                            />
                        ) : (
                            <PortalListItems
                                authUser={authUser}
                                items={items}
                                isEmbed={isEmbed ?? false}
                                organization={organization}
                                subscriptionList={subscriptions}
                                favorites={favorites}
                                handlePendingSubscriptions={false}
                                handleFavoriteClick={handleFavoriteClick}
                            />
                        )}
                    </List>
                }
                summaryProps={{
                    classes: {
                        content: classes.expansionSummary
                    }
                }}
            />

            <LoginNoticeDialog isOpen={loginNoticeOpen} onClose={handleCloseLoginNotice} handleLogin={handleLogin} />

            <RemoveFavoriteDialog
                isOpen={unfavoriteDialogSettings.open}
                handleConfirm={handleConfirmUnfavorite}
                onClose={() => setUnfavoriteDialogSettings({ open: false, link: null })}
            />
        </>
    );
};

export default FeaturedDashboard;
