import ReactGA from "react-ga4";

type EventProps = {
    action: string;
    category: string;
    label?: string;
    value?: number;
    orgName?: string;
    transport?: string;
};

export const GAcontentSelected = (type: string, value: string, orgName?: string): void => {
    ReactGA.gtag("event", "select_content", {
        organization: orgName,
        content_type: type,
        item_id: value
    });
};

export const GAevent = ({ action, category, label, orgName, value, transport }: EventProps): void => {
    ReactGA.gtag("event", action, {
        category,
        label,
        value,
        organization: orgName
    });
};

export const GAinitialize = (gaKey: string): void => {
    ReactGA.initialize(gaKey);
};

export const GAlogin = (fromEmbed: boolean): void => {
    ReactGA.gtag("event", "login", {
        method: fromEmbed ? "Embed" : "Portal"
    });
};

export const GAlinkSelected = (orgName: string, id: string, title: string, section: string): void => {
    ReactGA.gtag("event", "select_item", {
        organization: orgName,
        item_list_name: section,
        items: [
            {
                item_name: title,
                item_id: id
            }
        ]
    });
};

export const GAuser = (gaKey: string, userId: string): void => {
    ReactGA.gtag("config", gaKey, {
        user_id: userId
    });
};
