/* eslint-disable no-sequences */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { useEffect, useState } from "react";
import HiddenPageAlert from "../../../components/HiddenPageAlert";
import Link from "@civicplus/preamble-ui/lib/Link";
import Titlebar from "@civicplus/preamble-ui/lib/Titlebar";
import { defaultApplicationLabels } from "../../../shared/constants";
import { HtmlDescription } from "../../../components/Layout/HtmlDescription";
import { Link as RouterLink } from "react-router-dom";
import { NavigationOption } from "../../../types/Organization";
import { ProductType } from "../../../types/ProductType";
import { useFeatures } from "../../../shared/useFeatureFlags";
import { useOrganization } from "../../../stores/organizationStore";

export const CivicServiceEmbed: React.FC = () => {
    const organization = useOrganization((state) => state.organization);
    const [flags, _, orgFlagsLoaded] = useFeatures();
    const [application, setApplication] = useState<{
        publicUri?: string;
        isActive: boolean;
        additionalProductInformation?: string;
        name?: string;
        title?: string;
        configured: boolean;
    }>({ isActive: false, configured: false });

    if (!application.configured && organization && orgFlagsLoaded) {
        if (flags.showSettingsNavigation) {
            const item = organization.navigation[NavigationOption.SubmitARequest];

            item &&
                setApplication({
                    publicUri: item.publicUri,
                    name: item.customLabel ?? item.defaultLabel,
                    title: item.customLabel ?? item.defaultLabel,
                    additionalProductInformation: item.additionalProductInformation,
                    isActive: item.isActive,
                    configured: true
                });
        } else {
            if (organization && organization.applicationCount > 0) {
                const orgApp = organization.applications.find((app) => {
                    return app.productType === ProductType.SeeClickFix && app.publicUri;
                });

                orgApp &&
                    setApplication({
                        publicUri: orgApp.publicUri,
                        isActive: true,
                        additionalProductInformation: orgApp.additionalProductInformation,
                        name: orgApp.name,
                        title: orgApp.customLabel ?? defaultApplicationLabels[ProductType.SeeClickFix],
                        configured: true
                    });
            }
        }
    }

    const splittedUri = application.publicUri?.split("/");
    const token = splittedUri ? splittedUri[4] : undefined;
    const CDNSrc =
        splittedUri && splittedUri[2].includes("int")
            ? "https://s3.amazonaws.com/cdn.seeclickfix.com/web_portal_int/embed.js"
            : "https://s3.amazonaws.com/cdn.seeclickfix.com/web_portal_prod/embed.js";

    // trying to fit inside the section
    const embedHeight = window?.innerHeight - window?.innerHeight * 0.22;

    const placeEmbed = (browserWindow: Window | any, browserDocument: Document, CDNSrc: string, id: any) => {
        (browserWindow.SCF = browserWindow.SCF || {}),
            (browserWindow.SCF.portalEmbeds = browserWindow.SCF.portalEmbeds || []);

        const scriptElement = browserDocument.createElement("script");
        (scriptElement.type = "text/javascript"),
            (scriptElement.async = !0),
            (scriptElement.src = CDNSrc),
            (scriptElement.id = id);

        const embedSection = browserDocument.getElementById("embedCivicService");

        embedSection?.appendChild(scriptElement);
    };

    const embedService = () => {
        const id = "scf-portalEmbed";

        const section = document.getElementById("embedCivicService");
        const searchIframe = section?.getElementsByTagName("iframe");

        if (searchIframe && searchIframe.length > 0) {
            return;
        }

        placeEmbed(window, document, CDNSrc, id),
            (window as any).SCF!.portalEmbeds.push({
                insertDomLocation: id,
                token: token,
                width: "100%",
                height: embedHeight,
                startingPage: "exploreMap",
                requestTypeId: ""
            });
    };

    useEffect(() => {
        if (token && CDNSrc) {
            embedService();
        }
    }, [token, CDNSrc]);

    return (
        <>
            <Titlebar
                id="title"
                title={application?.title}
                description={<HtmlDescription description={application?.additionalProductInformation} />}
                breadcrumbs={[
                    <Link key="home" to={`/${organization?.name}`} component={RouterLink}>
                        Home
                    </Link>
                ]}
            />

            <HiddenPageAlert navOption={NavigationOption.SubmitARequest} />

            <section id="embedCivicService" data-testid="embedCivicService" />;
        </>
    );
};
