import React, { useMemo } from "react";
import AccessibilityMessage from "../AccessibilityMessage";
import ChipPortal from "../Chip";
import clsx from "clsx";
import List from "@civicplus/preamble-ui/lib/List";
import ListItem from "@civicplus/preamble-ui/lib/ListItem";
import SkeletonLoader from "@civicplus/preamble-ui/lib/SkeletonLoader";
import Typography from "@civicplus/preamble-ui/lib/Typography";
import useStyles from "./styles";
import { NotificationsService } from "../../services/notificationsService";
import { NotificationToggle } from "./NotificationToggle";
import { Organization } from "../../types/Organization";
import { ProductType } from "../../types/ProductType";
import { SubscriptionList } from "../../types/Subscription";
import { User as AuthUser } from "oidc-client-ts";
import { WebsiteProductTypes, getProductTypeLabels } from "../../shared/functions";

export interface NotificationsListProps {
    notifications?: SubscriptionList[];
    organization?: Organization;
    searchText?: string;
    authUser?: AuthUser | null;
    notificationService: NotificationsService;
    handleSubscriptionUpdate?: (subscription: SubscriptionList) => void;
}

const NotificationsList: React.FC<NotificationsListProps> = (props) => {
    const classes = useStyles();
    const { notifications, organization, searchText, authUser, notificationService, handleSubscriptionUpdate } = props;

    const renderedNotifications = useMemo(() => {
        if (notifications === undefined) {
            return Array.from({ length: 5 }, (_, i) => (
                <ListItem
                    key={`skeleton-${i}`}
                    divider={true}
                    button={false}
                    ListItemTextProps={{
                        primary: <SkeletonLoader width={300} />
                    }}
                />
            ));
        } else if (notifications.length === 0) {
            return [
                <ListItem
                    key="empty-list"
                    ListItemTextProps={{
                        primary: (
                            <Typography align="center">
                                {searchText ? "No results found" : "No notifications found"}
                            </Typography>
                        )
                    }}
                />
            ];
        } else {
            return notifications.map((item) => {
                if (!organization) {
                    return null;
                }

                return (
                    <ListItem
                        key={item.id}
                        className={classes.item}
                        divider={true}
                        ListItemTextProps={{
                            className: clsx({ [classes.featured]: item.isFeatured }),
                            primary: (
                                <div className={classes.row}>
                                    <Typography className={classes.title}>{item.name}</Typography>
                                </div>
                            ),
                            secondary: (
                                <div className={classes.chipsContainer}>
                                    {item.tags?.map((tag) => <ChipPortal label={tag} key={tag} size="small" />)}
                                    <ChipPortal
                                        label={
                                            WebsiteProductTypes.includes(item.productType)
                                                ? "Website"
                                                : getProductTypeLabels(item.productType)
                                        }
                                        key={item.productType}
                                        size="small"
                                    />
                                    {item.productType === ProductType.Notifications &&
                                    item.additionalProducts.length > 0 &&
                                    item.additionalProducts.some((p) => WebsiteProductTypes.includes(p)) ? (
                                        <ChipPortal label="Website" key="Website" size="small" />
                                    ) : (
                                        <></>
                                    )}
                                </div>
                            )
                        }}
                        itemSecondaryAction={
                            <div className={classes.toggles}>
                                <div className={classes.toggle}>
                                    <NotificationToggle
                                        toggleType="email"
                                        organization={organization}
                                        authUser={authUser}
                                        subscription={item}
                                        notificationService={notificationService}
                                        emailSubscription={item.emailSubscription ?? false}
                                        smsSubscription={item.smsSubscription ?? false}
                                        handlePendingSubscription={true}
                                        onChangeComplete={(success: boolean) => {
                                            if (success) {
                                                item.emailSubscription = !item.emailSubscription;
                                                if (handleSubscriptionUpdate) {
                                                    handleSubscriptionUpdate(item);
                                                }
                                            }
                                        }}
                                    />
                                </div>

                                <div className={classes.toggle}>
                                    <NotificationToggle
                                        toggleType="sms"
                                        organization={organization}
                                        authUser={authUser}
                                        subscription={item}
                                        notificationService={notificationService}
                                        emailSubscription={item.emailSubscription ?? false}
                                        smsSubscription={item.smsSubscription ?? false}
                                        handlePendingSubscription={true}
                                        onChangeComplete={(success: boolean) => {
                                            if (success) {
                                                item.smsSubscription = !item.smsSubscription;
                                                if (handleSubscriptionUpdate) {
                                                    handleSubscriptionUpdate(item);
                                                }
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        }
                    />
                );
            });
        }
    }, [notifications, searchText, organization, classes, authUser, notificationService, handleSubscriptionUpdate]);

    const isLoading = notifications === undefined;

    return (
        <>
            <List id="notifications-list" wrapperStyles={true} aria-busy={isLoading}>
                {renderedNotifications}
            </List>

            <AccessibilityMessage
                message={isLoading ? "Loading Subscription Details" : "Subscription Details Loaded"}
            />
        </>
    );
};

export default NotificationsList;
