import React, { useCallback, useMemo, useRef, useState } from "react";
import ApiService, { ApiError, parseErrorMessage } from "../../services/apiService";
import Autocomplete, { OptionShape } from "@civicplus/preamble-ui/lib/Autocomplete";
import Button from "@civicplus/preamble-ui/lib/Button";
import Checkbox from "@civicplus/preamble-ui/lib/Checkbox";
import DateTimeRange, { ValueShape } from "@civicplus/preamble-ui/lib/DateTimeRange";
import Dialog from "@civicplus/preamble-ui/lib/Dialog";
import Grid from "@civicplus/preamble-ui/lib/Grid";
import Icon from "@mdi/react";
import TextInput from "@civicplus/preamble-ui/lib/TextInput";
import Typography from "@civicplus/preamble-ui/lib/Typography";
import { makeStyles } from "@civicplus/preamble-ui/lib/Utilities/ThemeHelper";
import { isValidUrlValidation, softMaxLengthValidation } from "../../shared/customValidation";
import { useAuth } from "../../providers/AuthProvider";
import { Sections, LinksSectionOptions, AllSectionOptions } from "../../types/Sections";
import { Link } from "../../types/Link";
import { getIconSetForSection, getSectionIconKeyFromValue, SectionsIcon } from "../SectionsIcons/SectionsIcons";
import { DirtyStateDialog } from "../DirtyState";
import { GAevent } from "../../services/googleAnalyticsService";
import { isStaffUser } from "../../shared/functions";
import { FormatTags, GetSectionValue, sectionsType } from "../EditLinkDialog/EditLinkUtilities";
import { DocumentType } from "../../types/SearchDocument";
import enhanceWithValidation, {
    requiredValidation,
    dateRangeValidDateValidation,
    dateRangeBoundaryValidation,
    dateRangeMinValueValidation,
    ValidationResult
} from "@civicplus/preamble-ui/lib/Validations";
import { useSnackbar } from "notistack";

const useStyles = makeStyles(() => ({
    gridTextWrapper: {
        display: "grid",
        alignItems: "center",
        paddingRight: "0px !important"
    },
    gridIconWrapper: {
        paddingRight: "35px !important"
    }
}));

const EnhancedTextInput = enhanceWithValidation(TextInput);
const EnhancedAutocomplete = enhanceWithValidation(Autocomplete);
const EnhancedDateTimeRange = enhanceWithValidation(DateTimeRange);

interface CreateNewLinkDialogProps {
    isOpen: boolean;
    onClose: () => void;
    onSave: (link?: Link) => void;
    orgName: string;
    defaultSection?: string;
    modalTitle?: string;
    documentType?: DocumentType;
}

interface ICreateNewLinkIconSection {
    icon: string | undefined;
    iconOptions: SectionsIcon[];
    setIcon: React.Dispatch<React.SetStateAction<string | undefined>>;
}

const CreateNewLinkIconSection: React.FC<ICreateNewLinkIconSection> = React.memo(
    function CreateNewLinkIconSectionInternal({ icon, iconOptions, setIcon }) {
        const classes = useStyles();

        return (
            <Grid container={true} spacing={5}>
                {iconOptions.length ? (
                    <Grid className={classes.gridTextWrapper}>
                        <Typography style={{ fontSize: 14 }}>Select an icon:</Typography>
                    </Grid>
                ) : null}

                {iconOptions.map((iconOption) => {
                    const key = iconOption.key;
                    const opacity = icon === getSectionIconKeyFromValue(key) ? 1 : 0.25;
                    return (
                        <Grid key={iconOption.key} xs={1} className={classes.gridIconWrapper}>
                            <Icon
                                title={key}
                                style={{
                                    opacity,
                                    cursor: "pointer"
                                }}
                                path={iconOption?.path}
                                size={2}
                                /* @ts-ignore onClick does exist */
                                onClick={() => {
                                    setIcon(getSectionIconKeyFromValue(key));
                                }}
                            />
                        </Grid>
                    );
                })}
            </Grid>
        );
    }
);

export const CreateNewLinkDialog: React.FC<CreateNewLinkDialogProps> = ({
    isOpen,
    onClose,
    onSave,
    orgName,
    defaultSection,
    modalTitle,
    documentType
}) => {
    const auth = useAuth();
    const { enqueueSnackbar } = useSnackbar();

    const [url, setUrl] = useState<string | undefined>(undefined);
    const [title, setTitle] = useState<string | undefined>(undefined);
    const [description, setDescription] = useState<string | undefined>(undefined);
    const [section, setSection] = useState<string | undefined>(defaultSection);
    const [icon, setIcon] = useState<string | undefined>(undefined);
    const [isFeatured, setIsFeatured] = useState<boolean | undefined>(undefined);
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [iconOptions, setIconOptions] = useState<SectionsIcon[]>([]);
    const [tags, setTags] = useState<string[]>([]);
    const [allTags, setAllTags] = useState<OptionShape[]>([]);
    const [selectedTags, setSelectedTags] = useState<OptionShape | OptionShape[]>();
    const [isLeavingWithoutSaving, setIsLeavingWithoutSaving] = useState<boolean>(false);
    const [hitSave, setHitSave] = useState<boolean>(false);
    const [eventDate, setEventDate] = useState<ValueShape>();

    const urlRef = useRef<{ validate: () => ValidationResult }>();
    const titleRef = useRef<{ validate: () => ValidationResult }>();
    const sectionRef = useRef<{ validate: () => ValidationResult }>();
    const descriptionRef = useRef<{ validate: () => ValidationResult }>();

    const isDirty = useMemo(() => {
        return (
            url !== undefined ||
            title !== undefined ||
            description !== undefined ||
            (section !== undefined && !defaultSection) ||
            icon !== undefined ||
            isFeatured !== undefined ||
            tags.length > 0 ||
            eventDate?.from !== undefined ||
            eventDate?.to !== undefined
        );
    }, [description, icon, section, title, url, defaultSection, isFeatured, tags, eventDate]);

    const onSaveDialog = useCallback(async () => {
        setIsSaving(true);
        setHitSave(true);

        let link: Link | undefined = undefined;

        const validationResults: ValidationResult[] = await Promise.all([
            urlRef.current!.validate(),
            titleRef.current!.validate(),
            sectionRef.current!.validate(),
            descriptionRef.current!.validate()
        ]);

        if (validationResults.some((x) => x.error)) {
            setIsSaving(false);
            enqueueSnackbar("All fields must be valid to continue.", {
                variant: "error",
                persist: true
            });
            return;
        }

        if (documentType === DocumentType.Event && (eventDate?.from === undefined || eventDate?.to === undefined)) {
            setIsSaving(false);
            enqueueSnackbar("Start Date and End Date are required to continue.", {
                variant: "error",
                persist: true
            });
            return;
        }

        if (auth?.user?.access_token) {
            let stopExecution = false;
            try {
                link = await ApiService.post({
                    url: `${orgName}/links`,
                    requestInit: {
                        headers: {
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify({
                            title,
                            description,
                            url,
                            section,
                            icon,
                            isFeatured,
                            tags,
                            startDate: eventDate?.from,
                            endDate: eventDate?.to
                        })
                    },
                    authUser: auth.user
                });

                if (!isStaffUser(auth.user)) {
                    GAevent({
                        action: "link_created",
                        category: "Links",
                        orgName,
                        label: `Section: ${section}`
                    });
                }
            } catch (ex) {
                setIsSaving(false);
                const errorMessage = parseErrorMessage(ex as ApiError);

                enqueueSnackbar(errorMessage, {
                    variant: "error",
                    persist: true
                });
                console.error(ex);
                stopExecution = true;
            } finally {
                setIsSaving(false);
            }

            if (stopExecution) {
                return;
            }
        }

        if (onSave) {
            onSave(link);
        }

        enqueueSnackbar(`"${title}" has been saved.`, {
            variant: "success"
        });

        if (onClose) {
            onClose();
            setHitSave(false);
        }
    }, [
        auth.user,
        onSave,
        enqueueSnackbar,
        title,
        onClose,
        orgName,
        description,
        url,
        section,
        icon,
        isFeatured,
        tags,
        eventDate,
        documentType
    ]);

    const onCloseDialog = useCallback(() => {
        if (isDirty) {
            setIsLeavingWithoutSaving(true);
        } else {
            if (onClose) {
                onClose();
            }
        }
    }, [isDirty, onClose]);

    const onEnterDialog = useCallback(() => {
        const getTags = async () => {
            const apiTags = await ApiService.get({
                url: `${orgName}/search/tags`,
                cache: false,
                authUser: auth.user
            });

            const sortedTags = [...new Set(apiTags.map((tag: string) => tag.toLowerCase()))]
                .sort()
                .map((tag) => apiTags.find((t: string) => t.toLowerCase() === tag));

            const tagsList = FormatTags(sortedTags);

            AllSectionOptions.forEach((option: any) => {
                option.options.forEach((tag: OptionShape) => {
                    tagsList.push(tag);
                });
            });

            setAllTags(tagsList);
        };
        getTags();
    }, [auth.user, orgName]);

    const onExitedDialog = useCallback(() => {
        setUrl(undefined);
        setTitle(undefined);
        setDescription(undefined);
        setSection(defaultSection);
        setIcon(undefined);
        setIconOptions([]);
        setIsFeatured(undefined);
        setSelectedTags([]);
        setTags([]);
        setEventDate({
            from: undefined,
            to: undefined
        });
        setHitSave(false);
    }, [defaultSection]);

    const sectionValue = useMemo(() => {
        const currentSectionOptions = defaultSection !== undefined ? AllSectionOptions : LinksSectionOptions;

        const value = section && GetSectionValue(section, currentSectionOptions as sectionsType[]);

        return value;
    }, [section, defaultSection]);

    const onUrlChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setUrl(e.currentTarget.value || undefined);
    }, []);

    const onTitleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setTitle(e.currentTarget.value || undefined);
    }, []);

    const onEventDateChange = (newValue: ValueShape) => {
        setEventDate(newValue);
    };

    const onDescriptionChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setDescription(e.currentTarget.value || undefined);
    }, []);

    const onIsFeaturedChange = useCallback((e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        setIsFeatured(checked);
    }, []);

    const onSectionChange = useCallback((selected: { label: string; value: string }) => {
        setSection(selected.value);
        setIcon(undefined);
        const key = selected.label;
        const newIconOptions: SectionsIcon[] = getIconSetForSection(key as Sections);

        setIconOptions(newIconOptions);
        setIcon(newIconOptions.length > 0 ? getSectionIconKeyFromValue(newIconOptions[0].key) : undefined);
    }, []);

    const onTagChange = useCallback((selected: OptionShape[] | undefined) => {
        const tags: string[] = [];

        if (Array.isArray(selected)) {
            selected.forEach((option: OptionShape) => {
                tags.push(option.label.toString());
            });
        }

        setTags(tags);
        setSelectedTags(selected);
    }, []);

    return (
        <>
            <Dialog
                open={isOpen}
                title={modalTitle ? modalTitle : "Create New Link"}
                onEnter={onEnterDialog}
                onClose={onCloseDialog}
                onExited={onExitedDialog}
                maxWidth={documentType === DocumentType.Event ? "md" : "sm"}
                actions={[
                    <Button
                        color="primary"
                        onClick={onSaveDialog}
                        isLoading={isSaving}
                        id="modal-save-button"
                        key="create-new-link-btn"
                        data-testid="modal-save-button"
                    >
                        Save
                    </Button>,
                    <Button onClick={onCloseDialog} key="cancel">
                        Cancel
                    </Button>
                ]}
            >
                <Grid container={true}>
                    <Grid xs={12}>
                        <EnhancedTextInput
                            ref={urlRef}
                            id="new-link-url"
                            label="URL"
                            value={url}
                            onChange={onUrlChange}
                            fullWidth={true}
                            required={true}
                            validations={[isValidUrlValidation, requiredValidation]}
                        />
                    </Grid>

                    <Grid xs={12}>
                        <EnhancedAutocomplete
                            ref={sectionRef}
                            id="new-link-section"
                            label="Section"
                            required={true}
                            value={sectionValue}
                            onChange={onSectionChange}
                            options={defaultSection !== undefined ? AllSectionOptions : LinksSectionOptions}
                            isInDialog={true}
                            validations={[requiredValidation]}
                            disabled={defaultSection !== undefined}
                        />
                    </Grid>

                    <CreateNewLinkIconSection icon={icon} iconOptions={iconOptions} setIcon={setIcon} />

                    <Grid xs={12}>
                        <EnhancedTextInput
                            ref={titleRef}
                            id="new-link-title"
                            label="Title"
                            value={title}
                            required={true}
                            fullWidth={true}
                            helperText={`${title?.length === undefined ? 0 : title?.length}/100`}
                            softMaxLength={100}
                            onChange={onTitleChange}
                            validations={[requiredValidation, softMaxLengthValidation]}
                        />
                    </Grid>

                    {documentType === DocumentType.Event ? (
                        <Grid xs={12}>
                            <EnhancedDateTimeRange
                                id="meeting-link-datetimerange"
                                pickerType="dateTime"
                                minValue={eventDate?.from ?? new Date()}
                                required={true}
                                keyboard={true}
                                value={eventDate}
                                onChange={onEventDateChange}
                                format="MM/dd/yyyy h:mm:ss a"
                                fromProps={{ label: "Start Date" }}
                                toProps={{ label: "End Date" }}
                                error={{
                                    from: hitSave && eventDate?.from === undefined,
                                    to: hitSave && eventDate?.to === undefined
                                }}
                                errorMessage={{
                                    from: "Start Date can't be blank.",
                                    to: "End Date can't be blank."
                                }}
                                validations={[
                                    requiredValidation,
                                    dateRangeMinValueValidation,
                                    dateRangeValidDateValidation,
                                    dateRangeBoundaryValidation
                                ]}
                            />
                        </Grid>
                    ) : null}

                    <Grid xs={12}>
                        <EnhancedTextInput
                            ref={descriptionRef}
                            id="new-link-description"
                            label="Description"
                            value={description}
                            onChange={onDescriptionChange}
                            multiline={true}
                            rows={5}
                            fullWidth={true}
                            helperText={`${description?.length === undefined ? 0 : description?.length}/1000`}
                            softMaxLength={1000}
                            validations={[softMaxLengthValidation]}
                        />
                    </Grid>

                    <Grid xs={12}>
                        <Autocomplete
                            id="new-link-tag"
                            label="Tags"
                            onChange={onTagChange}
                            value={selectedTags}
                            options={allTags}
                            multiSelect={true}
                            allowOptionCreation={true}
                            isInDialog={true}
                        />
                    </Grid>

                    <Grid xs={12}>
                        <Checkbox
                            id="featured-link"
                            label="Featured in Portal"
                            onChange={onIsFeaturedChange}
                            checked={isFeatured}
                        />
                    </Grid>
                </Grid>
            </Dialog>

            <DirtyStateDialog
                isOpen={isLeavingWithoutSaving}
                onReturnToContent={() => {
                    setIsLeavingWithoutSaving(false);
                }}
                onDiscardChanges={() => {
                    setIsLeavingWithoutSaving(false);
                    if (onClose) {
                        onClose();
                    }
                }}
            />
        </>
    );
};
