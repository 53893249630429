import React, { useState, useMemo, useEffect, useCallback, useRef } from "react";
import AdvancedTableWrapper, {
    AdvancedTableWrapper as AdvancedTableWrapperClass
} from "@civicplus/preamble-ui/lib/AdvancedTable/AdvancedTableWrapper";
import ApiService, { parseErrorMessage, ApiError } from "../../services/apiService";
import Button from "@civicplus/preamble-ui/lib/Button";
import Chip from "@civicplus/preamble-ui/lib/Chip";
import Close from "@mui/icons-material/Close";
import Grid from "@civicplus/preamble-ui/lib/Grid";
import Icon from "@mdi/react";
import IconCircleCheck from "@civicplus/preamble-ui/lib/Icons/IconCircleCheck";
import Menu from "@civicplus/preamble-ui/lib/Menu";
import { RowStatus, TableData } from "@civicplus/preamble-ui/lib/AdvancedTable/AdvancedTableWrapperConstants";
import spacetime from "spacetime";
import Tooltip from "@civicplus/preamble-ui/lib/Tooltip";
import Typography from "@civicplus/preamble-ui/lib/Typography";
import { AuthContextProps } from "../react-oidc-context/AuthContext";
import { CreateNewLinkDialog } from "../../components/CreateNewLinkDialog/CreateNewLinkDialog";
import { EditLinkDialog } from "../../components/EditLinkDialog/EditLinkDialog";
import { getIconByKey, SectionIconKey } from "../../components/SectionsIcons/SectionsIcons";
import { Link } from "../../types/Link";
import { makeStyles } from "@civicplus/preamble-ui/lib/Utilities/ThemeHelper";
import Table from "@civicplus/preamble-ui/lib/Table";
import TableBody from "@civicplus/preamble-ui/lib/TableBody";
import TableCell from "@civicplus/preamble-ui/lib/TableCell";
import TableHeader from "@civicplus/preamble-ui/lib/TableHeader";
import TableRow from "@civicplus/preamble-ui/lib/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { OptionShape } from "@civicplus/preamble-ui/lib/Autocomplete";
import { Organization } from "../../types/Organization";
import { Sections } from "../../types/Sections";
import { SimpleDialog } from "../../components/SimpleDialog/SimpleDialog";
import { useLinks } from "../../stores/linksStore";
import { FormatTags } from "../EditLinkDialog/EditLinkUtilities";
import { filterLogic } from "../../shared/functions";
import { BaseSettingsSectionProps } from "./SettingsSection";
import { useSnackbar } from "notistack";
import {
    ByUser,
    LastModified,
    makeCustomSelectPicker,
    makeDateRangeFilter,
    standardFilterLogic
} from "@civicplus/preamble-ui/lib/Utilities/CommonTableComponents";

const useStyles = makeStyles((theme) => ({
    settingsColumn: {
        minWidth: "120px"
    },

    columnHeaderSpacing: {
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(2.5)
    },
    btnWrapper: {
        display: "flex",
        justifyContent: "end"
    },
    columnWrap: {
        overflowWrap: "anywhere"
    },
    tableHead: {
        "& th": {
            fontWeight: 700
        }
    },
    tagChip: {
        backgroundColor: theme.palette.grey[300],
        "& > span": {
            color: "#000"
        }
    }
}));

interface LinkActionMenuProps {
    value: any;
    tableData: any;
    links: Link[];
    setLinkToBeEdited: React.Dispatch<React.SetStateAction<{ data: Link; index: number } | undefined>>;
    setLinkToBeDeleted: React.Dispatch<React.SetStateAction<{ data: Link; index: number } | undefined>>;
    setIsEditDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setIsDeleteDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
    organization: Organization;
    auth: AuthContextProps;
    refContainer: any;
    timeZone: string;
}

const LinkActionMenu: React.FC<LinkActionMenuProps> = ({
    tableData,
    links,
    setLinkToBeEdited,
    setLinkToBeDeleted,
    setIsEditDialogOpen,
    setIsDeleteDialogOpen,
    organization,
    auth,
    refContainer,
    timeZone
}) => {
    const linkIndex = links.findIndex((link) => link.id === tableData.rowData[0]);
    const link = links[linkIndex];

    const linkActions = [
        {
            children: "Edit",
            onClick: () => {
                setLinkToBeEdited({ data: link, index: linkIndex });
                setIsEditDialogOpen(true);
            }
        },
        {
            children: `${tableData.rowData[5] === true ? "Unfeature" : "Feature"} in Portal`,
            onClick: async () => {
                const result = await ApiService.put({
                    url: `${organization.name}/links/toggle/featured/${link.aggregateId}`,
                    authUser: auth.user
                });

                const tableRef = refContainer && (refContainer.current as typeof AdvancedTableWrapper);
                tableData.rowData[5] = result.isFeatured;
                tableData.rowData[8] = (
                    <LastModified date={result.LastModifiedOn} organizationTimeZone={timeZone} key="lastModified" />
                );
                tableData.rowData[9] = <ByUser key="By-User" users={new Map()} entity={result.lastModifiedByFull} />;
                tableRef.reloadRow(tableData.rowIndex, tableData.rowData);
            }
        },
        {
            children: "Delete",
            onClick: () => {
                setLinkToBeDeleted({ data: link, index: linkIndex });
                setIsDeleteDialogOpen(true);
            }
        }
    ];
    return <Menu type="action" items={linkActions} id={`actions-${link.id}`} key={`actions-${link.id}`} />;
};

interface LinksTableProps {
    refContainer: React.RefObject<AdvancedTableWrapperClass>;
    loading: boolean;
    links: Link[];
    tags: OptionShape[];
    timeZone: string;
    setLinkToBeEdited: React.Dispatch<React.SetStateAction<{ data: Link; index: number } | undefined>>;
    setLinkToBeDeleted: React.Dispatch<React.SetStateAction<{ data: Link; index: number } | undefined>>;
    setIsEditDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setIsDeleteDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
    organization: Organization;
    auth: AuthContextProps;
}

const LinksTable: React.FC<LinksTableProps> = React.memo(function LinksTableInternal({
    refContainer,
    loading,
    links,
    tags,
    timeZone,
    setLinkToBeEdited,
    setLinkToBeDeleted,
    setIsEditDialogOpen,
    setIsDeleteDialogOpen,
    organization,
    auth
}) {
    const classes = useStyles();

    /* istanbul ignore next */
    const mapData = useCallback(
        (link: Link, index: number) => {
            // Ordering of columns is important. If order changes, ensure all spots that read the row data by index is updated.
            const res = [
                link.id,
                link.sectionString,
                link?.url,
                link.title,
                link.tags,
                link.isFeatured,
                link.description,
                link.icon,
                link.lastModified ? (
                    <LastModified date={link.lastModified} organizationTimeZone={timeZone} key="lastModified" />
                ) : null,
                <ByUser key="By-User" users={new Map()} entity={link.lastModifiedByFull} />
            ];

            return res;
        },
        [timeZone]
    );

    const getRows = useCallback(async (): Promise<TableData> => {
        if (loading) {
            return { count: 0, data: [], status: RowStatus.Loading };
        }

        const data = links.map((item, index) => mapData(item, index));

        return { count: links.length, data };
    }, [links, mapData, loading]);

    const customSearch = useCallback((searchQuery: string, currentRow: string[]) => {
        let isFound = false;
        currentRow.forEach((col) => {
            if (col) {
                if (col.toString().toLowerCase().indexOf(searchQuery.toLowerCase()) >= 0) {
                    isFound = true;
                }
            }
        });
        return isFound;
    }, []);

    const columns = useMemo(() => {
        return [
            {
                name: "id",
                label: "Id",
                options: {
                    sort: false,
                    display: "false"
                }
            },
            {
                name: "section",
                label: "Section",
                options: {
                    sort: false,
                    filter: true,
                    customBodyRender: (value: string) => {
                        return <div className={classes.settingsColumn}>{value}</div>;
                    },
                    ...makeCustomSelectPicker(
                        "Section",
                        Array.from(new Set(links.map((c) => c.sectionString))).map((c) => ({
                            label: c,
                            value: c
                        })),
                        filterLogic
                    )
                }
            },
            {
                name: "url",
                label: "Url",
                options: {
                    display: "excluded",
                    sort: false,
                    filter: false
                }
            },
            {
                name: "title",
                label: "Title",
                options: {
                    sort: false,
                    filter: true,
                    ...makeCustomSelectPicker(
                        "Title",
                        Array.from(new Set(links.map((c) => c.title))).map((c) => ({
                            label: c,
                            value: c
                        })),
                        filterLogic
                    )
                }
            },
            {
                name: "tags",
                label: "Tags",
                options: {
                    sort: false,
                    filter: true,
                    customBodyRender: (values: string[]) => (
                        <Grid container={true}>
                            {values.length > 0
                                ? values.map((tag) => {
                                      return (
                                          <Grid key={tag}>
                                              <Chip id={tag} label={tag} className={classes.tagChip} />
                                          </Grid>
                                      );
                                  })
                                : undefined}
                        </Grid>
                    ),
                    ...makeCustomSelectPicker(
                        "Tags",
                        Array.from(new Set(tags)).map((t) => ({
                            label: t.label,
                            value: t.value
                        })),
                        filterLogic
                    )
                }
            },
            {
                name: "isFeatured",
                label: "Featured",
                options: {
                    sort: false,
                    filter: true,
                    customBodyRender: (value: boolean) => {
                        return (
                            <Tooltip
                                key={`tooltip-isFeatured-${value}`}
                                title={value ? "Featured in Portal" : "Not featured in Portal"}
                            >
                                {value ? <IconCircleCheck /> : <Close color="disabled" />}
                            </Tooltip>
                        );
                    },
                    ...makeCustomSelectPicker(
                        "Featured",
                        [
                            { label: "Featured", value: true },
                            { label: "Not Featured", value: false }
                        ],
                        standardFilterLogic
                    )
                }
            },
            {
                name: "description",
                label: "Description",
                options: {
                    display: "excluded",
                    sort: false,
                    filter: false
                }
            },
            {
                name: "icon",
                label: "Icon",
                options: {
                    display: "excluded",
                    sort: false,
                    filter: false,
                    customBodyRender: (value: string) => {
                        return <Icon path={getIconByKey(value as SectionIconKey)?.path || ""} size={2} />;
                    }
                }
            },
            {
                name: "lastModified",
                label: "Updated",
                options: {
                    filter: true,
                    sortLabel: "Sort by Updated",
                    sort: true.valueOf,
                    sortOrder: {
                        name: "Updated sort order",
                        direction: "asc"
                    },
                    ...makeDateRangeFilter(
                        "Updated",
                        timeZone,
                        true,
                        (rowValue: { props: { date: Date } }, filteredValues: Date[]) => {
                            const rowInTimeZone = spacetime(
                                rowValue && rowValue.props && rowValue.props.date,
                                undefined
                            );
                            if (rowInTimeZone.isValid()) {
                                if (filteredValues[0] && filteredValues[1]) {
                                    return (
                                        rowInTimeZone.toNativeDate() < filteredValues[0] ||
                                        rowInTimeZone.toNativeDate() > filteredValues[1]
                                    );
                                } else if (filteredValues[0]) {
                                    return rowInTimeZone.toNativeDate() < filteredValues[0];
                                } else if (filteredValues[1]) {
                                    return rowInTimeZone.toNativeDate() > filteredValues[1];
                                }
                            }
                            return false;
                        }
                    ),

                    customBodyRender: (value: string) => {
                        return <div className={classes.settingsColumn}>{value}</div>;
                    },

                    customHeadRender: (
                        { index, name, ...column }: { index: number; name: string; column: any },
                        sortColumn: (index: number) => void,
                        sortOptions: { name: string; direction: "asc" | "desc" | undefined }
                    ) => {
                        return (
                            <Tooltip placement="bottom" title="Sort by Updated">
                                <TableSortLabel
                                    className={classes.columnHeaderSpacing}
                                    aria-label="Sort by Last Modified"
                                    active={sortOptions.direction !== undefined}
                                    direction={sortOptions.direction ?? "asc"}
                                >
                                    <th style={{ cursor: "default" }} onClick={() => sortColumn(index)} key={index}>
                                        UPDATED
                                    </th>
                                </TableSortLabel>
                            </Tooltip>
                        );
                    }
                }
            },
            {
                name: "lastModifiedBy",
                label: "By",
                options: {
                    filter: false,
                    sort: false
                }
            },
            {
                name: "actions",
                label: "Actions",
                options: {
                    filter: false,
                    sort: false,
                    searchable: false,
                    viewColumns: false,
                    customBodyRender: (value: any, tableData: any) => (
                        <LinkActionMenu
                            value={value}
                            tableData={tableData}
                            links={links}
                            setLinkToBeEdited={setLinkToBeEdited}
                            setLinkToBeDeleted={setLinkToBeDeleted}
                            setIsDeleteDialogOpen={setIsDeleteDialogOpen}
                            setIsEditDialogOpen={setIsEditDialogOpen}
                            organization={organization}
                            auth={auth}
                            refContainer={refContainer}
                            timeZone={timeZone}
                        />
                    )
                }
            }
        ];
    }, [
        links,
        tags,
        timeZone,
        classes.settingsColumn,
        classes.tagChip,
        setLinkToBeEdited,
        setLinkToBeDeleted,
        setIsDeleteDialogOpen,
        setIsEditDialogOpen,
        organization,
        auth,
        refContainer
    ]);

    return (
        <AdvancedTableWrapper
            key={`${loading}-${JSON.stringify(links)}`}
            columns={columns}
            rows={getRows}
            scrollToTop={true}
            showFilter={true}
            showSearch={true}
            emptyMessage="You currently have no Links."
            serverSide={false}
            customSearch={customSearch}
            ref={refContainer}
            expandableRows={true}
            expandableRowsHeader={false}
            expandableRowsOnClick={true}
            renderExpandableRow={(rowData: string[]) => {
                const colSpan = rowData.length + 1;
                return (
                    <TableRow aria-expanded="true">
                        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={colSpan}>
                            <Typography variant="h6" gutterBottom={true} component="div">
                                Additional Information
                            </Typography>

                            <Table size="small" aria-label="Additional Information">
                                <TableHeader className={classes.tableHead}>
                                    <TableRow>
                                        <TableCell>URL</TableCell>
                                        <TableCell>Description</TableCell>
                                        <TableCell align="right">Icon</TableCell>
                                    </TableRow>
                                </TableHeader>

                                <TableBody>
                                    <TableRow>
                                        <TableCell>{rowData[2]}</TableCell>
                                        <TableCell className={classes.columnWrap}>{rowData[6]}</TableCell>
                                        <TableCell align="right">{rowData[7]}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableCell>
                    </TableRow>
                );
            }}
        />
    );
});

const LinksSettings: React.FC<BaseSettingsSectionProps> = ({ org, auth }) => {
    const classes = useStyles();
    const [links, setLinks] = useState<Link[]>([]);
    const [loading, setLoading] = useState(true);
    const [tags, setTags] = useState<OptionShape[]>([]);
    const [timeZone, setTimeZone] = useState("America/Chicago");
    const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
    const [linkToBeDeleted, setLinkToBeDeleted] = useState<{ data: Link; index: number }>();
    const [linkToBeEdited, setLinkToBeEdited] = useState<{ data: Link; index: number }>();

    const { enqueueSnackbar } = useSnackbar();
    const refContainer = useRef<AdvancedTableWrapperClass>(null);

    const { clearLinks } = useLinks((state) => ({
        clearLinks: state.clear
    }));

    const getLinks = useCallback(async () => {
        const linksArray: Link[] = await ApiService.get({
            url: `${org.name}/links/admin`,
            cache: false,
            authUser: auth?.user
        });

        if (linksArray) {
            setLinks(
                linksArray.filter(
                    (link: Link) => link.section !== Sections.Forms && link.section !== Sections.Notifications
                )
            );
        }

        // Clear LinkStore to force reload when navigating to front-end sections
        clearLinks();
    }, [org.name, auth?.user, clearLinks]);

    useEffect(() => {
        const getTags = async () => {
            const allTags = await ApiService.get({
                url: `${org.name}/search/tags`,
                cache: false,
                authUser: auth.user
            });

            const allFormattedTags = FormatTags(allTags);
            setTags(allFormattedTags);
        };

        if (tags.length === 0) {
            getTags();
        }
    }, [auth.user, org.name, tags.length]);

    useEffect(
        function loadTimezone() {
            if (org.settings.timeZone) {
                setTimeZone(org.settings.timeZone);
            }
        },
        [org]
    );

    useEffect(() => {
        async function initialLinksLoad() {
            await getLinks();
            setLoading(false);
        }
        initialLinksLoad();
    }, [getLinks]);

    const onCreateDialogSave = useCallback(async () => {
        await getLinks();
        setIsCreateDialogOpen(false);
    }, [getLinks]);

    const onEditDialogSave = useCallback(async () => {
        await getLinks();
        setIsEditDialogOpen(false);
        setLinkToBeEdited(undefined);

        // TODO: Also fix returning to first table page after updating Link
        // const tableRef = refContainer && (refContainer.current as typeof AdvancedTableWrapper);
        // if (linkToBeEdited)
        //     tableRef.reloadRow(linkToBeEdited?.index, mapData(UpdatedDataFromLinkEdited, linkToBeEdited.index));
        // tableRef.loadRows();
    }, [getLinks]);

    const onCreateDialogClose = useCallback(() => {
        setIsCreateDialogOpen(false);
    }, []);

    const onEditDialogClose = useCallback(() => {
        setIsEditDialogOpen(false);
        setLinkToBeEdited(undefined);
    }, []);

    const onDeleteDialogClose = useCallback(() => {
        setIsDeleteDialogOpen(false);
        setLinkToBeDeleted(undefined);
    }, []);

    const onDeleteDialogAction = useCallback(async () => {
        if (!linkToBeDeleted) {
            console.error("No link to delete");
            return;
        }

        try {
            await ApiService.delete({
                url: `${org.name}/links/${linkToBeDeleted.data.id}`,
                authUser: auth.user
            });

            await getLinks();

            onDeleteDialogClose();
            const tableRef = refContainer.current;
            if (tableRef) {
                tableRef.deleteRow(linkToBeDeleted.index);
                tableRef.loadRows();
            }

            enqueueSnackbar(`"${linkToBeDeleted.data.title}" has been deleted.`, {
                variant: "success"
            });
        } catch (ex) {
            console.error(ex);
            const message = parseErrorMessage(ex as ApiError, "An error occured while deleting the link.");
            enqueueSnackbar(message, {
                variant: "error",
                persist: true
            });
        }
    }, [auth.user, enqueueSnackbar, getLinks, linkToBeDeleted, onDeleteDialogClose, org.name]);

    return (
        <>
            <div className={classes.btnWrapper}>
                <Button key="create" id="settings-create" onClick={() => setIsCreateDialogOpen(true)} color="primary">
                    + New Link
                </Button>
            </div>

            <LinksTable
                links={links}
                tags={tags}
                refContainer={refContainer}
                timeZone={timeZone}
                loading={loading}
                setLinkToBeEdited={setLinkToBeEdited}
                setLinkToBeDeleted={setLinkToBeDeleted}
                setIsEditDialogOpen={setIsEditDialogOpen}
                setIsDeleteDialogOpen={setIsDeleteDialogOpen}
                organization={org}
                auth={auth}
            />

            <CreateNewLinkDialog
                orgName={org.name}
                isOpen={isCreateDialogOpen}
                onClose={onCreateDialogClose}
                onSave={onCreateDialogSave}
            />

            <EditLinkDialog
                orgName={org.name}
                isOpen={isEditDialogOpen}
                onClose={onEditDialogClose}
                onSave={onEditDialogSave}
                linkToEdit={linkToBeEdited?.data}
                showFeatureOption={true}
            />

            <SimpleDialog
                isOpen={isDeleteDialogOpen}
                title="Delete Link"
                primaryAction={onDeleteDialogAction}
                primaryBtnText="Confirm Deletion"
                onClose={onDeleteDialogClose}
            >
                <Typography>Are you sure you want to delete this link?</Typography>
            </SimpleDialog>
        </>
    );
};

export default LinksSettings;
