import React, { useState, useEffect, useMemo, useCallback } from "react";
import { Link as RouterLink, useParams } from "react-router-dom";

import Layout from "@civicplus/preamble-ui/lib/Layout";
import Link from "@civicplus/preamble-ui/lib/Link";
import ApiService from "../../../services/apiService";
import { useOrganization } from "../../../stores/organizationStore";
import { PagedResult } from "../../../types/Organization";
import { DocumentType, SearchDocument } from "../../../types/SearchDocument";
import { useAuth } from "../../../providers/AuthProvider";
import { NotificationsService } from "../../../services/notificationsService";
import { SearchTable } from "../../../components/Search/SearchTable";

export const Search: React.FC = () => {
    const auth = useAuth();
    const { query: searchValue } = useParams();
    const notificationService = useMemo(() => new NotificationsService(), []) as NotificationsService;

    const [isFetchingSubscriptions, setIsFetchingSubscriptions] = useState(true);
    const [currentSearchValue, setCurrentSearchValue] = useState<string | null>();
    const [organization, subsAndUnsubs, orgSubscriptionsList, setOrgSubscriptionsList] = useOrganization((state) => [
        state.organization,
        state.subsAndUnsubs,
        state.orgSubscriptionsList,
        state.setOrgSubscriptionsList
    ]);

    useEffect(() => {
        const fetchSubscriptions = async () => {
            try {
                // Fetch the data from the user account and general parameters
                const subscriptions = await notificationService.getSubscriptions({
                    orgName: organization?.name,
                    authUser: auth.user,
                    documentType: DocumentType.All
                });

                setOrgSubscriptionsList(subscriptions);
            } catch (ex) {
                console.error(ex);
            } finally {
                setIsFetchingSubscriptions(false);
            }
        };

        if (organization && !auth.isLoading) {
            fetchSubscriptions();
        }
    }, [organization, auth.isLoading, auth.user, subsAndUnsubs, notificationService, setOrgSubscriptionsList]);

    const loadTable = useCallback(
        async (currentPage: number, rowsPerPage: number, searchValue?: string) => {
            if (organization) {
                let searchResults: PagedResult<SearchDocument> = {
                    items: [],
                    count: 0
                };

                const pageToRequest = searchValue === currentSearchValue ? currentPage : 0;

                if (searchValue) {
                    searchResults = await ApiService.get({
                        url: `${organization.name}/search/${searchValue}?$top=${rowsPerPage}&$skip=${
                            pageToRequest * rowsPerPage
                        }&$orderby=title asc`,
                        cache: false,
                        authUser: auth.user
                    });
                }

                setCurrentSearchValue(searchValue);

                return searchResults;
            } else {
                return {
                    items: [],
                    count: 0,
                    isLoading: true
                };
            }
        },
        [auth.user, currentSearchValue, organization]
    );

    const titlebarDescription = searchValue ? `Results for ${searchValue}` : "No search term or phrase";

    return (
        <Layout
            TitlebarProps={{
                id: "search-titlebar",
                title: "Search Results",
                description: titlebarDescription,
                breadcrumbs: [
                    <Link key="home" to={`/${organization?.name}`} component={RouterLink}>
                        Home
                    </Link>
                ]
            }}
        >
            <SearchTable
                isFetchingSubscriptions={isFetchingSubscriptions}
                getRows={loadTable}
                organization={organization}
                searchValue={searchValue}
                subscriptionList={orgSubscriptionsList}
            />
        </Layout>
    );
};
