import React from "react";
import Grid from "@civicplus/preamble-ui/lib/Grid";
import List from "@civicplus/preamble-ui/lib/List";
import ListItem from "@civicplus/preamble-ui/lib/ListItem";
import Radio from "@civicplus/preamble-ui/lib/Radio";
import PhysicalAddressListItem from "./PhysicalAddressListItem";
import { makeStyles } from "@civicplus/preamble-ui/lib/Utilities/ThemeHelper";
import { OptionShape } from "@civicplus/preamble-ui/lib/Autocomplete";

const useStyles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(2, 0)
    },
    grid: {
        [theme.breakpoints.up("sm")]: {
            flexWrap: "nowrap"
        }
    },
    button: {
        flexShrink: 0
    },
    card: {
        padding: theme.spacing(2),
        border: `1px solid ${theme.palette.divider}`
    },
    disabled: {
        opacity: 0.5
    },
    flexGrow: {
        flexGrow: 1
    },
    listItem: {
        display: "block"
    }
}));

export type PhysicalAddress = {
    id: string;
    label: string;
    country?: OptionShape | string | null;
    address1: string;
    address2: string;
    city: string;
    governingDistrict: string;
    postalArea: string;
    latitude: number | null;
    longitude: number | null;
    fullAddress?: string;
};

export type PhysicalAddressListProps = {
    existingPhysicalAddresses: PhysicalAddress[];
    selectedPhysicalAddress?: PhysicalAddress;
    onChangeSelectedPhysicalAddresses: (selectedPhysicalAddress?: PhysicalAddress) => void;
};

export const PhysicalAddressList: React.FC<PhysicalAddressListProps> = (props) => {
    const { existingPhysicalAddresses, selectedPhysicalAddress, onChangeSelectedPhysicalAddresses } = props;
    const classes = useStyles();

    const handleProvideChange = (addressId: string) => {
        const address = existingPhysicalAddresses.find((item) => item.id === addressId);
        if (!address) return;

        if (onChangeSelectedPhysicalAddresses) {
            onChangeSelectedPhysicalAddresses(address);
        }
    };

    return (
        <div className={classes.root}>
            <List id="location-list">
                {existingPhysicalAddresses.map((address) => {
                    return (
                        <ListItem
                            key={address.id}
                            disableGutters={true}
                            data-testid={address.id}
                            className={classes.listItem}
                            onClick={() => {
                                handleProvideChange(address.id);
                            }}
                        >
                            <Grid container={true} spacing={2} alignItems="center">
                                <Grid xs="auto">
                                    <Radio
                                        id={`radio-${address.id}`}
                                        color="primary"
                                        checked={selectedPhysicalAddress?.id === address.id}
                                        value={address.id}
                                        aria-label="Select this address"
                                        aria-describedby={`address-${address.id}-name`}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
                                            handleProvideChange(address.id);
                                        }}
                                    />
                                </Grid>

                                <Grid xs="auto" className={classes.flexGrow}>
                                    <PhysicalAddressListItem {...address} key={address.id} />
                                </Grid>
                            </Grid>
                        </ListItem>
                    );
                })}
            </List>
        </div>
    );
};

export default PhysicalAddressList;
