import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import ProductThemeProvider from "@civicplus/preamble-ui/lib/ProductThemeProvider";
import Navbar from "../Navbar";
import EmbedTopbar from "../EmbedTopbar";
import { useConfig } from "../../providers/ConfigProvider";
import { GAinitialize, GAlogin } from "../../services/googleAnalyticsService";
import { useAuth } from "../../providers/AuthProvider";
import { useEmbedStore } from "../../stores/embedStore";
import { useLinks } from "../../stores/linksStore";
import { isStaffUser } from "../../shared/functions";
import SnackbarProviderProps from "../SnackBar/config";

interface DefaultProps {
    children: React.ReactElement;
}

const Layout: React.FC<DefaultProps> = ({ children }) => {
    const config = useConfig();
    const auth = useAuth();
    const navigate = useNavigate();
    const [embedSignInPending, setEmbedSignInPending] = useState(true);
    const [embedRedirectedOnce, setEmbedRedirectedOnce] = useState(false);
    const [GAchecked, setGAChecked] = useState(false);
    const { clearFavorites } = useLinks((state) => ({
        clearFavorites: state.clearFavorites
    }));

    const gaKey = config?.googleAnalyticsKey;
    const gainsightKey = config?.gainsightKey;
    const pendoKey = config?.pendoKey;

    const [isEmbed, baseUrl, hideNavigation, defaultSection, organizationName, backgroundColor] = useEmbedStore(
        (state) => [
            state.isInitialized,
            state.baseUrl,
            state.hideNavigation,
            state.defaultSection,
            state.organizationName,
            state.backgroundColor
        ]
    );

    useEffect(() => {
        if (auth.user !== undefined && !isStaffUser(auth.user) && !GAchecked) {
            GAinitialize(gaKey);
            setGAChecked(true);
        }
    }, [auth.user, gaKey]);

    useEffect(() => {
        function crossDomainUserSignIn(event: any) {
            if (event.data?.type?.indexOf("embeded") > -1) {
                console.log("event", event);
            }
            if (event.origin === baseUrl) {
                if (event.data.type === "embededUserSignedIn") {
                    auth.storeUser(event.data.user);
                    setEmbedSignInPending(false);

                    if (!isStaffUser(auth.user)) {
                        GAlogin(true);
                    }
                } else if (event.data.type === "embedSignInSilentFailed") {
                    clearFavorites();
                    setEmbedSignInPending(false);
                }
            } else if (event.data.type === "embededUserSignedOut" && auth.isAuthenticated) {
                auth.signoutPopup()
                    .then(() => {
                        auth.removeUser();
                        auth.clearStaleState();
                        return null;
                    })
                    .catch((e) => console.error(e));
            }
        }

        if (isEmbed) {
            window.addEventListener("message", crossDomainUserSignIn);
        }

        return () => {
            if (isEmbed) {
                window.removeEventListener("message", crossDomainUserSignIn);
            }
        };
    }, [isEmbed, baseUrl, auth, clearFavorites]);

    useEffect(() => {
        if (isEmbed && !embedRedirectedOnce && defaultSection && organizationName) {
            navigate(`${organizationName}/${defaultSection}`);
            setEmbedRedirectedOnce(true);
        }
    }, [defaultSection, embedRedirectedOnce, isEmbed, navigate, organizationName]);

    return (
        <ProductThemeProvider
            specificity={isEmbed ? 3 : undefined}
            cssReset={isEmbed}
            prefix="cp-portal-embed-wrapper"
            SnackbarProviderProps={SnackbarProviderProps}
        >
            <Helmet>
                <script type="text/javascript">
                    {`
                        (function(n,t,a,e,co){var i="aptrinsic";n[i]=n[i]||function(){
                            (n[i].q=n[i].q||[]).push(arguments)},n[i].p=e;n[i].c=co;
                        var r=t.createElement("script");r.async=!0,r.src=a+"?a="+e;
                        var c=t.getElementsByTagName("script")[0];c.parentNode.insertBefore(r,c)
                        })(window,document,"https://web-sdk.aptrinsic.com/api/aptrinsic.js", "${gainsightKey}");
                    `}
                </script>

                <script type="text/javascript">
                    {`
                        (function(apiKey){
                            (function(p,e,n,d,o){var v,w,x,y,z;o=p[d]=p[d]||{};o._q=o._q||[];
                            v=['initialize','identify','updateOptions','pageLoad','track'];for(w=0,x=v.length;w<x;++w)(function(m){
                                o[m]=o[m]||function(){o._q[m===v[0]?'unshift':'push']([m].concat([].slice.call(arguments,0)));};})(v[w]);
                                y=e.createElement(n);y.async=!0;y.src='https://cdn.pendo.io/agent/static/'+apiKey+'/pendo.js';
                                z=e.getElementsByTagName(n)[0];z.parentNode.insertBefore(y,z);})(window,document,'script','pendo');
                        })("${pendoKey}");
                    `}
                </script>
            </Helmet>

            <Navbar isEmbed={isEmbed!} hideNavigation={hideNavigation ?? false} backgroundColor={backgroundColor}>
                <>
                    {isEmbed ? <EmbedTopbar signInPending={embedSignInPending} /> : null}
                    {children}
                </>
            </Navbar>
        </ProductThemeProvider>
    );
};

export default Layout;
