import { OrgApplicationDto } from "../../types/Organization";
import { ProductType } from "../../types/ProductType";
import { SubscriptionList } from "../../types/Subscription";
import { NotificationsGroupMap } from "./NotificationsListGroupCard";

export enum NotificationGroupType {
    AgendasMeetings = "Agendas & Meetings",
    ExternalLinks = "External Notifications Links"
}

export const groupNotifications = (
    notifications: SubscriptionList[],
    applications: OrgApplicationDto[],
    organizationName: string
): NotificationsGroupMap[] => {
    if (!notifications || notifications.length === 0) return [];

    const groups = [];

    // Agendas & Meetings group
    const agendasMeetings = notifications.filter(
        (n) => n.productType === ProductType.Notifications && n.additionalProducts.includes(ProductType.CivicClerk)
    );
    if (agendasMeetings.length > 0) {
        groups.push({
            groupName: NotificationGroupType.AgendasMeetings,
            source: ProductType.Notifications,
            notifications: agendasMeetings,
            subGroups: []
        });
    }

    // Engage Open group
    const engageOpen = notifications.filter(
        (n) => n.productType === ProductType.Notifications && n.additionalProducts.includes(ProductType.CivicEngageOpen)
    );
    if (engageOpen.length > 0) {
        // Group by application
        const instances = engageOpen.reduce(
            (tempNotifications, notification) => {
                const application = notification.applicationId;
                if (!tempNotifications[application]) {
                    tempNotifications[application] = [];
                }
                tempNotifications[application].push(notification);
                return tempNotifications;
            },
            {} as Record<string, SubscriptionList[]>
        );

        Object.entries(instances).forEach(([application, notifications]) => {
            const applicationName =
                applications.find((a) => a.productType === ProductType.CivicEngageOpen)?.name || application;
            groups.push({
                groupName: applicationName,
                source: ProductType.CivicEngageOpen,
                notifications: notifications,
                subGroups: []
            });
        });
    }

    // Website Central Notifications group
    const websiteCentral = notifications.filter((n) => n.productType === ProductType.CivicEngageCentral);
    if (websiteCentral.length > 0) {
        // Group by application
        const instances = websiteCentral.reduce(
            (tempNotifications, notification) => {
                const application = notification.applicationId;
                if (!tempNotifications[application]) {
                    tempNotifications[application] = [];
                }
                tempNotifications[application].push(notification);
                return tempNotifications;
            },
            {} as Record<string, SubscriptionList[]>
        );

        // Group by submodule
        Object.entries(instances).forEach(([application, notifications]) => {
            const subGroups = notifications.reduce(
                (tempNotifications, notification) => {
                    const submodule =
                        notification.additionalInformation?.filter((e) => e.key === "ModuleName")[0]?.value || "Other";
                    if (!tempNotifications[submodule]) {
                        tempNotifications[submodule] = [];
                    }
                    tempNotifications[submodule].push(notification);
                    return tempNotifications;
                },
                {} as Record<string, SubscriptionList[]>
            );

            const applicationName = applications.find((a) => a.id === application)?.name || application;

            groups.push({
                groupName: applicationName,
                source: ProductType.CivicEngageCentral,
                notifications: [],
                subGroups: Object.entries(subGroups).map(([subgroupName, notifications]) => ({
                    groupName: subgroupName,
                    notifications,
                    subGroups: []
                }))
            });
        });
    }

    // Website Evolve Notifications group
    const websiteEvolve = notifications.filter(
        (n) => n.productType === ProductType.Notifications && n.additionalProducts.includes(ProductType.HCMS)
    );

    if (websiteEvolve.length > 0) {
        // Group by application
        const instances = websiteEvolve.reduce(
            (tempNotifications, notification) => {
                const application = notification.applicationId;
                if (!tempNotifications[application]) {
                    tempNotifications[application] = [];
                }
                tempNotifications[application].push(notification);
                return tempNotifications;
            },
            {} as Record<string, SubscriptionList[]>
        );

        Object.entries(instances).forEach(([_, notifications]) => {
            groups.push({
                groupName: `${organizationName} Website`,
                source: ProductType.CivicEngageEvolve,
                notifications: notifications,
                subGroups: []
            });
        });
    }

    // External Notifications Links group
    const externalLinks = notifications.filter((n) => n.productType === ProductType.CivicPlusPortal);
    if (externalLinks.length > 0) {
        groups.push({
            groupName: NotificationGroupType.ExternalLinks,
            source: ProductType.CivicPlusPortal,
            notifications: externalLinks,
            subGroups: []
        });
    }

    return groups;
};
