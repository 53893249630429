import React, { useMemo } from "react";
import AccessibilityMessage from "../AccessibilityMessage";
import AlertTabListGroupCard from "./AlertTabListGroupCard";
import List from "@civicplus/preamble-ui/lib/List/List";
import ListItem from "@civicplus/preamble-ui/lib/ListItem";
import SkeletonLoader from "@civicplus/preamble-ui/lib/SkeletonLoader";
import Typography from "@civicplus/preamble-ui/lib/Typography";
import { KonexusAlertListItem } from "../../types/Alerts";
import { makeStyles } from "@civicplus/preamble-ui/lib/Utilities/ThemeHelper";
import { Organization } from "../../types/Organization";
import { SubscriptionList } from "../../types/Subscription";
import { transferItemsIfConditionMet, stringHasValue } from "../../shared/functions";
import { useAccountInfo } from "../../hooks/useAccountInfo";

export type AlertsGroupMap = Map<string, [SubscriptionList[], AlertsGroupMap]>;

const useStyles = makeStyles((theme) => ({
    card: {
        background: theme.palette.background.paper,
        padding: theme.spacing(2, 3, 3),
        borderRadius: 5,
        boxShadow: theme.shadows[1],
        marginBottom: theme.spacing(2)
    }
}));

export const enum ColumnMap {
    AggregateId,
    Name,
    Groups,
    Tags,
    Description,
    IsSubscribed,
    Featured,
    List,
    Pending
}

export interface AlertTabListProps {
    alerts: SubscriptionList[] | undefined;
    organization: Organization;
    searchText?: string;
    setRefreshRows?: React.Dispatch<React.SetStateAction<boolean>>;
    handleSubscriptionUpdate?: (subscription: SubscriptionList, subscribedToDefaultGroups: boolean) => void;
}

const AlertTabList: React.FC<AlertTabListProps> = (props) => {
    const classes = useStyles();

    const [isAccountInfoLoading] = useAccountInfo();
    const { alerts, handleSubscriptionUpdate, organization, searchText, setRefreshRows } = props;

    const mapMultipleParentAlerts = (alerts: SubscriptionList[]) => {
        const groupsMap = new Map<string, any>();

        function addAlertToGroup(alert: SubscriptionList, currentMap: Map<string, any>, parentGroups: string[]) {
            const groupName = parentGroups.shift();

            if (groupName) {
                if (!currentMap.has(groupName)) {
                    currentMap.set(groupName, [[], new Map()]);
                }

                const [alertsList, nextParentMap] = currentMap.get(groupName);

                if (parentGroups.length === 0) {
                    alertsList.push(alert);
                } else {
                    if (nextParentMap !== null) {
                        addAlertToGroup(alert, nextParentMap, parentGroups);
                    }
                }
            }
        }

        alerts.forEach((alert) => {
            const parentGroups = [...(alert.item as KonexusAlertListItem).parentGroups];
            addAlertToGroup(alert, groupsMap, parentGroups);
        });

        return groupsMap;
    };

    const renderedAlertRows = useMemo(() => {
        if (alerts === undefined) {
            return (
                <div className={classes.card}>
                    <List>
                        {Array.from({ length: 5 }, (_, i) => (
                            <ListItem
                                key={`skeleton-${i}`}
                                divider={true}
                                button={false}
                                ListItemTextProps={{
                                    primary: <SkeletonLoader width={300} />
                                }}
                            />
                        ))}
                    </List>
                </div>
            );
        } else if (alerts.length === 0) {
            return (
                <div className={classes.card}>
                    <Typography align="center">{searchText ? "No results found" : "No alerts found"}</Typography>
                </div>
            );
        } else {
            if (!organization) {
                return null;
            }

            const filteredAlerts = [...alerts];

            const defaultGroups: SubscriptionList[] = [];
            const looseAlerts: SubscriptionList[] = [];

            transferItemsIfConditionMet(
                filteredAlerts,
                defaultGroups,
                (a) => (a.item as KonexusAlertListItem)?.defaultGroup === true
            );

            transferItemsIfConditionMet(
                filteredAlerts,
                looseAlerts,
                (a) => (a.item as KonexusAlertListItem)?.parentGroups.length === 0
            );

            const alertsWithParentsMapped = mapMultipleParentAlerts(filteredAlerts);

            const allAlerts: AlertsGroupMap = new Map([]);

            if (defaultGroups.length > 0) {
                allAlerts.set("Default Groups", [[...defaultGroups], new Map()]);
            }

            for (const [key, value] of alertsWithParentsMapped) {
                allAlerts.set(key, value);
            }

            if (looseAlerts.length > 0) {
                allAlerts.set("", [[...looseAlerts], new Map()]);
            }

            return (
                <>
                    {Array.from(allAlerts.entries()).map(([groupName, [alerts, subGroups]]) => {
                        return (
                            <div key={`${groupName}-card-key`} className={classes.card}>
                                <AlertTabListGroupCard
                                    id={`${stringHasValue(groupName) ? groupName.replace(/\s+/g, "-").toLowerCase() : "loose-alerts"}`}
                                    groupName={groupName}
                                    alerts={alerts}
                                    subGroups={subGroups}
                                    organization={organization}
                                    setRefreshRows={setRefreshRows}
                                    handleSubscriptionUpdate={handleSubscriptionUpdate}
                                />
                            </div>
                        );
                    })}
                </>
            );
        }
    }, [alerts, classes.card, handleSubscriptionUpdate, organization, searchText, setRefreshRows]);

    const isLoading = isAccountInfoLoading || alerts === undefined;

    return (
        <>
            <div id="alerts-list" aria-busy={isLoading}>
                {renderedAlertRows}
            </div>

            <AccessibilityMessage
                message={isLoading ? "Loading Subscription Details" : "Subscription Details Loaded"}
            />
        </>
    );
};

export default AlertTabList;
