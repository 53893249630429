import { createWithEqualityFn } from "zustand/traditional";
import { devtools } from "zustand/middleware";
import { User as AuthUser } from "oidc-client-ts";
import { Organization, OrgApplicationDto } from "../types/Organization";
import { SubscriptionList } from "../types/Subscription";
import { NotificationsService } from "../services/notificationsService";
import { DocumentType } from "../types/SearchDocument";

export enum NotificationStatus {
    Pending = "Pending",
    Loading = "Loading",
    Complete = "Complete",
    Error = "Error"
}

export interface OrganizationState {
    organization?: Organization;
    orgApplications?: OrgApplicationDto[];
    setOrganization: (organization?: Organization) => void;
    setOrgApplications: (applications: OrgApplicationDto[]) => void;
    subsAndUnsubs: number;
    incrementSubsAndUnsubs: () => void;
    currentSettingsTab: number;
    setCurrentSettingsTab: (tab: number) => void;
    orgSubscriptionsList: SubscriptionList[];
    setOrgSubscriptionsList: (subscriptionsList: SubscriptionList[]) => void;
    fetchNotificationsStatus: NotificationStatus;
    setFetchNotificationsStatus: (status: NotificationStatus) => void;
    fetchNotifications: (
        organizationName: string | undefined,
        notificationService: NotificationsService,
        authUser: AuthUser | undefined,
        orderByQuery?: string
    ) => Promise<SubscriptionList[]>;
    fetchNotificationsAvailability: (
        organizationName: string,
        notificationService: NotificationsService
    ) => Promise<Record<DocumentType, boolean>>;
    availableNotifications: Record<DocumentType, boolean> | undefined;
}

export const useOrganization = createWithEqualityFn<OrganizationState>()(
    devtools(
        (set, get) => ({
            organization: undefined,
            availableNotifications: undefined,
            setOrganization: (organization) => {
                set({
                    organization,
                    orgApplications: organization?.applications,
                    fetchNotificationsStatus: NotificationStatus.Pending
                });

                if (organization?.name) {
                    localStorage?.setItem("organization:Portal", organization.name);
                }
            },

            orgApplications: undefined,
            setOrgApplications: (updatedApplications) => {
                set({ orgApplications: updatedApplications });
            },

            subsAndUnsubs: 0,
            incrementSubsAndUnsubs: (): void => {
                const { subsAndUnsubs } = get();
                set({ subsAndUnsubs: subsAndUnsubs + 1, fetchNotificationsStatus: NotificationStatus.Pending });
            },

            currentSettingsTab: 0,
            setCurrentSettingsTab: (tab) => set({ currentSettingsTab: tab }),

            orgSubscriptionsList: [],
            setOrgSubscriptionsList: (subscriptionsList) => {
                set({ orgSubscriptionsList: subscriptionsList });
            },

            fetchNotificationsStatus: NotificationStatus.Pending,
            setFetchNotificationsStatus: (status) => {
                set({ fetchNotificationsStatus: status });
            },
            fetchNotifications: async (
                organizationName: string | undefined,
                notificationService: NotificationsService,
                authUser: AuthUser | undefined,
                orderByQuery?: string
            ) => {
                if (
                    get().fetchNotificationsStatus === "Pending" ||
                    get().organization?.name !== organizationName ||
                    get().fetchNotificationsAvailability !== undefined
                ) {
                    set({ fetchNotificationsStatus: NotificationStatus.Loading });

                    const notifications = await notificationService.getSubscriptions({
                        orgName: organizationName,
                        authUser: authUser,
                        orderByQuery,
                        documentType: DocumentType.All
                    });

                    set({
                        orgSubscriptionsList: notifications,
                        fetchNotificationsStatus: NotificationStatus.Complete
                    });

                    return notifications;
                }
                return get().orgSubscriptionsList;
            },
            fetchNotificationsAvailability: async (
                organizationName: string,
                notificationService: NotificationsService
            ) => {
                const availability = await notificationService.getNotificationsAvailability(organizationName);
                set({ availableNotifications: availability });

                return availability;
            }
        }),
        { name: "organizationStore" }
    )
);
