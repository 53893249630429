import React, { useMemo, useCallback, useRef } from "react";
import AdvancedTableWrapper, {
    AdvancedTableWrapper as AdvancedTableWrapperClass
} from "@civicplus/preamble-ui/lib/AdvancedTable/AdvancedTableWrapper";
import ApiService, { ApiError, parseErrorMessage } from "../../services/apiService";
import CheckCircleOutline from "@mui/icons-material/CheckCircleOutline";
import CloseIcon from "@mui/icons-material/Close";
import Menu from "@civicplus/preamble-ui/lib/Menu";
import Tooltip from "@mui/material/Tooltip";
import { OrgApplicationDto } from "../../types/Organization";
import { ProductType } from "../../types/ProductType";
import { publicUriTypes, defaultApplicationLabels, ProductTypeLabels } from "../../shared/constants";
import { getCustomOrDefaultLabel } from "../../shared/functions";
import { TableData } from "@civicplus/preamble-ui/lib/AdvancedTable/AdvancedTableWrapperConstants";
import { useNavigate } from "react-router-dom";
import { useOrganization } from "../../stores/organizationStore";
import { BaseSettingsSectionProps } from "./SettingsSection";
import { useSnackbar } from "notistack";

const ApplicationsSettings: React.FC<BaseSettingsSectionProps> = ({ org, auth }) => {
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const refContainer = useRef<AdvancedTableWrapperClass>(null);
    const [orgApplications, setOrgApplications] = useOrganization((state) => [
        state.orgApplications,
        state.setOrgApplications
    ]);

    const selectedApplications = useMemo(
        () =>
            org.applications.filter((app) => {
                return publicUriTypes.includes(app.productType) && app.publicUri;
            }),
        [org]
    );

    const updateOrgApplications = ({ enhancedApplication }: { enhancedApplication: OrgApplicationDto }) => {
        if (orgApplications) {
            const index = orgApplications.findIndex((o) => o.id === enhancedApplication.id);

            if (index >= 0) {
                orgApplications[index].customLabel = enhancedApplication.customLabel;
                orgApplications[index].isActive = !enhancedApplication.isActive;

                setOrgApplications([...orgApplications]);
            }
        }
    };

    const updateEnhancedApplication = async ({ enhancedApplication }: { enhancedApplication: OrgApplicationDto }) => {
        try {
            await ApiService.put({
                url: `${org.name}/enhancedApplications/${enhancedApplication.id}`,
                authUser: auth.user,
                requestInit: {
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        isActive: !enhancedApplication.isActive,
                        customLabel: enhancedApplication.customLabel
                    })
                }
            });

            let message = "";
            !enhancedApplication.isActive
                ? (message = "Application is now visible for CivicPlus Portal users")
                : (message = "Application is now hidden for CivicPlus Portal users");

            enqueueSnackbar(message, {
                variant: "success"
            });

            updateOrgApplications({ enhancedApplication });
        } catch (ex) {
            console.error(ex);

            const error = parseErrorMessage(ex as ApiError);
            enqueueSnackbar(`An error occured while updating the application.\r\n${error}`, {
                variant: "error",
                persist: true
            });
        }
    };

    /* istanbul ignore next */
    const mapData = useCallback((enhancedApplication: OrgApplicationDto, index: number) => {
        switch (enhancedApplication.productType) {
            case ProductType.CivicGov:
                enhancedApplication.customLabel = getCustomOrDefaultLabel(
                    enhancedApplication,
                    defaultApplicationLabels[ProductType.CivicGov]
                );
                break;

            case ProductType.CivicRec:
                enhancedApplication.customLabel = getCustomOrDefaultLabel(
                    enhancedApplication,
                    defaultApplicationLabels[ProductType.CivicRec]
                );
                break;

            case ProductType.MunicodeNEXT:
                enhancedApplication.customLabel = getCustomOrDefaultLabel(
                    enhancedApplication,
                    defaultApplicationLabels[ProductType.MunicodeNEXT]
                );
                break;

            case ProductType.NextRequest:
                enhancedApplication.customLabel = getCustomOrDefaultLabel(
                    enhancedApplication,
                    defaultApplicationLabels[ProductType.NextRequest]
                );
                break;

            case ProductType.SeeClickFix:
                enhancedApplication.customLabel = getCustomOrDefaultLabel(
                    enhancedApplication,
                    defaultApplicationLabels[ProductType.SeeClickFix]
                );
                break;
        }

        const data = [
            ProductTypeLabels[enhancedApplication.productType],
            enhancedApplication.customLabel,
            enhancedApplication.isActive,
            enhancedApplication.publicUri
        ];

        return data;
    }, []);

    const getRows = useCallback(async (): Promise<TableData> => {
        const data = selectedApplications.map((item, index) => mapData(item, index)); //index for future
        return { count: data.length, data };
    }, [selectedApplications, mapData]);

    const onRowClick = async (rowData: string[], rowMeta: unknown, e: React.ChangeEvent): Promise<void> => {
        if (["svg", "path"].includes(e.target.tagName)) {
            return;
        } else {
            const currentApp: OrgApplicationDto | undefined = org.applications.find((o) => o.publicUri === rowData[3]);

            if (currentApp) {
                navigate(currentApp.id);
            }
        }
    };

    const applicationActions = useMemo(
        () => (index: number) => {
            return [
                {
                    children: "Edit application",
                    onClick: async () => {
                        const currentApp = selectedApplications[index];

                        if (currentApp) {
                            navigate(currentApp.id);
                        }
                    }
                },
                {
                    children: selectedApplications[index].isActive ? "Hide in Portal" : "Show in Portal",
                    onClick: async () => {
                        const currentApp = selectedApplications[index];

                        if (currentApp) {
                            updateEnhancedApplication({ enhancedApplication: currentApp });
                        }
                    }
                }
            ];
        },
        [selectedApplications]
    );

    const renderActionsMenu = useMemo(
        // eslint-disable-next-line react/display-name
        () => (_value: any, tableData: any) => {
            return (
                <Menu
                    type="action"
                    items={applicationActions(
                        selectedApplications.findIndex((app) => app.publicUri === tableData.rowData[3])
                    )}
                    id={`actions-${tableData.rowIndex++}`}
                    key={`actions-${tableData.rowIndex++}`}
                    stopPropagation={true}
                />
            );
        },
        [applicationActions, selectedApplications]
    );

    const columns = useMemo(() => {
        return [
            {
                name: "product",
                label: "Product",
                options: {
                    sort: false,
                    filter: false,
                    display: "true"
                }
            },
            {
                name: "label",
                label: "Label",
                options: {
                    sort: false,
                    filter: false,
                    display: "true"
                }
            },
            {
                name: "visible",
                label: "Visible",
                options: {
                    sort: true,
                    filter: false,
                    display: "true",
                    setCellProps: () => ({ style: { minWidth: 150 } }),
                    customBodyRender: (_value: any, tableData: any) => {
                        const isVisible = tableData.rowData[2];
                        return (
                            <Tooltip title={isVisible ? "Application is visible" : "Application is hidden"}>
                                {isVisible ? <CheckCircleOutline /> : <CloseIcon color="disabled" />}
                            </Tooltip>
                        );
                    }
                }
            },
            {
                name: "publicUri",
                label: "Public URI",
                options: {
                    sort: false,
                    filter: false,
                    display: "true"
                }
            },
            {
                name: "actions",
                label: "Actions",
                options: {
                    filter: false,
                    sort: false,
                    searchable: false,
                    viewColumns: false,
                    customBodyRender: renderActionsMenu
                }
            }
        ];
    }, [selectedApplications, renderActionsMenu]);

    return (
        <AdvancedTableWrapper
            key={`${JSON.stringify(selectedApplications)}`}
            columns={columns}
            rows={getRows}
            scrollToTop={true}
            emptyMessage="You currently have no Applications."
            serverSide={false}
            ref={refContainer}
            onRowClick={onRowClick}
            onRowClickLabel={(rowData: string[]) =>
                `Press enter or space to go to the ${rowData[0]} application edit page`
            }
        />
    );
};

export default ApplicationsSettings;
