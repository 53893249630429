import React, { useReducer } from "react";

export interface Location {
    id: number;
    userId: string;
    city: string;
    state: string;
    organizationId: string;
    default: boolean;
}

export interface IAddress {
    street: string;
    apartment?: number;
    zip: string;
    city: string;
    state: string;
    country: string;
    type: string;
    default?: boolean;
    id: number;
}

type LocationPageState = {
    addresses: IAddress[];
    locations: Location[];
    edit_block: boolean;
    location_block: boolean;
    address_block: boolean;
    new_location_name: string;
};

type LocationPageAction = {
    type: string;
    value: any;
};

export enum LocationTypes {
    ADD_LOCATION = "ADD_LOCATION",
    SET_LOCATION = "SET_LOCATION",
    DELETE_LOCATION = "DELETE_LOCATION",
    SET_PRIMARY_LOCATION = "SET_PRIMARY_LOCATION",
    ADD_ADDRESS = "ADD_ADDRESS",
    SET_ADDRESS = "SET_ADDRESS",
    DELETE_ADDRESS = "DELETE_ADDRESS",
    SET_PRIMARY_ADDRESS = "SET_PRIMARY_ADDRESS",
    SET_EDIT_ITEM_BLOCK = "SET_EDIT_ITEM_BLOCK",
    SET_NEW_LOCATION_BLOCK = "SET_NEW_LOCATION_BLOCK",
    SET_NEW_ADDRESS_BLOCK = "SET_NEW_ADDRESS_BLOCK",
    SET_NEW_LOCATION_NAME = "SET_NEW_LOCATION_NAME"
}

export const initialState: LocationPageState = {
    addresses: [],
    locations: [],
    edit_block: false,
    location_block: false,
    address_block: false,
    new_location_name: ""
};

function reducer(state: LocationPageState = initialState, action: LocationPageAction) {
    let index, setValues, nonDeleteValues;
    switch (action.type) {
        case LocationTypes.ADD_ADDRESS:
            return { ...state, addresses: [...state.addresses, action.value] };
        case LocationTypes.SET_ADDRESS:
            index = state.addresses.findIndex((x) => x.id === action.value.id);
            setValues = [...state.addresses];
            setValues[index] = action.value;
            return { ...state, addresses: [...setValues] };
        case LocationTypes.DELETE_ADDRESS:
            nonDeleteValues = state.addresses.filter((x) => x.id !== action.value);
            return { ...state, addresses: [...nonDeleteValues] };
        case LocationTypes.ADD_LOCATION:
            return { ...state, locations: [...state.locations, action.value] };
        case LocationTypes.SET_LOCATION:
            index = state.locations.findIndex((x) => x.id === action.value.id);
            setValues = [...state.locations];
            setValues[index] = action.value;
            return { ...state, locations: [...setValues] };
        case LocationTypes.DELETE_LOCATION:
            nonDeleteValues = state.locations.filter((x) => x.id !== action.value);
            return { ...state, locations: [...nonDeleteValues] };
        case LocationTypes.SET_PRIMARY_LOCATION:
            index = state.locations.findIndex((x) => x && x.default === true); // look for current default. Very important, always we need to have 1 primary location
            setValues = [...state.locations];
            if (setValues[index]) {
                setValues[index].default = false;
            }
            index = state.locations.findIndex((x) => x.id === action.value); // look item to modify
            if (setValues[index]) {
                setValues[index].default = true;
            }
            return { ...state, locations: [...setValues] };
        case LocationTypes.SET_PRIMARY_ADDRESS:
            index = state.addresses.findIndex((x) => x.default === true); // look for current default. Very important, always we need to have 1 primary address
            setValues = [...state.addresses];
            setValues[index].default = false;
            index = state.addresses.findIndex((x) => x.id === action.value); // look item to modify
            setValues[index].default = true;
            return { ...state, addresses: [...setValues] };
        case LocationTypes.SET_EDIT_ITEM_BLOCK:
            return { ...state, edit_block: action.value };
        case LocationTypes.SET_NEW_LOCATION_BLOCK: {
            const rr = { ...state, location_block: action.value };
            return rr;
        }
        case LocationTypes.SET_NEW_ADDRESS_BLOCK:
            return { ...state, address_block: action.value };
        case LocationTypes.SET_NEW_LOCATION_NAME: {
            return { ...state, new_location_name: action.value };
        }
    }
    return state;
}

export const LocationContext = React.createContext<[LocationPageState, React.Dispatch<LocationPageAction>]>([
    initialState,
    () => {}
]);

export function Provider(props: any) {
    const [state, dispatch] = useReducer(reducer, initialState);

    return <LocationContext.Provider value={[state, dispatch]}>{props.children}</LocationContext.Provider>;
}
