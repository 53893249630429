import React, { useState, useEffect, useMemo } from "react";
import Grid from "@civicplus/preamble-ui/lib/Grid";
import HiddenPageAlert from "../HiddenPageAlert";
import Link from "@civicplus/preamble-ui/lib/Link";
import Loader from "@civicplus/preamble-ui/lib/Loader";
import Titlebar from "@civicplus/preamble-ui/lib/Titlebar";
import { defaultApplicationLabels } from "../../shared/constants";
import { HtmlDescription } from "../Layout/HtmlDescription";
import { Link as RouterLink } from "react-router-dom";
import { makeStyles } from "@civicplus/preamble-ui/lib/Utilities/ThemeHelper";
import { ProductType } from "../../types/ProductType";
import { NavigationOption } from "../../types/Organization";
import { toggleDisplayFraseChatbot } from "../../shared/functions";
import { useEmbedStore } from "../../stores/embedStore";
import { useFeatures } from "../../shared/useFeatureFlags";
import { useOrganization } from "../../stores/organizationStore";

const useStyles = makeStyles((theme) => ({
    root: {
        flex: 1,
        flexDirection: "column"
    },
    titleContainer: {
        flex: 0
    },
    iframeContainer: {
        width: "100%",
        flex: 1
    },
    iframe: {
        width: "100%",
        height: "100%",
        border: 0
    }
}));

export interface EmbedPublicPortalProps {
    productType:
        | ProductType.CivicGov
        | ProductType.CivicRec
        | ProductType.MunicodeNEXT
        | ProductType.NextRequest
        | ProductType.SeeClickFix;
    navigationType: NavigationOption;
}

const EmbedPublicPortal: React.FC<EmbedPublicPortalProps> = (props) => {
    const { navigationType, productType } = props;
    const classes = useStyles(props);
    const [flags, _, orgFlagsLoaded] = useFeatures();

    const [application, setApplication] = useState<{
        publicUri?: string;
        isActive: boolean;
        additionalProductInformation?: string;
        name?: string;
        title?: string;
        configured: boolean;
    }>({ isActive: false, configured: false });

    const isEmbed = useEmbedStore((state) => state.isInitialized);
    const org = useOrganization((state) => state.organization);

    if (!application.configured && org && orgFlagsLoaded) {
        if (flags.showSettingsNavigation) {
            const item = org.navigation[navigationType];

            item &&
                setApplication({
                    publicUri: item.publicUri,
                    name: item.customLabel ?? item.defaultLabel,
                    title: item.customLabel ?? item.defaultLabel,
                    additionalProductInformation: item.additionalProductInformation,
                    isActive: item.isActive,
                    configured: true
                });
        } else {
            if (org && org.applicationCount > 0) {
                const orgApp = org.applications.find((app) => {
                    return app.productType === productType && app.publicUri;
                });

                orgApp &&
                    setApplication({
                        publicUri: orgApp.publicUri,
                        isActive: true,
                        additionalProductInformation: orgApp.additionalProductInformation,
                        name: orgApp.name,
                        title: orgApp.customLabel ?? defaultApplicationLabels[productType],
                        configured: true
                    });
            }
        }
    }

    const breadcrumbs = useMemo(
        () => [
            <Link key="home" to={`/${org?.name}`} component={RouterLink}>
                Home
            </Link>
        ],
        [org?.name]
    );

    useEffect(() => {
        toggleDisplayFraseChatbot(org, isEmbed || false);
    }, [org, isEmbed]);

    return (
        <Grid container={true} spacing={3} className={classes.root}>
            <Grid xs={12} className={classes.titleContainer}>
                <Titlebar
                    id="embed-titlebar"
                    title={application.title}
                    description={<HtmlDescription description={application?.additionalProductInformation} />}
                    breadcrumbs={breadcrumbs}
                    isLoading={!application.configured}
                />
            </Grid>

            {navigationType && <HiddenPageAlert navOption={navigationType} />}

            <Grid xs={12} className={classes.iframeContainer}>
                {application.configured ? (
                    <iframe
                        src={application.publicUri}
                        title={application.name}
                        id={`iframe-${application.name}`}
                        loading="lazy"
                        role="application"
                        width="100%"
                        height="100%"
                        className={classes.iframe}
                    />
                ) : (
                    <Loader verticallyCenter={true} />
                )}
            </Grid>
        </Grid>
    );
};

export default EmbedPublicPortal;
