import React, { useEffect, useMemo, useRef, useState } from "react";
import Grid from "@civicplus/preamble-ui/lib/Grid";
import Link from "@civicplus/preamble-ui/lib/Link";
import Loader from "@civicplus/preamble-ui/lib/Loader";
import Titlebar from "@civicplus/preamble-ui/lib/Titlebar";
import Typography from "@civicplus/preamble-ui/lib/Typography";
import { decodeToken } from "@civicplus/preamble-ui/lib/Utilities/TokenHelper";
import { makeStyles } from "@civicplus/preamble-ui/lib/Utilities/ThemeHelper";
import { NotificationsService } from "../services/notificationsService";
import { toggleDisplayFraseChatbot } from "../shared/functions";
import { useAuth } from "../providers/AuthProvider";
import { useConfig } from "../providers/ConfigProvider";
import { NotificationStatus, useOrganization } from "../stores/organizationStore";
import { useEmbedStore } from "../stores/embedStore";
import { SigninPopupArgs } from "oidc-client-ts";
import { setupAcrValue } from "../components/react-oidc-context";
import { useLocation } from "react-router-dom";
import { shallow } from "zustand/shallow";
import * as DashboardZones from "../components/DashboardZones/zones";
import { useFeatures } from "../shared/useFeatureFlags";
import { Organization } from "../types/Organization";

const useStyles = makeStyles((theme) => ({
    dashboardZone: {
        marginBottom: 20
    },
    subtitle: {
        fontWeight: "bold",
        color: theme.palette.secondary.main
    },
    welcomeMessage: {
        textAlign: "center",
        "& h5": {
            fontWeight: "bold",
            marginBottom: 10
        },
        margin: "20px 0 30px 0"
    }
}));

type DashboardProps = {
    userName?: string;
};

const Dashboard: React.FC<DashboardProps> = ({ userName }) => {
    const config = useConfig();
    const classes = useStyles();

    const auth = useAuth();
    const location = useLocation();
    const [flags] = useFeatures();
    const isLoadingSession = auth?.isLoading;

    const [isLoading, setIsLoading] = useState<boolean>(isLoadingSession);
    const [organization] = useOrganization((state) => [state.organization]);
    const [isEmbed] = useEmbedStore((state) => [state.isInitialized]);

    const userFirstName = useRef<string | undefined>(userName);

    const notificationService = useMemo(() => new NotificationsService(), []);
    const [
        orgSubscriptionsList,
        setFetchNotificationsStatus,
        fetchNotificationsStatus,
        fetchNotifications,
        fetchNotificationsAvailability
    ] = useOrganization(
        (state) => [
            state.orgSubscriptionsList,
            state.setFetchNotificationsStatus,
            state.fetchNotificationsStatus,
            state.fetchNotifications,
            state.fetchNotificationsAvailability
        ],
        shallow
    );

    useEffect(() => {
        const fetchSubscriptions = async (organization: Organization) => {
            try {
                await fetchNotificationsAvailability(organization.name, notificationService);
                await fetchNotifications(
                    organization?.name,
                    notificationService,
                    auth.user ?? undefined,
                    "isFeatured desc,lastModifiedDate desc"
                );
            } catch (ex) {
                console.error(ex);
            }
        };

        if (organization && fetchNotificationsStatus === "Pending") {
            fetchSubscriptions(organization);
        }
    }, [
        organization,
        auth.user,
        notificationService,
        fetchNotificationsStatus,
        fetchNotifications,
        fetchNotificationsAvailability
    ]);

    useEffect(() => {
        if (auth.isAuthenticated) {
            setFetchNotificationsStatus(NotificationStatus.Pending);
        }
    }, [auth.isAuthenticated, setFetchNotificationsStatus]);

    useEffect(
        function gettingUserFirstName() {
            if (auth.user) {
                const givenName = auth.user.profile?.given_name;
                if (givenName) {
                    userFirstName.current = givenName;
                } else {
                    const idPayload = auth.user?.id_token ? decodeToken(auth.user?.id_token) : "";
                    userFirstName.current = idPayload["given_name"];
                }
            }
            setIsLoading(false);
        },
        [auth.user]
    );

    useEffect(() => {
        toggleDisplayFraseChatbot(organization, isEmbed || false);
    }, [organization, isEmbed]);

    const widgets = [
        DashboardZones.FeaturedDashboard,
        DashboardZones.LinksDashboard,
        DashboardZones.FormsDashboard,
        DashboardZones.NotificationsDashboard,
        DashboardZones.EventsDashboard,
        DashboardZones.AlertsDashboard
    ];

    if (isLoading) {
        return <Loader verticallyCenter={true} />;
    }

    return (
        <>
            <Titlebar id="title" title="Dashboard" />

            <Grid container={true} spacing={3}>
                {organization?.friendlyName && (
                    <Grid xs={12}>
                        {auth?.user ? (
                            <Typography className={classes.subtitle}>{`${
                                userFirstName.current ? userFirstName.current + "'s" : "Your Saved"
                            } Content from ${organization.friendlyName}`}</Typography>
                        ) : (
                            <Typography
                                className={classes.subtitle}
                            >{`Featured Content from ${organization.friendlyName}`}</Typography>
                        )}
                    </Grid>
                )}

                {!auth.isAuthenticated && (
                    <Grid xs={12}>
                        <div className={classes.welcomeMessage}>
                            {organization?.friendlyName ? (
                                <Typography
                                    variant="h5"
                                    component="h2"
                                >{`Welcome to the Dashboard of ${organization.friendlyName}!`}</Typography>
                            ) : (
                                <>
                                    <Typography variant="h5" component="h3">
                                        Welcome to the CivicPlus Portal Dashboard
                                    </Typography>
                                    <Typography variant="h6" id="search-org-msg">
                                        Search for and select an Organization above to begin viewing content from your
                                        local municipality.
                                    </Typography>
                                </>
                            )}

                            <Typography variant="h6" component="h3" data-testid="welcomeDivText">
                                <Link
                                    href={`${config.getIdentityServerBaseUrl()}Identity/Account/Register`}
                                    underline="always"
                                >
                                    Create an account
                                </Link>
                                {" or "}
                                <Link
                                    id="signIn"
                                    href="#"
                                    onClick={(e: React.MouseEvent<HTMLElement>) => {
                                        e.preventDefault();
                                        const args = {
                                            state: { from: location }
                                        } as SigninPopupArgs;
                                        auth.signinPopup(setupAcrValue(auth, args, organization?.id));
                                    }}
                                    underline="always"
                                >
                                    sign in
                                </Link>{" "}
                                to bookmark your own links and personalize your dashboard.
                            </Typography>
                        </div>
                    </Grid>
                )}

                {auth.isAuthenticated && !organization && (
                    <Grid xs={12}>
                        <div className={classes.welcomeMessage}>
                            <Typography variant="h2" component="h3">
                                Welcome to the CivicPlus Portal Dashboard
                            </Typography>

                            <Typography variant="h6" component="h4" id="search-org-msg">
                                Search for and select an Organization above to begin viewing content from your local
                                municipality.
                            </Typography>
                        </div>
                    </Grid>
                )}

                {organization && (
                    <Grid xs={12}>
                        {widgets.map((Widget, index) => (
                            <Widget
                                key={`widget-${index}`}
                                authUser={auth.user}
                                organization={organization}
                                subscriptions={orgSubscriptionsList}
                            />
                        ))}
                    </Grid>
                )}
            </Grid>
        </>
    );
};

export default Dashboard;
