import React, { useState, useCallback, useMemo, useEffect } from "react";
import AlertsSubscriptionDialog, { DataType } from "../../../../components/AlertsSubscriptionDialog";
import AlertTabList from "../../../../components/AlertList/AlertTabList";
import Button from "@civicplus/preamble-ui/lib/Button";
import ButtonGroup from "@civicplus/preamble-ui/lib/ButtonGroup";
import CloseIcon from "@mui/icons-material/Close";
import ErrorPage from "@civicplus/preamble-ui/lib/ErrorPage";
import FilterButton from "../../../../components/FilterButton/FilterButton";
import languages from "../../../../shared/languages";
import Grid from "@civicplus/preamble-ui/lib/Grid";
import useMediaQuery from "@mui/material/useMediaQuery";
import useStyles from "../styles";
import { ApiError, parseErrorMessage } from "../../../../services/apiService";
import { DocumentType } from "../../../../types/SearchDocument";
import { NotificationsService, SubscriptionAction } from "../../../../services/notificationsService";
import { NotificationStatus, useOrganization } from "../../../../stores/organizationStore";
import { SearchInput } from "@civicplus/preamble-ui/lib/SearchInput";
import { setProfileInfo, validateKonexusInfo } from "../../../../shared/alertFunctions";
import { styled, useTheme } from "@mui/material/styles";
import { SubscriptionList, SubscriptionType } from "../../../../types/Subscription";
import { SubscriptionProfileInformation } from "../../../../types/Account";
import { useAuth } from "../../../../providers/AuthProvider";
import { useEmbedStore } from "../../../../stores/embedStore";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAccountInfo } from "../../../../hooks/useAccountInfo";
import { useApplicationPhoneNumbers } from "../../../../hooks/useApplicationPhoneNumbers";
import { useSnackbar } from "notistack";

const ErrorMessage = styled(ErrorPage)(({ theme }) => ({
    margin: theme.spacing(4, 0, 2)
}));

export interface AlertsTabProps {
    onShowContactDialog: () => void;
}

export const AlertsTab: React.FC<AlertsTabProps> = ({ onShowContactDialog }) => {
    const classes = useStyles();
    const auth = useAuth();
    const authUser = auth.user;
    const theme = useTheme();
    const isMobileView = useMediaQuery(theme.breakpoints.down("md"));
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isUserAccountInfoLoading, userAccountInfo, reloadAccountInfo] = useAccountInfo();
    const [isPhoneNumbersLoading, phoneNumbers] = useApplicationPhoneNumbers();
    const isEmbed = useEmbedStore((state) => state.isInitialized);
    const notificationService = useMemo(() => new NotificationsService(), []);
    const [alerts, setAlerts] = useState<SubscriptionList[]>();
    const [showSubscriptionDialog, setShowSubscriptionDialog] = React.useState(false);
    const [isUnsubscribing, setIsUnsubscribing] = useState(false);
    const [subscribeToAllPending, setSubscribeToAllPending] = useState(false);
    const [searchText, setSearchText] = useState<string>(() => searchParams.get("keyword") ?? "");
    const [
        setOrgSubscriptionsList,
        setFetchNotificationsStatus,
        organization,
        incrementSubsAndUnsubs,
        fetchNotificationsStatus
    ] = useOrganization((state) => [
        state.setOrgSubscriptionsList,
        state.setFetchNotificationsStatus,
        state.organization,
        state.incrementSubsAndUnsubs,
        state.fetchNotificationsStatus
    ]);
    const isSubscriptionDialogLoading = useMemo(
        () => isPhoneNumbersLoading || isUserAccountInfoLoading,
        [isPhoneNumbersLoading, isUserAccountInfoLoading]
    );

    const fetchSubscriptions = useCallback(
        async (controller?: AbortController) => {
            setFetchNotificationsStatus(NotificationStatus.Loading);

            try {
                const subscriptions = await notificationService.getSubscriptions({
                    orgName: organization?.name,
                    authUser: auth.user,
                    documentType: DocumentType.AlertList,
                    controller
                });
                if (controller && controller.signal.aborted) return;
                setOrgSubscriptionsList(subscriptions);
                setAlerts(subscriptions);
                setFetchNotificationsStatus(NotificationStatus.Complete);
            } catch (error) {
                if (controller?.signal.aborted) return;
                console.error(error);
                const message = parseErrorMessage(
                    error as ApiError,
                    "An error occurred while fetching subscriptions. Please try again."
                );

                // Refresh here...
                enqueueSnackbar(message, {
                    variant: "error",
                    persist: true,
                    action: (
                        <>
                            <Button
                                variant="outlined"
                                id="refresh-page-btn"
                                data-testid="refresh-page-btn"
                                onClick={() => window.location.reload()}
                            >
                                Refresh
                            </Button>

                            <Button
                                id="dismiss-message-btn"
                                data-testid="dismiss-message-btn"
                                type="icon"
                                size="small"
                                style={{ marginLeft: 15 }}
                                onClick={() => closeSnackbar()}
                                title="Dismiss message"
                            >
                                <CloseIcon />
                            </Button>
                        </>
                    )
                });
                setFetchNotificationsStatus(NotificationStatus.Error);
            }
        },
        [
            auth.user,
            closeSnackbar,
            enqueueSnackbar,
            notificationService,
            organization?.name,
            setFetchNotificationsStatus,
            setOrgSubscriptionsList
        ]
    );

    useEffect(() => {
        const controller = new AbortController();
        fetchSubscriptions(controller);
        return () => {
            controller.abort();
        };
    }, [fetchSubscriptions]);

    const handleSubscriptionUpdated = (subscription: SubscriptionList) => {
        setAlerts((prev) => {
            if (!prev) return prev;
            return prev.map((item) => {
                if (item.aggregateId === subscription.aggregateId) return subscription;
                else return item;
            });
        });
    };

    const handleSubscriptionDialogClose = () => {
        setShowSubscriptionDialog(false);
    };

    const handleSearch = (searchQuery: string) => {
        if (!isEmbed) {
            navigate(`?tab=alerts&keyword=${searchQuery}`, { replace: true });
        }
        setSearchText(searchQuery);
    };

    const alertsFiltered = useMemo(() => {
        return alerts
            ?.filter((item) => item.name.toLowerCase().includes(searchText.toLowerCase()))
            .sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? 1 : 0));
    }, [alerts, searchText]);

    const handleUnsubscribeAll = useCallback(async () => {
        if (organization && authUser) {
            try {
                const subscriptionType = SubscriptionType.None;
                const documentType = DocumentType.AlertList;

                setIsUnsubscribing(true);

                const response = await notificationService.unsubscribeFromAllLists({
                    organization,
                    authUser,
                    subscriptionType,
                    documentType
                });

                if (response.ok) {
                    incrementSubsAndUnsubs();
                    await fetchSubscriptions();
                    enqueueSnackbar("Unsubscribed successfully from all lists.", {
                        variant: "success"
                    });
                } else {
                    enqueueSnackbar(response.error, {
                        variant: "error",
                        persist: true
                    });
                }
            } catch {
                const errorMessage = "Error while trying to unsubscribe user from all lists.";

                enqueueSnackbar(errorMessage, {
                    variant: "error",
                    persist: true
                });
            } finally {
                setIsUnsubscribing(false);
            }
        }
    }, [organization, authUser, notificationService, incrementSubsAndUnsubs, fetchSubscriptions, enqueueSnackbar]);

    const handleSubscribeAll = () => {
        if (!organization || !alerts || alerts.length === 0) {
            return;
        }

        const applicationId = alerts[0].applicationId;

        const { isValid } = validateKonexusInfo(userAccountInfo, phoneNumbers, applicationId);

        if (!isValid) {
            setShowSubscriptionDialog(true);
            return;
        }

        handleSubscribeAllWithSignUpData(null);
    };

    const handleSubscribeAllWithSignUpData = useCallback(
        async (signUpData: DataType | null) => {
            if (!organization || !auth.user) {
                return false;
            }

            try {
                setSubscribeToAllPending(true);
                const profileInfo: SubscriptionProfileInformation | null = setProfileInfo(signUpData);

                const response = await notificationService.subscribeToAllLists({
                    authUser: auth.user,
                    documentType: DocumentType.AlertList,
                    organization,
                    profileInfo
                } as SubscriptionAction);

                if (response.success) {
                    incrementSubsAndUnsubs();
                    await fetchSubscriptions();
                    handleSubscriptionDialogClose();
                    enqueueSnackbar("Successfully subscribed to all lists.", {
                        variant: "success"
                    });
                } else {
                    throw new Error(response.error);
                }
                return response.success;
            } catch (error) {
                const message = (error as Error)?.message;
                enqueueSnackbar(message ?? "An error occurred while subscribing to all lists. Please try again.", {
                    variant: "error",
                    persist: true
                });
            } finally {
                setSubscribeToAllPending(false);
            }
            return false;
        },
        [auth.user, enqueueSnackbar, fetchSubscriptions, incrementSubsAndUnsubs, notificationService, organization]
    );

    if (!organization) {
        return null;
    }

    return (
        <Grid container={true} spacing={2} justifyContent="space-between">
            <Grid xs={12} className={classes.lg6Grid} alignItems="center">
                {auth.user && (
                    <ButtonGroup layout="right" className={classes.tabButtonGroup}>
                        <Button
                            id="manage-contact-btn"
                            onClick={onShowContactDialog}
                            className={classes.tabButton}
                            fullWidth={isMobileView}
                            isLoading={isSubscriptionDialogLoading}
                        >
                            Manage contact information
                        </Button>

                        <Button
                            key="btn-subscribe-alert-subscriptions"
                            color="primary"
                            onClick={handleSubscribeAll}
                            isLoading={subscribeToAllPending}
                            aria-label="Subscribe to all alerts notifications"
                            className={classes.tabButton}
                            fullWidth={isMobileView}
                            disabled={alertsFiltered === undefined || alertsFiltered.length === 0}
                        >
                            Subscribe to all
                        </Button>

                        <Button
                            key="btn-unsubscribe-alert-subscriptions"
                            color="primary"
                            onClick={handleUnsubscribeAll}
                            isLoading={isUnsubscribing}
                            aria-label="Unsubscribe from all alerts notifications"
                            className={classes.tabButton}
                            fullWidth={isMobileView}
                            disabled={alertsFiltered === undefined || alertsFiltered.length === 0}
                        >
                            Unsubscribe from all
                        </Button>
                    </ButtonGroup>
                )}
            </Grid>

            <Grid xs={12} className={classes.lg6Grid}>
                <Grid container={true} xs={12} alignItems="center" spacing={2}>
                    <Grid xs={true}>
                        <SearchInput
                            id="alert-search-bar"
                            value={searchText ?? ""}
                            searchOnChange={true}
                            onSearch={handleSearch}
                            fullWidth={true}
                            margin={false}
                            style={{ minWidth: 220 }}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                handleSearch(e.target.value);
                            }}
                        />
                    </Grid>

                    <Grid width="auto">
                        <FilterButton id="alert-filter" onFilterReset={() => {}}>
                            {/* todo: define which filter do we want to have here */}
                            <></>
                        </FilterButton>
                    </Grid>
                </Grid>
            </Grid>

            <Grid xs={12}>
                {!alertsFiltered && fetchNotificationsStatus === NotificationStatus.Error ? (
                    <ErrorMessage
                        title="We couldn't retrieve your alerts"
                        linkDescription="Try again"
                        onClick={() => fetchSubscriptions()}
                        severity="error"
                    >
                        An error occurred while retrieving your alerts. Please try again.
                    </ErrorMessage>
                ) : (
                    <AlertTabList
                        alerts={alertsFiltered}
                        handleSubscriptionUpdate={handleSubscriptionUpdated}
                        organization={organization}
                        searchText={searchText}
                    />
                )}
            </Grid>

            {alerts && alerts.length > 0 && showSubscriptionDialog && organization && userAccountInfo && (
                <AlertsSubscriptionDialog
                    applicationId={alerts[0].applicationId}
                    documentType={DocumentType.AlertWeather}
                    languages={languages}
                    onClose={handleSubscriptionDialogClose}
                    onSubmit={handleSubscribeAllWithSignUpData}
                    open={showSubscriptionDialog}
                    organization={organization}
                    phoneNumbers={phoneNumbers[alerts[0].applicationId]}
                    reloadAccountDetails={reloadAccountInfo}
                    showDefaultSubscriptionCheck={false}
                    userAccountDetails={userAccountInfo}
                />
            )}
        </Grid>
    );
};

export default AlertsTab;
