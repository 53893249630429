import React, { useCallback } from "react";
import Dialog from "@civicplus/preamble-ui/lib/Dialog";
import Button from "@civicplus/preamble-ui/lib/Button";
import { useNavigate } from "react-router-dom";

interface DirtyStateProps {
    isOpen: boolean;
    onDiscardChanges: () => void;
    onReturnToContent: () => void;
    warningText?: string;
}

export const DirtyStateDialog: React.FC<DirtyStateProps> = ({
    isOpen,
    onDiscardChanges,
    onReturnToContent,
    warningText
}) => {
    const message = warningText || "Are you sure you want to discard changes for this link?";
    const [redirectUrl, setRedirectUrl] = React.useState<string | undefined>(undefined);
    const navigate = useNavigate();

    const onDiscard = useCallback(() => {
        if (redirectUrl) {
            navigate(redirectUrl);
            return;
        }

        onDiscardChanges();
    }, [onDiscardChanges, redirectUrl, navigate]);

    const onReturn = useCallback(() => {
        if (redirectUrl) {
            setRedirectUrl(undefined);
        }

        onReturnToContent();
    }, [onReturnToContent, redirectUrl]);

    return (
        <Dialog
            title="Pending Changes"
            open={isOpen || redirectUrl !== undefined}
            onClose={onReturn}
            actions={[
                <Button color="primary" onClick={onDiscard} key="discard">
                    Discard Changes
                </Button>,
                <Button onClick={onReturn} key="cancel">
                    Return to Content
                </Button>
            ]}
        >
            <span>{message}</span>
        </Dialog>
    );
};
