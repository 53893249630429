import React from "react";
import ErrorPage from "@civicplus/preamble-ui/lib/ErrorPage";

const AccessDenied: React.FC = () => {
    return (
        <ErrorPage id="access-denied" title="Unauthorized Access" severity="warning">
            You do not have access to this page.
        </ErrorPage>
    );
};

export default AccessDenied;
