import { DocumentType } from "./SearchDocument";

export enum CppOrganizationProductType {
    CivicPlus = 0,
    CivicEngageCentral = 1,
    CivicReady = 2,
    CivicHr = 3,
    CivicRec = 5,
    HCMS = 6,
    CpPay = 7,
    CivicEngageEvolve = 8,
    CivicClerk = 9,
    Notifications = 10,
    CpConnect = 11,
    CivicPlusIntegrationHub = 14,
    SeeClickFix = 15,
    ArchiveSocial = 16,
    CivicEngageOpen = 17,
    CivicGov = 18,
    CivicOptimize = 19,
    CivicPlusOrganizations = 20,
    CivicPlusPortal = 21,
    Monsido = 22,
    MunicodeMeetings = 23,
    MunicodeNEXT = 24,
    NextRequest = 25
}

export enum ProductType {
    CivicEngageCentral = "CivicEngageCentral",
    CivicReady = "CivicReady",
    CivicRec = "CivicRec",
    HCMS = "HCMS",
    CpPay = "CpPay",
    CivicEngageEvolve = "CivicEngageEvolve",
    CivicClerk = "CivicClerk",
    Notifications = "Notifications",
    CivicPlusIntegrationHub = "CivicPlusIntegrationHub",
    SeeClickFix = "SeeClickFix",
    ArchiveSocial = "ArchiveSocial",
    CivicEngageOpen = "CivicEngageOpen",
    CivicGov = "CivicGov",
    CivicOptimize = "CivicOptimize",
    CivicPlusOrganizations = "CivicPlusOrganizations",
    CivicPlusPortal = "CivicPlusPortal",
    Monsido = "Monsido",
    MunicodeMeetings = "MunicodeMeetings",
    MunicodeNEXT = "MunicodeNEXT",
    NextRequest = "NextRequest"
}

export const formProductTypes: ProductType[] = [
    ProductType.CivicPlusPortal,
    ProductType.CivicOptimize,
    ProductType.CivicEngageCentral,
    ProductType.CivicEngageOpen
];

export const notificationsProductTypes: ProductType[] = [
    ProductType.Notifications,
    ProductType.CivicPlusPortal,
    ProductType.CivicEngageCentral
];

export const eventProductTypes: ProductType[] = [ProductType.CivicClerk, ProductType.CivicPlusPortal];

export const municodeProductTypes: ProductType[] = [ProductType.MunicodeNEXT];

export const getProductTypeOptions = (type: DocumentType): ProductType[] => {
    switch (type) {
        case DocumentType.SubscriptionList:
            return notificationsProductTypes;
        case DocumentType.Event:
            return eventProductTypes;
        case DocumentType.Form:
            return formProductTypes;
        case DocumentType.Publication:
            return municodeProductTypes;
    }
    return [];
};
