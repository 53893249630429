import React, { useState, useCallback, useMemo, useEffect } from "react";
import ApiService from "../../../../../services/apiService";
import Autocomplete, { OptionShape } from "@civicplus/preamble-ui/lib/Autocomplete";
import Button from "@civicplus/preamble-ui/lib/Button";
import Dialog from "@civicplus/preamble-ui/lib/Dialog";
import Grid from "@civicplus/preamble-ui/lib/Grid";
import Link from "@civicplus/preamble-ui/lib/Link";
import Loader from "@civicplus/preamble-ui/lib/Loader";
import PhoneNumberForm from "../../../../../components/AlertsSubscriptionDialog/PhoneNumber/PhoneNumberForm";
import TextInput from "@civicplus/preamble-ui/lib/TextInput";
import Typography from "@civicplus/preamble-ui/lib/Typography";
import { DirtyStateDialog } from "../../../../../components/DirtyState";
import { PhoneNumber } from "../../../../../components/AlertsSubscriptionDialog/PhoneNumber/PhoneNumberUtils";
import { phoneOptions } from "../../../../../components/AlertsSubscriptionDialog/PhoneNumber/PhoneNumberHelper";
import { useAccountInfo } from "../../../../../hooks/useAccountInfo";
import { useAuth } from "../../../../../providers/AuthProvider";
import { useConfig } from "../../../../../providers/ConfigProvider";
import { NotificationsService } from "../../../../../services/notificationsService";
import { useSnackbar } from "notistack";

interface NotificationsContactDialogProps {
    show: boolean;
    toggleDialogVisibility: (value: boolean) => void;
    orgName?: string;
    setExternalPhoneNumberState?: React.Dispatch<React.SetStateAction<string>>;
}

const NotificationsContactDialog: React.FC<NotificationsContactDialogProps> = ({
    show,
    toggleDialogVisibility,
    orgName,
    setExternalPhoneNumberState
}) => {
    const auth = useAuth();
    const config = useConfig();
    const { enqueueSnackbar } = useSnackbar();
    const [, userAccountDetails, reloadAccountInfo, updateAccountInfoPhoneNumbers] = useAccountInfo();

    const [isDirty, setIsDirty] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [isAdding, setIsAdding] = useState<boolean>(false);
    const [isLeavingWithoutSaving, setIsLeavingWithoutSaving] = useState(false);
    const [userPhoneNumbers, setUserPhoneNumbers] = useState<PhoneNumber[] | undefined>([]);
    const [selectedPhoneNumber, setSelectedPhoneNumber] = useState<OptionShape | undefined>();
    const notificationService = useMemo(() => new NotificationsService(), []) as NotificationsService;

    useEffect(() => {
        setUserPhoneNumbers(userAccountDetails?.phoneNumbers);
    }, [userAccountDetails?.phoneNumbers]);

    const handlePhoneNumberChange = (selected: OptionShape | undefined) => {
        if (selected) {
            setIsDirty(true);
            setSelectedPhoneNumber(selected);

            if (setExternalPhoneNumberState) {
                setExternalPhoneNumberState(selected.label.toString());
            }
        }
    };

    const updateUserPhoneNumber = useCallback(async () => {
        if (selectedPhoneNumber?.value) {
            setIsSaving(true);

            const response = await notificationService.updateSubscriberPhoneNumber({
                orgName,
                phoneNumber: selectedPhoneNumber?.label.toString(),
                phoneNumberId: selectedPhoneNumber?.value.toString(),
                authUser: auth.user
            });

            if (response.error) {
                enqueueSnackbar(`${response.error}`, {
                    variant: "error",
                    persist: true
                });
            } else {
                reloadAccountInfo();

                enqueueSnackbar("Phone Number was updated successfully!", {
                    variant: "success"
                });

                setSelectedPhoneNumber(undefined);
                toggleDialogVisibility(false);
            }

            setIsSaving(false);
        }
    }, [
        auth.user,
        orgName,
        selectedPhoneNumber,
        enqueueSnackbar,
        reloadAccountInfo,
        toggleDialogVisibility,
        notificationService
    ]);

    const reloadUserPhoneNumbers = async () => {
        setIsLoading(true);

        const updatedPhoneNumbers = (await ApiService.get({
            url: "user/account/phoneNumber",
            cache: false,
            authUser: auth?.user
        })) as PhoneNumber[];

        setUserPhoneNumbers(updatedPhoneNumbers);
        updateAccountInfoPhoneNumbers(updatedPhoneNumbers);

        setIsLoading(false);
    };

    const finishedAddPhoneNumberAction = (isLoading: boolean, newNumber?: PhoneNumber) => {
        setIsLoading(isLoading);
        setIsAdding(false);

        if (newNumber) {
            reloadUserPhoneNumbers();
        }
    };

    const handleCancelDialog = useCallback(() => {
        if (isDirty) {
            setIsLeavingWithoutSaving(true);
            return;
        }

        toggleDialogVisibility(false);
        setSelectedPhoneNumber(undefined);
    }, [isDirty]);

    const actionButtons = useMemo(() => {
        if (isAdding) {
            return [];
        }

        return [
            <Button
                color="primary"
                isLoading={isSaving}
                key="save-contact-dialog-btn"
                id="modal-save-button"
                data-testid="modal-save-button"
                onClick={updateUserPhoneNumber}
                disabled={selectedPhoneNumber === undefined}
            >
                Save
            </Button>,
            <Button
                id="cancel-manage-contact-btn"
                data-testid="cancel-manage-contact-btn"
                key="cancel"
                onClick={handleCancelDialog}
            >
                Cancel
            </Button>
        ];
    }, [isAdding, isSaving, selectedPhoneNumber, updateUserPhoneNumber, handleCancelDialog]);

    return (
        <>
            {show && (
                <Dialog
                    open={show}
                    title="Manage Contact Information"
                    actions={actionButtons}
                    onClose={() => {
                        setIsLeavingWithoutSaving(false);
                        toggleDialogVisibility(false);
                    }}
                >
                    <Grid container={true}>
                        <Grid xs={12}>
                            <Typography style={{ marginBottom: 15 }}>
                                Manage how the information you have provide through your{" "}
                                <Link
                                    underline="always"
                                    style={{ cursor: "pointer" }}
                                    key="account-service-phone-numbers-link"
                                    onClick={() => window.open(`${config.accountServiceBaseUrl}/contact`, "_blank")}
                                >
                                    Account Service Profile
                                </Link>{" "}
                                will receive notifications.
                            </Typography>
                        </Grid>

                        {isLoading ? (
                            <Loader verticallyCenter={true} />
                        ) : (
                            <>
                                {isAdding ? (
                                    <PhoneNumberForm
                                        finishedAddPhoneNumberAction={finishedAddPhoneNumberAction}
                                        existingNumberCount={userPhoneNumbers?.length || 0}
                                    />
                                ) : (
                                    <>
                                        <Grid xs={12}>
                                            <TextInput
                                                id="user-email"
                                                label="Email"
                                                value={userAccountDetails?.email}
                                                disabled={true}
                                                fullWidth={true}
                                            />
                                        </Grid>

                                        <Grid xs={12}>
                                            <Autocomplete
                                                id="phoneNumberList"
                                                data-testid="phoneNumberList"
                                                label="Phone Number"
                                                value={selectedPhoneNumber}
                                                onChange={handlePhoneNumberChange}
                                                options={phoneOptions(userPhoneNumbers)}
                                                multiSelect={false}
                                                allowOptionCreation={false}
                                                isInDialog={true}
                                            />
                                        </Grid>

                                        <Grid xs={6}>
                                            <Button
                                                variant="text"
                                                id="addPhoneNumber"
                                                data-testid="addPhoneNumber"
                                                disabled={(userPhoneNumbers?.length ?? 0) >= config.allowedPhoneNumbers}
                                                onClick={() => setIsAdding(true)}
                                                style={{ marginTop: 15 }}
                                            >
                                                + ADD PHONE NUMBER
                                            </Button>
                                        </Grid>
                                    </>
                                )}
                            </>
                        )}
                    </Grid>
                </Dialog>
            )}

            <DirtyStateDialog
                isOpen={isLeavingWithoutSaving}
                warningText="You have unsaved changes. Are you sure you want to leave?"
                onReturnToContent={() => {
                    setIsLeavingWithoutSaving(false);
                }}
                onDiscardChanges={() => {
                    setIsDirty(false);
                    setIsLeavingWithoutSaving(false);
                    toggleDialogVisibility(false);
                    setSelectedPhoneNumber(undefined);
                }}
            />
        </>
    );
};

export default NotificationsContactDialog;
