import {
    mdiAccountGroup,
    mdiAccountPlus,
    mdiAlert,
    mdiAlertCircleOutline,
    mdiAlertDecagram,
    mdiAlertDecagramOutline,
    mdiAlertRhombus,
    mdiAt,
    mdiBookEducation,
    mdiCalendar,
    mdiCalendarClock,
    mdiCalendarCursor,
    mdiCalendarMultipleCheck,
    mdiCalendarStar,
    mdiCarBrakeAlert,
    mdiCardAccountDetails,
    mdiCardAccountDetailsOutline,
    mdiCarEmergency,
    mdiCashLock,
    mdiCastEducation,
    mdiCellphoneSound,
    mdiCertificate,
    mdiCertificateOutline,
    mdiCharity,
    mdiClipboardAccount,
    mdiDelete,
    mdiDeleteRestore,
    mdiEmail,
    mdiEmailAlert,
    mdiEmailEdit,
    mdiFileCertificate,
    mdiFileCertificateOutline,
    mdiFileDocument,
    mdiFileDocumentMultiple,
    mdiFileDocumentOutline,
    mdiFire,
    mdiFlash,
    mdiFolderInformation,
    mdiHandHeart,
    mdiHandshake,
    mdiHandshakeOutline,
    mdiHandsPray,
    mdiHeartHalfFull,
    mdiHelpBox,
    mdiHelpCircle,
    mdiHomeLightbulbOutline,
    mdiInformation,
    mdiInformationVariant,
    mdiLightbulbOnOutline,
    mdiMessage,
    mdiMessageReplyText,
    mdiNewspaperVariantMultiple,
    mdiNewspaperVariantMultipleOutline,
    mdiPaw,
    mdiPhone,
    mdiPhoneClassic,
    mdiSchool,
    mdiStarCog,
    mdiTextBoxMultipleOutline,
    mdiTextBoxOutline,
    mdiTextBoxSearch,
    mdiTextBoxSearchOutline,
    mdiTicket,
    mdiWaterOutline,
    mdiFormDropdown,
    mdiFormSelect,
    mdiFormTextarea,
    mdiFormTextbox,
    mdiFormTextboxLock,
    mdiWeb,
    mdiFormatListBulleted
} from "@mdi/js";
import { Sections } from "../../types/Sections";

export enum SectionIconKey {
    CashLockIcon = "cashLockIcon",
    FireIcon = "fireIcon",
    FlashIcon = "flashIcon",
    LightBulbOutlineIcon = "lightBulbOnOutlineIcon",
    HomeLightBulbOnOutlineIcon = "homeLightBulbOnOutlineIcon",
    WaterOutlineIcon = "waterOutlineIcon",
    HandHeartIcon = "handHeartIcon",
    HandsPrayIcon = "handsPrayIcon",
    ChirtyIcon = "chirtyIcon",
    CardAccountDetailsIcon = "cardAccountDetailsIcon",
    TicketIcon = "ticketIcon",
    FileDocumentMultipleIcon = "fileDocumentMultipleIcon",
    StarCogIcon = "starCogIcon",
    CalendarClockIcon = "calendarClockIcon",
    CalendarIcon = "calendarIcon",
    CalendarCursorIcon = "calendarCursorIcon",
    SchoolIcon = "schoolIcon",
    ClipboardAccountIcon = "clipboardAccountIcon",
    AccountPlusIcon = "accountPlusIcon",
    CardAccountDetailsOutlineIcon = "cardAccountDetailsOutlineIcon",
    CastEducationIcon = "castEducationIcon",
    BookEducationIcon = "bookEducationIcon",
    FolderInformationIcon = "folderInformationIcon",
    FileDocumentIcon = "fileDocumentIcon",
    FileDocumentOutlineIcon = "fileDocumentOutlineIcon",
    TextBoxMultipleOutlineIcon = "textBoxMultipleOutlineIcon",
    TextBoxOutlineIcon = "textBoxOutlineIcon",
    CertificateIcon = "certificateIcon",
    CertificateOutlineIcon = "certificateOutlineIcon",
    FileCertificateIcon = "fileCertificateIcon",
    FileCertificateOutlineIcon = "fileCertificateOutlineIcon",
    TextBoxSearchIcon = "textBoxSearchIcon",
    TextBoxSearchOutlineIcon = "textBoxSearchOutlineIcon",
    DeleteRestoreIcon = "deleteRestoreIcon",
    DeleteIcon = "deleteIcon",
    AlertIcon = "alertIcon",
    AlertRhombusIcon = "alertRhombusIcon",
    MessageIcon = "messageIcon",
    MessageReplyTextIcon = "messageReplyTextIcon",
    EmailAlertIcon = "emailAlertIcon",
    EmailIcon = "emailIcon",
    HeartHalfFullIcon = "heartHalfFullIcon",
    NewspaperVariantMultipleIcon = "newspaperVariantMultipleIcon",
    NewspaperVariantMultipleOutlineIcon = "newspaperVariantMultipleOutlineIcon",
    CalendarStarIcon = "calendarStarIcon",
    CalendarMultipleCheckIcon = "calendarMultipleCheckIcon",
    AccountGroupIcon = "accountGroupIcon",
    HandshakeIcon = "handshakeIcon",
    HandshakeOutlineIcon = "handshakeOutlineIcon",
    AlertCircleOutlineIcon = "alertCircleOutlineIcon",
    CarEmergencyIcon = "carEmergencyIcon",
    CarBrakeAlertIcon = "carBrakeAlertIcon",
    PawIcon = "pawIcon",
    InformationIcon = "informationIcon",
    PhoneIcon = "phoneIcon",
    CellphoneSoundIcon = "cellphoneSoundIcon",
    PhoneClassicIcon = "phoneClassicIcon",
    AtIcon = "atIcon",
    EmailEditIcon = "emailEditIcon",
    HelpBoxIcon = "helpBoxIcon",
    HelpCircleIcon = "helpCircleIcon",
    AlertDecagramIcon = "alertDecagramIcon",
    AlertDecagramOutlineIcon = "alertDecagramOutlineIcon",
    InformationVariantIcon = "informationVariantIcon",
    FormDropdownIcon = "formDropdownIcon",
    FormSelectIcon = "formSelectIcon",
    FormTextareaIcon = "formTextareaIcon",
    FormTextboxIcon = "formTextboxIcon",
    FormTextboxLockIcon = "formTextboxLockIcon",
    LinkWebIcon = "linkWebIcon",
    NotificationListIcon = "notificationListIcon"
}

export interface SectionsIcon {
    key: SectionIconKey;
    path: string;
}

export const linksIcons: SectionsIcon[] = [{ key: SectionIconKey.LinkWebIcon, path: mdiWeb }];

export const notificationsIcons: SectionsIcon[] = [
    { key: SectionIconKey.NotificationListIcon, path: mdiFormatListBulleted }
];

export const paymentsIcons: SectionsIcon[] = [{ key: SectionIconKey.CashLockIcon, path: mdiCashLock }];

export const utilitiesIcons: SectionsIcon[] = [
    { key: SectionIconKey.FireIcon, path: mdiFire },
    { key: SectionIconKey.FlashIcon, path: mdiFlash },
    { key: SectionIconKey.LightBulbOutlineIcon, path: mdiLightbulbOnOutline },
    { key: SectionIconKey.HomeLightBulbOnOutlineIcon, path: mdiHomeLightbulbOutline },
    { key: SectionIconKey.WaterOutlineIcon, path: mdiWaterOutline }
];

export const donationsIcons: SectionsIcon[] = [
    { key: SectionIconKey.HandHeartIcon, path: mdiHandHeart },
    { key: SectionIconKey.HandsPrayIcon, path: mdiHandsPray },
    { key: SectionIconKey.ChirtyIcon, path: mdiCharity }
];

export const variousIcons: SectionsIcon[] = [
    { key: SectionIconKey.CardAccountDetailsIcon, path: mdiCardAccountDetails },
    { key: SectionIconKey.TicketIcon, path: mdiTicket },
    { key: SectionIconKey.FileDocumentMultipleIcon, path: mdiFileDocumentMultiple }
];

export const servicesIcons: SectionsIcon[] = [{ key: SectionIconKey.StarCogIcon, path: mdiStarCog }];

export const eventRegistrationIcons: SectionsIcon[] = [
    { key: SectionIconKey.CalendarClockIcon, path: mdiCalendarClock },
    { key: SectionIconKey.CalendarIcon, path: mdiCalendar },
    { key: SectionIconKey.CalendarCursorIcon, path: mdiCalendarCursor }
];

export const academicEnrollmentIcons: SectionsIcon[] = [
    { key: SectionIconKey.SchoolIcon, path: mdiSchool },
    { key: SectionIconKey.ClipboardAccountIcon, path: mdiClipboardAccount },
    { key: SectionIconKey.AccountPlusIcon, path: mdiAccountPlus },
    { key: SectionIconKey.CardAccountDetailsOutlineIcon, path: mdiCardAccountDetailsOutline },
    { key: SectionIconKey.CastEducationIcon, path: mdiCastEducation },
    { key: SectionIconKey.BookEducationIcon, path: mdiBookEducation }
];

export const documentsIcons: SectionsIcon[] = [
    { key: SectionIconKey.FolderInformationIcon, path: mdiFolderInformation },
    { key: SectionIconKey.FileDocumentIcon, path: mdiFileDocument },
    { key: SectionIconKey.FileDocumentOutlineIcon, path: mdiFileDocumentOutline },
    { key: SectionIconKey.TextBoxMultipleOutlineIcon, path: mdiTextBoxMultipleOutline },
    { key: SectionIconKey.TextBoxOutlineIcon, path: mdiTextBoxOutline }
];

export const formsIcons: SectionsIcon[] = [
    { key: SectionIconKey.FormDropdownIcon, path: mdiFormDropdown },
    { key: SectionIconKey.FormSelectIcon, path: mdiFormSelect },
    { key: SectionIconKey.FormTextareaIcon, path: mdiFormTextarea },
    { key: SectionIconKey.FormTextboxIcon, path: mdiFormTextbox },
    { key: SectionIconKey.FormTextboxLockIcon, path: mdiFormTextboxLock }
];

export const applicationsAndPermitsIcons: SectionsIcon[] = [
    { key: SectionIconKey.CertificateIcon, path: mdiCertificate },
    { key: SectionIconKey.CertificateOutlineIcon, path: mdiCertificateOutline },
    { key: SectionIconKey.FileCertificateIcon, path: mdiFileCertificate },
    { key: SectionIconKey.FileCertificateOutlineIcon, path: mdiFileCertificateOutline }
];

export const onlineRequestIcons: SectionsIcon[] = [
    { key: SectionIconKey.TextBoxSearchIcon, path: mdiTextBoxSearch },
    { key: SectionIconKey.TextBoxSearchOutlineIcon, path: mdiTextBoxSearchOutline },
    { key: SectionIconKey.DeleteRestoreIcon, path: mdiDeleteRestore },
    { key: SectionIconKey.DeleteIcon, path: mdiDelete },
    { key: SectionIconKey.FileDocumentIcon, path: mdiFileDocument },
    { key: SectionIconKey.FileDocumentOutlineIcon, path: mdiFileDocumentOutline },
    { key: SectionIconKey.TextBoxMultipleOutlineIcon, path: mdiTextBoxMultipleOutline },
    { key: SectionIconKey.TextBoxOutlineIcon, path: mdiTextBoxOutline }
];

export const alertsAndMessagesIcons: SectionsIcon[] = [
    { key: SectionIconKey.AlertIcon, path: mdiAlert },
    { key: SectionIconKey.AlertRhombusIcon, path: mdiAlertRhombus },
    { key: SectionIconKey.MessageIcon, path: mdiMessage },
    { key: SectionIconKey.MessageReplyTextIcon, path: mdiMessageReplyText },
    { key: SectionIconKey.EmailAlertIcon, path: mdiEmailAlert },
    { key: SectionIconKey.EmailIcon, path: mdiEmail }
];

export const communityIcons: SectionsIcon[] = [{ key: SectionIconKey.HeartHalfFullIcon, path: mdiHeartHalfFull }];

export const newsAndEventIcons: SectionsIcon[] = [
    { key: SectionIconKey.NewspaperVariantMultipleIcon, path: mdiNewspaperVariantMultiple },
    { key: SectionIconKey.NewspaperVariantMultipleOutlineIcon, path: mdiNewspaperVariantMultipleOutline },
    { key: SectionIconKey.CalendarStarIcon, path: mdiCalendarStar },
    { key: SectionIconKey.CalendarMultipleCheckIcon, path: mdiCalendarMultipleCheck }
];

export const getInvolvedIcons: SectionsIcon[] = [
    { key: SectionIconKey.AccountGroupIcon, path: mdiAccountGroup },
    { key: SectionIconKey.HandshakeIcon, path: mdiHandshake },
    { key: SectionIconKey.HandshakeOutlineIcon, path: mdiHandshakeOutline }
];

export const reportAnIssueIcons: SectionsIcon[] = [
    { key: SectionIconKey.AlertCircleOutlineIcon, path: mdiAlertCircleOutline },
    { key: SectionIconKey.CarEmergencyIcon, path: mdiCarEmergency },
    { key: SectionIconKey.CarBrakeAlertIcon, path: mdiCarBrakeAlert },
    { key: SectionIconKey.PawIcon, path: mdiPaw }
];

export const supportIcons: SectionsIcon[] = [{ key: SectionIconKey.InformationIcon, path: mdiInformation }];

export const contactIcons: SectionsIcon[] = [
    { key: SectionIconKey.PhoneIcon, path: mdiPhone },
    { key: SectionIconKey.CellphoneSoundIcon, path: mdiCellphoneSound },
    { key: SectionIconKey.PhoneClassicIcon, path: mdiPhoneClassic },
    { key: SectionIconKey.AtIcon, path: mdiAt },
    { key: SectionIconKey.EmailIcon, path: mdiEmail },
    { key: SectionIconKey.EmailEditIcon, path: mdiEmailEdit }
];

export const helpIcons: SectionsIcon[] = [
    { key: SectionIconKey.HelpBoxIcon, path: mdiHelpBox },
    { key: SectionIconKey.HelpCircleIcon, path: mdiHelpCircle },
    { key: SectionIconKey.AlertDecagramIcon, path: mdiAlertDecagram },
    { key: SectionIconKey.AlertDecagramOutlineIcon, path: mdiAlertDecagramOutline },
    { key: SectionIconKey.InformationVariantIcon, path: mdiInformationVariant }
];

const allIcons = [
    ...paymentsIcons,
    ...utilitiesIcons,
    ...donationsIcons,
    ...variousIcons,
    ...servicesIcons,
    ...eventRegistrationIcons,
    ...academicEnrollmentIcons,
    ...formsIcons,
    ...documentsIcons,
    ...applicationsAndPermitsIcons,
    ...onlineRequestIcons,
    ...alertsAndMessagesIcons,
    ...communityIcons,
    ...newsAndEventIcons,
    ...getInvolvedIcons,
    ...reportAnIssueIcons,
    ...supportIcons,
    ...contactIcons,
    ...helpIcons,
    ...linksIcons,
    ...notificationsIcons
];

// ("cashLockIcon") = > returns "CashLockIcon"
export const getSectionIconKeyFromValue = (value: string): string | undefined => {
    value = value.charAt(0).toLowerCase() + value.slice(1);
    const key = Object.keys(SectionIconKey).find((x) => SectionIconKey[x as keyof typeof SectionIconKey] === value);

    return key;
};

export const getIconByKey = (key: SectionIconKey): SectionsIcon | undefined => {
    const iconByKey = allIcons.find((x) => x.key.toUpperCase() === key.toUpperCase());

    if (!iconByKey) {
        console.warn("No icon could be found with the given key:", key);
    }
    return iconByKey;
};

export const getIconSetForSection = (section: Sections): SectionsIcon[] => {
    let iconOptions: SectionsIcon[] = [];

    switch (section) {
        case Sections.AcademicEnrollment:
            iconOptions = academicEnrollmentIcons;
            break;
        case Sections.AlertsAndMessages:
            iconOptions = alertsAndMessagesIcons;
            break;
        case Sections.ApplicationsAndPermits:
            iconOptions = applicationsAndPermitsIcons;
            break;
        case Sections.Community:
            iconOptions = communityIcons;
            break;
        case Sections.Contact:
            iconOptions = contactIcons;
            break;
        case Sections.Donations:
            iconOptions = donationsIcons;
            break;
        case Sections.EventRegistration:
            iconOptions = eventRegistrationIcons;
            break;
        case Sections.Documents:
            iconOptions = documentsIcons;
            break;
        case Sections.GetInvolved:
            iconOptions = getInvolvedIcons;
            break;
        case Sections.Help:
            iconOptions = helpIcons;
            break;
        case Sections.Links:
            iconOptions = linksIcons;
            break;
        case Sections.NewsAndEvents:
            iconOptions = newsAndEventIcons;
            break;
        case Sections.OnlineRequest:
            iconOptions = onlineRequestIcons;
            break;
        case Sections.Payments:
            iconOptions = paymentsIcons;
            break;
        case Sections.ReportAnIssue:
            iconOptions = reportAnIssueIcons;
            break;
        case Sections.Services:
            iconOptions = servicesIcons;
            break;
        case Sections.Support:
            iconOptions = supportIcons;
            break;
        case Sections.Utilities:
            iconOptions = utilitiesIcons;
            break;
        case Sections.Various:
            iconOptions = variousIcons;
            break;
        default:
            iconOptions = [];
            break;
    }

    return iconOptions;
};
