import React from "react";
import Button from "@civicplus/preamble-ui/lib/Button";
import Popover from "@civicplus/preamble-ui/lib/Popover";
import SearchIcon from "@mui/icons-material/Search";
import SearchInput from "@civicplus/preamble-ui/lib/SearchInput";
import useMediaQuery from "@mui/material/useMediaQuery";
import { makeStyles, useTheme } from "@civicplus/preamble-ui/lib/Utilities/ThemeHelper";
import { OrganizationState, useOrganization } from "../../stores/organizationStore";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    searchIcon: {
        border: `1px solid ${theme.palette.text.secondary}`,
        borderRadius: "100%",
        padding: theme.spacing(0.2)
    },
    popover: {
        padding: theme.spacing(2),
        width: "100%"
    }
}));

const GlobalSearchInput: React.FC = () => {
    const classes = useStyles();
    const organization = useOrganization((state: OrganizationState) => state.organization);
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobileView = useMediaQuery(theme.breakpoints.down("md"));
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    let searchPlaceholder = `Search ${organization?.friendlyName ?? organization?.name} Portal...`;
    if (window.navigator.platform.indexOf("Mac") !== -1) {
        searchPlaceholder += " (⌘+k)";
    } else if (window.navigator.platform.indexOf("Win") !== -1 || !("ontouchstart" in document.documentElement)) {
        searchPlaceholder += " (ctrl+k)";
    }

    const handleSearch = async (value: string) => {
        navigate(`/${organization?.name}/search/${encodeURIComponent(value)}`);
        setAnchorEl(null);
    };

    const searchInput = (() => {
        return (
            <SearchInput
                useHotkey={true}
                placeholder={searchPlaceholder}
                onSearch={handleSearch}
                searchOnChange={true}
                onClear={() => handleSearch("")}
                fullWidth={true}
                margin={false}
                size="small"
                aria-label="Global Search Portal"
                onChange={() => {
                    // Empty to force Clear option to show.
                }}
            />
        );
    })();

    if (isMobileView) {
        return (
            <>
                <Button
                    type="icon"
                    size="small"
                    aria-owns={anchorEl ? "global-search-popover" : undefined}
                    aria-haspopup="true"
                    aria-expanded={Boolean(anchorEl)}
                    aria-label="Search button"
                    onClick={(event: { currentTarget: React.SetStateAction<HTMLButtonElement | null> }) =>
                        setAnchorEl(event.currentTarget)
                    }
                >
                    <SearchIcon className={classes.searchIcon} />
                </Button>

                <Popover
                    id="global-search-popover"
                    className={classes.popover}
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={() => setAnchorEl(null)}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center"
                    }}
                >
                    {searchInput}
                </Popover>
            </>
        );
    }
    return searchInput;
};

export default GlobalSearchInput;
