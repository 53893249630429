import React, { useCallback, useEffect, useRef, useState } from "react";
import { AdvancedTableWrapper as AdvancedTableWrapperClass } from "@civicplus/preamble-ui/lib/AdvancedTable/AdvancedTableWrapper";
import ApiService, { ApiError, parseErrorMessage } from "../../services/apiService";
import Button from "@civicplus/preamble-ui/lib/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@civicplus/preamble-ui/lib/Grid";
import Typography from "@civicplus/preamble-ui/lib/Typography";
import { AuthContextProps } from "../react-oidc-context/AuthContext";
import { convertLinkToSearchDocument, DocumentType, SearchDocument } from "../../types/SearchDocument";
import { GetSectionFromDocumentType, Link } from "../../types/Link";
import { CreateNewLinkDialog } from "../../components/CreateNewLinkDialog/CreateNewLinkDialog";
import { EditExternalLinkDialog } from "../EditLinkDialog/EditExternalLinkDialog";
import { EditLinkDialog } from "../../components/EditLinkDialog/EditLinkDialog";
import { FormatTags } from "../EditLinkDialog/EditLinkUtilities";
import { LastModified } from "@civicplus/preamble-ui/lib/Utilities/CommonTableComponents";
import { OptionShape } from "@civicplus/preamble-ui/lib/Autocomplete";
import { Organization, PagedResult } from "../../types/Organization";
import { SimpleDialog } from "../../components/SimpleDialog/SimpleDialog";
import useStyles from "./styles";
import SettingsTable from "./SettingsTable";
import { useCheckWeatherAlertsEnabledForOrg } from "../../hooks/useCheckWeatherAlertsEnabledForOrg";
import { useSnackbar } from "notistack";
export interface BaseSettingsSectionProps {
    org: Organization;
    auth: AuthContextProps;
}
export interface SettingsSectionProps extends BaseSettingsSectionProps {
    type: DocumentType;
    searchMultipleDocumentTypes?: DocumentType[] | undefined;
    itemName: string;
    tabName: string;
    showFeatureOption: boolean;
    showNewLink: boolean;
}

const SettingsSection: React.FC<SettingsSectionProps> = ({
    org,
    auth,
    type,
    searchMultipleDocumentTypes,
    tabName,
    itemName,
    showFeatureOption,
    showNewLink
}) => {
    const classes = useStyles();
    const [items, setItems] = useState<PagedResult<SearchDocument>>({
        items: [],
        count: 0
    });
    const [loading, setLoading] = useState(true);
    const [isFetchingItems, setIsFetchingItems] = useState(false);
    const [timeZone, setTimeZone] = useState("America/Chicago");
    const [tags, setTags] = useState<OptionShape[]>([]);
    const [lastTimeSynced, setLastTimeSynced] = useState<string | undefined>();
    const [isSyncing, setIsSyncing] = useState<boolean>(false);
    const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
    const [isEditExternalDialogOpen, setIsEditExternalDialogOpen] = useState(false);
    const [itemToBeDeleted, setItemToBeDeleted] = useState<{ data: SearchDocument; index: number }>();
    const [itemToBeEdited, setItemToBeEdited] = useState<{ data: Link; index: number }>();
    const [weatherAlertEnabledPending, weatherAlertsEnabled] = useCheckWeatherAlertsEnabledForOrg();

    const refContainer = useRef<AdvancedTableWrapperClass | null>(null);
    const { enqueueSnackbar } = useSnackbar();

    const getItems = useCallback(async () => {
        setLoading(true);

        if (isFetchingItems || weatherAlertEnabledPending) {
            return;
        }

        setIsFetchingItems(true);

        let url = `${org.name}/search/all?documentType=${type}`;

        if (searchMultipleDocumentTypes) {
            url = `${org.name}/search/all/?`;

            searchMultipleDocumentTypes.forEach((type: DocumentType, index: number) => {
                url += `${index > 0 ? "&" : ""}documentType=${type}`;
            });
        }

        const results: PagedResult<SearchDocument> = await ApiService.get({
            url: url,
            cache: false,
            authUser: auth.user
        });

        if (type.includes(DocumentType.AlertList) && weatherAlertsEnabled) {
            const weatherResults: PagedResult<SearchDocument> = await ApiService.get({
                url: `${org.name}/search/all?documentType=${DocumentType.AlertWeather}`,
                cache: false,
                authUser: auth.user
            });

            results.items.push(...weatherResults.items);
            results.count += weatherResults.count;
        }

        setItems(results);
        setLoading(false);
        setIsFetchingItems(false);
    }, [weatherAlertEnabledPending, org.name, type, auth.user]);

    useEffect(
        function loadTimezone() {
            if (org.settings.timeZone) {
                setTimeZone(org.settings.timeZone);
            }
        },
        [org]
    );

    const getIndexTimeSynced = useCallback(() => {
        let url = `search/${org.name}/indexTime?documentType=${type}`;

        if (type.includes(DocumentType.AlertList) && weatherAlertsEnabled) {
            url = `search/${org.name}/indexTime?documentType=${DocumentType.AlertWeather}`;
        }
        const lastTime = ApiService.get({
            url: url,
            cache: false,
            authUser: auth.user
        });

        lastTime
            .then((datetimeoffset: string) => {
                setLastTimeSynced(datetimeoffset);
                return;
            })
            .catch((e) => {
                // eslint-disable-next-line no-console
                console.error(e);
            });
    }, [auth.user, org.name, type, weatherAlertsEnabled]);

    useEffect(() => {
        async function initialLoad() {
            await getItems();
            await getIndexTimeSynced();
            setLoading(false);
        }
        initialLoad();
    }, [getItems, getIndexTimeSynced]);

    useEffect(() => {
        const getTags = async () => {
            const tags = await ApiService.get({
                url: `${org.name}/search/tags`,
                cache: false,
                authUser: auth.user
            });

            const formattedTags = FormatTags(tags);
            setTags(formattedTags);
        };

        if (tags.length === 0) {
            getTags();
        }
    }, [auth.user, org.name, tags.length]);

    const syncItems = async () => {
        setIsSyncing(true);

        let url = `search/${org.name}/refresh?documentType=${type}`;

        if (type.includes(DocumentType.AlertList) && weatherAlertsEnabled) {
            url = `search/${org.name}/refresh?documentType=${DocumentType.AlertWeather}`;
        }

        await ApiService.post({
            url: url,
            authUser: auth.user
        });

        enqueueSnackbar(`${tabName} sync initiated.`, {
            variant: "success"
        });

        setIsSyncing(false);

        getIndexTimeSynced();
    };

    const onCreateDialogSave = useCallback(
        async (link?: Link) => {
            setIsCreateDialogOpen(false);
            if (link !== undefined) {
                const newItems = {
                    items: [...items.items, convertLinkToSearchDocument(link, type)],
                    count: ++items.count
                };
                setItems(newItems);
            }
        },
        [items]
    );

    const onEditDialogSave = useCallback(
        async (external = false, link?: Link) => {
            external ? setIsEditExternalDialogOpen(false) : setIsEditDialogOpen(false);
            if (link && itemToBeEdited) {
                const itemsCopy = { items: [...items.items], count: items.count };
                itemsCopy.items[itemToBeEdited.index] = convertLinkToSearchDocument(link, type);
                setItems(itemsCopy);
            }
            setItemToBeEdited(undefined);
        },
        [items, itemToBeEdited]
    );

    const onCreateDialogClose = useCallback(() => {
        setIsCreateDialogOpen(false);
    }, []);

    const onEditDialogClose = useCallback(() => {
        setIsEditDialogOpen(false);
        setItemToBeEdited(undefined);
    }, []);

    const onExternalEditDialogClose = useCallback(() => {
        setIsEditExternalDialogOpen(false);
        setItemToBeEdited(undefined);
    }, []);

    const onDeleteDialogClose = useCallback(() => {
        setIsDeleteDialogOpen(false);
        setItemToBeDeleted(undefined);
    }, []);

    const onDeleteDialogAction = async () => {
        if (!itemToBeDeleted) {
            return;
        }

        try {
            await ApiService.delete({
                url: `${org.name}/links/${itemToBeDeleted.data.itemId}`,
                authUser: auth.user
            });
            const updatedItems = [
                ...items.items.filter((i, index) => {
                    return index !== itemToBeDeleted.index;
                })
            ];
            setItems({ items: updatedItems, count: --items.count });
            onDeleteDialogClose();

            enqueueSnackbar(`"${itemToBeDeleted.data.title}" has been deleted.`, {
                variant: "success"
            });
        } catch (ex) {
            // eslint-disable-next-line no-console
            console.error(ex);
            const message = `An error occured while deleting the ${type}.`;
            const error = parseErrorMessage(ex as ApiError);
            enqueueSnackbar(`${message}\r\n${error}`, {
                variant: "error",
                persist: true
            });
        }
    };

    return (
        <>
            <Grid container={true} justifyContent="flex-end">
                {lastTimeSynced && !isSyncing ? (
                    <Grid className={classes.syncMessage}>
                        <Typography variant="subtitle1">
                            <b>{tabName} Last Synced:</b>{" "}
                            <LastModified date={lastTimeSynced} organizationTimeZone={timeZone} key="indexTimeSynced" />
                        </Typography>
                    </Grid>
                ) : (
                    <Grid className={classes.syncMessage}>
                        <CircularProgress size={16} color="inherit" style={{ marginRight: 10 }} />
                        <Typography variant="subtitle1">
                            {isSyncing ? "Sync in progress..." : "Getting sync information..."}
                        </Typography>
                    </Grid>
                )}

                <Grid style={{ marginRight: 10 }}>
                    <Button key="sync" id="sync-items" color="default" onClick={() => syncItems()} disabled={isSyncing}>
                        Sync {tabName}
                    </Button>
                </Grid>

                {showNewLink && (
                    <Grid>
                        <Button
                            key="create-item"
                            id="items-settings-create"
                            onClick={() => setIsCreateDialogOpen(true)}
                            color="primary"
                        >
                            + New {itemName} Link
                        </Button>
                    </Grid>
                )}
            </Grid>

            <SettingsTable
                items={items}
                tags={tags}
                tabName={tabName}
                type={type}
                refContainer={refContainer}
                timeZone={timeZone}
                loading={loading}
                organization={org}
                auth={auth}
                showFeatureOption={showFeatureOption}
                setItemToBeEdited={setItemToBeEdited}
                setItemToBeDeleted={setItemToBeDeleted}
                setIsEditDialogOpen={setIsEditDialogOpen}
                setIsEditExternalDialogOpen={setIsEditExternalDialogOpen}
                setIsDeleteDialogOpen={setIsDeleteDialogOpen}
            />

            <CreateNewLinkDialog
                orgName={org.name}
                isOpen={isCreateDialogOpen}
                onClose={onCreateDialogClose}
                onSave={onCreateDialogSave}
                defaultSection={GetSectionFromDocumentType(type)}
                modalTitle={`Create New ${itemName} Link`}
                documentType={type}
            />

            <EditExternalLinkDialog
                disableSectionSelect={true}
                isOpen={isEditExternalDialogOpen}
                linkToEdit={itemToBeEdited?.data}
                onClose={onExternalEditDialogClose}
                orgName={org.name}
                onSave={onEditDialogSave}
                title={`Edit ${itemName}`}
                showFeatureOption={showFeatureOption}
            />

            <EditLinkDialog
                orgName={org.name}
                isOpen={isEditDialogOpen}
                onClose={onEditDialogClose}
                onSave={onEditDialogSave}
                linkToEdit={itemToBeEdited?.data}
                disableSectionSelect={true}
                documentType={type}
                showFeatureOption={true}
            />

            <SimpleDialog
                isOpen={isDeleteDialogOpen}
                title={`Delete ${itemName}`}
                primaryAction={onDeleteDialogAction}
                primaryBtnText="Confirm Deletion"
                onClose={onDeleteDialogClose}
            >
                <Typography>Are you sure you want to delete this {itemName}?</Typography>
            </SimpleDialog>
        </>
    );
};

export default SettingsSection;
