import React, { useCallback, useEffect, useMemo, useState } from "react";
import Alert from "@civicplus/preamble-ui/lib/Alert";
import { AllSectionOptions } from "../../types/Sections";
import ApiService, { ApiError, JSONPatchOperationType, parseErrorMessage } from "../../services/apiService";
import Autocomplete, { OptionShape } from "@civicplus/preamble-ui/lib/Autocomplete";
import Button from "@civicplus/preamble-ui/lib/Button";
import Checkbox from "@civicplus/preamble-ui/lib/Checkbox";
import Dialog from "@civicplus/preamble-ui/lib/Dialog";
import { DirtyStateDialog } from "../DirtyState";
import { EditLinkDialogProps, FormatTags, GetSectionValue, arrayEquality, sectionsType } from "./EditLinkUtilities";
import enhanceWithValidation from "@civicplus/preamble-ui/lib/Validations";
import { GAevent } from "../../services/googleAnalyticsService";
import { getSectionIconKeyFromValue, SectionIconKey } from "../SectionsIcons/SectionsIcons";
import Grid from "@civicplus/preamble-ui/lib/Grid";
import { isStaffUser } from "../../shared/functions";
import Link from "@civicplus/preamble-ui/lib/Link";
import TextInput from "@civicplus/preamble-ui/lib/TextInput";
import { useAuth } from "../../providers/AuthProvider";
import { useConfig } from "../../providers/ConfigProvider";
import { useOrganization } from "../../stores/organizationStore";
import { useSnackbar } from "notistack";

const EnhancedAutocomplete = enhanceWithValidation(Autocomplete);
const EnhancedTextInput = enhanceWithValidation(TextInput);

export default interface JSONPatchOperation {
    op: JSONPatchOperationType;
    path: string;
    value: any;
}

export const EditExternalLinkDialog: React.FC<EditLinkDialogProps> = ({
    isOpen,
    onClose,
    onSave,
    orgName,
    linkToEdit,
    title,
    showFeatureOption
}) => {
    const config = useConfig();

    const organization = useOrganization((state) => state.organization);

    const [isFeatured, setIsFeatured] = useState<boolean | undefined>(undefined);
    const [disabled, setDisabled] = useState<boolean>(true);
    const [isDirty, setIsDirty] = useState<boolean>(false);
    const [selectedTags, setSelectedTags] = useState<OptionShape | OptionShape[]>();
    const [customTags, setCustomTags] = useState<string[]>([]);
    const [externalTags, setExternalTags] = useState<OptionShape | OptionShape[]>(
        FormatTags(linkToEdit?.externalTags || [])
    );
    const [allTags, setAllTags] = useState<OptionShape[]>([]);
    const [isLeavingWithoutSaving, setIsLeavingWithoutSaving] = useState<boolean>(false);
    const [linkToEditDefaults, setLinkToEditDefaults] = useState<
        | {
              title: string | undefined;
              description: string | undefined;
              section: string | undefined;
              icon: string | undefined;
              url: string | undefined;
              isFeatured: boolean | undefined;
              customTags: string[];
              externalTags: string[];
          }
        | undefined
    >(undefined);

    const auth = useAuth();
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        if (!linkToEditDefaults) {
            setIsDirty(false);
        }

        if (
            linkToEditDefaults &&
            linkToEditDefaults?.isFeatured !== undefined &&
            linkToEditDefaults?.customTags !== undefined
        ) {
            const tagsEquality = arrayEquality(customTags, linkToEditDefaults?.customTags);
            const edited = isFeatured !== linkToEditDefaults?.isFeatured || !tagsEquality;

            if (edited) {
                setDisabled(false);
                setIsDirty(edited);
            }
        }
    }, [linkToEditDefaults, isFeatured, customTags, linkToEdit?.customTags]);

    const orgServiceBaseUrl = useMemo(() => {
        const orgServiceBaseUrl = config.getOrgServiceBaseUrl();
        const appId = linkToEdit && linkToEdit.aggregateId.split("_")[1];

        const adminAppURL = `${orgServiceBaseUrl}${organization?.name}/applications/${appId}/edit`;
        return adminAppURL;
    }, [config, linkToEdit, organization?.name]);

    useEffect(() => {
        linkToEdit && setExternalTags(FormatTags(linkToEdit.externalTags));
    }, [linkToEdit, linkToEdit?.externalTags]);

    useEffect(() => {
        if (linkToEdit) {
            const title = linkToEdit.title;
            const description = linkToEdit.description;
            const section = linkToEdit.sectionString.split(" ").join("");
            const url = linkToEdit.url;
            const isFeatured = linkToEdit.isFeatured;

            setIsFeatured(linkToEdit.isFeatured);
            linkToEdit.customTags && setCustomTags(linkToEdit.customTags);

            setSelectedTags(
                (linkToEdit.customTags || []).map((tag) => {
                    return { label: tag, value: tag };
                })
            );

            setLinkToEditDefaults({
                title,
                description,
                section,
                icon: getSectionIconKeyFromValue(SectionIconKey.LinkWebIcon),
                url,
                isFeatured,
                customTags: linkToEdit.customTags || [],
                externalTags: linkToEdit.externalTags || []
            });
        }
    }, [linkToEdit]);

    const onUpdateDialog = useCallback(async () => {
        setDisabled(true);

        if (auth.user?.access_token && linkToEdit) {
            try {
                const metadata = {
                    isFeatured: isFeatured,
                    isHidden: linkToEdit.isHidden,
                    tags: customTags
                };

                await ApiService.put({
                    url: `${orgName}/links/metadata/${linkToEdit.aggregateId}`,
                    requestInit: {
                        headers: {
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify(metadata)
                    },
                    authUser: auth.user
                });

                enqueueSnackbar(`"${linkToEdit.title}" has been updated.`, {
                    variant: "success"
                });

                if (!isStaffUser(auth.user)) {
                    GAevent({
                        action: "link_updated",
                        category: "Links",
                        orgName,
                        label: `Title: ${linkToEdit.title}`
                    });
                }
            } catch (ex) {
                const errorMessage = parseErrorMessage(ex as ApiError, "An error occured while updating link.");
                enqueueSnackbar(errorMessage, {
                    variant: "error",
                    persist: true
                });
                console.error(ex);
                setDisabled(false);
            }
        }

        if (onSave) {
            if (linkToEdit) {
                linkToEdit.customTags = customTags;

                const newLink = {
                    ...linkToEdit,
                    tags: customTags.concat(linkToEdit.externalTags || []),
                    isFeatured: isFeatured || false,
                    externalTags: linkToEdit.externalTags
                };

                onSave(true, newLink);
            }
        }
    }, [auth.user, linkToEdit, onSave, orgName, isFeatured, enqueueSnackbar, customTags]);

    const onEnterDialog = useCallback(() => {
        const getTags = async () => {
            const tags = await ApiService.get({
                url: `${orgName}/search/tags`,
                cache: false,
                authUser: auth.user
            });

            const sortedTags = [...new Set(tags.map((tag: string) => tag.toLowerCase()))]
                .sort()
                .map((tag) => tags.find((t: string) => t.toLowerCase() === tag));

            const tagsList = FormatTags(sortedTags);

            AllSectionOptions.forEach(
                (option: { label: string; options: { label: string; value: string | number }[] }) => {
                    option.options.forEach((tag: OptionShape) => {
                        tagsList.push(tag);
                    });
                }
            );

            setAllTags(tagsList);
        };
        getTags();
    }, [auth.user, orgName]);

    const onCloseDialog = useCallback(() => {
        if (isDirty) {
            setIsLeavingWithoutSaving(true);
        } else {
            if (onClose) {
                onClose();
            }
        }
        setDisabled(true);
    }, [isDirty, onClose]);

    const onExitedDialog = useCallback(() => {
        setDisabled(true);
        setLinkToEditDefaults(undefined);
        setIsFeatured(undefined);
        setCustomTags([]);
    }, []);

    const onIsFeaturedChange = useCallback((e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        setIsFeatured(checked);
    }, []);

    const onCustomTagsChange = useCallback((selected: OptionShape[] | undefined) => {
        const tags: string[] = [];

        if (Array.isArray(selected)) {
            selected.forEach((option: OptionShape) => {
                tags.push(option.label.toString());
            });
        }

        setCustomTags(tags);
        setSelectedTags(selected);
    }, []);

    const sectionValue = useMemo(() => {
        return (
            linkToEdit &&
            linkToEdit.section &&
            GetSectionValue(linkToEdit?.section, AllSectionOptions as sectionsType[])
        );
    }, [linkToEdit]);

    return (
        <>
            <Dialog
                open={isOpen}
                title={title || "Edit Link"}
                onEnter={onEnterDialog}
                onClose={onCloseDialog}
                onExited={onExitedDialog}
                actions={[
                    <Button
                        color="primary"
                        onClick={onUpdateDialog}
                        disabled={disabled}
                        id="modal-update-button"
                        key="update-link-btn"
                    >
                        Update
                    </Button>,
                    <Button onClick={onCloseDialog} key="cancel">
                        Cancel
                    </Button>
                ]}
            >
                <Alert id="disabled-fields-helper-text" severity="info">
                    The disabled input fields are synced from an external product. Please update these details in{" "}
                    <Link href={orgServiceBaseUrl} underline="always">
                        {linkToEdit?.productType}.
                    </Link>
                </Alert>

                <Grid container={true}>
                    <Grid xs={12}>
                        <EnhancedTextInput
                            id="edit-link-url"
                            label="URL"
                            value={linkToEdit?.url}
                            fullWidth={true}
                            required={true}
                            disabled={true}
                            errorMessages={{
                                regExpPattern: "You must insert a valid URL."
                            }}
                        />
                    </Grid>

                    <Grid xs={12}>
                        <EnhancedAutocomplete
                            id="edit-link-section"
                            label="Section"
                            required={true}
                            value={sectionValue}
                            isInDialog={true}
                            disabled={true}
                        />
                    </Grid>

                    <Grid xs={12}>
                        <EnhancedTextInput
                            id="edit-link-title"
                            label="Title"
                            value={linkToEdit?.title}
                            required={true}
                            fullWidth={true}
                            helperText={`${
                                linkToEdit?.title?.length === undefined ? 0 : linkToEdit?.title?.length
                            }/100`}
                            disabled={true}
                        />
                    </Grid>

                    <Grid xs={12}>
                        <EnhancedTextInput
                            id="edit-link-description"
                            label="Description"
                            value={linkToEdit?.description}
                            disabled={true}
                            multiline={true}
                            rows={5}
                            fullWidth={true}
                            helperText={`${
                                linkToEdit?.description?.length === undefined ? 0 : linkToEdit?.description?.length
                            }/1000`}
                        />
                    </Grid>

                    <Grid xs={12}>
                        <Autocomplete
                            id="link-tag"
                            label="Tags"
                            value={externalTags}
                            isInDialog={true}
                            disabled={true}
                            multiSelect={true}
                        />
                    </Grid>

                    <Grid xs={12}>
                        <Autocomplete
                            id="edit-link-tag"
                            label="Portal Tags"
                            onChange={onCustomTagsChange}
                            value={selectedTags}
                            options={allTags}
                            multiSelect={true}
                            allowOptionCreation={true}
                            isInDialog={true}
                        />
                    </Grid>
                    {showFeatureOption && (
                        <Grid xs={12}>
                            <Checkbox
                                id="featured-link"
                                label="Featured in Portal"
                                onChange={onIsFeaturedChange}
                                checked={isDirty ? isFeatured : linkToEdit?.isFeatured}
                            />
                        </Grid>
                    )}
                </Grid>
            </Dialog>

            <DirtyStateDialog
                isOpen={isLeavingWithoutSaving}
                onDiscardChanges={() => {
                    setIsLeavingWithoutSaving(false);
                    if (onClose) {
                        onClose();
                    }
                }}
                onReturnToContent={() => {
                    setIsLeavingWithoutSaving(false);
                }}
            />
        </>
    );
};
