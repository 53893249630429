import React, { useMemo } from "react";
import Button from "@civicplus/preamble-ui/lib/Button";
import ChipPortal from "../Chip";
import clsx from "clsx";
import Icon from "@mdi/react";
import List from "@civicplus/preamble-ui/lib/List/List";
import Typography from "@civicplus/preamble-ui/lib/Typography";
import { Link as RouterLink } from "react-router-dom";
import { LinkUtilities } from "../../types/Link";
import { LoginNoticeDialog } from "../Authentication/LoginNoticeDialog";
import { makeStyles } from "@civicplus/preamble-ui/lib/Utilities/ThemeHelper";
import { mdiBookmark, mdiBookmarkOutline, mdiOpenInNew } from "@mdi/js";
import { RemoveFavoriteDialog } from "../Favorites/RemoveFavoriteDialog";
import { SearchDocument } from "../../types/SearchDocument";
import { useEmbedStore } from "../../stores/embedStore";
import { useFavorites } from "../../shared/useFavorites";

const useStyles = makeStyles((theme) => ({
    chipsContainer: {
        display: "flex",
        flexWrap: "wrap",
        gap: 5,
        margin: theme.spacing(1, 0, 0),
        overflowX: "auto"
    },
    externalChip: {
        "& svg": {
            marginLeft: theme.spacing(1) + "!important"
        }
    },
    featured: {
        position: "relative",
        "&:after": {
            content: '""',
            position: "absolute",
            left: 0,
            width: 5,
            height: "100%",
            backgroundColor: theme.palette.primary.main
        }
    },
    formListing: {
        margin: theme.spacing(2, 0)
    }
}));

export interface FormsListProps {
    /**
     * Array of forms to display in list.
     */
    forms: SearchDocument[];
    /**
     * Name of the Organization.
     */
    orgName?: string;
}

const FormsList: React.FC<FormsListProps> = (props) => {
    const classes = useStyles();
    const isEmbed = useEmbedStore((state) => state.isInitialized);
    const { forms, orgName } = props;
    const [
        { formsFavoriteMap, loginNoticeOpen, unfavoriteDialogSettings },
        {
            handleCloseLoginNotice,
            handleCloseUnfavoriteNotice,
            handleConfirmUnfavorite,
            handleLogin,
            handleFavoriteClick
        }
    ] = useFavorites();

    const renderedFormRows = useMemo(() => {
        if (forms && forms.length === 0) {
            return [
                {
                    itemText: {
                        children: <Typography align="center">Sorry, no results found</Typography>
                    }
                }
            ];
        }

        return (
            forms &&
            forms.map((form) => {
                const { url, isExternal } = LinkUtilities.navigateUrl(
                    form.productType,
                    orgName,
                    form.itemId,
                    form.url,
                    undefined,
                    isEmbed,
                    form.additionalIndexedProperties
                );

                form.isFavorite = formsFavoriteMap.get(form.id) !== undefined;
                const linkBehavior = isExternal ? { href: url } : { component: RouterLink, to: url };

                return {
                    className: clsx({ [classes.featured]: form.isFeatured }),
                    itemText: {
                        primary: (
                            <>
                                <Typography>{form.title}</Typography>

                                <Typography variant="body2" color="textSecondary">
                                    {form.description}
                                </Typography>

                                <div className={classes.chipsContainer}>
                                    {isExternal && (
                                        <ChipPortal
                                            label="External"
                                            size="small"
                                            icon={<Icon path={mdiOpenInNew} size={0.75} aria-hidden="true" />}
                                            className={classes.externalChip}
                                        />
                                    )}

                                    {form.tags.map((tag) => (
                                        <ChipPortal label={tag} key={tag} size="small" />
                                    ))}
                                </div>
                            </>
                        )
                    },
                    divider: true,
                    button: true,
                    ...linkBehavior,
                    openInNewWindow: isExternal,
                    itemSecondaryAction: (
                        <Button
                            id={`bookmark-icon-btn-${form.id}`}
                            data-testid={`bookmark-icon-btn-${form.id}`}
                            size="small"
                            type="icon"
                            stopPropagation={true}
                            aria-pressed={form.isFavorite}
                            title={`${form.isFavorite ? `Remove ${form.title} from` : `Add ${form.title} to`} my bookmarks`}
                            onClick={(e: React.MouseEvent<HTMLElement>) => {
                                handleFavoriteClick(e, form);
                            }}
                        >
                            <Icon
                                size={1}
                                aria-hidden="true"
                                path={form.isFavorite ? mdiBookmark : mdiBookmarkOutline}
                                data-testid={form.isFavorite ? `${form.id}-remove-favorite` : `${form.id}-add-favorite`}
                            />
                        </Button>
                    )
                };
            })
        );
    }, [
        classes.chipsContainer,
        classes.externalChip,
        classes.featured,
        formsFavoriteMap,
        forms,
        handleFavoriteClick,
        isEmbed,
        orgName
    ]);

    return (
        <>
            <List className={classes.formListing} id="forms-list" listItems={renderedFormRows} wrapperStyles={true} />

            <LoginNoticeDialog isOpen={loginNoticeOpen} onClose={handleCloseLoginNotice} handleLogin={handleLogin} />

            <RemoveFavoriteDialog
                isOpen={unfavoriteDialogSettings.open}
                handleConfirm={handleConfirmUnfavorite}
                onClose={handleCloseUnfavoriteNotice}
            />
        </>
    );
};

export default FormsList;
