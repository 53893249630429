import React, { useState, useEffect } from "react";
import ApiService from "../../../services/apiService";
import Button from "@civicplus/preamble-ui/lib/Button";
import ButtonNavigation from "../Components/ButtonNavigation";
import Link from "@civicplus/preamble-ui/lib/Link";
import Loader from "@civicplus/preamble-ui/lib/Loader";
import Typography from "@civicplus/preamble-ui/lib/Typography";
import { PhoneNumberList } from "./PhoneNumberList";
import { PhoneNumber, PhoneNumberType } from "./PhoneNumberUtils";
import { StepProps } from "..";
import { useAccountInfo } from "../../../hooks/useAccountInfo";
import { useAuth } from "../../../providers/AuthProvider";
import { useConfig } from "../../../providers/ConfigProvider";
import AddPhoneNumberDialog from "./AddPhoneNumberDialog";

interface PhoneNumberStepProps extends StepProps {
    existingPhoneNumbers: PhoneNumber[];
}

const PhoneNumberStep: React.FC<PhoneNumberStepProps> = (props) => {
    const { data, nextStep, setData, setCompleted, existingPhoneNumbers } = props;

    if (!nextStep) throw new Error("nextStep is required");
    if (!setData) throw new Error("setData is required");
    if (!setCompleted) throw new Error("setCompleted is required");
    if (!data) throw new Error("data is required");

    const config = useConfig();

    const auth = useAuth();

    const [userPhoneNumbers, setUserPhoneNumbers] = useState<PhoneNumber[] | undefined>(existingPhoneNumbers);
    const [isAdding, setIsAdding] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState(false);
    const [, , , updateAccountInfoPhoneNumbers] = useAccountInfo();

    useEffect(() => {
        setUserPhoneNumbers(existingPhoneNumbers);
    }, [existingPhoneNumbers]);

    const handleChangeSelectedPhoneNumbers = (type: PhoneNumberType, selectedPhoneNumber?: PhoneNumber) => {
        const newPhoneNumbersArr = data?.phoneNumbers ? data.phoneNumbers.filter((p) => p.type !== type) : [];

        if (selectedPhoneNumber) {
            newPhoneNumbersArr.push({ ...selectedPhoneNumber, type });
        } else {
            const index = newPhoneNumbersArr.findIndex((p) => p.type === type);
            if (index !== -1) {
                newPhoneNumbersArr.splice(index, 1);
            }
        }

        setData({ phoneNumbers: newPhoneNumbersArr });
        setCompleted(newPhoneNumbersArr.length > 0);
    };

    const reloadUserPhoneNumbers = async () => {
        setIsLoading(true);

        const updatedPhoneNumbers = (await ApiService.get({
            url: "user/account/phoneNumber",
            cache: false,
            authUser: auth?.user
        })) as PhoneNumber[];

        setUserPhoneNumbers(updatedPhoneNumbers);
        updateAccountInfoPhoneNumbers(updatedPhoneNumbers);

        setIsLoading(false);
    };

    const finishedAddPhoneNumberAction = (newNumber?: PhoneNumber) => {
        setIsAdding(false);

        if (newNumber) {
            reloadUserPhoneNumbers();
        }
    };

    return (
        <>
            <Typography gutterBottom={true} variant="h5">
                Phone Number
            </Typography>

            <Typography gutterBottom={true} variant="subtitle1">
                {(userPhoneNumbers?.length ?? 0) > 0 ? (
                    <>
                        Please select your number in the dropdown menus to subscribe. Text Message & Data rates may
                        apply.
                    </>
                ) : (
                    "Please provide a phone number and select if it supports voice calls/text messages. Selecting if your phone number supports voice calls and/or SMS Text Messages will determine how your phone will receive alerts."
                )}
            </Typography>

            {isLoading ? (
                <Loader verticallyCenter={true} style={{ padding: 50 }} />
            ) : (
                <>
                    {existingPhoneNumbers.length > 0 && (
                        <PhoneNumberList
                            existingPhoneNumbers={existingPhoneNumbers}
                            selectedPhoneNumbers={data?.phoneNumbers}
                            onChangeSelectedPhoneNumber={handleChangeSelectedPhoneNumbers}
                        />
                    )}

                    <Typography variant="subtitle1" gutterBottom={true}>
                        If you need to update your provided information, you can do so from{" "}
                        <Link
                            underline="always"
                            style={{ cursor: "pointer" }}
                            key="account-service-phone-numbers-link"
                            onClick={() => window.open(`${config.accountServiceBaseUrl}/contact`, "_blank")}
                        >
                            Account Service
                        </Link>
                        .
                    </Typography>

                    <Button
                        variant="outlined"
                        id="addPhoneNumber"
                        data-testid="addPhoneNumber"
                        disabled={(userPhoneNumbers?.length ?? 0) >= config.allowedPhoneNumbers}
                        isLoading={existingPhoneNumbers === undefined}
                        onClick={() => setIsAdding(true)}
                    >
                        + ADD PHONE NUMBER
                    </Button>

                    <AddPhoneNumberDialog
                        finishedAddPhoneNumberAction={finishedAddPhoneNumberAction}
                        existingNumberCount={userPhoneNumbers?.length || 0}
                        show={isAdding}
                    />
                </>
            )}

            <ButtonNavigation nextStep={nextStep} nextAriaLabel="Phone Verification" />
        </>
    );
};

export default PhoneNumberStep;
