import React, { createContext, useContext, useEffect, useState, useCallback } from "react";
import ApiService from "../services/apiService";
import { useAuth } from "../providers/AuthProvider";
import { useOrganization } from "../stores/organizationStore";
import { AppIdsAndPhoneNumbers } from "../types/Subscription";
import { useSnackbar } from "notistack";

const PhoneNumbersContext = createContext<
    [boolean, AppIdsAndPhoneNumbers, (applicationId: string, phoneNumber: string) => void, () => void] | undefined
>(undefined);

/**
 * Gets Users Phone Numbers registered with notification services
 */
export function ApplicationPhoneNumbersProvider({ children }: { children: React.ReactNode }): React.ReactElement {
    const auth = useAuth();
    const [organization] = useOrganization((state) => [state.organization]);
    const [phoneNumbers, setPhoneNumbers] = useState<AppIdsAndPhoneNumbers>({});
    const [isLoading, setIsLoading] = useState(true);
    const [reloadPhoneNumbers, setReloadPhoneNumbers] = useState<boolean>(false);
    const { enqueueSnackbar } = useSnackbar();

    const reloadData = useCallback(() => {
        setReloadPhoneNumbers((prev) => {
            return !prev;
        });
    }, []);

    function setPhoneValidated(applicationId: string, phoneNumber: string) {
        setPhoneNumbers((prevState) => {
            const state = { ...prevState };

            if (state[applicationId] === undefined) {
                state[applicationId] = [
                    {
                        phoneNumber: phoneNumber,
                        isValidated: true
                    }
                ];
            } else {
                const position = state[applicationId].findIndex((p) => p.phoneNumber === phoneNumber);

                if (position > -1) {
                    state[applicationId][position].isValidated = true;
                } else {
                    state[applicationId].push({
                        phoneNumber: phoneNumber,
                        isValidated: true
                    });
                }
            }
            return state;
        });
    }

    useEffect(() => {
        const controller = new AbortController();
        setIsLoading(true);

        const fetchPhoneNumbers = async (orgName: string) => {
            const url = `${orgName.toLowerCase()}/notifications/phoneNumbers`;
            try {
                const response = await ApiService.get({
                    url,
                    cache: false,
                    authUser: auth?.user,
                    controller
                });

                if (!controller.signal.aborted) {
                    setPhoneNumbers(response);
                    setIsLoading(false);
                }
            } catch (error) {
                if (controller?.signal.aborted) return;
                console.error(error);
                const message = (error as Error)?.message;
                enqueueSnackbar(message ?? "An error occurred while fetching phone numbers", {
                    variant: "error",
                    persist: true
                });
            } finally {
                setIsLoading(false);
            }
        };

        if (!auth.isLoading && auth.isAuthenticated && organization) {
            fetchPhoneNumbers(organization.name);
        }

        return () => {
            controller.abort();
        };
    }, [auth.isLoading, auth.isAuthenticated, auth.user, organization, reloadPhoneNumbers]);

    return (
        <PhoneNumbersContext.Provider value={[isLoading, phoneNumbers, setPhoneValidated, reloadData]}>
            {children}
        </PhoneNumbersContext.Provider>
    );
}

export function useApplicationPhoneNumbers(): [
    boolean,
    AppIdsAndPhoneNumbers,
    (applicationId: string, phoneNumber: string) => void,
    () => void
] {
    const value = useContext(PhoneNumbersContext);

    if (value === undefined) {
        throw Error(
            "`useApplicationPhoneNumbers` must be used inside of a `<ApplicationPhoneNumbersProvider>...</ApplicationPhoneNumbersProvider>`"
        );
    }

    return value;
}
