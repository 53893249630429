import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Button from "@civicplus/preamble-ui/lib/Button";
import Dialog from "@civicplus/preamble-ui/lib/Dialog";
import Grid from "@civicplus/preamble-ui/lib/Grid";
import TextInput from "@civicplus/preamble-ui/lib/TextInput";
import enhanceWithValidation, { requiredValidation, ValidationResult } from "@civicplus/preamble-ui/lib/Validations";
//import softMaxLengthValidation from preamble after the preamble 15 upgrade.
import { isValidUrlValidation, softMaxLengthValidation } from "../../shared/customValidation";
import { useAuth } from "../../providers/AuthProvider";
import { useLinks } from "../../stores/linksStore";
import ApiService, { ApiError, parseErrorMessage } from "../../services/apiService";
import { DirtyStateDialog } from "../DirtyState";
import { PublicLink } from "../../types/Link";
import { useSnackbar } from "notistack";

const EnhancedTextInput = enhanceWithValidation(TextInput);

interface CreatePublicLinkDialogProps {
    isOpen: boolean;
    onClose: () => void;
    onSave: () => void;
    userId?: string | any;
    modifying?: boolean;
    linkToModify?: PublicLink;
}

export const CreatePublicLinkDialog: React.FC<CreatePublicLinkDialogProps> = ({
    isOpen,
    onClose,
    onSave,
    modifying,
    linkToModify
}) => {
    const [url, setUrl] = useState<string | undefined>(undefined);
    const [title, setTitle] = useState<string | undefined>(undefined);
    const [description, setDescription] = useState<string | undefined>(undefined);
    const [linkId, setLinkId] = useState<string>("");
    const auth = useAuth();
    const { clearPublicLinks } = useLinks((state) => ({
        clearPublicLinks: state.clearPublicLinks
    }));

    const { enqueueSnackbar } = useSnackbar();
    const urlRef = useRef<{ validate: () => ValidationResult }>();
    const titleRef = useRef<{ validate: () => ValidationResult }>();
    const descriptionRef = useRef<{ validate: () => ValidationResult }>();
    const [isLeavingWithoutSaving, setIsLeavingWithoutSaving] = useState<boolean>(false);
    const [linkToEditDefaults, setLinkToEditDefaults] = useState<
        | {
              title: string | undefined;
              description: string | undefined;
              url: string | undefined;
          }
        | undefined
    >(undefined);

    const isDirty = useMemo(() => {
        if (!linkToEditDefaults) {
            return url !== undefined || title !== undefined || description !== undefined;
        }
        return (
            url !== linkToEditDefaults.url ||
            title !== linkToEditDefaults.title ||
            description !== linkToEditDefaults.description
        );
    }, [description, linkToEditDefaults, title, url]);

    useEffect(() => {
        if (linkToModify) {
            const title = linkToModify.title;
            const description = linkToModify.description;
            const url = linkToModify.url;

            setUrl(url);
            setTitle(title);
            setDescription(description);
            setLinkId(linkToModify.id);

            setLinkToEditDefaults({
                title: title,
                description: description,
                url: url
            });
        }
    }, [linkToModify, modifying]);

    const onSaveDialog = useCallback(async () => {
        const validationResults: ValidationResult[] = await Promise.all([
            urlRef.current!.validate(),
            titleRef.current!.validate(),
            descriptionRef.current!.validate()
        ]);

        if (validationResults.some((x) => x.error)) {
            enqueueSnackbar("All fields must be valid to continue.", {
                variant: "error",
                persist: true
            });
            return;
        }

        if (auth.user?.access_token) {
            let stopExecution = false;
            try {
                if (modifying) {
                    await ApiService.put({
                        url: `publicLinks/${linkId}`,
                        requestInit: {
                            headers: {
                                "Content-Type": "application/json"
                            },
                            body: JSON.stringify({
                                title,
                                description,
                                url
                            })
                        },
                        authUser: auth.user
                    });

                    enqueueSnackbar(`"${title}" has been updated.`, {
                        variant: "success"
                    });

                    onClose();
                } else {
                    await ApiService.post({
                        url: `publicLinks`,
                        requestInit: {
                            headers: {
                                "Content-Type": "application/json"
                            },
                            body: JSON.stringify({
                                title,
                                description,
                                url
                            })
                        },
                        authUser: auth.user
                    });

                    enqueueSnackbar(`"${title}" has been saved.`, {
                        variant: "success"
                    });
                }
            } catch (ex) {
                const errorMessage = parseErrorMessage(ex as ApiError);
                enqueueSnackbar(errorMessage, {
                    variant: "error",
                    persist: true
                });

                console.error(ex);
                stopExecution = true;
            } finally {
                clearPublicLinks();
            }

            if (stopExecution) {
                return;
            }
        }

        if (onSave) {
            onSave();
        }
    }, [description, enqueueSnackbar, onSave, clearPublicLinks, auth, title, url, modifying, onClose, linkId]);

    const onCloseDialog = useCallback(() => {
        if (isDirty) {
            setIsLeavingWithoutSaving(true);
        } else {
            if (onClose) {
                onClose();
            }
        }
    }, [isDirty, onClose]);

    const onExitedDialog = useCallback(() => {
        setUrl(undefined);
        setTitle(undefined);
        setDescription(undefined);
    }, []);

    const onUrlChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setUrl(e.currentTarget.value || undefined);
    }, []);

    const onTitleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setTitle(e.currentTarget.value || undefined);
    }, []);

    const onDescriptionChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setDescription(e.currentTarget.value || undefined);
    }, []);

    return (
        <>
            <Dialog
                open={isOpen}
                title={modifying ? `Modify ${title} Link` : "Create New Link"}
                onClose={onCloseDialog}
                onExited={onExitedDialog}
                actions={[
                    <Button color="primary" onClick={onSaveDialog} id="modal-save-button" key="save-update">
                        {modifying ? "Update" : "Save"}
                    </Button>,
                    <Button onClick={onCloseDialog} key="cancel">
                        Cancel
                    </Button>
                ]}
            >
                <Grid container={true}>
                    <Grid xs={12}>
                        <EnhancedTextInput
                            ref={urlRef}
                            id="new-link-url"
                            label="URL"
                            value={url}
                            onChange={onUrlChange}
                            fullWidth={true}
                            required={true}
                            validations={[isValidUrlValidation, requiredValidation]}
                        />
                    </Grid>

                    <Grid xs={12}>
                        <EnhancedTextInput
                            ref={titleRef}
                            id="new-link-title"
                            label="Title"
                            value={title}
                            required={true}
                            fullWidth={true}
                            helperText={`${title?.length === undefined ? 0 : title?.length}/100`}
                            softMaxLength={100}
                            onChange={onTitleChange}
                            validations={[requiredValidation, softMaxLengthValidation]}
                        />
                    </Grid>

                    <Grid xs={12}>
                        <EnhancedTextInput
                            id="new-link-description"
                            ref={descriptionRef}
                            label="Description"
                            value={description}
                            onChange={onDescriptionChange}
                            multiline={true}
                            rows={5}
                            validations={[softMaxLengthValidation]}
                            softMaxLength={1000}
                            helperText={`${description?.length === undefined ? 0 : description?.length}/1000`}
                            fullWidth={true}
                        />
                    </Grid>
                </Grid>
            </Dialog>

            <DirtyStateDialog
                isOpen={isLeavingWithoutSaving}
                onReturnToContent={() => {
                    setIsLeavingWithoutSaving(false);
                }}
                onDiscardChanges={() => {
                    setIsLeavingWithoutSaving(false);
                    if (onClose) {
                        onClose();
                    }
                }}
            />
        </>
    );
};
