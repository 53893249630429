import {
    mdiViewDashboard,
    mdiViewDashboardOutline,
    mdiViewDashboardVariantOutline,
    mdiViewDashboardVariant,
    mdiBookmarkBox,
    mdiBellRing,
    mdiBellOutline,
    mdiBellCheck,
    mdiMessageBadge,
    mdiMessageBadgeOutline,
    mdiFormSelect,
    mdiListBoxOutline,
    mdiFormTextbox,
    mdiListBox,
    mdiTextBoxOutline,
    mdiLink,
    mdiWeb,
    mdiCursorDefaultClickOutline,
    mdiLinkVariant,
    mdiLinkBoxVariant,
    mdiBookOpenVariantOutline,
    mdiBookOpenVariant,
    mdiBookOpenPageVariantOutline,
    mdiFountainPen,
    mdiBank,
    mdiCalendar,
    mdiCalendarTextOutline,
    mdiCalendarClock,
    mdiAccountGroup,
    mdiCash,
    mdiCashMultiple,
    mdiAccountCreditCard,
    mdiCreditCardOutline,
    mdiCurrencyUsd,
    mdiBankCheck,
    mdiFileSearchOutline,
    mdiPlaylistCheck,
    mdiTextSearch,
    mdiTextBoxCheck,
    mdiFileDocument,
    mdiFileDocumentOutline,
    mdiFileDocumentArrowRight,
    mdiFolderArrowRight,
    mdiFileDocumentCheckOutline,
    mdiForum,
    mdiMessageText,
    mdiFileDocumentPlusOutline,
    mdiFileDocumentPlus,
    mdiMessageFlashOutline,
    mdiPineTree,
    mdiForestOutline,
    mdiCalendarMultiple,
    mdiForest,
    mdiCalendarMonth,
    mdiScaleBalance
} from "@mdi/js";
import { NavigationOption } from "../../../types/Organization";

export type IconType = {
    key: string;
    path: string;
};

const dashboardIcons = [
    { key: "mdiViewDashboard", path: mdiViewDashboard },
    { key: "mdiViewDashboardOutline", path: mdiViewDashboardOutline },
    { key: "mdiViewDashboardVariantOutline", path: mdiViewDashboardVariantOutline },
    { key: "mdiViewDashboardVariant", path: mdiViewDashboardVariant },
    { key: "mdiBookmarkBox", path: mdiBookmarkBox }
];

const alertsAndNotificationsIcons = [
    { key: "mdiBellRing", path: mdiBellRing },
    { key: "mdiBellOutline", path: mdiBellOutline },
    { key: "mdiBellCheck", path: mdiBellCheck },
    { key: "mdiMessageBadge", path: mdiMessageBadge },
    { key: "mdiMessageBadgeOutline", path: mdiMessageBadgeOutline }
];

const formsIcons = [
    { key: "mdiFormSelect", path: mdiFormSelect },
    { key: "mdiListBoxOutline", path: mdiListBoxOutline },
    { key: "mdiFormTextbox", path: mdiFormTextbox },
    { key: "mdiListBox", path: mdiListBox },
    { key: "mdiTextBoxOutline", path: mdiTextBoxOutline }
];

const linksIcons = [
    { key: "mdiLink", path: mdiLink },
    { key: "mdiWeb", path: mdiWeb },
    { key: "mdiCursorDefaultClickOutline", path: mdiCursorDefaultClickOutline },
    { key: "mdiLinkVariant", path: mdiLinkVariant },
    { key: "mdiLinkBoxVariant", path: mdiLinkBoxVariant }
];

const legislationIcons = [
    { key: "mdiScaleBalance", path: mdiScaleBalance },
    { key: "mdiBookOpenVariantOutline", path: mdiBookOpenVariantOutline },
    { key: "mdiBookOpenVariant", path: mdiBookOpenVariant },
    { key: "mdiBookOpenPageVariantOutline", path: mdiBookOpenPageVariantOutline },
    { key: "mdiFountainPen", path: mdiFountainPen }
];

const meetingsIcons = [
    { key: "mdiBank", path: mdiBank },
    { key: "mdiCalendar", path: mdiCalendar },
    { key: "mdiCalendarTextOutline", path: mdiCalendarTextOutline },
    { key: "mdiCalendarClock", path: mdiCalendarClock },
    { key: "mdiAccountGroup", path: mdiAccountGroup }
];

const parksAndRecIcons = [
    { key: "mdiPineTree", path: mdiPineTree },
    { key: "mdiForestOutline", path: mdiForestOutline },
    { key: "mdiCalendarMultiple", path: mdiCalendarMultiple },
    { key: "mdiForest", path: mdiForest },
    { key: "mdiCalendarMonth", path: mdiCalendarMonth }
];

const paymentsIcons = [
    { key: "mdiCash", path: mdiCash },
    { key: "mdiCashMultiple", path: mdiCashMultiple },
    { key: "mdiAccountCreditCard", path: mdiAccountCreditCard },
    { key: "mdiCreditCardOutline", path: mdiCreditCardOutline },
    { key: "mdiCurrencyUsd", path: mdiCurrencyUsd }
];

const permitsIcons = [
    { key: "mdiBankCheck", path: mdiBankCheck },
    { key: "mdiFileSearchOutline", path: mdiFileSearchOutline },
    { key: "mdiPlaylistCheck", path: mdiPlaylistCheck },
    { key: "mdiTextSearch", path: mdiTextSearch },
    { key: "mdiTextBoxCheck", path: mdiTextBoxCheck }
];

const recordRequestsIcons = [
    { key: "mdiFileDocument", path: mdiFileDocument },
    { key: "mdiFileDocumentOutline", path: mdiFileDocumentOutline },
    { key: "mdiFileDocumentArrowRight", path: mdiFileDocumentArrowRight },
    { key: "mdiFolderArrowRight", path: mdiFolderArrowRight },
    { key: "mdiFileDocumentCheckOutline", path: mdiFileDocumentCheckOutline }
];

const submitRequestsIcons = [
    { key: "mdiForum", path: mdiForum },
    { key: "mdiMessageText", path: mdiMessageText },
    { key: "mdiFileDocumentPlusOutline", path: mdiFileDocumentPlusOutline },
    { key: "mdiFileDocumentPlus", path: mdiFileDocumentPlus },
    { key: "mdiMessageFlashOutline", path: mdiMessageFlashOutline }
];

const navIconSet: { [key in NavigationOption]: IconType[] } = {
    [NavigationOption.Dashboard]: dashboardIcons,
    [NavigationOption.AlertsAndNotifications]: alertsAndNotificationsIcons,
    [NavigationOption.Forms]: formsIcons,
    [NavigationOption.Legislation]: legislationIcons,
    [NavigationOption.Links]: linksIcons,
    [NavigationOption.Meetings]: meetingsIcons,
    [NavigationOption.ParksAndRec]: parksAndRecIcons,
    [NavigationOption.Payments]: paymentsIcons,
    [NavigationOption.Permits]: permitsIcons,
    [NavigationOption.RecordsRequest]: recordRequestsIcons,
    [NavigationOption.SubmitARequest]: submitRequestsIcons
};

export const getIconSetForNavigation = (navOption: NavigationOption): IconType[] => {
    return navIconSet[navOption] || navIconSet[NavigationOption.Dashboard];
}

export const getIconPathByName = (iconName: string, navOption: NavigationOption): string => {
    const navIconGroup = navIconSet[navOption];

    let iconPath = navIconGroup[0].path

    navIconGroup.forEach(icon => {
        if (icon.key === iconName) {
            iconPath = icon.path;
        }
    });

    return iconPath;
};
