import React from "react";
import PrmblLink, { LinkProps as PrmblLinkProps } from "@civicplus/preamble-ui/lib/Link";
import OpenInNewTab from "@mui/icons-material/OpenInNew";
import { makeStyles } from "@civicplus/preamble-ui/lib/Utilities/ThemeHelper";
import classNames from "classnames";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "inline-flex",
        alignItems: "center",
        "& svg": {
            marginLeft: theme.spacing(0.5)
        }
    }
}));

export type LinkProps = PrmblLinkProps;

export const Link: React.FC<LinkProps> = (props) => {
    const { children, className, ...otherProps } = props;
    const classes = useStyles();
    return (
        <PrmblLink {...otherProps} className={classNames(classes.root, className)}>
            {children}
            {props.target === "_blank" ? <OpenInNewTab fontSize="small" /> : null}
        </PrmblLink>
    );
};

export default Link;
