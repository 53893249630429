import { makeStyles } from "@civicplus/preamble-ui/lib/Utilities/ThemeHelper";

const useStyles = makeStyles((theme) => ({
    pagination: {
        justifyContent: "center",
        padding: "10px"
    },
    fullWidth: {
        [theme.breakpoints.down("md")]: {
            flex: "1"
        }
    }
}));

export default useStyles;
