import React, { useMemo } from "react";
import AlertToggle from "../AlertToggle";
import ChipPortal from "../Chip";
import clsx from "clsx";
import languages from "../../shared/languages";
import List from "@civicplus/preamble-ui/lib/List";
import ListItem from "@civicplus/preamble-ui/lib/ListItem";
import Typography from "@civicplus/preamble-ui/lib/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { AlertsGroupMap } from "./AlertTabList";
import { Divider } from "@civicplus/preamble-ui/lib/Divider";
import { DocumentType } from "../../types/SearchDocument";
import { makeStyles, useTheme } from "@civicplus/preamble-ui/lib/Utilities/ThemeHelper";
import { NotificationsService } from "../../services/notificationsService";
import { Organization } from "../../types/Organization";
import { SubscriptionList } from "../../types/Subscription";
import { useAccountInfo } from "../../hooks/useAccountInfo";

const useStyles = makeStyles((theme) => ({
    toggle: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        gap: theme.spacing(1),
        [theme.breakpoints.up("md")]: {
            justifyContent: "center"
        },
        [theme.breakpoints.down("md")]: {
            flexDirection: "column"
        }
    },
    alignEnd: {
        justifyContent: "flex-end"
    },
    chipsContainer: {
        display: "flex",
        flexWrap: "wrap",
        gap: 5,
        margin: theme.spacing(1, 0, 0),
        overflowX: "auto"
    },
    featured: {
        position: "relative",
        "&:after": {
            content: '""',
            position: "absolute",
            left: 0,
            top: 0,
            width: 5,
            height: "100%",
            backgroundColor: theme.palette.primary.main
        }
    },
    mobileListButton: {
        paddingRight: theme.spacing(2) + "!important"
    },
    mobileToggle: {
        display: "flex",
        position: "relative",
        transform: "none",
        justifyContent: "center"
    },
    mobileSubscribeLabel: {
        fontWeight: theme.typography.fontWeightBold as number,
        margin: theme.spacing(0, 0, 2)
    },
    listTitle: {
        fontWeight: theme.typography.fontWeightBold as number,
        marginTop: theme.spacing(1),
        fontSize: "1.25rem",
        lineHeight: 1.6
    },
    gutterBottom: {
        marginBottom: theme.spacing(1)
    },
    listItem: {
        border: `1px solid ${theme.palette.divider}`,
        borderTop: "none"
    },
    fistListItem: {
        borderTop: `1px solid ${theme.palette.divider}`
    },
    childList: {
        display: "block",
        paddingRight: 0 + " !important",
        paddingLeft: theme.spacing(4),
        [theme.breakpoints.down("md")]: {
            paddingLeft: 0
        }
    }
}));

interface GroupCardProps {
    id: string;
    groupName: string;
    alerts: SubscriptionList[];
    subGroups: AlertsGroupMap;
    organization: Organization;
    setRefreshRows?: React.Dispatch<React.SetStateAction<boolean>>;
    handleSubscriptionUpdate?: (subscription: SubscriptionList, subscribedToDefaultGroups: boolean) => void;
}

const AlertTabListGroupCard: React.FC<GroupCardProps> = (props) => {
    const { id, groupName, alerts, subGroups, organization, setRefreshRows, handleSubscriptionUpdate } = props;
    const theme = useTheme();
    const classes = useStyles();
    const isMobileView = useMediaQuery(theme.breakpoints.down("md"));

    const notificationService = useMemo(() => new NotificationsService(), []);
    const [_, userAccountInfo, reloadAccountInfo] = useAccountInfo();

    const numberOfSubsgroups = [...subGroups.entries()].length;

    return (
        <>
            <Typography
                variant="h2"
                id={`${id}-group-title`}
                className={clsx(classes.listTitle, { [classes.gutterBottom]: alerts.length > 0 })}
            >
                {groupName}
            </Typography>

            <List id={`${id}-group-list`} aria-labelledby={`${id}-group-title`}>
                {alerts.map((alert: SubscriptionList, index: number) => (
                    <ListItem
                        key={alert.id}
                        className={clsx({ [classes.mobileListButton]: isMobileView })}
                        classes={{
                            container: clsx(classes.listItem, {
                                [classes.fistListItem]: index === 0,
                                [classes.featured]: alert.isFeatured
                            })
                        }}
                        ListItemTextProps={{
                            primary: alert.name,
                            secondary: (
                                <div>
                                    <Typography variant="body2">
                                        {alert.description}
                                    </Typography>

                                    {alert.tags?.length && alert.tags?.length > 0 ? (
                                        <div className={classes.chipsContainer}>
                                            {alert.tags?.map((tag) => (
                                                <ChipPortal label={tag} key={tag} size="small" />
                                            ))}
                                        </div>
                                    ) : null}
                                </div>
                            )
                        }}
                        itemSecondaryAction={
                            <div className={clsx(classes.toggle, classes.alignEnd)}>
                                <AlertToggle
                                    id={`${alert.aggregateId}-alert-toggle`}
                                    alerts={alerts}
                                    documentType={DocumentType.AlertList}
                                    languages={languages}
                                    notificationService={notificationService}
                                    organization={organization}
                                    subscribed={alert.emailSubscription ?? false}
                                    subscription={alert}
                                    userAccountDetails={userAccountInfo}
                                    reloadAccountDetails={reloadAccountInfo}
                                    handlePendingSubscription={true}
                                    onChangeComplete={(success: boolean, subscribedToDefaultGroups: boolean) => {
                                        if (success) {
                                            alert.emailSubscription = !alert.emailSubscription;
                                            alert.smsSubscription = !alert.smsSubscription;
                                            if (alert.emailSubscription && subscribedToDefaultGroups) {
                                                setRefreshRows?.((prev) => !prev);
                                            } else {
                                                handleSubscriptionUpdate?.(alert, subscribedToDefaultGroups);
                                            }
                                        } else {
                                            handleSubscriptionUpdate?.(alert, subscribedToDefaultGroups);
                                        }
                                    }}
                                />

                                {isMobileView ? (
                                    <Typography className={classes.mobileSubscribeLabel}>Subscribe</Typography>
                                ) : null}
                            </div>
                        }
                        ListItemSecondaryActionProps={{
                            className: isMobileView ? classes.mobileToggle : undefined
                        }}
                    />
                ))}

                {isMobileView && numberOfSubsgroups > 0 ? <Divider orientation="horizontal" /> : null}

                {Array.from(subGroups.entries()).map(([subGroupName, [subAlerts, childrenSubGroups]]) => (
                    <ListItem key={`${subGroupName}-card-key`} className={classes.childList}>
                        <AlertTabListGroupCard
                            id={`${subGroupName.replace(/\s+/g, "-").toLowerCase()}`}
                            groupName={subGroupName}
                            alerts={subAlerts}
                            subGroups={childrenSubGroups}
                            organization={organization}
                            setRefreshRows={setRefreshRows}
                            handleSubscriptionUpdate={handleSubscriptionUpdate}
                            aria-describedby={`${subGroupName}-subgroup-card`}
                        />
                    </ListItem>
                ))}
            </List>
        </>
    );
};

export default AlertTabListGroupCard;
