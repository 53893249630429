import React, { useEffect, useState } from "react";
import { useEmbedStore } from "../../stores/embedStore";
import { useAuth } from "../../components/react-oidc-context";
import { useConfig } from "../../providers/ConfigProvider";
import { User } from "oidc-client-ts";

const EmbedSignOut: React.FC = () => {
    const [isEmbed] = useEmbedStore((state) => [state.isInitialized]);
    const [logoutUrl, setLogoutUrl] = useState<string>("");

    const auth = useAuth();
    const config = useConfig();

    useEffect(() => {
        function crossDomainSignOut(event: any) {
            if (!isEmbed) {
                if (event.data.type === "embededUserSignOutRequest")
                    auth.signinSilent()
                        .then((user: User | null) => {
                            if (user) {
                                const logoutUrl = config.getSignoutUrl(user, "");
                                setLogoutUrl(logoutUrl);
                            } else {
                                if (window.parent) {
                                    window.parent.postMessage({ type: "embededUserSignOutSuccessful" }, "*");
                                }
                            }
                            return;
                        })
                        .catch(() => {
                            if (window.parent) {
                                window.parent.postMessage({ type: "embededUserSignOutSuccessful" }, "*");
                            }
                        });
            }
        }

        window.addEventListener("message", crossDomainSignOut);

        return () => {
            window.removeEventListener("message", crossDomainSignOut);
        };
    }, [auth, config, isEmbed, setLogoutUrl]);

    const onLoaded = () => {
        if (logoutUrl !== "") {
            auth.removeUser();
            auth.clearStaleState();
            if (window.parent) {
                window.parent.postMessage({ type: "embededUserSignOutSuccessful" }, "*");
            }
            setLogoutUrl("");
        }
    };

    return <iframe title="Hidden Signout frame" src={logoutUrl} style={{ display: "none" }} onLoad={onLoaded} />;
};

export default EmbedSignOut;
