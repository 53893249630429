import React, { useState, useEffect, useMemo } from "react";
import { useParams, Link as RouterLink, useNavigate, useSearchParams } from "react-router-dom";
import ApiService from "../../services/apiService";
import Autocomplete, { OptionShape } from "@civicplus/preamble-ui/lib/Autocomplete";
import FilterButton from "../../components/FilterButton";
import Grid from "@civicplus/preamble-ui/lib/Grid";
import HiddenPageAlert from "../../components/HiddenPageAlert";
import Layout from "@civicplus/preamble-ui/lib/Layout";
import Link from "@civicplus/preamble-ui/lib/Link";
import LinkSection, { LinkSectionProps } from "../../components/LinkSection";
import Loader from "@civicplus/preamble-ui/lib/Loader";
import Typography from "@civicplus/preamble-ui/lib/Typography";
import { DocumentType } from "../../types/SearchDocument";
import { HtmlDescription } from "../../components/Layout/HtmlDescription";
import { LinkCardProps } from "../../components/LinkCard/LinkCard";
import { NavigationOption } from "../../types/Organization";
import { shallow } from "zustand/shallow";
import { toggleDisplayFraseChatbot } from "../../shared/functions";
import { useAuth } from "../../providers/AuthProvider";
import { useEmbedStore } from "../../stores/embedStore";
import { useFavorites } from "../../shared/useFavorites";
import { useLinks } from "../../stores/linksStore";
import { useOrganization } from "../../stores/organizationStore";
import { useSnackbar } from "notistack";
import {
    LinksSections,
    CommunitySections,
    PaymentsSections,
    SectionObject,
    Sections,
    ServiceSections,
    SupportSections
} from "../../types/Sections";

interface SectionProps {
    queryTags: OptionShape[];
}

export const SectionWrapper: React.FC = () => {
    const [searchParams] = useSearchParams();
    const allQueryTags = searchParams.getAll("tag") ?? "";
    const queryTags: OptionShape[] = [];

    if (allQueryTags.length > 0) {
        allQueryTags.forEach((tag: string) => {
            if (!queryTags.find((t) => t.value === tag)) {
                queryTags.push({
                    label: tag,
                    value: tag
                });
            }
        });
    }

    return <Section queryTags={queryTags} />;
};

export const Section: React.FC<SectionProps> = ({ queryTags }) => {
    const params = useParams();
    const auth = useAuth();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const { locationName, sectionName } = params;
    const [organization] = useOrganization((state) => [state.organization]);
    const [links, setLinks] = useState<{ [key: string]: LinkSectionProps["links"] }>({});
    const [section, setSection] = useState<SectionObject | undefined>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const isEmbed = useEmbedStore((state) => state.isInitialized);
    const {
        organizationLinks,
        getLinksFromSection,
        fetchSpecificFavorites,
        hasLoadedFavorites,
        fetchLinks,
        fetchLinksStatus
    } = useLinks(
        (state) => ({
            organizationLinks: state.links,
            getLinksFromSection: state.getLinksFromSection,
            fetchSpecificFavorites: state.fetchSpecificFavorites,
            hasLoadedFavorites: state.loadedFavorites,
            fetchLinks: state.fetchLinks,
            fetchLinksStatus: state.fetchLinksStatus
        }),
        shallow
    );
    const [{ linksFavoriteMap }] = useFavorites();
    const [tags, setTags] = useState<OptionShape[]>([]);
    const [selectedTags, setSelectedTags] = useState<OptionShape[]>(() => queryTags);

    useEffect(() => {
        toggleDisplayFraseChatbot(organization, isEmbed || false);
    }, [organization, isEmbed]);

    useEffect(() => {
        const getOrgAndLinks = async () => {
            if (locationName === undefined) {
                return;
            }

            await fetchLinks(locationName, true);
        };
        getOrgAndLinks();
    }, [locationName, fetchLinks]);

    useEffect(() => {
        if (!organizationLinks || !auth.isAuthenticated) {
            return;
        }

        const fetchFavoritesAndSetOrgLinks = async () => {
            if (fetchLinksStatus === "Complete" && auth.user && organization?.id) {
                await fetchSpecificFavorites(auth.user, DocumentType.Link, enqueueSnackbar, organization.id);
            }
        };

        fetchFavoritesAndSetOrgLinks();
    }, [organizationLinks, fetchLinksStatus, auth, fetchSpecificFavorites, enqueueSnackbar, organization?.id]);

    useEffect(() => {
        if (!sectionName) {
            return;
        }

        let sectionEnum: SectionObject | undefined = undefined;

        switch (sectionName.toLowerCase()) {
            case "links":
                sectionEnum = {
                    ...LinksSections,
                    ...CommunitySections,
                    ...ServiceSections,
                    ...SupportSections
                };
                break;
            case "payments":
                sectionEnum = PaymentsSections;
                break;
            default:
                break;
        }

        if (!sectionEnum) {
            setIsLoading(false);
            return;
        }

        if (fetchLinksStatus !== "Complete") {
            return;
        }

        const currentLinks: { [key: string]: LinkSectionProps["links"] } = {};

        Object.keys(sectionEnum).forEach(async (section) => {
            const sectionsLinks = getLinksFromSection(locationName, section);
            currentLinks[section] = [];

            sectionsLinks?.forEach((link) => {
                let toPush: LinkCardProps;

                if (selectedTags.length === 0 || selectedTags.some((s) => link.tags.includes(s["value"] as string))) {
                    toPush = {
                        id: link.id.toString(),
                        aggregateId: link.aggregateId,
                        title: link.title,
                        description: link.description,
                        href: link.url,
                        icon: link.icon,
                        favorite: linksFavoriteMap.get(link.aggregateId) !== undefined,
                        section: link.section,
                        productType: link.productType,
                        organizationId: link.organizationId,
                        tags: link.tags,
                        isFeatured: link.isFeatured,
                        fullLink: link
                    };
                } else return;

                currentLinks[section].push(toPush);

                if (link.isFeatured) {
                    currentLinks["Featured"] = currentLinks["Featured"] || [];
                    currentLinks["Featured"].push(toPush);
                }
            });

            if (section === "Payments") {
                const paymentTypeForms = organizationLinks.filter(
                    (l) =>
                        l.section === Sections.Forms &&
                        l.additionalInformation &&
                        l.additionalInformation?.length > 0 &&
                        ["ePayment", "CP_PAY"].includes(l.additionalInformation[0].value)
                );

                paymentTypeForms.forEach((payForm) => {
                    currentLinks[section].push({
                        id: payForm.id.toString(),
                        aggregateId: payForm.aggregateId,
                        title: payForm.title,
                        description: payForm.description,
                        href: payForm.url,
                        icon: payForm.icon,
                        favorite: linksFavoriteMap.get(payForm.aggregateId) !== undefined,
                        section: Sections.Forms,
                        productType: payForm.productType,
                        additionalInformation: payForm.additionalInformation,
                        organizationId: payForm.organizationId,
                        isFeatured: payForm.isFeatured,
                        fullLink: payForm
                    });
                });
            }
        });

        setSection(sectionEnum);
        setLinks(currentLinks);
        setIsLoading(false);
    }, [
        getLinksFromSection,
        locationName,
        organizationLinks,
        sectionName,
        fetchLinksStatus,
        hasLoadedFavorites,
        linksFavoriteMap,
        selectedTags
    ]);

    useEffect(() => {
        const getTags = async () => {
            const allTags = await ApiService.get({
                url: `${organization?.name}/search/tags?documentType=${DocumentType.Link}`,
                cache: false,
                authUser: auth.user
            });

            allTags.sort((a: string, b: string) => {
                return a.toLowerCase() > b.toLowerCase() ? 1 : -1;
            });

            const convertedTags: OptionShape[] = [];

            if (allTags.length > 0) {
                allTags.forEach((tag: string) => {
                    convertedTags.push({ label: tag, value: tag });
                });

                setTags(convertedTags);
            }
        };

        if (organization && organization.name) {
            getTags();
        }
    }, [auth.user, organization]);

    const onChangeTag = (selected: OptionShape[] | undefined) => {
        selected ? setSelectedTags(selected) : setSelectedTags([]);
    };

    useEffect(
        function updateUrlForNewTagsAndKeyword() {
            if (isEmbed) {
                return;
            }

            const url = window.location.href.split("?");

            if (selectedTags.length > 0) {
                let newUrl = `${url[0]}?`;

                selectedTags.forEach((tag, index) => {
                    if (index !== 0) newUrl = newUrl.concat("&");
                    newUrl = newUrl.concat(`tag=${tag.value}`);
                });

                window.history.replaceState({}, "", newUrl);
            } else {
                window.history.replaceState({}, "", `${url[0]}`);
            }
        },
        [isEmbed, selectedTags]
    );

    useEffect(() => {
        if (!isLoading && !section) {
            navigate(`/${locationName}/links`);
        }
    }, [isLoading, navigate, section, locationName]);

    const navOption = useMemo(() => {
        switch (sectionName?.toLowerCase()) {
            case "payments":
                return NavigationOption.Payments;
            case "links":
            default:
                return NavigationOption.Links;
        }
    }, [sectionName]);

    const title = useMemo(() => {
        if (!section) {
            return "";
        }

        if (Object.values(section)[0] === "General") {
            return "Links";
        }

        return Object.values(section)[0];
    }, [section]);

    const linkSection = useMemo(() => {
        if (!section) {
            return null;
        }

        let totalLinks = 0;

        return Object.keys(section).map((key, index) => {
            const sectionTitle = section[key];

            if (links[key]) {
                totalLinks += links[key].length;
            }

            if (index === Object.keys(section).length - 1 && totalLinks === 0) {
                return (
                    <Grid container={true} justifyContent="center" key={`section${index}`}>
                        <Grid>
                            <Typography variant="h5" style={{ marginTop: 40 }}>
                                Currently there are no links for this section.
                            </Typography>
                        </Grid>
                    </Grid>
                );
            }

            return (
                <>
                    {links[key].length > 0 ? (
                        <Grid key={`section${index}`} xs={12}>
                            <LinkSection
                                id={`section-${index}`}
                                title={sectionTitle !== title ? sectionTitle : ""}
                                links={links[key] ?? []}
                                orgName={organization?.name}
                                favorites={linksFavoriteMap}
                            />
                        </Grid>
                    ) : (
                        <></>
                    )}
                </>
            );
        });
    }, [links, section, title, organization, linksFavoriteMap]);

    const breadcrumbs = useMemo(
        () => [
            <Link key="home" to={`/${organization?.name}`} component={RouterLink}>
                Home
            </Link>
        ],
        [organization?.name]
    );

    const navigationOption = useMemo(() => {
        if (sectionName?.toLowerCase() === "payments") {
            return NavigationOption.Payments;
        }
        return NavigationOption.Links;
    }, [sectionName]);

    const navigationItem = organization?.navigation && organization?.navigation[navigationOption];

    if (isLoading) {
        return <Loader verticallyCenter={true} />;
    }

    if (!section) {
        return null;
    }

    return (
        <Layout
            TitlebarProps={{
                id: "section-titlebar",
                title: navigationItem ? navigationItem.customLabel ?? navigationItem.defaultLabel : "Links",
                breadcrumbs: breadcrumbs,
                description: <HtmlDescription description={navigationItem?.additionalProductInformation} />
            }}
        >
            <>
                <HiddenPageAlert navOption={navOption} />

                {navigationOption === NavigationOption.Links ? (
                    <Grid xs={12}>
                        <Grid container={true} justifyContent="flex-end">
                            <Grid>
                                <FilterButton
                                    id="links-filter"
                                    activeFilters={selectedTags.length}
                                    onFilterReset={() => setSelectedTags([])}
                                >
                                    <Autocomplete
                                        id="links-tag-filter"
                                        label="Tags"
                                        onChange={onChangeTag}
                                        value={selectedTags}
                                        options={tags}
                                        multiSelect={true}
                                        margin={false}
                                        fullWidth={true}
                                        isInDialog={true}
                                    />
                                </FilterButton>
                            </Grid>
                        </Grid>
                    </Grid>
                ) : null}

                <>
                    {links["Featured"] && links["Featured"].length > 0 ? (
                        <Grid key="section-featured" xs={12}>
                            <LinkSection
                                id="section-featured"
                                title={Sections.Featured}
                                links={links["Featured"] ?? []}
                                orgName={organization?.name}
                                favorites={linksFavoriteMap}
                            />
                        </Grid>
                    ) : (
                        <></>
                    )}
                </>

                {linkSection}
            </>
        </Layout>
    );
};
