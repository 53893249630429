import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Button from "@civicplus/preamble-ui/lib/Button";
import Dialog from "@civicplus/preamble-ui/lib/Dialog";
import { DirtyStateDialog } from "../../DirtyState";
import Grid from "@civicplus/preamble-ui/lib/Grid";
import Icon from "@mdi/react";
import TextInput from "@civicplus/preamble-ui/lib/TextInput";
import enhanceWithValidation, {
    requiredValidation,
    maxLengthValidation,
    ValidationResult
} from "@civicplus/preamble-ui/lib/Validations";
import InputLabel from "@civicplus/preamble-ui/lib/InputLabel";
import ButtonGroup from "@civicplus/preamble-ui/lib/ButtonGroup";
import RichText, { RichTextProps } from "@civicplus/preamble-ui/lib/RichText";
import { NavigationItem } from "../../../types/Organization";
import { getIconSetForNavigation } from "./NavigationIcons";
import { mdiArrowULeftTop } from "@mdi/js";
import { makeStyles } from "@civicplus/preamble-ui/lib/Utilities/ThemeHelper";
import FormGroup from "@civicplus/preamble-ui/lib/FormGroup";
import Toggle from "@civicplus/preamble-ui/lib/Toggle";
import "../../../shared/cpCharCounter";
import { useSnackbar } from "notistack";
const useStyles = makeStyles((theme) => ({
    formIconLabel: {
        margin: theme.spacing(1, 0),
        fontWeight: theme.typography.fontWeightBold as "bold"
    }
}));

const EnhancedTextInput = enhanceWithValidation(TextInput);

interface NavigationDialogProps {
    item: NavigationItem;
    isOpen: boolean;
    onClose: () => void;
    onSave: (item: NavigationItem) => Promise<void>;
}

export const NavigationDialog: React.FC<NavigationDialogProps> = (props) => {
    const { item, isOpen, onClose, onSave } = props;

    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();
    const [formState, setFormState] = useState(item);
    const [isLeavingWithoutSaving, setIsLeavingWithoutSaving] = useState<boolean>(false);
    const titleInputRef = useRef<{ validate: () => Promise<ValidationResult> }>();

    const icons = getIconSetForNavigation(item.type);

    useEffect(() => {
        const isValidIcon = icons.some((i) => i.key === item.icon);
        if (!isValidIcon) {
            setFormState((prev) => ({
                ...prev,
                icon: icons[0].key
            }));
        }
    }, [item.icon, icons]);

    const isDirty = useMemo(() => {
        // compare every field in the form to the original form state
        return Object.keys(formState).some((key) => {
            const navigationKey = key as keyof NavigationItem;
            return formState[navigationKey] !== item[navigationKey];
        });
    }, [formState, item]);

    const handleSubmit = useCallback(async () => {
        if (!titleInputRef.current) {
            return;
        }

        const validationResults: ValidationResult = await titleInputRef.current.validate();

        if (validationResults.error) {
            enqueueSnackbar("Please correct the errors on the form before submitting.", {
                variant: "error",
                persist: true
            });
            return;
        }

        onSave(formState);
    }, [onSave, enqueueSnackbar, formState]);

    const onCloseDialog = useCallback(() => {
        if (isDirty) {
            setIsLeavingWithoutSaving(true);
        } else {
            onClose();
        }
    }, [isDirty, onClose]);

    const handleFormChange =
        (key: string) => (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, checked?: boolean) => {
            setFormState((prev) => ({
                ...prev,
                [key]: checked ?? event.target.value
            }));
        };

    const handleSetDefaultLabel = () => {
        setFormState((prev) => ({
            ...prev,
            customLabel: prev.defaultLabel
        }));
    };

    const handleSetIcon = (icon: string) => {
        setFormState((prev) => ({
            ...prev,
            icon
        }));
    };

    return (
        <>
            <Dialog
                open={isOpen}
                title={`Edit ${item.defaultLabel}`}
                onClose={onCloseDialog}
                actions={[
                    <Button color="primary" onClick={handleSubmit} id="modal-update-button" key="update-link-btn">
                        Update
                    </Button>,
                    <Button onClick={onCloseDialog} key="cancel">
                        Cancel
                    </Button>
                ]}
            >
                <Grid container={true}>
                    <Grid xs={12}>
                        <EnhancedTextInput
                            id="form-default-label"
                            label="Default Label"
                            value={formState.defaultLabel}
                            onChange={handleFormChange("defaultLabel")}
                            fullWidth={true}
                            disabled={true}
                        />
                    </Grid>

                    <Grid xs={12}>
                        <EnhancedTextInput
                            ref={titleInputRef}
                            id="form-page-title"
                            label="Page Title"
                            value={formState.customLabel || ""}
                            onChange={handleFormChange("customLabel")}
                            fullWidth={true}
                            required={true}
                            maxLength={25}
                            validations={[requiredValidation, maxLengthValidation]}
                            helperText="Use this field to customize the text on the link in the navigation."
                            endAdornment={
                                <Button
                                    type="icon"
                                    title="Copy default label to page title"
                                    onClick={handleSetDefaultLabel}
                                >
                                    <Icon size={1} path={mdiArrowULeftTop} />
                                </Button>
                            }
                        />
                    </Grid>

                    <Grid xs={12}>
                        <InputLabel focused={true} shrink={true} className={classes.formIconLabel}>
                            Select an icon
                        </InputLabel>

                        <ButtonGroup id="form-icon-group">
                            {icons.map((icon) => {
                                const isSelected = icon.key === formState.icon;
                                return (
                                    <Button
                                        key={`icon-${icon.key}`}
                                        type="icon"
                                        color={isSelected ? "secondary" : "default"}
                                        aria-pressed={isSelected}
                                        onClick={() => {
                                            handleSetIcon(icon.key);
                                        }}
                                    >
                                        <Icon size={1} path={icon.path} />
                                    </Button>
                                );
                            })}
                        </ButtonGroup>
                    </Grid>
                    <Grid xs={12}>
                        <FormGroup>
                            <InputLabel
                                htmlFor="form-is-active"
                                focused={true}
                                shrink={true}
                                className={classes.formIconLabel}
                            >
                                Visibility
                            </InputLabel>

                            <Toggle
                                id="form-is-active"
                                checked={formState.isActive}
                                onChange={handleFormChange("isActive")}
                                label={`Navigation item is ${formState.isActive ? "visible" : "hidden"}`}
                            />
                        </FormGroup>
                    </Grid>
                    <Grid xs={12}>
                        <RichText
                            id="form-additional-info"
                            label="Additional Information"
                            value={formState.additionalProductInformation}
                            onChange={handleFormChange("additionalProductInformation")}
                            helperText="Use this field to add a description to your page for any additional context or content you require for your users."
                            toolbarButtons={
                                {
                                    moreText: {
                                        align: "left",
                                        buttonsVisible: 2,
                                        buttons: ["bold", "italic", "underline"]
                                    },
                                    moreRich: {
                                        align: "left",
                                        buttonsVisible: 2,
                                        buttons: ["insertLink"]
                                    }
                                } as RichTextProps["toolbarButtons"]
                            }
                            config={{
                                attribution: false,
                                charCounterCount: true,
                                countHTMLCharacters: true,
                                charCounterMax: 2500,
                                quickInsertEnabled: false,
                                enter: "<br />"
                            }}
                        />
                    </Grid>
                </Grid>
            </Dialog>

            <DirtyStateDialog
                isOpen={isLeavingWithoutSaving}
                onDiscardChanges={() => {
                    onClose();
                }}
                onReturnToContent={() => {
                    setIsLeavingWithoutSaving(false);
                }}
            />
        </>
    );
};
