import { makeStyles } from "@civicplus/preamble-ui/lib/Utilities/ThemeHelper";

const useGlobalStyles = makeStyles((theme) => ({
    alignedCenter: {
        display: "flex",
        alignItems: "center"
    },
    justifiedFlexEnd: {
        display: "flex",
        justifyContent: "flex-end"
    }
}));

export default useGlobalStyles;
