import React from "react";
import { makeStyles, PreambleTheme } from "@civicplus/preamble-ui/lib/Utilities/ThemeHelper";
import Typography from "@civicplus/preamble-ui/lib/Typography";
import Button from "@civicplus/preamble-ui/lib/Button";
import Alert from "@civicplus/preamble-ui/lib/Alert";
import { useSnackbar, SnackbarContent, CustomContentProps } from "notistack";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CloseIcon from "@mui/icons-material/Close";
import Collapse from "@mui/material/Collapse";
import clsx from "clsx";
import ButtonGroup from "@civicplus/preamble-ui/lib/ButtonGroup";

const useStyles = makeStyles((theme: PreambleTheme) => ({
    root: {
        maxWidth: "500px"
    },
    actionRoot: {
        gap: theme.spacing(1),
        justifyContent: "space-between",
        display: "flex",
        alignItems: "center"
    },
    message: {
        overflow: "hidden",
        padding: 0
    },
    expand: {
        transform: "rotate(0deg)",
        transition: "all .2s"
    },
    expandOpen: {
        transform: "rotate(180deg)"
    }
}));

type ErrorProps = CustomContentProps;

const ErrorVariant = React.forwardRef<HTMLDivElement, ErrorProps>((props, ref) => {
    const { id, message } = props;

    const { closeSnackbar } = useSnackbar();
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(false);

    const handleExpandClick = React.useCallback(() => {
        setExpanded((oldExpanded) => !oldExpanded);
    }, []);

    const handleDismiss = React.useCallback(() => {
        closeSnackbar(id);
    }, [id, closeSnackbar]);

    return (
        <SnackbarContent ref={ref}>
            <Alert
                severity="error"
                classes={{
                    root: classes.root,
                    message: classes.message
                }}
            >
                <div className={classes.actionRoot}>
                    <Typography variant="body1" sx={{ lineHeight: 1.2, paddingY: 1, flex: 1 }}>
                        We have encountered an issue completing your request.
                    </Typography>
                    <ButtonGroup>
                        <Button
                            title="Show details"
                            type="icon"
                            className={clsx(classes.expand, {
                                [classes.expandOpen]: expanded
                            })}
                            onClick={handleExpandClick}
                        >
                            <ExpandMoreIcon fontSize="small" />
                        </Button>

                        <Button
                            data-testid="dismiss-message-btn"
                            title="Dismiss message"
                            type="icon"
                            onClick={handleDismiss}
                        >
                            <CloseIcon fontSize="small" />
                        </Button>
                    </ButtonGroup>
                </div>
                <Collapse in={expanded} timeout="auto">
                    <Typography gutterBottom={true} variant="body2" color="secondary">
                        {message}
                    </Typography>
                </Collapse>
            </Alert>
        </SnackbarContent>
    );
});

ErrorVariant.displayName = "ErrorVariant";

export default ErrorVariant;
