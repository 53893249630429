import { Organization } from "../types/Organization";
import { User } from "oidc-client-ts";
import ApiService from "../services/apiService";

export const getIsSuperUser = (user: User, superUserRole: string): boolean => {
    const profile = user.profile;
    return Array.isArray(profile.role) ? profile.role.some((x) => x === superUserRole) : profile.role === superUserRole;
};

export const getIsOwnerOfOrganization = (user: User, organization: Organization): boolean => {
    return organization.organizationOwners.find((x) => x.userId === user.profile.sub) !== undefined;
};

export const isSuperUserOrOrganizationOwner = (
    user: User,
    organization: Organization,
    superUserRole: string
): boolean => {
    return getIsSuperUser(user, superUserRole) || getIsOwnerOfOrganization(user, organization);
};

export const getHasGroupMemberAccess = async (user: User, organization: Organization): Promise<boolean> => {
    const hasGroupAccess = await ApiService.get({
        url: `${organization?.name}/permissions/groupAccess`,
        authUser: user
    });
    return hasGroupAccess;
};
