import React from "react";
import { SimpleDialog } from "../SimpleDialog/SimpleDialog";
import Typography from "@civicplus/preamble-ui/lib/Typography";

interface LoginNoticeDialogProps {
    isOpen: boolean;
    handleLogin: () => void;
    onClose: () => void;
}

export const LoginNoticeDialog: React.FC<LoginNoticeDialogProps> = (props: LoginNoticeDialogProps) => {
    return (
        <SimpleDialog
            isOpen={props.isOpen}
            title="Sign in or create an account"
            onClose={props.onClose}
            primaryBtnText="Sign In or Create an Account"
            primaryAction={props.handleLogin}
        >
            <Typography>You must be signed in to save a bookmark.</Typography>
        </SimpleDialog>
    );
};
