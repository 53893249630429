import React from "react";
import Loader from "@civicplus/preamble-ui/lib/Loader/Loader";
import { useAuth } from "../../providers/AuthProvider";
import { useLocation, useSearchParams } from "react-router-dom";

export const SignIn: React.FC = () => {
    const auth = useAuth();
    const location = useLocation();
    const [searchParams] = useSearchParams();

    if (!auth.isLoading) {
        const from: string = (location.state as any)?.from?.pathname || "/";
        const isEmbed = searchParams.get("isEmbed");
        const acr = searchParams.get("acr_values") ?? undefined;
        auth.settings.acr_values = acr;

        auth.signinRedirect({
            state: {
                from: from,
                isEmbed: isEmbed === "true"
            },
            acr_values: acr
        });
    }

    return <Loader verticallyCenter={true} />;
};
