import { Link } from "../../types/Link";
import { OptionShape } from "@civicplus/preamble-ui/lib/Autocomplete";
import { DocumentType } from "../../types/SearchDocument";
import { User as AuthUser } from "oidc-client-ts";
import ApiService from "../../services/apiService";

export interface EditLinkDialogProps {
    isOpen: boolean;
    onClose: () => void;
    onSave: (externalList: boolean, link?: Link) => void;
    orgName: string;
    linkToEdit?: Link;
    disableSectionSelect?: boolean;
    title?: string;
    documentType?: DocumentType;
    showFeatureOption: boolean;
}

export interface sectionsType {
    label: string;
    options: {
        label: string;
        value: string | number;
    }[];
}

export interface DateTimeRangeValueType {
    from: Date | undefined;
    to: Date | undefined;
}

export function GetSectionValue(section: string, allSectionOptions: sectionsType[]): string | undefined {
    let value = undefined;
    allSectionOptions.find((secOption) =>
        secOption.options.find((option) => {
            if (section && option.value.toString().toUpperCase() === section.toUpperCase()) {
                value = option;
            }
            return null;
        })
    );

    return value;
}

export function FormatTags(tagNamesList: string[] | undefined): OptionShape[] {
    const tags: OptionShape[] = [];
    if (tagNamesList && tagNamesList.length > 0) {
        tagNamesList.forEach((option: string) => {
            tags.push({ label: option, value: option, name:"tag" });
        });
    }
    return tags;
}

export async function getTags(orgName: string | undefined, authUser: AuthUser | null | undefined, documentType : DocumentType): Promise<string[]> {
    const allTags: string[] = await ApiService.get({
        url: `${orgName}/search/tags?documentType=${documentType}`,
        cache: false,
        authUser: authUser
    });

    return allTags.sort((a: string, b: string) => {
        return a.toLowerCase() > b.toLowerCase() ? 1 : -1;
    });
}

export function arrayEquality(a: string[], b: string[]): boolean {
    return Array.isArray(a) && Array.isArray(b) && a.length === b.length && a.every((val, index) => val === b[index]);
}
