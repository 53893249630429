/* eslint-disable @typescript-eslint/no-explicit-any */
import HtmlParser from "html-react-parser";
import Purify from "dompurify";
import { chatbotOrgs } from "./constants";
import { CppOrganization } from "@civicplus/preamble-ui/lib/Services/OrgService/Types/Organization";
import { CppOrganizationProductType, ProductType } from "../types/ProductType";
import { Dictionary } from "../types/Content";
import { Location } from "../types/Location";
import { NavigationDictionary, NavigationOption, Organization, OrgApplicationDto } from "../types/Organization";
import { User as AuthUser } from "oidc-client-ts";

export const toggleDisplayFraseChatbot = (
    organization: Organization | Location | undefined,
    isEmbed: boolean,
    shouldHide?: boolean
): void => {
    if (shouldHide) return;

    const isAvailableForOrg = (organization?.name && chatbotOrgs.includes(organization?.name.toLowerCase())) as boolean;

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (!isEmbed && window && (window.frase as unknown)) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const fraseChatbot = window.frase;

        if (fraseChatbot.ready || fraseChatbot.active) {
            fraseChatbot.settings = {
                display: isAvailableForOrg
            };

            if (isAvailableForOrg) {
                fraseChatbot.widget.show();
            } else {
                fraseChatbot.widget.hide();
            }
        }
    }
};

export function sanitizeAndParseStringToHtml(toParse: string | undefined): string | JSX.Element | JSX.Element[] {
    // See: https://github.com/cure53/DOMPurify/issues/317#issuecomment-698800327
    Purify.addHook("afterSanitizeAttributes", function (node) {
        // set all elements owning target to target=_blank
        if ("target" in node) {
            node.setAttribute("target", "_blank");
            node.setAttribute("rel", "noopener");
        }
    });
    return HtmlParser(Purify.sanitize(toParse ?? ""));
}

export function scrollTo(element: HTMLElement, container?: HTMLElement, timeout?: number): void {
    const y = element.offsetTop + element.offsetHeight;
    const ctn = container ? container : window;

    if (timeout) {
        setTimeout(
            () => {
                ctn.scrollTo({ top: y, behavior: "smooth" });
            },
            timeout,
            ctn,
            y
        );
    } else {
        ctn.scrollTo({ top: y, behavior: "smooth" });
    }
}

export const toPascalCase = (word: string): string => {
    return `${word}`
        .replace(new RegExp(/[-_]+/, "g"), " ")
        .replace(new RegExp(/[^\w\s]/, "g"), "")
        .replace(new RegExp(/\s+(.)(\w*)/, "g"), ($1, $2, $3) => `${$2.toUpperCase() + $3.toLowerCase()}`)
        .replace(new RegExp(/\w/), (s) => s.toUpperCase());
};

export const renderOrgName = (org: Organization | Location): string | number => {
    if ("settings" in org && org?.settings?.brandingSettings?.friendlyName) {
        return org.settings.brandingSettings.friendlyName;
    }
    if (org?.name) {
        return org.name;
    }
    if ("city" in org && org?.city) {
        return org?.city;
    }

    return org?.id;
};

export const orderByProperty = (array: any[], property: string, desc?: boolean): any[] => {
    const orderedArray = array.sort((a, b) => {
        if (a[property] < b[property]) {
            return -1;
        }
        if (a[property] > b[property]) {
            return 1;
        }
        return 0;
    });

    return desc ? orderedArray.reverse() : orderedArray;
};

export const orderByDateProperty = (array: any[], property: string, desc?: boolean): any[] => {
    const orderedArray = array.sort((a, b) => {
        if (new Date(a[property]) < new Date(b[property])) {
            return -1;
        }
        if (new Date(a[property]) > new Date(b[property])) {
            return 1;
        }
        return 0;
    });

    return desc ? orderedArray.reverse() : orderedArray;
};

export function sortByBooleanAndDate(array: any[], booleanProperty: string, dateProperty: string): any[] {
    return array.sort((a, b) => {
        const booleanComparison = a[booleanProperty] === b[booleanProperty] ? 0 : a[booleanProperty] ? -1 : 1;

        if (booleanComparison === 0) {
            if (new Date(a[dateProperty]) < new Date(b[dateProperty])) {
                return -1;
            }
            if (new Date(a[dateProperty]) > new Date(b[dateProperty])) {
                return 1;
            }
            return 0;
        }

        return booleanComparison;
    });
}

export const stringHasValue = (string: string | undefined): boolean => {
    if (string === undefined || string === "" || string === null) {
        return false;
    }

    return true;
};

export const filterUniqueItemsByProperty = (array: any[], key: string): any[] => {
    // TODO: Typescript issue
    return [...new Map(array.map((item: any) => [item[key], item])).values()];
};

/**
 *
 * @param rowValue - Value for row
 * @param filteredValues - List of selected filters
 * @param valueMapping - Dictionary mapping Row Values to Filter Values
 * @returns true if row should be hidden
 */
export const filterLogic = (
    rowValue: string,
    filteredValues: string[],
    valueMapping: Dictionary | null = null
): boolean => {
    if (!Array.isArray(filteredValues)) {
        return true;
    }

    if (Array.isArray(rowValue)) {
        return filteredValues.length > 0 && !filteredValues.map((x) => x).includes(rowValue[0]);
    }

    if (valueMapping && valueMapping[rowValue]) {
        rowValue = valueMapping[rowValue];
    }

    return (
        filteredValues.length > 0 &&
        !filteredValues.map((x) => x.toLowerCase()).includes(rowValue.toString().toLowerCase())
    );
};

export const randomIntFromInterval = (min: number, max: number): number => {
    return Math.floor(Math.random() * (max - min + 1) + min);
};

export const isStaffUser = (user: AuthUser | undefined | null): boolean => {
    if (user && user.profile.name?.includes("@civicplus.com")) {
        return true;
    }

    return false;
};

export const getCustomOrDefaultLabel = (enhancedApplication: OrgApplicationDto, defaultLabel: string): string => {
    return (enhancedApplication.customLabel =
        enhancedApplication.customLabel !== undefined && enhancedApplication.customLabel !== null
            ? enhancedApplication.customLabel
            : defaultLabel);
};

export const getProductTypeLabels = (productType: ProductType): string => {
    switch (productType) {
        case ProductType.CivicPlusPortal:
            return "Resident Portal";
        case ProductType.CivicPlusOrganizations:
            return "Staff Portal";
        case ProductType.CivicClerk:
            return "Meetings Select";
        case ProductType.CivicEngageEvolve:
            return "Web Evolve";
        case ProductType.CivicEngageCentral:
            return "Web Central";
        case ProductType.CivicOptimize:
            return "Process Automation";
        case ProductType.CivicReady:
            return "Mass Notification";
        case ProductType.CivicRec:
            return "Recreation Management";
        case ProductType.SeeClickFix:
            return "311 CRM";
        case ProductType.Notifications:
            return "Notifications Admin";
        case ProductType.MunicodeNEXT:
            return "Online Code Hosting";
        case ProductType.CivicEngageOpen:
            return "Web Open";
        default:
            return "";
    }
};

export const convertProductTypeToCppOrganizationProductType = (
    productType: ProductType
): CppOrganizationProductType => {
    switch (productType) {
        case ProductType.CivicEngageCentral:
            return CppOrganizationProductType.CivicEngageCentral;
        case ProductType.CivicReady:
            return CppOrganizationProductType.CivicReady;
        case ProductType.CivicRec:
            return CppOrganizationProductType.CivicRec;
        case ProductType.HCMS:
            return CppOrganizationProductType.HCMS;
        case ProductType.CpPay:
            return CppOrganizationProductType.CpPay;
        case ProductType.CivicEngageEvolve:
            return CppOrganizationProductType.CivicEngageEvolve;
        case ProductType.CivicClerk:
            return CppOrganizationProductType.CivicClerk;
        case ProductType.Notifications:
            return CppOrganizationProductType.Notifications;
        case ProductType.CivicPlusIntegrationHub:
            return CppOrganizationProductType.CivicPlusIntegrationHub;
        case ProductType.SeeClickFix:
            return CppOrganizationProductType.SeeClickFix;
        case ProductType.ArchiveSocial:
            return CppOrganizationProductType.ArchiveSocial;
        case ProductType.CivicEngageOpen:
            return CppOrganizationProductType.CivicEngageOpen;
        case ProductType.CivicGov:
            return CppOrganizationProductType.CivicGov;
        case ProductType.CivicOptimize:
            return CppOrganizationProductType.CivicOptimize;
        case ProductType.CivicPlusOrganizations:
            return CppOrganizationProductType.CivicPlusOrganizations;
        case ProductType.CivicPlusPortal:
            return CppOrganizationProductType.CivicPlusPortal;
        case ProductType.Monsido:
            return CppOrganizationProductType.Monsido;
        case ProductType.MunicodeMeetings:
            return CppOrganizationProductType.MunicodeMeetings;
        case ProductType.MunicodeNEXT:
            return CppOrganizationProductType.MunicodeNEXT;
        case ProductType.NextRequest:
            return CppOrganizationProductType.NextRequest;
        default:
            return CppOrganizationProductType.CivicPlusPortal;
    }
};

export const WebsiteProductTypes = [
    ProductType.CivicEngageCentral,
    ProductType.HCMS,
    ProductType.CivicEngageOpen,
    ProductType.CivicEngageEvolve
];

export function omit<T extends object>(obj: T, paths: Array<keyof T>): Omit<T, keyof T> {
    const ret = Object.create(null);
    Object.entries(obj).forEach(([key, value], index) => {
        if (!paths.includes(key as keyof T)) {
            ret[key] = value;
        }
    });
    return ret;
}

export function getCppOrganizationFromOrganization(organization: Organization): CppOrganization {
    return {
        ...omit<Organization>(organization, ["applications"]),
        applications: organization.applications?.map((x) => {
            return {
                ...omit<OrgApplicationDto>(x, ["productType"]),
                productType: convertProductTypeToCppOrganizationProductType(x.productType)
            };
        })
    } as CppOrganization;
}

export function getLocallyStoredOrgName(): string | null {
    return localStorage?.getItem("organization:Portal");
}

export function transferItemsIfConditionMet(
    source: unknown[],
    target: unknown[],
    condition: (x: any) => boolean
): void {
    for (let i = source.length - 1; i >= 0; i--) {
        if (condition(source[i])) {
            target.push(source[i]);
            source.splice(i, 1);
        }
    }
}

export function divergenceBetweenArrays(firstArray: unknown[], otherArray: unknown[]): unknown[] {
    return firstArray.filter((x) => !otherArray.includes(x));
}

export function getCurrentTabFromTabName(tabName?: string): number {
    if (!tabName) {
        return 0;
    }

    function getTabOffset(position: number) {
        // future offset values
        return position;
    }

    switch (tabName.toLowerCase()) {
        case "links":
            return 0;
        case "forms":
            return 1;
        case "notifications":
            return 2;
        case "meetings":
            return getTabOffset(3);
        case "alerts":
            return getTabOffset(4);
        case "legislation":
            return getTabOffset(5);
        case "applications":
        case "navigation":
            return getTabOffset(6);
        case "permissions":
            return getTabOffset(7);
        default:
            return 0;
    }
}

export const parseJwt = (token: string): any => {
    const base64Url = token.split(".")[1];

    if (base64Url) {
        const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
        const jsonPayload = decodeURIComponent(
            window
                .atob(base64)
                .split("")
                .map(function (c) {
                    return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
                })
                .join("")
        );

        return JSON.parse(jsonPayload);
    }
};

export const nextDate = (days: number): Date => {
    return new Date(Date.now() + days * 24 * 60 * 60 * 1000);
};

export const isNullOrUndefined = (value: unknown): boolean => {
    return value === null || value === undefined;
};

export const checkIfPageIsHidden = (navOption: NavigationOption, orgNavigation?: NavigationDictionary): boolean => {
    if (navOption === NavigationOption.Dashboard) {
        return false;
    }

    if (!orgNavigation) {
        return false;
    }

    const isActive = orgNavigation[navOption] ? orgNavigation[navOption].isActive : false;
    const isHidden = isActive === undefined ? false : !isActive;

    return isHidden;
};

export const formatSecondsToMMSS = (totalSeconds: number): string => {
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
};
