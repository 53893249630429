import React, { useEffect, useMemo, useState, useCallback } from "react";
import Autocomplete, { OptionShape } from "@civicplus/preamble-ui/lib/Autocomplete";
import Button from "@civicplus/preamble-ui/lib/Button";
import ButtonDropDown from "@civicplus/preamble-ui/lib/ButtonDropDown";
import ButtonGroup from "@civicplus/preamble-ui/lib/ButtonGroup";
import Checkbox from "@civicplus/preamble-ui/lib/Checkbox";
import clsx from "clsx";
import FilterButton from "../../../../../components/FilterButton";
import FormGroup from "@civicplus/preamble-ui/lib/FormGroup";
import Grid from "@civicplus/preamble-ui/lib/Grid";
import InputLabel from "@civicplus/preamble-ui/lib/InputLabel";
import NotificationsList from "../../../../../components/Notifications/NotificationsList";
import NotificationsListGroups from "../../../../../components/Notifications/NotificationsListGroups";
import SearchInput from "@civicplus/preamble-ui/lib/SearchInput";
import TablePagination from "@civicplus/preamble-ui/lib/TablePagination";
import Typography from "@civicplus/preamble-ui/lib/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import useStyles from "../../styles";
import { ApiError, parseErrorMessage } from "../../../../../services/apiService";
import { DocumentType } from "../../../../../types/SearchDocument";
import { FormatTags, getTags } from "../../../../../components/EditLinkDialog/EditLinkUtilities";
import { GAevent } from "../../../../../services/googleAnalyticsService";
import { NotificationGroupType } from "../../../../../components/Notifications/utils";
import { NotificationsService } from "../../../../../services/notificationsService";
import { ProductType } from "../../../../../types/ProductType";
import { Organization } from "../../../../../types/Organization";
import { SubscriptionList, SubscriptionType } from "../../../../../types/Subscription";
import { useTheme } from "@mui/material/styles";
import { useAuth } from "../../../../../providers/AuthProvider";
import { useEmbedStore } from "../../../../../stores/embedStore";
import { useFeatures } from "../../../../../shared/useFeatureFlags";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useOrganization } from "../../../../../stores/organizationStore";
import { useDebounce } from "use-debounce";
import { WebsiteProductTypes, getProductTypeLabels, isStaffUser } from "../../../../../shared/functions";
import {
    filterByFeatured,
    filterByName,
    filterBySource,
    filterBySubscription,
    filterByTags,
    generateQueryString
} from "./utils";

interface NotificationsTabProps {
    onShowContactDialog?: () => void;
}

export const NotificationPageWrapper: React.FC<NotificationsTabProps> = ({ onShowContactDialog }) => {
    const [searchParams] = useSearchParams();

    const notificationService = useMemo(() => new NotificationsService(), []);

    const lowerCaseSearchParams = useMemo(() => {
        return new URLSearchParams([...searchParams].map(([key, value]) => [key.toLowerCase(), value]));
    }, [searchParams]);

    const keyword = lowerCaseSearchParams.get("keyword") ?? "";

    const filter = useMemo(() => {
        const filterOptions = ["type", "featured", "tag", "source", "showActive"];
        const filterTerms: OptionShape[] = [];

        filterOptions.forEach((type) => {
            const params = lowerCaseSearchParams.getAll(type);

            if (params && params.length > 0) {
                params.forEach((param) => {
                    filterTerms.push({ label: param, name: type, value: param });
                });
            }
        });

        return filterTerms;
    }, [lowerCaseSearchParams]);

    return (
        <NotificationsPage
            notificationService={notificationService}
            searchQuery={keyword}
            filterQuery={filter}
            onShowContactDialog={onShowContactDialog}
        />
    );
};

interface NotificationsPageProps {
    organization?: Organization;
    notificationService: NotificationsService;
    searchQuery?: string;
    filterQuery?: OptionShape[];
    onShowContactDialog?: () => void;
}

export type FilterType = {
    selectedTags: OptionShape[];
    selectedIsFeatured: OptionShape[];
    selectedSubscription: OptionShape[];
    selectedSource: OptionShape[];
    showActive: boolean[];
};

export const NotificationsPage: React.FC<NotificationsPageProps> = ({
    notificationService,
    searchQuery,
    filterQuery,
    onShowContactDialog
}) => {
    const classes = useStyles();
    const auth = useAuth();
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobileView = useMediaQuery(theme.breakpoints.down("md"));
    const { enqueueSnackbar } = useSnackbar();

    const [reloadingAllSubs, setReloadingAllSubs] = useState(false);
    const [tags, setTags] = useState<OptionShape[]>();
    const [tagsLoaded, setTagsLoaded] = useState(false);
    const [searchText, setSearchText] = useState(searchQuery);
    const [filterState, setFilterState] = useState<FilterType>({
        selectedTags: [],
        selectedIsFeatured: [],
        selectedSubscription: [],
        selectedSource: [],
        showActive: []
    });

    const [notifications, setNotifications] = useState<SubscriptionList[]>();
    const [currentPage, setCurrentPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [disableManageBtn, setDisableManageBtn] = useState(false);
    const [unsubscribeToAllPending, setUnsubscribeToAllPending] = useState(false);
    const [isFetchingSubscriptions, setIsFetchingSubscriptions] = useState(true);

    const [flags] = useFeatures();

    const isEmbed = useEmbedStore((state) => state.isInitialized);
    const organization = useOrganization((state) => state.organization);

    const [debouncedSearch] = useDebounce(searchText, 300);

    useEffect(() => {
        const fetchSubscriptions = async () => {
            try {
                // Fetch the data from the user account and general parameters
                const subscriptions = await notificationService.getSubscriptions({
                    orgName: organization?.name,
                    authUser: auth.user,
                    documentType: DocumentType.SubscriptionList
                });

                setNotifications(subscriptions);
            } catch (error) {
                const message = parseErrorMessage(error as ApiError, "Error while trying to fetch subscriptions");
                enqueueSnackbar(message, {
                    variant: "error",
                    persist: true
                });
            } finally {
                setIsFetchingSubscriptions(false);
            }
        };

        if (organization && !auth.isLoading) {
            fetchSubscriptions();
        }
    }, [organization, auth.isLoading, auth.user, notificationService, reloadingAllSubs, enqueueSnackbar]);

    useEffect(() => {
        if (filterQuery && filterQuery.length > 0) {
            const filteredFeatured: OptionShape[] = [];
            const filteredType: OptionShape[] = [];
            const filteredTags: OptionShape[] = [];
            const filteredSource: OptionShape[] = [];
            const filteredShowActive: boolean[] = [];

            filterQuery.forEach((filter) => {
                switch (filter.name) {
                    case "featured":
                        filteredFeatured.push(filter);
                        break;
                    case "type":
                        filteredType.push(filter);
                        break;
                    case "tag":
                        filteredTags.push(filter);
                        break;
                    case "source":
                        filteredSource.push(filter);
                        break;
                    case "showActive":
                        filteredShowActive.push(true);
                        break;
                    default:
                        break;
                }
            });

            setFilterState((prev) => ({
                ...prev,
                selectedIsFeatured: filteredFeatured,
                selectedSubscription: filteredType,
                selectedTags: filteredTags,
                selectedSource: filteredSource,
                showActive: filteredShowActive
            }));
        }
    }, [filterQuery]);

    useEffect(() => {
        if (notifications) {
            const isUserSubscribedToAny =
                notifications?.find((n) => n.emailSubscription || n.smsSubscription) !== undefined;
            setDisableManageBtn(!isUserSubscribedToAny);
        } else {
            setDisableManageBtn(true);
        }
    }, [notifications]);

    useEffect(() => {
        if (isEmbed) return;

        const basePath = `/${organization?.name}/notifications?tab=notifications`;

        const params = {
            keyword: debouncedSearch ? [debouncedSearch] : [],
            featured: filterState.selectedIsFeatured.map((f) => f.value),
            type: filterState.selectedSubscription.map((f) => f.value),
            tag: filterState.selectedTags.map((f) => f.value),
            source: filterState.selectedSource.map((f) => f.value),
            showActive: filterState.showActive.length > 0 ? ["true"] : []
        };

        const queryString = generateQueryString(params);
        const url = queryString ? `${basePath}&${queryString}` : basePath;

        navigate(url, { replace: true });
    }, [isEmbed, debouncedSearch, navigate, organization?.name, filterState]);

    useEffect(() => {
        const loadTags = async () => {
            try {
                const allTags = await getTags(organization?.name, auth.user, DocumentType.SubscriptionList);
                const convertedTags = FormatTags(allTags);
                setTags(convertedTags);
            } catch (error) {
                console.error("Failed to load tags:", error);
            } finally {
                setTagsLoaded(true);
            }
        };

        if (!tagsLoaded) {
            loadTags();
        }
    }, [auth.user, organization?.name, tagsLoaded]);

    const subscriptionSections = useMemo(() => {
        if (organization) {
            return {
                [organization.friendlyName || organization.name]: notifications
            };
        }
    }, [organization, notifications]);

    const handleChangePage = async (
        _event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
        newPage: number
    ) => {
        setCurrentPage(newPage);
    };

    const handleChangeRowsPerPage = async (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10) || 10);
    };

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchText(e.target.value);
    };

    const handleSubscriptionUpdate = (subscription: SubscriptionList) => {
        setNotifications((prev) => {
            if (!prev) return prev;
            return prev.map((item) => {
                if (item.aggregateId === subscription.aggregateId) {
                    return { ...subscription };
                }
                return item;
            });
        });
    };

    const handleUnsubscribeAll = useCallback(
        async (subscriptionType: SubscriptionType) => {
            if (auth.user && organization) {
                try {
                    setUnsubscribeToAllPending(true);
                    const response = await notificationService.unsubscribeFromAllLists({
                        organization,
                        subscriptionType,
                        authUser: auth.user,
                        documentType: DocumentType.SubscriptionList
                    });

                    if (response.ok) {
                        setReloadingAllSubs((prev) => !prev);
                        const message =
                            subscriptionType === SubscriptionType.None
                                ? "Unsubscribed successfully from all lists"
                                : `Unsubscribed all ${subscriptionType} notifications from lists`;
                        enqueueSnackbar(message, {
                            variant: "success"
                        });

                        if (!isStaffUser(auth.user)) {
                            GAevent({
                                action: `Unsubscribed from all${subscriptionType !== SubscriptionType.None ? ` [${subscriptionType}]` : ""}`,
                                category: "Subscriptions",
                                orgName: organization.name,
                                label: `User Id: ${auth.user.id_token}`
                            });
                        }
                    } else {
                        enqueueSnackbar(response.error, {
                            variant: "error",
                            persist: true
                        });
                    }
                } catch {
                    const errorMessage = `Error while trying to unsubscribe user from all lists`;
                    enqueueSnackbar(errorMessage, {
                        variant: "error",
                        persist: true
                    });
                } finally {
                    setUnsubscribeToAllPending(false);
                }
            }
        },
        [enqueueSnackbar, notificationService, organization, auth.user]
    );

    const notificationsFiltered = useMemo(() => {
        if (!notifications) return undefined;

        let searchList = notifications;

        if (debouncedSearch) {
            searchList = filterByName(searchList, debouncedSearch);
        }
        if (filterState.selectedSubscription.length > 0) {
            searchList = filterBySubscription(searchList, filterState.selectedSubscription);
        }
        if (filterState.selectedIsFeatured.length > 0) {
            searchList = filterByFeatured(searchList, filterState.selectedIsFeatured);
        }
        if (filterState.selectedTags.length > 0) {
            searchList = filterByTags(searchList, filterState.selectedTags);
        }
        if (filterState.selectedSource.length > 0) {
            searchList = filterBySource(searchList, filterState.selectedSource, WebsiteProductTypes);
        }
        if (filterState.showActive.length > 0) {
            searchList = searchList.filter((item) => item.emailSubscription || item.smsSubscription);
        }

        return searchList;
    }, [notifications, debouncedSearch, filterState]);

    const notificationsPaginated = useMemo(() => {
        return notificationsFiltered?.slice(currentPage * rowsPerPage, currentPage * rowsPerPage + rowsPerPage);
    }, [notificationsFiltered, currentPage, rowsPerPage]);

    const activeFilters = useMemo(
        () =>
            [
                ...filterState.selectedTags,
                ...filterState.selectedIsFeatured,
                ...filterState.selectedSubscription,
                ...filterState.selectedSource,
                ...filterState.showActive
            ].length,
        [filterState]
    );

    const sourceFilterOptions = useMemo(() => {
        const filterOptions = [];

        const agendasMeetings = notificationsFiltered?.find(
            (n: SubscriptionList) =>
                n.productType === ProductType.Notifications && n.additionalProducts.includes(ProductType.CivicClerk)
        );
        agendasMeetings &&
            filterOptions.push({
                label: NotificationGroupType.AgendasMeetings,
                value: NotificationGroupType.AgendasMeetings
            });

        const engageOpen = notificationsFiltered?.find(
            (n: SubscriptionList) =>
                n.productType === ProductType.Notifications &&
                n.additionalProducts.includes(ProductType.CivicEngageOpen)
        );
        engageOpen &&
            filterOptions.push({
                label: "CivicEngage Open",
                value: getProductTypeLabels(ProductType.CivicEngageOpen)
            });

        const websiteEvolve = notificationsFiltered?.find(
            (n: SubscriptionList) =>
                n.productType === ProductType.Notifications && n.additionalProducts.includes(ProductType.HCMS)
        );
        websiteEvolve &&
            filterOptions.push({
                label: "CivicEngage Evolve",
                value: getProductTypeLabels(ProductType.CivicEngageEvolve)
            });

        const websiteCentral = notificationsFiltered?.find(
            (n: SubscriptionList) => n.productType === ProductType.CivicEngageCentral
        );
        websiteCentral &&
            filterOptions.push({
                label: "CivicEngage Central",
                value: "Website"
            });

        const civicNotifications = notificationsFiltered?.find(
            (n: SubscriptionList) => n.productType === ProductType.Notifications
        );
        civicNotifications &&
            filterOptions.push({
                label: getProductTypeLabels(ProductType.Notifications),
                value: getProductTypeLabels(ProductType.Notifications)
            });

        const externalLinks = notificationsFiltered?.find(
            (n: SubscriptionList) => n.productType === ProductType.CivicPlusPortal
        );
        externalLinks &&
            filterOptions.push({
                label: getProductTypeLabels(ProductType.CivicPlusPortal),
                value: getProductTypeLabels(ProductType.CivicPlusPortal)
            });

        return filterOptions;
    }, [notificationsFiltered]);

    const handleOnChangeFilter = (filter: string) => (selected: OptionShape[] | undefined) => {
        setFilterState((prev) => ({ ...prev, [filter]: selected ?? [] }));
    };

    const handleActiveFilter = (_: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        setFilterState((prev) => ({
            ...prev,
            showActive: checked ? [true] : []
        }));
    };

    const handleFilterReset = () => {
        setFilterState({
            selectedTags: [],
            selectedIsFeatured: [],
            selectedSubscription: [],
            selectedSource: [],
            showActive: []
        });
    };

    if (
        ((notifications && notifications.length === 0) ||
            subscriptionSections === undefined ||
            organization === undefined) &&
        !reloadingAllSubs
    ) {
        return (
            <Typography>
                The location you have selected does not yet have subscription management available. While we work to
                integrate with the location’s subscription provider(s), you will need to manage subscriptions following
                existing directions from the relevant organization.
            </Typography>
        );
    }

    const unsubscribeFromAllButtonOptions = unsubscribeToAllPending
        ? [
              {
                  children: "Unsubscribe from all",
                  isLoading: true
              }
          ]
        : [
              {
                  children: "Unsubscribe from all",
                  onClick: () => handleUnsubscribeAll(SubscriptionType.None),
                  id: "unsubscribe-all-btn",
                  "aria-label": "Unsubscribe from all notifications"
              },
              {
                  children: "Only SMS",
                  onClick: () => handleUnsubscribeAll(SubscriptionType.Sms)
              },
              {
                  children: "Only Email",
                  onClick: () => handleUnsubscribeAll(SubscriptionType.Email)
              }
          ];

    return (
        <Grid container={true} spacing={2} justifyContent="space-between">
            <Grid xs={12} className={clsx(classes.lg6Grid, classes.md7Grid, classes.mdxs12Grid)}>
                {auth.user && (
                    <ButtonGroup layout="right" className={classes.tabButtonGroup}>
                        <Button
                            key="manage-contact-btn"
                            id="manage-contact-btn"
                            data-testid="manage-contact-btn"
                            onClick={onShowContactDialog}
                            disabled={disableManageBtn}
                            className={classes.tabButton}
                            fullWidth={isMobileView}
                            title={
                                disableManageBtn
                                    ? "Please subscribe to a list below before managing your contact information."
                                    : undefined
                            }
                        >
                            Manage contact information
                        </Button>

                        <ButtonDropDown
                            color="primary"
                            fullWidth={isMobileView}
                            options={unsubscribeFromAllButtonOptions}
                            className={classes.tabButton}
                            MenuProps={{ ButtonProps: { "aria-label": "Select specific unsubscribe options", className: classes.tabButton } }}
                        />
                    </ButtonGroup>
                )}
            </Grid>

            <Grid xs={12} className={clsx(classes.lg6Grid, classes.md5Grid, classes.mdxs12Grid)}>
                <Grid container={true} xs={12} alignItems="center" spacing={2}>
                    <Grid xs={true}>
                        <SearchInput
                            id="notifications-search-bar"
                            value={searchText}
                            onChange={handleSearch}
                            searchOnChange={true}
                            onSearch={setSearchText}
                            fullWidth={true}
                            margin={false}
                            sx={{ minWidth: 220 }}
                        />
                    </Grid>

                    <Grid width="auto">
                        <FilterButton
                            id="notifications-filter"
                            onFilterReset={handleFilterReset}
                            activeFilters={activeFilters}
                        >
                            <Grid container={true} spacing={2} sx={{ maxWidth: 500 }}>
                                <Grid xs={12} md={6}>
                                    <Autocomplete
                                        id="tag-filter"
                                        label="Tags"
                                        multiSelect={true}
                                        isInDialog={true}
                                        onChange={handleOnChangeFilter("selectedTags")}
                                        value={filterState.selectedTags}
                                        options={tags}
                                        fullWidth={true}
                                        margin={false}
                                    />
                                </Grid>

                                <Grid xs={12} md={6}>
                                    <Autocomplete
                                        id="subscrption-filter"
                                        label="Subscription Type"
                                        onChange={handleOnChangeFilter("selectedSubscription")}
                                        value={filterState.selectedSubscription}
                                        multiSelect={true}
                                        isInDialog={true}
                                        fullWidth={true}
                                        margin={false}
                                        options={[
                                            { label: "Email", value: SubscriptionType.Email },
                                            { label: "Sms", value: SubscriptionType.Sms }
                                        ]}
                                    />
                                </Grid>

                                <Grid xs={12} md={6}>
                                    <Autocomplete
                                        id="featured-filter"
                                        label="Featured"
                                        onChange={handleOnChangeFilter("selectedIsFeatured")}
                                        value={filterState.selectedIsFeatured}
                                        multiSelect={true}
                                        isInDialog={true}
                                        fullWidth={true}
                                        margin={false}
                                        options={[
                                            { label: "Featured", value: "Featured" },
                                            { label: "Not Featured", value: "Not Featured" }
                                        ]}
                                    />
                                </Grid>

                                <Grid xs={12} md={6}>
                                    <Autocomplete
                                        id="source-filter"
                                        label="Source"
                                        onChange={handleOnChangeFilter("selectedSource")}
                                        value={filterState.selectedSource}
                                        multiSelect={true}
                                        isInDialog={true}
                                        options={sourceFilterOptions}
                                        fullWidth={true}
                                        margin={false}
                                    />
                                </Grid>

                                <Grid xs={12}>
                                    <FormGroup className={classes.activeFilter}>
                                        <InputLabel htmlFor="show-is-active" focused={true} shrink={true}>
                                            Subscriptions
                                        </InputLabel>
                                        <Checkbox
                                            id="show-is-active"
                                            checked={filterState.showActive.length > 0}
                                            onChange={handleActiveFilter}
                                            label="Show only my active subscriptions"
                                        />
                                    </FormGroup>
                                </Grid>
                            </Grid>
                        </FilterButton>
                    </Grid>
                </Grid>
            </Grid>

            <Grid xs={12}>
                {flags.showNotificationsGrouped ? (
                    <NotificationsListGroups
                        notifications={notificationsFiltered}
                        organization={organization}
                        authUser={auth.user}
                        notificationService={notificationService}
                        searchText={searchText}
                        handleSubscriptionUpdate={handleSubscriptionUpdate}
                    />
                ) : (
                    <NotificationsList
                        notifications={isFetchingSubscriptions ? undefined : notificationsPaginated}
                        organization={organization}
                        authUser={auth.user}
                        notificationService={notificationService}
                        searchText={searchText}
                        handleSubscriptionUpdate={handleSubscriptionUpdate}
                    />
                )}

                {notificationsFiltered && notificationsFiltered.length > 0 && !flags.showNotificationsGrouped && (
                    <TablePagination
                        id="changes-results-pagination"
                        component="div"
                        count={notificationsFiltered.length}
                        page={currentPage}
                        onPageChange={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        rowsPerPageOptions={[10, 25, 50, 100]}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                )}
            </Grid>
        </Grid>
    );
};
