// Forked from: https://github.com/authts/react-oidc-context
import React from "react";
import type {
    UserManagerSettings,
    UserManagerEvents,
    User,
    SessionStatus,
    SigninPopupArgs,
    SigninSilentArgs,
    SigninRedirectArgs,
    SignoutRedirectArgs,
    SignoutPopupArgs,
    SignoutSilentArgs,
    QuerySessionStatusArgs,
    RevokeTokensTypes
} from "oidc-client-ts";

import type { AuthState } from "./AuthState";

/**
 * @public
 */
export interface AuthContextProps extends AuthState {
    /**
     * UserManager functions. See [UserManager](https://github.com/authts/oidc-client-ts) for more details.
     */
    readonly settings: UserManagerSettings;
    readonly events: UserManagerEvents;
    clearStaleState(): Promise<void>;
    removeUser(): Promise<void>;
    signinPopup(args?: SigninPopupArgs): Promise<User>;
    signinSilent(args?: SigninSilentArgs): Promise<User | null>;
    signinRedirect(args?: SigninRedirectArgs): Promise<void>;
    signoutRedirect(args?: SignoutRedirectArgs): Promise<void>;
    signoutPopup(args?: SignoutPopupArgs): Promise<void>;
    signoutSilent(args?: SignoutSilentArgs): Promise<void>;
    querySessionStatus(args?: QuerySessionStatusArgs): Promise<SessionStatus | null>;
    revokeTokens(types?: RevokeTokensTypes): Promise<void>;
    startSilentRenew(): void;
    stopSilentRenew(): void;
    // CivicPlus Addition
    // Once this is done we might be able to go back to npm version:
    // https://github.com/authts/react-oidc-context/issues/331
    storeUser(user: User | null): void;
}

/**
 * @public
 */
export const AuthContext = React.createContext<AuthContextProps | undefined>(undefined);
AuthContext.displayName = "AuthContext";

export const setupAcrValue = (
    context: AuthContextProps,
    args: SigninPopupArgs,
    organizationId: string | undefined | null
): SigninPopupArgs => {
    if (organizationId !== null && organizationId !== undefined) {
        context.settings.acr_values = "OrgBranding:" + organizationId;
        args.acr_values = "OrgBranding:" + organizationId;
    }
    return args;
};
