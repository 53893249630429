import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import ButtonNavigation from "../Components/ButtonNavigation";
import Typography from "@civicplus/preamble-ui/lib/Typography";
import PhoneNumberVerificationItems from "./PhoneNumberVerificationItem";
import ApiService from "../../../services/apiService";
import { StepProps } from "..";
import { Organization } from "../../../types/Organization";
import { PhoneNumber, PhoneNumberType } from "./PhoneNumberUtils";
import { KonexusUser } from "../../../types/Account";
import { useAuth } from "../../../providers/AuthProvider";
import Loader from "@civicplus/preamble-ui/lib/Loader";

interface PhoneNumberVerificationStepProps extends StepProps {
    organization: Organization;
    applicationId: string;
    konexusUser: KonexusUser | null;
    reloadAccountDetails?: () => void;
    reloadPhoneNumbers?: () => void;
    setSynced: Dispatch<SetStateAction<boolean>>;
    synced: boolean;
}

const PhoneNumberVerificationStep: React.FC<PhoneNumberVerificationStepProps> = (props) => {
    const {
        data,
        previousStep,
        nextStep,
        setData,
        setCompleted,
        organization,
        applicationId,
        konexusUser,
        reloadAccountDetails,
        reloadPhoneNumbers,
        setSynced,
        synced
    } = props;
    const auth = useAuth();

    const [isLoading, setIsLoading] = useState(false);

    if (!data) throw new Error("data is required");
    if (!nextStep) throw new Error("nextStep is required");
    if (!previousStep) throw new Error("previousStep is required");
    if (!setData) throw new Error("setData is required");
    if (!setCompleted) throw new Error("setCompleted is required");

    const dedupePhones = useCallback(() => {
        const cellPhoneNumbers = data.phoneNumbers.filter(
            (x) => x.type === PhoneNumberType.CellPhone || x.type === PhoneNumberType.CellPhoneSecondary
        );
        const seenIds = new Set();
        const deduped = cellPhoneNumbers.filter((p: PhoneNumber) => {
            if (seenIds.has(p.id)) {
                return false;
            } else {
                seenIds.add(p.id);
                return true;
            }
        });

        return deduped;
    }, [data.phoneNumbers]);

    const uniqueCellNumbers = dedupePhones();

    useEffect(() => {
        if (auth && auth.user && organization && !synced && !isLoading) {
            if (konexusUser) {
                return;
            }

            const konexusAccount = async () => {
                setIsLoading(true);
                const email = auth.user?.profile.name;
                const phone = data.phoneNumbers.find((x) => x.isDefaultNumber) ?? data.phoneNumbers?.[0];
                const number = phone?.number;
                const orgName = organization?.name;
                const userId = auth.user?.profile?.sub || "";
                const url = `${orgName}/user/${encodeURIComponent(userId)}/sync/konexusProfile`;

                const konexusProfile = await ApiService.post({
                    url,
                    requestInit: {
                        headers: {
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify({
                            email,
                            phoneNumber: number
                        })
                    },
                    authUser: auth.user
                });

                if (konexusProfile) {
                    reloadAccountDetails?.();
                    reloadPhoneNumbers?.();
                    setSynced(true);
                }

                setIsLoading(false);
            };

            konexusAccount();
        }
    }, [
        auth,
        data.phoneNumbers,
        isLoading,
        konexusUser,
        organization,
        reloadAccountDetails,
        reloadPhoneNumbers,
        setSynced,
        synced
    ]);

    const handleSetPhoneNumberVerified = (phoneNumber: string) => {
        setData({
            phoneNumbers: data.phoneNumbers.map((x) => {
                if (x.konexusPhoneNumber === phoneNumber) {
                    return {
                        ...x,
                        isPhoneVerified: true
                    };
                }
                return x;
            })
        });
    };

    return (
        <>
            <Typography variant="h5">Cell Phone Number Verification</Typography>
            {uniqueCellNumbers.length > 0 ? (
                <>
                    <Typography variant="subtitle1">
                        In order to begin receiving alerts, you will need to verify the cell phone number you have
                        provided below. You will receive a text message with a one time passcode.
                    </Typography>
                    {isLoading ? (
                        <Loader verticallyCenter={true} style={{ padding: 50 }} />
                    ) : (
                        uniqueCellNumbers.map((phoneNumber) => (
                            <PhoneNumberVerificationItems
                                key={phoneNumber.id}
                                phoneNumber={phoneNumber}
                                organization={organization}
                                applicationId={applicationId}
                                setPhoneNumberVerified={handleSetPhoneNumberVerified}
                            />
                        ))
                    )}
                </>
            ) : data.phoneNumbers.length === 0 ? (
                <Typography variant="subtitle1">Please select a phone number on Step 1.</Typography>
            ) : (
                <Typography variant="subtitle1">No cell phone numbers need to be verified.</Typography>
            )}

            <ButtonNavigation
                previousStep={previousStep}
                nextStep={nextStep}
                previousAriaLabel="Phone Number Selection"
                nextAriaLabel="Location Selection"
            />
        </>
    );
};

export default PhoneNumberVerificationStep;
