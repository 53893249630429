import React, { useEffect, useMemo, useState } from "react";
import AccessibilityMessage from "../../../components/AccessibilityMessage";
import ApiService from "../../../services/apiService";
import Autocomplete, { OptionShape } from "@civicplus/preamble-ui/lib/Autocomplete";
import Button from "@civicplus/preamble-ui/lib/Button";
import DateTimeRange, { ValueShape } from "@civicplus/preamble-ui/lib/DateTimeRange";
import EventsList from "../../../components/Events/EventsList";
import FilterButton from "../../../components/FilterButton";
import Grid from "@civicplus/preamble-ui/lib/Grid";
import HiddenPageAlert from "../../../components/HiddenPageAlert";
import Layout from "@civicplus/preamble-ui/lib/Layout";
import Link from "@civicplus/preamble-ui/lib/Link";
import Loader from "@civicplus/preamble-ui/lib/Loader";
import SearchInput from "@civicplus/preamble-ui/lib/SearchInput";
import useStyles from "./styles";
import { Breakpoint } from "@mui/material/styles";
import { DocumentType, SearchDocument } from "../../../types/SearchDocument";
import { FormatTags } from "../../../components/EditLinkDialog/EditLinkUtilities";
import { Link as RouterLink, useNavigate, useSearchParams } from "react-router-dom";
import { Organization, PagedResult, NavigationOption } from "../../../types/Organization";
import { OrganizationState, useOrganization } from "../../../stores/organizationStore";
import { TablePagination } from "@civicplus/preamble-ui/lib/TablePagination";
import { toggleDisplayFraseChatbot } from "../../../shared/functions";
import { useAuth } from "../../../providers/AuthProvider";
import { useDebounce } from "use-debounce";
import { useEmbedStore } from "../../../stores/embedStore";
import { useLinks } from "../../../stores/linksStore";
import enhanceWithValidation, {
    dateRangeValidDateValidation,
    dateRangeBoundaryValidation
} from "@civicplus/preamble-ui/lib/Validations";
import { HtmlDescription } from "../../../components/Layout/HtmlDescription";
import { useSnackbar } from "notistack";
const EnhancedDateRange = enhanceWithValidation(DateTimeRange);

interface EventsProps {
    organization: Organization;
    widthOverride?: Breakpoint;
    queryKeyword: string | null;
    queryTags: OptionShape[];
    queryDateRange: ValueShape;
}

export const EventsAccessWrapper: React.FC = () => {
    const auth = useAuth();
    const isLoadingSession = auth.isLoading;
    const [searchParams] = useSearchParams();
    const [organization] = useOrganization((state: OrganizationState) => [state.organization]);

    const lowerCaseSearchParams = useMemo(() => {
        return new URLSearchParams([...searchParams].map(([key, value]) => [key.toLowerCase(), value]));
    }, [searchParams]);
    const queryKeyword = lowerCaseSearchParams.get("keyword") ?? null;
    const allQueryTags = lowerCaseSearchParams.getAll("tag") ?? "";
    const from = lowerCaseSearchParams.get("from") ?? null;
    const to = lowerCaseSearchParams.get("to") ?? null;
    const queryTags: OptionShape[] = [];

    if (allQueryTags.length > 0) {
        allQueryTags.forEach((tag) => {
            if (!queryTags.find((t) => t.value === tag)) {
                queryTags.push({
                    label: tag,
                    value: tag
                });
            }
        });
    }

    if (isLoadingSession) {
        return <Loader verticallyCenter={true} />;
    }

    if (organization) {
        return (
            <Events
                organization={organization}
                queryKeyword={queryKeyword}
                queryTags={queryTags}
                queryDateRange={{ from: from ? new Date(from) : new Date(), to: to ? new Date(to) : null }}
            />
        );
    }

    return <Loader verticallyCenter={true} />;
};

const getOptionShapeValues = (options: OptionShape[]) => {
    const values: string[] = [];

    options.forEach((x) => {
        if (typeof x.value === "string") {
            values.push(x.value);
        }
    });

    return values;
};

export const Events: React.FC<EventsProps> = ({ organization, queryKeyword, queryTags, queryDateRange }) => {
    const auth = useAuth();
    const classes = useStyles();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const [events, setEvents] = useState<PagedResult<SearchDocument>>({
        items: [],
        count: 0
    });
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(10);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [tags, setTags] = useState<OptionShape[]>([]);
    const [selectedTags, setSelectedTags] = useState<OptionShape[]>(() => queryTags);
    const [currentSearchTerm, setCurrentSearchTerm] = useState<string | null>(() => queryKeyword);
    const [dateRange, setDateRange] = useState<ValueShape>(() => queryDateRange);
    const eventListRef = React.createRef<HTMLUListElement>();

    const isEmbed = useEmbedStore((state) => state.isInitialized);
    const [debouncedSearchTerm] = useDebounce(currentSearchTerm, 300);

    const { fetchSpecificFavorites } = useLinks((state) => ({
        fetchSpecificFavorites: state.fetchSpecificFavorites
    }));

    const onChange = (value: ValueShape) => {
        setDateRange(value);
    };

    useEffect(
        function updateFraseChatbot() {
            toggleDisplayFraseChatbot(organization, isEmbed || false);
        },
        [organization, isEmbed]
    );

    useEffect(
        function search() {
            let isCancelled = false;
            async function internalSearch() {
                const skip = page * rowsPerPage;
                const searchPath = debouncedSearchTerm === null ? "" : debouncedSearchTerm;

                let url = `${
                    organization.name
                }/search/${searchPath}?$top=${rowsPerPage}&$skip=${skip}&$orderby=startDate asc&$filter=${getOptionShapeValues(
                    selectedTags
                )}&documentType=${DocumentType.Event}`;

                if (dateRange.from && dateRange.from instanceof Date) {
                    url += `&start=${dateRange.from.toISOString()}`;
                }

                if (dateRange.to && dateRange.to instanceof Date) {
                    url += `&end=${dateRange.to.toISOString()}`;
                }

                const searchResults: PagedResult<SearchDocument> = await ApiService.get({
                    url: url,
                    cache: false,
                    authUser: auth.user
                });

                if (!isCancelled) {
                    setEvents(searchResults);
                    setIsLoading(false);
                }
            }
            internalSearch();

            return () => {
                isCancelled = true;
            };
        },
        [
            auth.user,
            dateRange.from,
            dateRange.to,
            debouncedSearchTerm,
            organization.name,
            page,
            rowsPerPage,
            selectedTags
        ]
    );

    useEffect(
        function updateUrlForNewTagsAndKeyword() {
            if (!isEmbed) {
                let url = `/${organization.name}/meetings`;
                const parts: string[] = [];

                if (currentSearchTerm) {
                    parts.push(`keyword=${currentSearchTerm}`);
                }

                if (selectedTags.length > 0) {
                    selectedTags.forEach((tag) => {
                        parts.push(`tag=${tag.value}`);
                    });
                }

                if (dateRange.from && dateRange.from instanceof Date) {
                    parts.push(`from=${dateRange.from.toLocaleDateString()}`);
                }

                if (dateRange.to && dateRange.to instanceof Date) {
                    parts.push(`to=${dateRange.to.toLocaleDateString()}`);
                }

                if (parts.length > 0) {
                    url += "?" + parts.join("&");
                }

                navigate(url, { replace: true });
            }
        },
        [isEmbed, currentSearchTerm, selectedTags, navigate, organization.name, dateRange.from, dateRange.to]
    );

    useEffect(
        function loadAllTags() {
            const getTags = async () => {
                const allTags = await ApiService.get({
                    url: `${organization.name}/search/tags?documentType=${DocumentType.Event}`,
                    cache: false,
                    authUser: auth.user
                });

                allTags.sort((a: string, b: string) => {
                    return a.toLowerCase() > b.toLowerCase() ? 1 : -1;
                });

                const convertedTags = FormatTags(allTags);
                setTags(convertedTags);
            };

            getTags();
        },
        [auth.user, organization.name]
    );

    useEffect(
        function loadUserFavorites() {
            if (!auth.isAuthenticated) {
                return;
            }

            const getFavorites = async () => {
                if (auth.user) {
                    await fetchSpecificFavorites(auth.user, DocumentType.Event, enqueueSnackbar, organization?.id);
                }
            };

            getFavorites();
        },
        [auth.isAuthenticated, auth.user, fetchSpecificFavorites, enqueueSnackbar, organization?.id]
    );

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
        setPage(newPage);
        if (eventListRef.current) {
            eventListRef.current.scrollIntoView({ behavior: "smooth" });
            eventListRef.current.focus();
        }
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleSearch = (searchQuery: string) => {
        if (searchQuery.length === 0) {
            setCurrentSearchTerm(null);
            setPage(0);
            setRowsPerPage(10);
        } else {
            setCurrentSearchTerm(searchQuery);
        }
    };

    const onChangeTag = (selected: OptionShape[] | undefined) => {
        selected ? setSelectedTags(selected) : setSelectedTags([]);
    };

    const navigationItem = organization.navigation && organization.navigation[NavigationOption.Meetings];

    return (
        <Layout
            TitlebarProps={{
                id: "events-titlebar",
                title: navigationItem ? navigationItem.customLabel ?? navigationItem.defaultLabel : "Meetings",
                breadcrumbs: [
                    <Link key="home" to={`/${organization?.name}`} component={RouterLink}>
                        Home
                    </Link>
                ],
                buttons: [
                    <Button
                        key="manage-notification-button"
                        id="events-subscriptions-btn"
                        color="primary"
                        component={RouterLink}
                        to={`/${organization?.name}/notifications?source=Agendas%20%26%20Meetings`}
                    >
                        Manage Notification Subscriptions
                    </Button>
                ],
                description: <HtmlDescription description={navigationItem?.additionalProductInformation} />
            }}
        >
            <>
                <HiddenPageAlert navOption={NavigationOption.Meetings} />

                <Grid container={true} spacing={2} justifyContent="flex-end" alignItems="center">
                    <Grid xs={12} md="auto">
                        <EnhancedDateRange
                            fromProps={{ label: "From date" }}
                            id="filter-events"
                            onChange={onChange}
                            toProps={{ label: "To date" }}
                            value={dateRange}
                            validations={[dateRangeValidDateValidation, dateRangeBoundaryValidation]}
                            fullWidth={true}
                            margin="none"
                            disabled={isLoading}
                        />
                    </Grid>

                    <Grid xs={12} md="auto">
                        <Grid container={true} spacing={2} justifyContent="flex-end" alignItems="center">
                            <Grid className={classes.fullWidth}>
                                <SearchInput
                                    id="events-search-bar"
                                    value={currentSearchTerm ?? ""}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        handleSearch(e.target.value);
                                    }}
                                    searchOnChange={true}
                                    onSearch={handleSearch}
                                    fullWidth={true}
                                    margin={false}
                                    disabled={isLoading}
                                />
                            </Grid>

                            <Grid>
                                <FilterButton
                                    id="events-filter"
                                    activeFilters={selectedTags.length}
                                    onFilterReset={() => setSelectedTags([])}
                                >
                                    <Autocomplete
                                        id="tag-filter"
                                        label="Tags"
                                        onChange={onChangeTag}
                                        value={selectedTags}
                                        options={tags}
                                        multiSelect={true}
                                        fullWidth={true}
                                        isInDialog={true}
                                    />
                                </FilterButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <section id="events-list" ref={eventListRef}>
                    {isLoading ? (
                        <Loader verticallyCenter={true} />
                    ) : (
                        <>
                            <EventsList events={events.items} aria-live="polite" />

                            <TablePagination
                                id="events-pagination"
                                component="div"
                                count={events.count}
                                page={page}
                                className={classes.pagination}
                                onPageChange={handleChangePage}
                                rowsPerPage={rowsPerPage}
                                rowsPerPageOptions={[10, 25, 50, 100]}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </>
                    )}
                    <AccessibilityMessage message={isLoading ? "Loading Event items" : "Event items Loaded"} />
                </section>
            </>
        </Layout>
    );
};
