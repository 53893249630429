import React from "react";
import clsx from "clsx";
import Icon from "@mdi/react";
import { defaultApplicationLabels } from "../../shared/constants";
import { FeatureFlags } from "../../shared/useFeatureFlags";
import { Link as RouterLink } from "react-router-dom";
import { getIconPathByName, getIconSetForNavigation } from "../Settings/Navigation/NavigationIcons";
import { mdiBankCheck as GovernmentBuilding } from "@mdi/js";
import { NavigationRailButton } from "@civicplus/preamble-ui/lib/NavigationRail";
import { NavigationOption, Organization } from "../../types/Organization";
import { ProductType } from "../../types/ProductType";
import { default as DocumentIcon } from "mdi-material-ui/FileDocument";
import { default as RecIcon } from "mdi-material-ui/PineTree";
import { default as ServiceIcon } from "mdi-material-ui/Forum";

const navigationItemPath: { [key in NavigationOption]: string } = {
    [NavigationOption.Dashboard]: "dashboard",
    [NavigationOption.AlertsAndNotifications]: "notifications",
    [NavigationOption.Forms]: "forms",
    [NavigationOption.Legislation]: "legislation",
    [NavigationOption.Links]: "links",
    [NavigationOption.Meetings]: "meetings",
    [NavigationOption.ParksAndRec]: "embedded/civicRec",
    [NavigationOption.Payments]: "payments",
    [NavigationOption.Permits]: "embedded/civicGov",
    [NavigationOption.RecordsRequest]: "embedded/nextRequest",
    [NavigationOption.SubmitARequest]: "embedded/civicService"
};

export const getOrgApplicationNavButtons = (
    org: Organization,
    NavSectionLink: typeof RouterLink,
    flags: FeatureFlags,
    navIconClass: string,
    hideNavClass: string
): NavigationRailButton[] => {
    const navButtons: NavigationRailButton[] = [];

    const getNavIcon = (navOption: NavigationOption): string => {
        return getIconPathByName(
            org.navigation[navOption]?.icon ?? getIconSetForNavigation(navOption)[0].path,
            navOption
        );
    };
    if (flags.showSettingsNavigation && org.navigation) {
        const keys = Object.keys(org.navigation) as (keyof typeof NavigationOption)[];
        keys.forEach((key) => {
            const type = org.navigation[key].type;
            navButtons.push({
                label: org.navigation[key].customLabel,
                icon: <Icon className={navIconClass} path={getNavIcon(type)} />,
                buttonProps: {
                    id: type,
                    to: `/${org.name}/${navigationItemPath[type]}`,
                    component: NavSectionLink,
                    className: clsx({
                        [hideNavClass]: !org.navigation[key]?.isActive
                    })
                }
            });
        });
    } else {
        const validOrgApps = org.applications.filter((o) => o.publicUri);
        validOrgApps.forEach((app) => {
            if (app.isActive) {
                switch (app.productType) {
                    case ProductType.CivicRec:
                        navButtons.push({
                            label: app.customLabel ?? defaultApplicationLabels[ProductType.CivicRec],
                            icon: <RecIcon />,
                            buttonProps: {
                                id: "Parks&Rec",
                                to: `/${org.name}/embedded/civicRec`,
                                component: NavSectionLink
                            }
                        });
                        break;

                    case ProductType.SeeClickFix:
                        navButtons.push({
                            label: app.customLabel ?? defaultApplicationLabels[ProductType.SeeClickFix],
                            icon: <ServiceIcon />,
                            buttonProps: {
                                id: "SubmitARequest",
                                to: `/${org.name}/embedded/civicService`,
                                component: NavSectionLink
                            }
                        });
                        break;

                    case ProductType.NextRequest:
                        navButtons.push({
                            label: app.customLabel ?? defaultApplicationLabels[ProductType.NextRequest],
                            icon: <DocumentIcon />,
                            buttonProps: {
                                id: "RecordsRequest",
                                to: `/${org.name}/embedded/nextRequest`,
                                component: NavSectionLink
                            }
                        });
                        break;

                    case ProductType.CivicGov:
                        navButtons.push({
                            label: app.customLabel ?? defaultApplicationLabels[ProductType.CivicGov],
                            icon: <Icon path={GovernmentBuilding} size={0.75} aria-hidden="true" />,
                            buttonProps: {
                                id: "PermitsAndInspections",
                                to: `/${org.name}/embedded/civicGov`,
                                component: NavSectionLink
                            }
                        });
                        break;

                    default:
                        break;
                }
            }
        });
    }

    return navButtons;
};
