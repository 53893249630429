import React, { useState } from "react";
import { makeStyles } from "@civicplus/preamble-ui/lib/Utilities/ThemeHelper";
import Typography from "@civicplus/preamble-ui/lib/Typography";
import { useCollapse } from "react-collapsed";
import ChevronRight from "mdi-material-ui/ChevronRight";

const useStyles = makeStyles((theme) => ({
    title: {
        paddingLeft: theme.spacing(1)
    },
    header: {
        cursor: "pointer",
        display: "flex",
        alignItems: "center"
    },
    linkTitle: {
        color: theme.palette.text.primary,
        textDecoration: "none",
        "&:hover": {
            textDecoration: "underline !important"
        }
    },
    icon: {
        transition: "all 0.3s ease"
    },
    iconExpanded: {
        transform: "rotate(90deg)",
        transition: "all 0.3s ease"
    }
}));

interface CollapsibleHeaderProps {
    children?: React.ReactElement;
    title: string;
    expandedDefault: boolean;
    id: string;
    color?: "inherit" | "disabled" | "primary" | "secondary" | "error" | "action" | undefined;
}

const CollapsibleHeader: React.FC<CollapsibleHeaderProps> = (props) => {
    const { children, expandedDefault, title, id, color } = props;
    const classes = useStyles();
    const [isExpanded, setIsExpanded] = useState<boolean>(expandedDefault);

    const collapseConfig = {
        defaultExpanded: expandedDefault || false,
        easing: "cubic-bezier(.93, .12, .42, .25)",
        collapsedHeight: 0
    };

    const { getCollapseProps, getToggleProps } = useCollapse(collapseConfig);

    return (
        <div>
            <div className={classes.header} {...getToggleProps({ onClick: () => setIsExpanded(!isExpanded) })}>
                <ChevronRight
                    color={color}
                    fontSize="large"
                    aria-hidden="true"
                    className={isExpanded ? classes.iconExpanded : classes.icon}
                />
                <Typography id={id} variant="h5" component="h2" className={classes.title}>
                    {title ? title : "General"}
                </Typography>
            </div>

            <div {...getCollapseProps()}>
                <div className="content">{children}</div>
            </div>
        </div>
    );
};

export default CollapsibleHeader;
