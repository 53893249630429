import React, { useState, useMemo } from "react";
import EmailIcon from "@mui/icons-material/EmailOutlined";
import NotificationsContactDialog from "../../pages/location/notificationsAndAlerts/components/notifications/NotificationsContactDialog";
import PhoneIcon from "@mui/icons-material/SmartphoneOutlined";
import Toggle from "@civicplus/preamble-ui/lib/Toggle";
import Tooltip from "@civicplus/preamble-ui/lib/Tooltip";
import Typography from "@civicplus/preamble-ui/lib/Typography";
import { AppIdsAndPhoneNumbers, SubscriptionList, SubscriptionType } from "../../types/Subscription";
import { isStaffUser } from "../../shared/functions";
import { GAevent } from "../../services/googleAnalyticsService";
import { NotificationsService } from "../../services/notificationsService";
import { Organization } from "../../types/Organization";
import { ProductType } from "../../types/ProductType";
import { setupAcrValue } from "../react-oidc-context";
import { SimpleDialog } from "../SimpleDialog/SimpleDialog";
import { useAuth } from "../../providers/AuthProvider";
import { User as AuthUser, SigninPopupArgs } from "oidc-client-ts";
import { useOrganization } from "../../stores/organizationStore";
import { useSnackbar } from "notistack";

interface NotificationToggleProps {
    /**
     * The toggle type
     */
    toggleType: "email" | "sms";
    /**
     * The subscription list
     */
    subscription: SubscriptionList;
    /**
     * The current organization
     */
    organization: Organization;
    /**
     * The current user session
     */
    authUser: AuthUser | null | undefined;
    /**
     * Configured notifications service;
     */
    notificationService: NotificationsService;
    /**
     * Boolean to check if the user is already subscribed to this list by email
     */
    emailSubscription: boolean;
    /**
     * Boolean to check if the user is already subscribed to this list by sms
     */
    smsSubscription: boolean;

    /**
     * True to check local storage for pending subscription
     */
    handlePendingSubscription: boolean;

    onChangeStart?: () => void;

    onChangeComplete?: (success: boolean) => void;
}

interface PendingChanges {
    [key: string]: {
        email: boolean;
        sms: boolean;
    };
}

export const NotificationToggle: React.FC<NotificationToggleProps> = (props) => {
    const {
        toggleType,
        subscription,
        organization,
        authUser,
        notificationService,
        emailSubscription,
        smsSubscription,
        handlePendingSubscription,
        onChangeStart,
        onChangeComplete
    } = props;

    const auth = useAuth();
    const { enqueueSnackbar } = useSnackbar();
    const [showContactDialog, setShowContactDialog] = useState(false);
    const [loginNoticeOpen, setLoginNoticeOpen] = useState(false);
    const [newPhoneNumber, setNewPhoneNumber] = useState("");
    const [pendingChanges, setPendingChanges] = useState<PendingChanges>({});
    const [isLoading, setIsLoading] = useState({ email: false, sms: false });
    const [phoneNumberList, setPhoneNumberList] = useState<AppIdsAndPhoneNumbers>();
    const [incrementSubsAndUnsubs] = useOrganization((state) => [state.incrementSubsAndUnsubs]);

    const portalNotification: boolean = useMemo(
        () => subscription.productType === ProductType.CivicPlusPortal,
        [subscription]
    );

    let retrySubscriptionAction = handlePendingSubscription
        ? localStorage?.getItem(`retrySubscriptionAction_${toggleType}_${subscription.aggregateId}`)
        : null;

    // TODO: This needs a refactor ↓ it's taking too long to get basically the same info every time
    const getPhoneNumber = async (subscription: SubscriptionList) => {
        let phoneNumbers = phoneNumberList;

        if (!phoneNumbers) {
            try {
                // This call is what makes this function slow (maybe receive the phone number list as a prop?)
                phoneNumbers = await notificationService.getSubscriberPhoneNumbers({
                    orgName: organization.name,
                    authUser
                });

                setPhoneNumberList(phoneNumbers);
            } catch (error) {
                console.error(error);
                enqueueSnackbar("Error fetching phone numbers", {
                    variant: "error",
                    persist: true
                });
            }
        }

        const listAppId = subscription.aggregateId.split("_")[1];

        for (const appId in phoneNumbers) {
            if (appId === listAppId) {
                const value = phoneNumbers[listAppId][0];

                if (value && value.phoneNumber) {
                    return value.phoneNumber;
                } else {
                    setShowContactDialog(true);
                    return undefined;
                }
            }
        }

        setShowContactDialog(true);

        return undefined;
    };

    const onPhoneNumberSave = async () => {
        setShowContactDialog(false);

        if (retrySubscriptionAction) {
            const retryObj = JSON.parse(retrySubscriptionAction);
            const { retryCheck, subscriptionType, unsubscriptionType, subscription, toggleTriggered } = retryObj;

            let phoneNumbers = phoneNumberList;
            const appId = subscription.aggregateId.split("_")[1];

            phoneNumbers
                ? (phoneNumbers[appId] = [{ phoneNumber: newPhoneNumber, isValidated: true }])
                : (phoneNumbers = { [appId]: [{ phoneNumber: newPhoneNumber, isValidated: true }] });

            setPhoneNumberList(phoneNumbers);

            retrySubscriptionAction = null;
            localStorage.removeItem(`retrySubscriptionAction_${toggleType}_${subscription.aggregateId}`);

            await handleUserSubscription(
                subscriptionType,
                unsubscriptionType,
                subscription,
                toggleTriggered,
                undefined,
                retryCheck
            );
        }
    };

    const convertTypeToMessage = (
        type: SubscriptionType,
        listName: string,
        unSubscribed: boolean,
        productType: ProductType
    ) => {
        const confirmationMessage: string =
            productType === ProductType.CivicEngageCentral
                ? `You should receive a text message from us shortly containing a confirmation code. Please send that code back to the number you received the message from to confirm your SMS subscription.`
                : "";

        switch (type) {
            case SubscriptionType.None:
                return `You are now unsubscribed from ${listName}.`;
            case SubscriptionType.Sms:
                return `You are now subscribed to ${unSubscribed ? "only" : ""} SMS notifications for ${listName}.${
                    unSubscribed ? "" : confirmationMessage.replace("{0}", "SMS device")
                }`;
            case SubscriptionType.Email:
                return `You are now subscribed to ${unSubscribed ? "only" : ""} Email notifications for ${listName}.${
                    unSubscribed ? "" : confirmationMessage.replace("{0}", "Email")
                }`;
            case SubscriptionType.EmailAndSms:
                return `You are now subscribed to both Email and SMS notifications for ${listName}.${
                    unSubscribed ? "" : confirmationMessage.replace("{0}", "Email and SMS device")
                }`;
            default:
                return "";
        }
    };

    const handleUserSubscription = async (
        subscriptionType: SubscriptionType,
        unsubscriptionType: SubscriptionType,
        subscription: SubscriptionList,
        toggleTriggered: "email" | "sms",
        checked: boolean | undefined = undefined,
        retryCheck?: boolean
    ): Promise<boolean> => {
        let subscriberPhoneNum = "";
        let success = false;

        if (toggleTriggered === "sms" && checked !== undefined) {
            if (!authUser) {
                setLoginNoticeOpen(true);

                const subscriptionCall = JSON.stringify({
                    waitingForPhoneNumber: false,
                    retryCheck: checked,
                    checked,
                    subscriptionType,
                    unsubscriptionType,
                    subscription,
                    toggleTriggered
                });

                localStorage?.setItem(
                    `retrySubscriptionAction_${toggleType}_${subscription.aggregateId}`,
                    subscriptionCall
                );

                retrySubscriptionAction = subscriptionCall;
                return false;
            } else {
                setIsLoading({ ...isLoading, sms: true });

                const subscriptionCall = JSON.stringify({
                    waitingForPhoneNumber: true,
                    retryCheck: checked,
                    subscriptionType,
                    unsubscriptionType,
                    subscription,
                    toggleTriggered
                });

                subscriberPhoneNum = (await getPhoneNumber(subscription)) || "";

                if (!subscriberPhoneNum || subscriberPhoneNum === "") {
                    localStorage?.setItem(
                        `retrySubscriptionAction_${toggleType}_${subscription.aggregateId}`,
                        subscriptionCall
                    );

                    retrySubscriptionAction = subscriptionCall;

                    return false;
                }
            }
        }

        if (authUser) {
            onChangeStart && onChangeStart();
            const isChecked = retryCheck ? retryCheck : checked;

            let response = undefined;

            setPendingChanges((prevState) => {
                const existingPending = { ...prevState };
                const existingCurrentAggregate = existingPending[subscription.aggregateId] ?? {};

                existingPending[subscription.aggregateId] = {
                    ...existingCurrentAggregate,
                    [toggleTriggered]: true
                };

                return { ...existingPending };
            });

            if (isChecked) {
                if (toggleTriggered === "sms") {
                    if (
                        (checked !== undefined && subscriberPhoneNum && subscriberPhoneNum !== "") ||
                        (newPhoneNumber && newPhoneNumber !== "")
                    ) {
                        response = await notificationService.subscribeUserToList({
                            aggregateId: subscription.aggregateId,
                            subscriptionType,
                            organization,
                            authUser,
                            subscriptionName: subscription.name,
                            phoneNumber: subscriberPhoneNum || newPhoneNumber,
                            profileInfo: null
                        });
                    }
                } else {
                    response = await notificationService.subscribeUserToList({
                        aggregateId: subscription.aggregateId,
                        subscriptionType,
                        organization,
                        authUser,
                        subscriptionName: subscription.name,
                        phoneNumber: "",
                        profileInfo: null
                    });
                }
            } else {
                const phone =
                    subscriberPhoneNum && subscriberPhoneNum !== ""
                        ? subscriberPhoneNum
                        : newPhoneNumber && newPhoneNumber !== ""
                          ? newPhoneNumber
                          : "";

                response = await notificationService.unsubscribeUserFromList({
                    aggregateId: subscription.aggregateId,
                    subscriptionType: unsubscriptionType,
                    organization,
                    authUser,
                    subscriptionName: subscription.name,
                    phoneNumber: phone,
                    profileInfo: null
                });
            }

            if (!response.error) {
                incrementSubsAndUnsubs();

                let remainingSubscription: SubscriptionType | null = null;
                !isChecked && (remainingSubscription = unsubscriptionType);

                const message = convertTypeToMessage(
                    remainingSubscription ?? subscriptionType,
                    subscription.name,
                    !isChecked,
                    subscription.productType
                );

                subscription.productType === ProductType.CivicEngageCentral
                    ? enqueueSnackbar(message, {
                          variant: "warning"
                      })
                    : enqueueSnackbar(message, {
                          variant: "success"
                      });

                if (!isStaffUser(auth.user)) {
                    GAevent({
                        action: `
                            User ${isChecked ? "Subscribed" : "Unsubscribed"} - [${SubscriptionType[subscriptionType]}]
                        `,
                        category: "Subscriptions",
                        orgName: organization.name,
                        label: subscription.name
                    });
                }

                success = subscription.productType !== ProductType.CivicEngageCentral;
            } else {
                const errorMessage = response.error
                    ? response.error
                    : `Error while trying to  ${isChecked ? "subscribe user to" : "unsubscribe user from"} list`;

                enqueueSnackbar(errorMessage, {
                    variant: "error",
                    persist: true
                });
            }
        } else {
            setLoginNoticeOpen(true);

            localStorage?.setItem(
                `retrySubscriptionAction_${toggleType}_${subscription.aggregateId}`,
                JSON.stringify({
                    retryCheck: checked,
                    subscriptionType,
                    unsubscriptionType,
                    subscription,
                    toggleTriggered
                })
            );

            return false;
        }

        setIsLoading({ email: false, sms: false });

        setPendingChanges((prevState) => {
            const existingPending = { ...prevState };
            const existingCurrentAggregate = existingPending[subscription.aggregateId] ?? {};

            existingPending[subscription.aggregateId] = { ...existingCurrentAggregate, [toggleTriggered]: false };

            return { ...existingPending };
        });

        onChangeComplete && onChangeComplete(success);

        return success;
    };

    if (authUser && retrySubscriptionAction) {
        const retryObj = JSON.parse(retrySubscriptionAction);

        const {
            retryCheck,
            subscriptionType,
            unsubscriptionType,
            subscription,
            toggleTriggered,
            waitingForPhoneNumber,
            checked
        } = retryObj;

        if (
            !waitingForPhoneNumber &&
            toggleTriggered === toggleType &&
            subscription.aggregateId === props.subscription.aggregateId
        ) {
            retrySubscriptionAction = null;
            localStorage?.removeItem(`retrySubscriptionAction_${toggleType}_${subscription.aggregateId}`);

            const callAsync = async () => {
                await handleUserSubscription(
                    subscriptionType,
                    unsubscriptionType,
                    subscription,
                    toggleTriggered,
                    checked,
                    retryCheck
                );
            };

            callAsync();
        }
    }

    const handleToggleDialogVisibility = (value: boolean) => {
        setShowContactDialog(value);

        if (value === false && newPhoneNumber !== "") {
            onPhoneNumberSave();
        }
    };

    const handleLogin = () => {
        const args = {
            state: { from: window.location.pathname }
        } as SigninPopupArgs;

        auth.signinPopup(setupAcrValue(auth, args, organization?.id));
        setLoginNoticeOpen(false);
    };

    return (
        <>
            {toggleType === "email" ? (
                <div style={{ display: "flex", alignItems: "center" }}>
                    <Tooltip
                        delay="instant"
                        placement="bottom"
                        title={
                            portalNotification
                                ? "Click to manage subscription settings."
                                : "Receive Email Notifications"
                        }
                    >
                        <EmailIcon color={portalNotification ? "disabled" : "inherit"} />
                    </Tooltip>

                    <Toggle
                        id={`Subscribe to Email Notifications - ${subscription.aggregateId}-email-toggle`}
                        checked={emailSubscription}
                        data-testid={`${subscription.aggregateId}-email-toggle`}
                        disabled={portalNotification}
                        loading={pendingChanges[subscription.aggregateId]?.email || isLoading.email}
                        onChange={async (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
                            const success = await handleUserSubscription(
                                smsSubscription ? SubscriptionType.EmailAndSms : SubscriptionType.Email,
                                smsSubscription ? SubscriptionType.Sms : SubscriptionType.None,
                                subscription,
                                "email",
                                checked
                            );
                            return success;
                        }}
                        inputProps={{ "aria-label": `Receive Email Notifications for ${subscription.name}` }}
                    />
                </div>
            ) : (
                <div style={{ display: "flex", alignItems: "center" }}>
                    <Tooltip
                        title={
                            portalNotification ? "Click to manage subscription settings." : "Receive SMS Notifications"
                        }
                    >
                        <PhoneIcon
                            color={
                                portalNotification || subscription.availableSubscriptionType === SubscriptionType.Email
                                    ? "disabled"
                                    : "inherit"
                            }
                        />
                    </Tooltip>

                    <Toggle
                        id={`Subscribe to SMS Notifications - ${subscription.aggregateId}-sms-toggle`}
                        checked={smsSubscription}
                        data-testid={`${subscription.aggregateId}-sms-toggle`}
                        loading={pendingChanges[subscription.aggregateId]?.sms || isLoading.sms}
                        disabled={
                            portalNotification || subscription.availableSubscriptionType === SubscriptionType.Email
                                ? true
                                : false
                        }
                        onChange={async (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
                            const success = await handleUserSubscription(
                                emailSubscription ? SubscriptionType.EmailAndSms : SubscriptionType.Sms,
                                emailSubscription ? SubscriptionType.Email : SubscriptionType.None,
                                subscription,
                                "sms",
                                checked
                            );
                            return success;
                        }}
                        inputProps={{ "aria-label": `Receive SMS Notifications for ${subscription.name}` }}
                    />
                </div>
                // ) : null}
            )}
            <SimpleDialog
                isOpen={loginNoticeOpen}
                title="Sign in needed"
                primaryBtnText="Sign In or Create an Account"
                primaryAction={handleLogin}
                onClose={() => {
                    setLoginNoticeOpen(false);
                    localStorage?.removeItem(`retrySubscriptionAction_${toggleType}_${subscription.aggregateId}`);
                }}
            >
                <Typography>You must be signed in to subscribe.</Typography>
            </SimpleDialog>

            <NotificationsContactDialog
                show={showContactDialog}
                toggleDialogVisibility={handleToggleDialogVisibility}
                orgName={organization?.name}
                setExternalPhoneNumberState={setNewPhoneNumber}
            />
        </>
    );
};
