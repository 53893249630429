import React, { useState } from "react";
import Tabs from "@civicplus/preamble-ui/lib/Tabs";
import LegislationTab from "./legislationTab";
import { ChangesType, LegislationType } from "../types";
import ChangesTab from "./RecentChangesTab";

export interface PublicationContentProps {
    legislationItems?: Array<LegislationType>;
    changesItems?: Array<ChangesType>;
}

export const PublicationContent: React.FC<PublicationContentProps> = (props) => {
    const { legislationItems, changesItems } = props;
    const [currentTab, setCurrentTab] = useState(0);

    const handleTabChange = (_e: React.ChangeEvent<unknown>, value: number) => {
        setCurrentTab(value);
    };

    const tabsContents = [
        {
            label: "Recently Passed Legislation",
            contents: <LegislationTab items={legislationItems} />
        },
        {
            label: "Recent Changes",
            contents: <ChangesTab items={changesItems} />
        }
    ];

    return (
        <Tabs
            id="publication-tabs"
            disableMargin={true}
            value={currentTab}
            onChange={handleTabChange}
            tabContents={tabsContents}
            tabsContentRenderingVariant="currentTab"
            color="default"
            variant="scrollable"
        />
    );
};

export default PublicationContent;
