import React, { useEffect, useState, useMemo, useCallback, useRef } from "react";
import AlertsContactDialog from "./components/AlertsContactDialog";
import AlertsTab from "./components/AlertsTab";
import ApiService from "../../../services/apiService";
import HiddenPageAlert from "../../../components/HiddenPageAlert";
import Layout from "@civicplus/preamble-ui/lib/Layout";
import Link from "@civicplus/preamble-ui/lib/Link";
import Loader from "@civicplus/preamble-ui/lib/Loader";
import NotificationsContactDialog from "./components/notifications/NotificationsContactDialog";
import Tabs, { TabsProps } from "@civicplus/preamble-ui/lib/Tabs";
import useStyles from "./styles";
import WeatherAlertsTab from "./components/WeatherAlertsTab";
import { NavigationOption } from "../../../types/Organization";
import { NotificationPageWrapper as NotificationsTab } from "./components/notifications/NotificationsTab";
import { toggleDisplayFraseChatbot } from "../../../shared/functions";
import { useAccountInfo } from "../../../hooks/useAccountInfo";
import { useAuth } from "../../../providers/AuthProvider";
import { useNavigate, useSearchParams, Link as RouterLink } from "react-router-dom";
import { useOrganization } from "../../../stores/organizationStore";
import { useEmbedStore } from "../../../stores/embedStore";
import { HtmlDescription } from "../../../components/Layout/HtmlDescription";
import { DocumentType } from "../../../types/SearchDocument";
import Typography from "@civicplus/preamble-ui/lib/Typography";
import { NotificationsService } from "../../../services/notificationsService";

const tabs = {
    Notifications: "notifications",
    Alerts: "alerts",
    "Weather Alerts": "weather"
} as const;

export const NotificationsAndAlertsPage: React.FC = () => {
    const auth = useAuth();
    const classes = useStyles();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const isEmbed = useEmbedStore((state) => state.isInitialized);
    const [isAccountLoading, userAccountInfo, reloadAccountInfo] = useAccountInfo();
    const [showContactDialog, setShowContactDialog] = useState(false);
    const notificationService = useMemo(() => new NotificationsService(), []);
    const [currentTab, setCurrentTab] = useState<false | number>(false);
    const isKonexusSyncCompleted = useRef(false);

    const lowerCaseSearchParams = useMemo(() => {
        return new URLSearchParams([...searchParams].map(([key, value]) => [key.toLowerCase(), value]));
    }, [searchParams]);

    const [organization, availableNotifications, fetchNotificationsAvailability] = useOrganization((state) => [
        state.organization,
        state.availableNotifications,
        state.fetchNotificationsAvailability
    ]);

    const handleShowContactDialog = () => {
        setShowContactDialog(true);
    };

    useEffect(() => {
        if (organization && !availableNotifications) {
            fetchNotificationsAvailability(organization.name, notificationService);
        }
    }, [organization, fetchNotificationsAvailability, availableNotifications, notificationService]);

    useEffect(() => {
        if (
            auth &&
            auth.user &&
            organization &&
            !isAccountLoading &&
            !userAccountInfo?.phoneNumbersUpdated &&
            availableNotifications
        ) {
            if (userAccountInfo?.konexusUser || isKonexusSyncCompleted.current) {
                return;
            }
            if (
                availableNotifications === undefined ||
                (!availableNotifications[DocumentType.AlertList] && !availableNotifications[DocumentType.AlertWeather])
            ) {
                return;
            }

            const konexusAccount = async () => {
                const email = auth.user?.profile.name;
                const orgName = organization?.name;
                const userId = auth.user?.profile?.sub || "";
                const url = `${orgName}/user/${encodeURIComponent(userId)}/sync/konexusProfile`;
                isKonexusSyncCompleted.current = true;

                const konexusUser = await ApiService.post({
                    url,
                    requestInit: {
                        headers: {
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify({
                            email
                        })
                    },
                    authUser: auth.user
                });

                if (konexusUser) {
                    reloadAccountInfo();
                }
            };

            konexusAccount();
        }
    }, [
        auth,
        isAccountLoading,
        organization,
        reloadAccountInfo,
        userAccountInfo?.konexusUser,
        userAccountInfo?.phoneNumbersUpdated,
        availableNotifications
    ]);

    // TODO: move every instance of toggleDisplayFraseChatbot to a higher level (e.g. Layout)
    useEffect(() => {
        toggleDisplayFraseChatbot(organization, isEmbed || false);
    }, [organization, isEmbed]);

    const handleTabChange = (_e: React.ChangeEvent<unknown>, value: number) => {
        const tabname = tabsContents[value].label;
        const urlParam = tabs[tabname as keyof typeof tabs];

        setCurrentTab(value);
        navigate(`/${organization?.name}/notifications?tab=${urlParam}`);
    };

    const handleToggleDialogVisibility = (value: boolean) => {
        setShowContactDialog(value);
    };

    const tabsContents = useMemo(() => {
        const tabs: TabsProps["tabContents"] = [];
        if (availableNotifications !== undefined) {
            if (availableNotifications[DocumentType.SubscriptionList] === true) {
                tabs.push({
                    label: "Notifications",
                    contents: <NotificationsTab onShowContactDialog={handleShowContactDialog} />
                });
            }

            if (availableNotifications[DocumentType.AlertList] === true) {
                tabs.push({
                    label: "Alerts",
                    contents: <AlertsTab onShowContactDialog={handleShowContactDialog} />
                });
            }
            if (availableNotifications[DocumentType.AlertWeather] === true) {
                tabs.push({
                    label: "Weather Alerts",
                    contents: <WeatherAlertsTab onShowContactDialog={handleShowContactDialog} />
                });
            }
        }

        return tabs;
    }, [availableNotifications]);

    const getCurrentTab = useCallback(
        (tabName: string | null): number => {
            if (!tabs) return 0;

            const tabLabel = Object.entries(tabs).find(([_, value]) => value === tabName)?.[0];
            const index = tabsContents.findIndex((tab) => tab.label === tabLabel);

            return index === -1 ? 0 : index;
        },
        [tabsContents]
    );

    useEffect(() => {
        if (availableNotifications !== undefined && tabsContents.length > 0) {
            const slug = lowerCaseSearchParams.get("tab");
            const currentTab = getCurrentTab(slug);
            setCurrentTab(currentTab);
        }
    }, [availableNotifications, getCurrentTab, lowerCaseSearchParams, navigate, organization?.name, tabsContents]);

    if (!organization || !auth || availableNotifications === undefined) {
        return <Loader verticallyCenter={true} />;
    }

    const navigationItem = organization.navigation && organization.navigation[NavigationOption.AlertsAndNotifications];

    return (
        <Layout
            TitlebarProps={{
                id: "alerts-and-notifications-titlebar",
                title: navigationItem
                    ? (navigationItem.customLabel ?? navigationItem.defaultLabel)
                    : "Alerts & Notifications",
                breadcrumbs: [
                    <Link key="home" to={`/${organization?.name}`} component={RouterLink}>
                        Home
                    </Link>
                ],
                description: (
                    <HtmlDescription
                        description={
                            navigationItem?.additionalProductInformation ??
                            `Manage your notification preferences for ${organization?.name}.`
                        }
                    />
                )
            }}
        >
            <HiddenPageAlert navOption={NavigationOption.AlertsAndNotifications} />

            {tabsContents.length === 0 ? (
                <Typography justifyContent="center" variant="body1">
                    The organization you have selected does not yet have subscription management available. While we
                    work to integrate with the location’s subscription provider(s), you will need to manage
                    subscriptions following existing directions from the relevant organization.
                </Typography>
            ) : (
                <>
                    <Tabs
                        id="notifications-tabs"
                        data-testid="notifications-tabs"
                        value={currentTab}
                        onChange={handleTabChange}
                        tabContents={tabsContents}
                        tabsContentRenderingVariant="currentTab"
                        color="default"
                        className={classes.tabRoot}
                        variant="scrollable"
                    />
                    {showContactDialog && currentTab !== false && tabsContents[currentTab].label === "Notifications" ? (
                        <NotificationsContactDialog
                            show={showContactDialog}
                            toggleDialogVisibility={handleToggleDialogVisibility}
                            orgName={organization.name}
                        />
                    ) : (
                        <AlertsContactDialog
                            show={showContactDialog}
                            toggleDialogVisibility={handleToggleDialogVisibility}
                        />
                    )}
                </>
            )}
        </Layout>
    );
};

export default NotificationsAndAlertsPage;
