import React, { useEffect } from "react";
import { useParams, useNavigate, Link as RouterLink } from "react-router-dom";
import Button from "@civicplus/preamble-ui/lib/Button";
import ButtonGroup from "@civicplus/preamble-ui/lib/ButtonGroup";
import ErrorMessage from "@civicplus/preamble-ui/lib/ErrorMessage";
import Layout from "@civicplus/preamble-ui/lib/Layout";
import Link from "@civicplus/preamble-ui/lib/Link";
import Loader from "@civicplus/preamble-ui/lib/Loader";
import { useOrganization } from "../../../../stores/organizationStore";
import { useTenantCivicPlus, formService, formsAppService, submissionService, OneBlinkAppsError } from "@oneblink/apps";
import { FormTypes } from "@oneblink/types";
import { Helmet } from "react-helmet";
import { useEmbedStore } from "../../../../stores/embedStore";
import { useSnackbar } from "notistack";
import "./OptimizeForm.css";

enum FormState {
    Loading,
    FormDefitionLoaded,
    Submitting,
    Complete,
    Errored
}

function copyToClipboard(val: string) {
    const dummy = document.createElement("input");
    document.body.appendChild(dummy);

    dummy.setAttribute("id", "dummy_id");
    (window.document!.getElementById("dummy_id") as HTMLInputElement).value = val;
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);
}

export const OptimizeForm: React.FC = () => {
    useTenantCivicPlus();
    const { formId } = useParams();
    const navigate = useNavigate();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [organization] = useOrganization((state: any) => [state.organization]);
    const [baseUrl] = useEmbedStore((state: any) => [state.baseUrl]);

    const [formState, setFormState] = React.useState<FormState>(FormState.Loading);
    const [formDefinition, setFormDefinition] = React.useState<FormTypes.Form | null>(null);
    const [formsAppServiceResponse, setFormsAppServiceResponse] = React.useState<
        formsAppService.FormsAppConfigurationResponse | undefined
    >(undefined);

    useEffect(() => {
        let isCancelled = false;
        async function loadForm() {
            try {
                const localFormDefinition = await formService.getForm(Number(formId));
                const formsAppServiceResponse: formsAppService.FormsAppConfigurationResponse =
                    await formsAppService.getFormsAppConfiguration(localFormDefinition.formsAppIds[0]);

                if (!isCancelled) {
                    setFormDefinition(localFormDefinition);
                    setFormsAppServiceResponse(formsAppServiceResponse);
                    setFormState(FormState.FormDefitionLoaded);
                }
            } catch {
                if (!isCancelled) {
                    setFormState(FormState.Errored);
                }
            }
        }

        loadForm();
        return () => {
            isCancelled = true;
        };
    }, [formId]);

    const onCancel = React.useCallback(() => {
        navigate(-1); // sends user back on browser history
    }, [navigate]);

    const onSubmit = React.useCallback(
        async (newFormSubmission: submissionService.NewFormSubmission) => {
            try {
                const formSubmission: submissionService.FormSubmission = {
                    ...newFormSubmission,
                    formsAppId: formDefinition!.formsAppIds[0],
                    externalId: null,
                    draftId: null,
                    jobId: null,
                    preFillFormDataId: null
                };

                let paymentReceiptUrl = undefined;

                if (formSubmission.definition.paymentEvents) {
                    paymentReceiptUrl = `${window.location.href}/payment-receipt`;
                }

                const formSubmissionResult: submissionService.FormSubmissionResult = await submissionService.submit({
                    formSubmission,
                    paymentReceiptUrl: paymentReceiptUrl,
                    isPendingQueueEnabled: false,
                    shouldRunExternalIdGeneration: false,
                    shouldRunServerValidation: true
                });

                let path = "";

                if (formSubmissionResult.payment) {
                    path = formSubmissionResult.payment.hostedFormUrl;
                }

                const pushRelativePath = () => {
                    navigate(path);
                };

                if (formSubmissionResult.submissionId && formSubmissionResult.payment) {
                    return submissionService.executePostSubmissionAction(formSubmissionResult, pushRelativePath);
                }

                enqueueSnackbar(
                    `Successfully submitted form with submission id: ${formSubmissionResult.submissionId}`,
                    {
                        variant: "success",
                        persist: true,
                        action: (snackbarId) => (
                            <ButtonGroup layout="right">
                                <Button
                                    id="copy-submission-id"
                                    size="small"
                                    title="Copy submission id"
                                    style={{ pointerEvents: "all" }}
                                    onClick={() => {
                                        copyToClipboard(formSubmissionResult.submissionId ?? "");
                                        closeSnackbar(snackbarId);
                                    }}
                                >
                                    Copy Submission Id
                                </Button>
                                <Button
                                    id="dismiss-submission-id"
                                    size="small"
                                    color="inherit"
                                    variant="outlined"
                                    style={{ pointerEvents: "all" }}
                                    onClick={() => {
                                        closeSnackbar(snackbarId);
                                    }}
                                >
                                    Dismiss
                                </Button>
                            </ButtonGroup>
                        )
                    }
                );

                navigate(`/${organization?.name}/forms`);
                return true;
            } catch (error) {
                let displayError: OneBlinkAppsError;

                if (!(error instanceof OneBlinkAppsError)) {
                    displayError = new OneBlinkAppsError((error as Error).message);
                } else {
                    displayError = error;
                }

                enqueueSnackbar(`An error has occurred while attempting to submit: ${displayError.message}`, {
                    variant: "error",
                    persist: true
                });

                console.error("An error has occurred while attempting to submit: ", displayError);

                return false;
            }
        },
        [closeSnackbar, enqueueSnackbar, formDefinition, navigate, organization?.name]
    );

    useEffect(() => {
        let windowTimeout: number | undefined = undefined;

        function waitForOptimizeFormApp() {
            return new Promise(function checkForRenderOptimizeForm(resolve) {
                if ((window as any)?.CivicPlus?.renderOptimizeForm !== undefined) {
                    return resolve((window as any).CivicPlus.renderOptimizeForm);
                }
                windowTimeout = window.setTimeout(() => checkForRenderOptimizeForm(resolve), 500);
            });
        }

        async function renderOptimizeForm() {
            await waitForOptimizeFormApp();

            if (formDefinition && formsAppServiceResponse) {
                (window as any).CivicPlus.renderOptimizeForm({
                    rootHtmlId: "optimizeForm",
                    form: formDefinition,
                    formsAppConfiguration: formsAppServiceResponse,
                    onCancel: onCancel,
                    onSubmit: onSubmit
                });
            }
        }

        renderOptimizeForm();

        return () => {
            if (windowTimeout !== undefined) {
                window.clearTimeout(windowTimeout);
            }
        };
    }, [formDefinition, formsAppServiceResponse, navigate, onCancel, onSubmit, organization]);

    return (
        <Layout
            TitlebarProps={{
                id: "forms-titlebar",
                title: formDefinition?.name,
                breadcrumbs: [
                    <Link key="home" to={`/${organization?.name}`} component={RouterLink}>
                        Home
                    </Link>,
                    <Link key="formListing" to={`/${organization?.name}/forms`} component={RouterLink}>
                        Forms
                    </Link>
                ]
            }}
        >
            <Helmet>
                <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
                <link rel="stylesheet" href={`${baseUrl ?? ""}/optimize/css/optimizeFormApp.css`} />
                <script id="optimizeFormAppScript" src={`${baseUrl ?? ""}/optimize/js/optimizeFormApp.js`}></script>
            </Helmet>

            <div id="optimizeForm" className="oneblink-apps-react-styles"></div>

            {formState === FormState.Loading ? <Loader verticallyCenter={true} /> : null}

            {formState === FormState.Errored ? (
                <ErrorMessage id="error-error" title="Failed to load form" align="center">
                    An unknown error occurred. Please try again later.
                </ErrorMessage>
            ) : null}
        </Layout>
    );
};

export default OptimizeForm;
