import { OptionShape } from "@civicplus/preamble-ui/lib/Autocomplete";
import { PhoneNumber } from "./PhoneNumberUtils";

export const phoneOptions = (
    existingPhoneNumbers: PhoneNumber[] | undefined,
    needTextSupport: boolean | undefined = false
): OptionShape[] => {
    if (!existingPhoneNumbers) {
        return [];
    }

    if (needTextSupport) {
        // Removing those phone numbers that have the checkbox completed, knowing they were created in Account or Portal
        existingPhoneNumbers = existingPhoneNumbers?.filter(
            (phoneNumber) => !(phoneNumber.allowVoice === true && phoneNumber.allowSms === false)
        );
    }

    return existingPhoneNumbers?.map((phoneNumber: PhoneNumber) => {
        return { value: phoneNumber.id, label: phoneNumber.number };
    });
};

export const formatPhoneValue = (phoneNumber: PhoneNumber | undefined): OptionShape | undefined => {
    if (!phoneNumber) {
        return undefined;
    }

    return { value: phoneNumber.id, label: phoneNumber.number };
};
