import { SectionIconKey } from "../components/SectionsIcons/SectionsIcons";
import { ProductType } from "./ProductType";
import { Dictionary } from "./Content";
import { DocumentType, SearchDocument } from "./SearchDocument";

interface BaseUser {
    id: string;
    email: string;
    firstName: string;
    lastName: string;
}

export interface User extends BaseUser {
    phoneNumber?: string;
    isValidated?: boolean;
}

export interface Link {
    id: number;
    aggregateId: string;
    url: string;
    title: string;
    section: string;
    sectionString: string;
    description?: string;
    icon: keyof SectionIconKey;
    created: string;
    createdBy: string;
    lastModified: string;
    lastModifiedBy: string;
    lastModifiedByFull?: User;
    organizationId: string;
    isFavorite: boolean;
    isHidden: boolean;
    productType: ProductType;
    additionalInformation?: Dictionary[];
    isFeatured: boolean;
    documentType?: DocumentType;
    tags: string[];
    customTags?: string[];
    externalTags?: string[];
    startDate?: Date | null;
    endDate?: Date | null;
}

export class LinkUtilities {
    static opensInNewWindow(
        productType: ProductType,
        isEmbed?: boolean,
        additionalInformation?: Dictionary[]
    ): boolean {
        switch (productType) {
            case ProductType.CivicOptimize:
                return isEmbed &&
                    additionalInformation &&
                    additionalInformation.length > 0 &&
                    ["paymentsEvents", "CP_PAY"].includes(additionalInformation[0].value)
                    ? true
                    : false;
            default:
                return true;
        }
    }

    static navigateUrl(
        productType: ProductType,
        organization?: string,
        id?: string,
        url?: string,
        documentType?: DocumentType,
        isEmbed?: boolean,
        payEvents?: Dictionary[]
    ): { url: string; isExternal: boolean } {
        let result = { url: url ?? "", isExternal: true };

        switch (productType) {
            case ProductType.CivicOptimize:
                if (
                    url === undefined ||
                    url === "" ||
                    (!isEmbed &&
                        payEvents &&
                        payEvents.length > 0 &&
                        ["paymentsEvents", "CP_PAY"].includes(payEvents[0].value))
                ) {
                    result = { url: `/${organization}/forms/optimize/${id}`, isExternal: false };
                } else {
                    result = { url, isExternal: true };
                }
                break;
            case ProductType.CivicPlusPortal:
                result = { url: url ?? "", isExternal: true };
                break;
        }

        if (documentType === DocumentType.SubscriptionList) {
            result = { url: `/${organization}/notifications`, isExternal: false };
        }

        return result;
    }
}

export interface PublicLink {
    id: string;
    url: string;
    href?: string;
    title: string;
    description?: string;
    created: string;
    lastModified: string;
    userId: string;
    section: string;
    productType: ProductType.CivicPlusPortal;
    onDeleteAction: any;
    onDeleteClose: any;
    onModifyAction: any;
    onModifyClose: any;
}

export interface UserFavoriteLink {
    userId: string;
    linkId: number;
}

export const convertSearchDocumentToLink = (searchDocument: SearchDocument, section: string): Link => {
    const newLink: Link = {
        id: parseInt(searchDocument.itemId),
        aggregateId: searchDocument.id,
        title: searchDocument.title,
        url: searchDocument.url,
        description: searchDocument.description || "",
        tags: searchDocument.tags,
        externalTags: searchDocument.externalTags,
        customTags: searchDocument.customTags,
        productType: searchDocument.productType,
        organizationId: searchDocument.organizationId,
        created: searchDocument.createdDate.toString(),
        createdBy: "",
        lastModified: searchDocument.lastModifiedDate.toString(),
        lastModifiedBy: "",
        isFavorite: searchDocument.isFavorite,
        isHidden: searchDocument.isHidden,
        sectionString: section,
        section: section,
        icon: 0,
        isFeatured: searchDocument.isFeatured,
        documentType: searchDocument.type,
        startDate: searchDocument.startDate,
        endDate: searchDocument.endDate
    };
    return newLink;
};

export const GetSectionFromDocumentType = (type: DocumentType): string => {
    switch (type) {
        case DocumentType.Form:
            return "Forms";
        case DocumentType.SubscriptionList:
            return "Notifications";
        case DocumentType.AlertList:
            return "AlertList";
        case DocumentType.AlertWeather:
            return "AlertList";
        case DocumentType.Event:
            return "Meetings";
        case DocumentType.Link:
        case DocumentType.All:
        default:
            return "Links";
    }
};
