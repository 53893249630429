import React, { useEffect } from "react";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import Link from "@civicplus/preamble-ui/lib/Link";
import { useOrganization } from "../../../../stores/organizationStore";
import { useTenantCivicPlus } from "@oneblink/apps";
import { Helmet } from "react-helmet";
import { useEmbedStore } from "../../../../stores/embedStore";
import "./OptimizeForm.css";
import Layout from "@civicplus/preamble-ui/lib/Layout";

export const OptimizePaymentReceipt: React.FC = () => {
    useTenantCivicPlus();
    const navigate = useNavigate();
    const [organization] = useOrganization((state: any) => [state.organization]);
    const [baseUrl] = useEmbedStore((state: any) => [state.baseUrl]);

    useEffect(() => {
        let windowTimeout: number | undefined = undefined;

        function waitForOptimizeFormPaymentReceipt() {
            return new Promise(function checkForRenderOptimizeFormReceipt(resolve) {
                if ((window as any)?.CivicPlus?.renderOptimizeFormPaymentReceipt !== undefined) {
                    return resolve((window as any).CivicPlus.renderOptimizeFormPaymentReceipt);
                }
                windowTimeout = window.setTimeout(() => checkForRenderOptimizeFormReceipt(resolve), 500);
            });
        }

        async function renderOptimizeFormPaymentReceipt() {
            await waitForOptimizeFormPaymentReceipt();
            (window as any).CivicPlus.renderOptimizeFormPaymentReceipt({
                rootHtmlId: "optimizeFormReceipt",
                doneRedirectUrl: `${window.location.origin}/${organization?.name}/forms`
            });
        }

        renderOptimizeFormPaymentReceipt();

        return () => {
            if (windowTimeout !== undefined) {
                window.clearTimeout(windowTimeout);
            }
        };
    }, [navigate, organization]);

    return (
        <Layout
            TitlebarProps={{
                id: "forms-titlebar",
                title: "Receipt",
                breadcrumbs: [
                    <Link key="home" to={`/${organization?.name}`} component={RouterLink}>
                        Home
                    </Link>,
                    <Link key="formListing" to={`/${organization?.name}/forms`} component={RouterLink}>
                        Forms
                    </Link>
                ]
            }}
        >
            <Helmet>
                <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
                <link rel="stylesheet" href={`${baseUrl ?? ""}/optimize/css/optimizeFormApp.css`} />
                <script id="optimizeFormAppScript" src={`${baseUrl ?? ""}/optimize/js/optimizeFormApp.js`}></script>
            </Helmet>

            <div id="optimizeFormReceipt" className="oneblink-apps-react-styles"></div>
        </Layout>
    );
};

export default OptimizePaymentReceipt;
