import ApiService from "./apiService";
import {
    IGetOrganizationListResponse,
    IOrganizationService
} from "@civicplus/preamble-ui/lib/Services/OrgService/OrgService";
import IOdata from "@civicplus/preamble-ui/lib/Services/OrgService/Types/Odata";
import { IUser } from "@civicplus/preamble-ui/lib/entities/baseEntities";
import { getStringError } from "@civicplus/preamble-ui/lib/Utilities/StringHelper";
import { CppOrganization } from "@civicplus/preamble-ui/lib/Services/OrgService/Types/Organization";
import { isInRole } from "@civicplus/preamble-ui/lib/Utilities/User";
import { decodeToken } from "@civicplus/preamble-ui/lib/Utilities/TokenHelper";
import { User } from "oidc-client-ts";

export default class PortalOrganizationService implements IOrganizationService {
    private accessToken: string;
    private idToken: string;

    constructor(user?: User | null) {
        this.accessToken = user?.access_token ?? "";
        this.idToken = user?.id_token ?? "";
    }

    IsInRole(role: string): boolean {
        return isInRole(role, this.GetUser());
    }

    async GetOrganization(organizationName: string): Promise<CppOrganization> {
        try {
            return await ApiService.get({ url: `Organization/${organizationName}` });
        } catch (e) {
            const errorMessage = getStringError(e);
            throw new Error(`PortalOrganizationService - GetOrganization: ${errorMessage}`);
        }
    }

    async GetOrganizationList({ search }: IOdata): Promise<IGetOrganizationListResponse> {
        try {
            const user = this.GetUser();
            const orgs = await ApiService.get({ url: `organization/search/${search ?? ""}`, authUser: user });
            return orgs;
        } catch (e) {
            const errorMessage = getStringError(e);
            throw new Error(`PortalOrganizationService - GetOrganizationList: ${errorMessage}`);
        }
    }

    GetUser(): IUser | undefined {
        let user: IUser | undefined;

        if (this.idToken && this.accessToken) {
            const idPayload = decodeToken(this.idToken);
            const accessPayload = decodeToken(this.accessToken);
            const firstName = idPayload["given_name"];
            const lastName = idPayload["family_name"];

            if (firstName && lastName) {
                user = {
                    accessToken: this.accessToken,
                    email: idPayload.email,
                    firstName,
                    id: idPayload.sub,
                    idToken: this.idToken,
                    lastName,
                    role: accessPayload.role
                };
            }
        }

        return user;
    }

    async GetUserOrganizationList({ search }: IOdata): Promise<IGetOrganizationListResponse> {
        return this.GetOrganizationList({ search });
    }
}
