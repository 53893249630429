import { makeStyles } from "@civicplus/preamble-ui/lib/Utilities/ThemeHelper";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
        marginTop: 25
    },
    zoneTitleWrapper: {
        display: "flex",
        alignItems: "center"
    },
    zoneTitle: {
        marginLeft: 15,
        fontWeight: "bold",
    },
    icon: {
        width: theme.typography.h4.fontSize,
        color: theme.palette.primary.main
    },
    newLinkButton: {
        marginRight: 15
    },
    content: {
        marginTop: 20
    },
    emptyZone: {
        width: "100%",
        padding: 25,
        textAlign: "center",
        backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='grey' stroke-width='2' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
        color: theme.palette.grey[700],
        "& > h5": {
            marginBottom: 8,
            color: theme.palette.grey[600],
            fontWeight: "bold"
        },
        "& > p": {
            fontSize: "1.2rem"
        }
    },
    emptyMessage: {
        fontSize: "1.2rem",
        marginBottom: 15,
        textAlign: "center",
        color: "#555"
    }
}));

export default useStyles;
