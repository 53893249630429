import React, { useMemo, useCallback, useState, useRef } from "react";
import ApiService, { ApiError, parseErrorMessage } from "../../../services/apiService";
import CheckCircleOutline from "@mui/icons-material/CheckCircleOutline";
import CloseIcon from "@mui/icons-material/Close";
import Menu from "@civicplus/preamble-ui/lib/Menu";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@civicplus/preamble-ui/lib/Typography";
import { BaseSettingsSectionProps } from "../SettingsSection";
import { visuallyHidden } from "@mui/utils";
import { NavigationItem, NavigationOption } from "../../../types/Organization";
import { makeStyles } from "@civicplus/preamble-ui/lib/Utilities/ThemeHelper";
import { NavigationDialog } from "./NavigationDialog";
import { TableData } from "@civicplus/preamble-ui/lib/AdvancedTable/AdvancedTableWrapperConstants";
import { useConfig } from "../../../providers/ConfigProvider";
import { useOrganization } from "../../../stores/organizationStore";
import AdvancedTableWrapper, {
    AdvancedTableWrapper as AdvancedTableWrapperClass
} from "@civicplus/preamble-ui/lib/AdvancedTable/AdvancedTableWrapper";
import { useSnackbar } from "notistack";

type RowDataType = string | number | boolean | NavigationItem;

type TableDataType = {
    rowData: RowDataType[];
    rowIndex: number;
};

const enum ColumnMap {
    Type,
    CustomLabel,
    DefaultLabel,
    IsActive,
    Disabled,
    Item
}

const useStyles = makeStyles((theme) => ({
    disabled: {
        cursor: "inherit",
        "& td": { color: theme.palette.text.disabled },
        "&&:hover, &:focus": {
            backgroundColor: theme.palette.common.white
        }
    },
    activeColumn: {
        width: "80px"
    },
    actionColumn: {
        width: "50px"
    }
}));

const NavigationTable: React.FC<BaseSettingsSectionProps> = ({ org, auth }) => {
    const { enqueueSnackbar } = useSnackbar();
    const config = useConfig();
    const classes = useStyles();
    const tableRef = useRef<AdvancedTableWrapperClass>(null);

    const [setOrganization] = useOrganization((state) => [state.setOrganization]);
    const [navigationSelected, setNavigationSelected] = useState<NavigationItem | null>(null);

    const mapData = useCallback((item: NavigationItem) => {
        const data = [item.type, item.customLabel, item.defaultLabel, item.isActive, item.disabled, item];
        return data;
    }, []);

    const getRows = useCallback(async (): Promise<TableData> => {
        const keys = Object.keys(org.navigation) as (keyof typeof NavigationOption)[];
        const data = keys.map((key) => mapData(org.navigation[key]));
        return { count: keys.length, data };
    }, [org.navigation, mapData]);

    const updateNavigationItem = useCallback(
        async (
            index: number,
            item: Pick<NavigationItem, "type" | "customLabel" | "icon" | "additionalProductInformation" | "isActive">
        ) => {
            try {
                const result: NavigationItem = await ApiService.put({
                    url: `${org.name}/navigation/${item.type}`,
                    authUser: auth.user,
                    requestInit: {
                        headers: {
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify(item)
                    }
                });

                if (tableRef.current) {
                    tableRef.current.reloadRow(index, mapData(result));
                }

                const updatedOrg = { ...org };
                updatedOrg.navigation[item.type] = result;
                setOrganization(updatedOrg);

                return result;
            } catch (ex) {
                const error = parseErrorMessage(ex as ApiError);
                enqueueSnackbar(`An error occurred while updating the navigation item.\r\n${error}`, {
                    variant: "error",
                    persist: true
                });

                return false;
            }
        },
        [auth.user, org, mapData, enqueueSnackbar, setOrganization]
    );

    const handleSaveNavigation = useCallback(
        async (item: NavigationItem) => {
            const index = Object.keys(org.navigation).findIndex((i) => i === item.type);
            const result = await updateNavigationItem(index, {
                type: item.type,
                customLabel: item.customLabel,
                icon: item.icon,
                additionalProductInformation: item.additionalProductInformation,
                isActive: item.isActive
            });

            if (result) {
                enqueueSnackbar("Navigation item saved successfully.", {
                    variant: "success"
                });
                setNavigationSelected(null);
            }
        },
        [enqueueSnackbar, updateNavigationItem, org.navigation]
    );

    const handleToggleVisibility = useCallback(
        async (index: number, item: NavigationItem) => {
            const updatedItem = { ...item, isActive: !item.isActive };
            const result = await updateNavigationItem(index, updatedItem);

            if (result) {
                const message: string = result.isActive
                    ? `${item.defaultLabel} is now visible for CivicPlus Portal users`
                    : `${item.defaultLabel} is now hidden for CivicPlus Portal users`;

                enqueueSnackbar(message, {
                    variant: "success"
                });
            }
        },
        [enqueueSnackbar, updateNavigationItem]
    );

    const actions = useMemo(
        () => (index: number, item: NavigationItem) => {
            const items = [
                {
                    children: "Edit",
                    onClick: () => {
                        setNavigationSelected(item);
                    }
                },
                {
                    children: item.isActive ? "Hide in Portal" : "Show in Portal",
                    onClick: () => {
                        handleToggleVisibility(index, item);
                    }
                }
            ];

            const orgServiceBaseUrl = config.getOrgServiceBaseUrl();

            item.applications.forEach((application) => {
                items.push({
                    children: `Manage ${application.name}`,
                    onClick: () => {
                        window.open(`${orgServiceBaseUrl}${org.name}/applications/${application.id}`, "_blank");
                    }
                });
            });

            return items;
        },
        [config, handleToggleVisibility, org.name]
    );

    const renderActionsMenu = useMemo(
        // eslint-disable-next-line react/display-name
        () => (_value: unknown, tableData: TableDataType) => {
            return (
                <>
                    <Menu
                        type="action"
                        items={actions(tableData.rowIndex, tableData.rowData[ColumnMap.Item] as NavigationItem)}
                        id={`actions-${tableData.rowIndex}`}
                        key={`actions-${tableData.rowIndex}`}
                        stopPropagation={true}
                        ButtonProps={{
                            disabled: tableData.rowData[ColumnMap.Disabled] === true
                        }}
                    />

                    {tableData.rowData[ColumnMap.Disabled] && (
                        <Typography style={visuallyHidden}>
                            The {tableData.rowData[ColumnMap.DefaultLabel].toString()} navigation is disabled.
                        </Typography>
                    )}
                </>
            );
        },
        [actions]
    );

    const columns = useMemo(() => {
        return [
            {
                name: "type",
                label: "Type",
                options: {
                    sort: false,
                    filter: false,
                    display: "excluded"
                }
            },
            {
                name: "label",
                label: "Label",
                options: {
                    sort: false,
                    filter: false,
                    display: "true"
                }
            },
            {
                name: "defaultLabel",
                label: "Default Label",
                options: {
                    sort: false,
                    filter: false,
                    display: "false"
                }
            },
            {
                name: "isActive",
                label: "Visible",
                options: {
                    sort: true,
                    filter: false,
                    display: "true",
                    setCellProps: () => ({ className: classes.activeColumn }),
                    customBodyRender: (_value: unknown, tableData: TableDataType) => {
                        const isVisible = tableData.rowData[ColumnMap.IsActive];
                        return (
                            <Tooltip title={isVisible ? "Navigation item is visible" : "Navigation item is hidden"}>
                                {isVisible ? <CheckCircleOutline /> : <CloseIcon color="disabled" />}
                            </Tooltip>
                        );
                    }
                }
            },
            {
                name: "disabled",
                label: "Disabled",
                options: {
                    sort: false,
                    filter: false,
                    display: "excluded"
                }
            },
            {
                name: "item",
                label: "item",
                options: {
                    sort: false,
                    filter: false,
                    display: "excluded"
                }
            },
            {
                name: "actions",
                label: "Actions",
                options: {
                    filter: false,
                    sort: false,
                    searchable: false,
                    viewColumns: false,
                    setCellHeaderProps: () => ({ className: classes.actionColumn }),
                    customBodyRender: renderActionsMenu
                }
            }
        ];
    }, [renderActionsMenu, classes.actionColumn, classes.activeColumn]);

    return (
        <>
            <AdvancedTableWrapper
                columns={columns}
                rows={getRows}
                rowsPerPage={25}
                scrollToTop={true}
                serverSide={false}
                ref={tableRef}
                setRowProps={(row: RowDataType[]) => ({ className: row[ColumnMap.Disabled] ? classes.disabled : "" })}
                emptyMessage="You currently have no Applications"
            />

            {navigationSelected && (
                <NavigationDialog
                    item={navigationSelected}
                    isOpen={true}
                    onClose={() => setNavigationSelected(null)}
                    onSave={handleSaveNavigation}
                />
            )}
        </>
    );
};

export default NavigationTable;
