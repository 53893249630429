import React, { useState } from "react";
import Button from "@civicplus/preamble-ui/lib/Button";
import GetAppIcon from "@mui/icons-material/GetApp";
import Grid from "@civicplus/preamble-ui/lib/Grid";
import Popover from "@civicplus/preamble-ui/lib/Popover";
import Typography from "@civicplus/preamble-ui/lib/Typography";
import { CivicClerkEvent, EventFilesType, EventFileTypeLabels, PublishedFile } from "../../types/CivicClerkEvent";
import { SearchDocument } from "../../types/SearchDocument";
import { makeStyles } from "@civicplus/preamble-ui/lib/Utilities/ThemeHelper";

const useStyles = makeStyles((theme) => ({
    eventsFilesPopover: {
        minWidth: "180px",
        maxWidth: "250px",
        padding: theme.spacing(1),
        boxShadow: theme.shadows[1]
    },
    downloadButton: {
        textTransform: "capitalize",
        letterSpacing: "normal",
        justifyContent: "left"
    }
}));

interface EventFilesPopoverProps {
    event: SearchDocument;
}

const EventFilesPopover: React.FC<EventFilesPopoverProps> = ({ event }) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const open = Boolean(anchorEl);
    const item = event.item as CivicClerkEvent;
    const hasFiles = item.publishedFiles?.length > 0;
    const baseUrl = "https://" + event.url.split("/")[2].replace("portal", "api");

    const handleClick = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);

    const handleDownloadButton = async (file: PublishedFile) => {
        const downloadUrl = `${baseUrl}/v1/Meetings/GetMeetingFileStream(fileId=${file.fileId},plainText=false)`;
        window.open(downloadUrl, "_blank");
    };

    return (
        <>
            <Button
                size="small"
                type="icon"
                onClick={handleClick}
                disabled={!hasFiles}
                stopPropagation={true}
                id={`files-btn-${item.id}`}
                data-testid={`files-btn-${item.id}`}
                title={hasFiles ? "Download Meetings Files" : "No files available to download"}
            >
                <GetAppIcon color={hasFiles ? "inherit" : "disabled"}></GetAppIcon>
            </Button>

            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                className={classes.eventsFilesPopover}
                id={`${item.id}-filesPopover`}
                data-testid={`${item.id}-filesPopover`}
                aria-label={`Files for ${item.eventName} event`}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center"
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center"
                }}
            >
                <Grid container={true} spacing={1}>
                    {item.publishedFiles && item.publishedFiles.length > 0 ? (
                        item.publishedFiles.map((i, index) => {
                            return (
                                <Grid xs={12} key={i.fileId}>
                                    <Button
                                        variant="text"
                                        fullWidth={true}
                                        id={`downloadEventFile-${index}`}
                                        data-testid={`downloadEventFile-${index}`}
                                        stopPropagation={true}
                                        onClick={() => handleDownloadButton(i)}
                                        onMouseDown={(e: { stopPropagation: () => void }) => e.stopPropagation()}
                                        startIcon={<GetAppIcon color="inherit" fontSize="small" />}
                                        className={classes.downloadButton}
                                    >
                                        <Typography variant="body1">
                                            {EventFileTypeLabels[i.fileType as EventFilesType]}
                                        </Typography>
                                    </Button>
                                </Grid>
                            );
                        })
                    ) : (
                        <></>
                    )}
                </Grid>
            </Popover>
        </>
    );
};

export default EventFilesPopover;
