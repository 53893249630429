import { Dictionary } from "./Content";
import { Link } from "./Link";
import { ProductType } from "./ProductType";

export interface SearchDocument {
    id: string;
    itemId: string;
    organizationId: string;
    applicationId: string;
    type: DocumentType;
    productType: ProductType;
    title: string;
    description: string;
    createdDate: Date;
    lastModifiedDate: Date;
    item: unknown;
    url: string;
    isFavorite: boolean;
    isHidden: boolean;
    isFeatured: boolean;
    tags: string[];
    externalTags?: string[];
    customTags?: string[];
    additionalIndexedProperties?: Dictionary[];
    startDate?:  Date | undefined | null;
    endDate?:  Date | undefined | null;
}

export enum DocumentType {
    All = "All",
    Form = "Form",
    Link = "Link",
    SubscriptionList = "SubscriptionList",
    Event = "Event",
    AlertList = "AlertList",
    AlertWeather = "AlertWeather",
    Publication = "Publication",
    PublicationDocument = "PublicationDocument"
}

export const convertLinkToSearchDocument = (link: Link, type: DocumentType): SearchDocument => {
    const newSearchDocument: SearchDocument = {
        id: link.aggregateId,
        itemId: link.id?.toString(),
        title: link.title,
        url: link.url,
        description: link.description || "",
        customTags: link.customTags,
        externalTags: link.externalTags,
        tags: link.externalTags != null ? link.externalTags?.concat(link.customTags || []) : (link.customTags || []),
        productType: link.productType,
        organizationId: link.organizationId,
        applicationId: "00000000-0000-0000-0000-000000000000",
        type: type,
        item: link.additionalInformation,
        createdDate: new Date(link.created),
        lastModifiedDate: new Date(link.lastModified),
        isFavorite: link.isFavorite,
        isHidden: link.isHidden,
        isFeatured: link.isFeatured,
        startDate: link.startDate,
        endDate: link.endDate
    };

    return newSearchDocument;
};
