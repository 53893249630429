import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import ApiService, { ApiError, parseErrorMessage } from "../../services/apiService";
import Autocomplete, { OptionShape } from "@civicplus/preamble-ui/lib/Autocomplete";
import Button from "@civicplus/preamble-ui/lib/Button";
import Checkbox from "@civicplus/preamble-ui/lib/Checkbox";
import DateTimeRange, { ValueShape } from "@civicplus/preamble-ui/lib/DateTimeRange";
import Dialog from "@civicplus/preamble-ui/lib/Dialog";
import { DirtyStateDialog } from "../DirtyState";
import { GAevent } from "../../services/googleAnalyticsService";
import Grid from "@civicplus/preamble-ui/lib/Grid";
import Icon from "@mdi/react";
import { isStaffUser } from "../../shared/functions";
import { isValidUrlValidation, softMaxLengthValidation } from "../../shared/customValidation";
import { Sections, LinksSectionOptions, AllSectionOptions } from "../../types/Sections";
import TextInput from "@civicplus/preamble-ui/lib/TextInput";
import Typography from "@civicplus/preamble-ui/lib/Typography";
import { useAuth } from "../../providers/AuthProvider";
import { DocumentType } from "../../types/SearchDocument";
import useStyles from "./styles";
import { EditLinkDialogProps, FormatTags, GetSectionValue, sectionsType } from "./EditLinkUtilities";
import enhanceWithValidation, {
    requiredValidation,
    dateRangeValidDateValidation,
    dateRangeBoundaryValidation,
    dateRangeMinValueValidation,
    ValidationResult
} from "@civicplus/preamble-ui/lib/Validations";
import {
    getIconSetForSection,
    getSectionIconKeyFromValue,
    SectionIconKey,
    SectionsIcon
} from "../SectionsIcons/SectionsIcons";
import { useSnackbar } from "notistack";

const EnhancedAutocomplete = enhanceWithValidation(Autocomplete);
const EnhancedTextInput = enhanceWithValidation(TextInput);
const EnhancedDateTimeRange = enhanceWithValidation(DateTimeRange);

export const EditLinkDialog: React.FC<EditLinkDialogProps> = ({
    isOpen,
    onClose,
    onSave,
    orgName,
    linkToEdit,
    disableSectionSelect = false,
    documentType
}) => {
    const classes = useStyles();
    const auth = useAuth();
    const { enqueueSnackbar } = useSnackbar();

    const [url, setUrl] = useState<string | undefined>(undefined);
    const [title, setTitle] = useState<string | undefined>(undefined);
    const [description, setDescription] = useState<string | undefined>(undefined);
    const [section, setSection] = useState<string | undefined>(undefined);
    const [icon, setIcon] = useState<string | undefined>(undefined);
    const [isFeatured, setIsFeatured] = useState<boolean | undefined>(undefined);
    const [eventDate, setEventDate] = useState<ValueShape>();

    const [updateDisabled, setUpdateDisabled] = useState<boolean>(false);
    const [validationErrors, setValidationErrors] = useState<string[]>([]);
    const [iconOptions, setIconOptions] = useState<SectionsIcon[]>([]);
    const [selectedTags, setSelectedTags] = useState<OptionShape | OptionShape[]>();
    const [tags, setTags] = useState<string[]>([]);
    const [allTags, setAllTags] = useState<OptionShape[]>([]);

    const urlRef = useRef<{ validate: () => ValidationResult }>();
    const titleRef = useRef<{ validate: () => ValidationResult }>();
    const descriptionRef = useRef<{ validate: () => ValidationResult }>();

    const [hitUpdate, setHitUpdate] = useState<boolean>(false);
    const [isLeavingWithoutSaving, setIsLeavingWithoutSaving] = useState<boolean>(false);

    const [linkToEditDefaults, setLinkToEditDefaults] = useState<
        | {
              title: string | undefined;
              description: string | undefined;
              section: string | undefined;
              icon: string | undefined;
              url: string | undefined;
              isFeatured: boolean | undefined;
              tags: string[];
              eventDate: ValueShape;
          }
        | undefined
    >(undefined);

    const isDirty = useMemo(() => {
        if (!linkToEditDefaults) {
            return false;
        }
        return (
            url !== linkToEditDefaults.url ||
            title !== linkToEditDefaults.title ||
            description !== linkToEditDefaults.description ||
            section !== linkToEditDefaults.section ||
            icon !== linkToEditDefaults.icon ||
            isFeatured !== linkToEditDefaults.isFeatured ||
            tags.length !== linkToEditDefaults.tags.length
        );
    }, [description, icon, linkToEditDefaults, section, title, url, isFeatured, tags]);

    const onSectionChange = useCallback(
        (selected: { label: string; value: string }) => {
            setSection(selected.value);
            setIcon(undefined);

            const key = selected.label;
            const newIconOptions: SectionsIcon[] = getIconSetForSection(key as Sections);

            const updatedValidationErrors = validationErrors.filter((item) => item !== "section");
            if (
                !updatedValidationErrors.every((x) => validationErrors.includes(x)) ||
                !validationErrors.every((x) => updatedValidationErrors.includes(x))
            ) {
                setValidationErrors(updatedValidationErrors);
            }

            setIconOptions(newIconOptions);
            setIcon(newIconOptions.length > 0 ? getSectionIconKeyFromValue(newIconOptions[0].key) : undefined);
        },
        [validationErrors]
    );

    useEffect(() => {
        if (linkToEdit) {
            const title = linkToEdit.title;
            const description = linkToEdit.description;
            const section = linkToEdit.sectionString.split(" ").join("");

            const sectionExist = GetSectionValue(section, AllSectionOptions as sectionsType[]);
            const icon = getSectionIconKeyFromValue(
                sectionExist ? linkToEdit.icon?.toString() : SectionIconKey.LinkWebIcon
            );
            const url = linkToEdit.url;

            const currentSection = sectionExist ? section : "Links";
            const startDate = linkToEdit.startDate;
            const endDate = linkToEdit.endDate;

            setUrl(url);
            setTitle(title);
            setDescription(description);
            setSection(currentSection);
            onSectionChange({ label: sectionExist ? linkToEdit.sectionString : currentSection, value: currentSection });
            setIcon(icon);
            setIsFeatured(linkToEdit.isFeatured);
            setTags(linkToEdit.tags);
            setEventDate({ from: startDate, to: endDate });

            const tagList = FormatTags(linkToEdit.tags);

            setSelectedTags(tagList);

            setLinkToEditDefaults({
                title: title,
                description: description,
                section: section,
                icon: icon,
                url: url,
                isFeatured: linkToEdit.isFeatured,
                tags: linkToEdit.tags,
                eventDate: { from: startDate, to: endDate }
            });
        }
    }, [linkToEdit, onSectionChange]);

    const onUpdateDialog = useCallback(async () => {
        setUpdateDisabled(true);
        setHitUpdate(true);

        const validationResults: ValidationResult[] = await Promise.all([
            urlRef.current!.validate(),
            titleRef.current!.validate(),
            descriptionRef.current!.validate(),
            (function (): ValidationResult {
                return {
                    error: section === undefined,
                    message: `${section}`,
                    validationName: "required"
                };
            })()
        ]);

        if (validationResults.some((x) => x.error)) {
            setUpdateDisabled(false);
            enqueueSnackbar("All fields must be valid to continue.", {
                variant: "error",
                persist: true
            });
            return;
        }

        if (documentType === DocumentType.Event && (eventDate?.from === undefined || eventDate?.to === undefined)) {
            setUpdateDisabled(false);
            enqueueSnackbar("Start Date and End Date are required to continue.", {
                variant: "error",
                persist: true
            });
            return;
        }

        if (auth.user?.access_token && linkToEdit) {
            let stopExecution = false;
            try {
                await ApiService.put({
                    url: `${orgName}/links/${linkToEdit.id}`,
                    requestInit: {
                        headers: {
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify({
                            title,
                            description,
                            url,
                            section,
                            icon: icon,
                            isFeatured,
                            tags,
                            startDate: eventDate?.from,
                            endDate: eventDate?.to
                        })
                    },
                    authUser: auth.user
                });

                enqueueSnackbar(`"${title}" has been updated.`, {
                    variant: "success"
                });

                if (!isStaffUser(auth.user)) {
                    GAevent({
                        action: "link_updated",
                        category: "Links",
                        orgName,
                        label: `Title: ${title}`
                    });
                }
            } catch (ex) {
                const errorMessage = parseErrorMessage(ex as ApiError, "An error occured while updating link.");
                enqueueSnackbar(errorMessage, {
                    variant: "error",
                    persist: true
                });

                console.error(ex);
                stopExecution = true;
            } finally {
                setUpdateDisabled(false);
                setHitUpdate(false);
            }

            if (stopExecution) {
                return;
            }
        }

        if (onSave) {
            if (linkToEdit) {
                onSave(false, {
                    ...linkToEdit,
                    title: title || "",
                    description: description || "",
                    url: url!,
                    section: section!,
                    isFeatured: isFeatured || false,
                    tags: tags,
                    startDate: eventDate?.from,
                    endDate: eventDate?.to
                });
            }
        }
    }, [
        auth.user,
        linkToEdit,
        onSave,
        section,
        orgName,
        title,
        description,
        url,
        icon,
        isFeatured,
        enqueueSnackbar,
        tags,
        eventDate,
        documentType
    ]);

    const onEnterDialog = useCallback(() => {
        const getTags = async () => {
            const tags = await ApiService.get({
                url: `${orgName}/search/tags`,
                cache: false,
                authUser: auth.user
            });

            const sortedTags = [...new Set(tags.map((tag: string) => tag.toLowerCase()))]
                .sort()
                .map((tag) => tags.find((t: string) => t.toLowerCase() === tag));

            const tagsList = FormatTags(sortedTags);

            AllSectionOptions.forEach((option: any) => {
                option.options.forEach((tag: OptionShape) => {
                    tagsList.push(tag);
                });
            });

            setAllTags(tagsList);
        };
        getTags();
    }, [auth.user, orgName]);

    const onCloseDialog = useCallback(() => {
        if (isDirty) {
            setIsLeavingWithoutSaving(true);
        } else {
            if (onClose) {
                onClose();
            }
        }
    }, [isDirty, onClose]);

    const onExitedDialog = useCallback(() => {
        setUrl(undefined);
        setTitle(undefined);
        setDescription(undefined);
        setSection(undefined);
        setUpdateDisabled(false);
        setHitUpdate(false);
        setIcon(undefined);
        setIconOptions([]);
        setLinkToEditDefaults(undefined);
        setIsFeatured(undefined);
        setTags([]);
        setEventDate({
            from: undefined,
            to: undefined
        });
    }, []);

    const sectionValue = useMemo(() => {
        return section && GetSectionValue(section, AllSectionOptions as sectionsType[]);
    }, [section]);

    const onUrlChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setUrl(e.currentTarget.value || undefined);
    }, []);

    const onTitleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setTitle(e.currentTarget.value || undefined);
    }, []);

    const onEventDateChange = (newValue: ValueShape) => {
        setEventDate(newValue);
    };

    const onDescriptionChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setDescription(e.currentTarget.value || undefined);
    }, []);

    const onIsFeaturedChange = useCallback((e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        setIsFeatured(checked);
    }, []);

    const onCategoryChange = useCallback((selected: OptionShape[] | undefined) => {
        const tags: string[] = [];

        if (Array.isArray(selected)) {
            selected.forEach((option: OptionShape) => {
                tags.push(option.label.toString());
            });
        }

        setTags(tags);
        setSelectedTags(selected);
    }, []);

    const iconSection = useMemo(() => {
        return (
            <Grid container={true} spacing={5}>
                {iconOptions.length ? (
                    <Grid className={classes.gridTextWrapper}>
                        <Typography style={{ fontSize: 14 }}>Select an icon:</Typography>
                    </Grid>
                ) : null}

                {iconOptions.map((iconOption) => {
                    const key = iconOption.key;
                    const opacity = icon === getSectionIconKeyFromValue(key) ? 1 : 0.25;

                    return (
                        <Grid key={iconOption.key} xs={1} className={classes.gridIconWrapper}>
                            <Icon
                                title={key}
                                size={2}
                                path={iconOption?.path}
                                style={{
                                    opacity,
                                    cursor: "pointer"
                                }}
                                /* @ts-ignore onClick does exist */
                                onClick={() => {
                                    setIcon(getSectionIconKeyFromValue(key));
                                }}
                            />
                        </Grid>
                    );
                })}
            </Grid>
        );
    }, [classes.gridIconWrapper, classes.gridTextWrapper, icon, iconOptions]);

    return (
        <>
            <Dialog
                open={isOpen}
                title="Edit Link"
                onEnter={onEnterDialog}
                onClose={onCloseDialog}
                onExited={onExitedDialog}
                maxWidth={documentType === DocumentType.Event ? "md" : "sm"}
                actions={[
                    <Button
                        color="primary"
                        onClick={onUpdateDialog}
                        disabled={updateDisabled}
                        id="modal-update-button"
                        key="update-link-btn"
                    >
                        Update
                    </Button>,
                    <Button onClick={onCloseDialog} key="cancel">
                        Cancel
                    </Button>
                ]}
            >
                <Grid container={true}>
                    <Grid xs={12}>
                        <EnhancedTextInput
                            ref={urlRef}
                            id="edit-link-url"
                            label="URL"
                            value={url}
                            onChange={onUrlChange}
                            fullWidth={true}
                            required={true}
                            validations={[isValidUrlValidation, requiredValidation]}
                        />
                    </Grid>

                    <Grid xs={12}>
                        <EnhancedAutocomplete
                            id="edit-link-section"
                            label="Section"
                            required={true}
                            value={sectionValue}
                            onChange={onSectionChange}
                            options={LinksSectionOptions}
                            isInDialog={true}
                            error={validationErrors.includes("section")}
                            validations={[requiredValidation]}
                            disabled={disableSectionSelect}
                        />
                    </Grid>

                    {iconSection}

                    <Grid xs={12}>
                        <EnhancedTextInput
                            ref={titleRef}
                            id="edit-link-title"
                            label="Title"
                            value={title}
                            required={true}
                            fullWidth={true}
                            onChange={onTitleChange}
                            softMaxLength={100}
                            helperText={`${title?.length === undefined ? 0 : title?.length}/100`}
                            validations={[requiredValidation, softMaxLengthValidation]}
                        />
                    </Grid>

                    {documentType === DocumentType.Event ? (
                        <Grid xs={12}>
                            <EnhancedDateTimeRange
                                id="meeting-link-datetimerange"
                                pickerType="dateTime"
                                minValue={eventDate?.from ?? new Date()}
                                required={true}
                                keyboard={true}
                                value={eventDate}
                                onChange={onEventDateChange}
                                format="MM/dd/yyyy h:mm:ss a"
                                fromProps={{ label: "Start Date" }}
                                toProps={{ label: "End Date" }}
                                error={{
                                    from: hitUpdate && eventDate?.from === undefined,
                                    to: hitUpdate && eventDate?.to === undefined
                                }}
                                errorMessage={{
                                    from: "Start Date can't be blank.",
                                    to: "End Date can't be blank."
                                }}
                                validations={[
                                    requiredValidation,
                                    dateRangeMinValueValidation,
                                    dateRangeValidDateValidation,
                                    dateRangeBoundaryValidation
                                ]}
                            />
                        </Grid>
                    ) : null}

                    <Grid xs={12}>
                        <EnhancedTextInput
                            ref={descriptionRef}
                            id="edit-link-description"
                            label="Description"
                            value={description}
                            onChange={onDescriptionChange}
                            multiline={true}
                            rows={5}
                            fullWidth={true}
                            validations={[softMaxLengthValidation]}
                            softMaxLength={1000}
                            helperText={`${description?.length === undefined ? 0 : description?.length}/1000`}
                        />
                    </Grid>

                    <Grid xs={12}>
                        <Autocomplete
                            id="edit-link-category"
                            label="Tags"
                            onChange={onCategoryChange}
                            value={selectedTags}
                            options={allTags}
                            multiSelect={true}
                            allowOptionCreation={true}
                            isInDialog={true}
                        />
                    </Grid>

                    <Grid xs={12}>
                        <Checkbox
                            id="featured-link"
                            label="Featured in Portal"
                            onChange={onIsFeaturedChange}
                            checked={isFeatured}
                        />
                    </Grid>
                </Grid>
            </Dialog>

            <DirtyStateDialog
                isOpen={isLeavingWithoutSaving}
                onDiscardChanges={() => {
                    setIsLeavingWithoutSaving(false);
                    if (onClose) {
                        onClose();
                    }
                }}
                onReturnToContent={() => {
                    setIsLeavingWithoutSaving(false);
                }}
            />
        </>
    );
};
