import React, { useEffect } from "react";
import Loader from "@civicplus/preamble-ui/lib/Loader";
import { useConfig } from "../providers/ConfigProvider";
import { useNavigate } from "react-router-dom";

export const AccountRedirect: React.FC = () => {
    const config = useConfig();
    const navigate = useNavigate();

    useEffect(() => {
        if (window.location.pathname.includes("account")) {
            window.open(`${config.accountServiceBaseUrl}/personal`, "_blank");
            navigate("/", { replace: true });
        }
    }, [config, navigate]);

    return (
        <Loader verticallyCenter={true} />
    );
};
