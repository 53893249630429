const languages = {
    defaultSpoken: {
        label: "English",
        value: "en"
    },
    spoken: [
        {
            label: "Arabic",
            value: "ar"
        },
        {
            label: "Catalan",
            value: "ca"
        },
        {
            label: "Chinese - Cantonese",
            value: "yue"
        },
        {
            label: "Chinese - Mandarin",
            value: "zh"
        },
        {
            label: "Czech",
            value: "cs"
        },
        {
            label: "Danish",
            value: "da"
        },
        {
            label: "Dutch",
            value: "nl"
        },
        {
            label: "English",
            value: "en"
        },
        {
            label: "Farsi - Persian",
            value: "fa"
        },
        {
            label: "Finnish",
            value: "fi"
        },
        {
            label: "French",
            value: "fr"
        },
        {
            label: "German",
            value: "de"
        },
        {
            label: "Hebrew",
            value: "he"
        },
        {
            label: "Hindi",
            value: "hi"
        },
        {
            label: "Hungarian",
            value: "hu"
        },
        {
            label: "Indonesian",
            value: "id"
        },
        {
            label: "Italian",
            value: "it"
        },
        {
            label: "Japanese",
            value: "ja"
        },
        {
            label: "Korean",
            value: "ko"
        },
        {
            label: "Norwegian",
            value: "nb"
        },
        {
            label: "Polish",
            value: "pl"
        },
        {
            label: "Portuguese - Brazil",
            value: "pt-BR"
        },
        {
            label: "Portuguese - Portugal",
            value: "pt-PT"
        },
        {
            label: "Romanian",
            value: "ro"
        },
        {
            label: "Russian",
            value: "ru"
        },
        {
            label: "Slovak",
            value: "sk"
        },
        {
            label: "Spanish - Mexico",
            value: "es"
        },
        {
            label: "Spanish - Spain",
            value: "es-ES"
        },
        {
            label: "Swedish",
            value: "sv"
        },
        {
            label: "Thai",
            value: "th"
        },
        {
            label: "Turkish",
            value: "tr"
        },
        {
            label: "Vietnamese",
            value: "vi"
        }
    ],

    defaultWritten: {
        label: "English",
        value: "en"
    },
    written: [
        {
            label: "Afrikaans",
            value: "af"
        },
        {
            label: "Albanian",
            value: "sq"
        },
        {
            label: "Amharic",
            value: "am"
        },
        {
            label: "Arabic",
            value: "ar"
        },
        {
            label: "Armenian",
            value: "hy"
        },
        {
            label: "Azeri - Latin",
            value: "az"
        },
        {
            label: "Bengali",
            value: "bn"
        },
        {
            label: "Bosnian",
            value: "bs"
        },
        {
            label: "Bulgarian",
            value: "bg"
        },
        {
            label: "Catalan",
            value: "ca"
        },
        {
            label: "Chinese - Cantonese",
            value: "yue"
        },
        {
            label: "Chinese - Mandarin",
            value: "zh"
        },
        {
            label: "Chinese - Simplified",
            value: "zh-Hans"
        },
        {
            label: "Croatian",
            value: "hr"
        },
        {
            label: "Czech",
            value: "cs"
        },
        {
            label: "Danish",
            value: "da"
        },
        {
            label: "Dutch",
            value: "nl"
        },
        {
            label: "English",
            value: "en"
        },
        {
            label: "Estonian",
            value: "et"
        },
        {
            label: "Farsi - Persian",
            value: "fa"
        },
        {
            label: "Finnish",
            value: "fi"
        },
        {
            label: "French",
            value: "fr"
        },
        {
            label: "FYRO Macedonia",
            value: "mk"
        },
        {
            label: "German",
            value: "de"
        },
        {
            label: "Greek",
            value: "el"
        },
        {
            label: "Gujarati",
            value: "gu"
        },
        /*
        {
            label: "Haitian - Creole",
            value: "ht"
        },
        */
        {
            label: "Hebrew",
            value: "he"
        },
        {
            label: "Hindi",
            value: "hi"
        },
        {
            label: "Hungarian",
            value: "hu"
        },
        {
            label: "Icelandic",
            value: "is"
        },
        {
            label: "Indonesian",
            value: "id"
        },
        {
            label: "Italian",
            value: "it"
        },
        {
            label: "Japanese",
            value: "ja"
        },
        {
            label: "Kannada",
            value: "kn"
        },
        {
            label: "Kazakh",
            value: "kk"
        },
        {
            label: "Korean",
            value: "ko"
        },
        {
            label: "Latvian",
            value: "lv"
        },
        {
            label: "Lithuanian",
            value: "lt"
        },
        {
            label: "Malay - Malaysia",
            value: "ms"
        },
        {
            label: "Malayalam",
            value: "ml"
        },
        {
            label: "Maltese",
            value: "mt"
        },
        {
            label: "Marathi",
            value: "mr"
        },
        {
            label: "Mongolian",
            value: "mn"
        },
        {
            label: "Norwegian",
            value: "nb"
        },
        {
            label: "Polish",
            value: "pl"
        },
        {
            label: "Portuguese - Brazil",
            value: "pt-BR"
        },
        {
            label: "Portuguese - Portugal",
            value: "pt-PT"
        },
        {
            label: "Punjabi",
            value: "pa"
        },
        {
            label: "Romanian",
            value: "ro"
        },
        {
            label: "Russian",
            value: "ru"
        },
        {
            label: "Serbian - Cyrillic",
            value: "sr-Cyrl"
        },
        {
            label: "Slovak",
            value: "sk"
        },
        {
            label: "Slovenian",
            value: "sl"
        },
        {
            label: "Spanish - Mexico",
            value: "es"
        },
        {
            label: "Spanish - Spain",
            value: "es-ES"
        },
        {
            label: "Swahili",
            value: "sw"
        },
        {
            label: "Swedish",
            value: "sv"
        },
        {
            label: "Tamil",
            value: "ta"
        },
        {
            label: "Telugu",
            value: "te"
        },
        {
            label: "Thai",
            value: "th"
        },
        {
            label: "Turkish",
            value: "tr"
        },
        {
            label: "Ukrainian",
            value: "uk"
        },
        {
            label: "Urdu",
            value: "ur"
        },
        {
            label: "Uzbek",
            value: "uz"
        },
        {
            label: "Vietnamese",
            value: "vi"
        },
        {
            label: "Welsh",
            value: "cy"
        }
    ]
};

export default languages;
