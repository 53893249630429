import { makeStyles } from "@civicplus/preamble-ui/lib/Utilities/ThemeHelper";

const useStyles = makeStyles((theme) => ({
    row: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        [theme.breakpoints.down("md")]: {
            flexDirection: "column"
        }
    },
    title: {
        flex: "1 1 auto",
        marginRight: theme.spacing(2)
    },
    toggles: {
        display: "flex",
        gap: theme.spacing(2),
        [theme.breakpoints.up("md")]: {
            justifyContent: "center"
        }
    },
    toggle: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        gap: theme.spacing(1),
        [theme.breakpoints.up("md")]: {
            justifyContent: "center"
        }
    },
    alignEnd: {
        justifyContent: "flex-end"
    },
    chipsContainer: {
        display: "flex",
        flexWrap: "wrap",
        gap: 5,
        margin: theme.spacing(1, 0, 0),
        overflowX: "auto",
        [theme.breakpoints.down("md")]: {
            justifyContent: "center"
        }
    },
    externalChip: {
        "& svg": {
            marginLeft: theme.spacing(1) + "!important"
        }
    },
    featured: {
        "&:after": {
            content: '""',
            position: "absolute",
            left: 0,
            top: 0,
            width: 5,
            height: "100%",
            backgroundColor: theme.palette.primary.main
        }
    },
    item: {
        position: "relative"
    },
    activeFilter: {
        marginTop: theme.spacing(1),
        "& label": {
            fontWeight: theme.typography.fontWeightBold as number
        }
    },
    tabRoot: {
        "& .MuiTab-root": {
            minWidth: 140,
            [theme.breakpoints.down("sm")]: {
                minWidth: 120,
            }
        }
    },
    tabButton: {
        height: 54,
        whiteSpace: "nowrap",
        [theme.breakpoints.down("sm")]: {
            height: 48,
            margin: "0px 0px 8px 0px"
        }
    },
    tabButtonGroup: {
        display: "flex",
        width: "100%",
        justifyContent: "start",
        "@media (max-width: 730px) and (min-width: 0px)": {
            flexDirection: "column",
        },
        "@media (max-width: 1500px) and (min-width: 730px)": {
            justifyContent: "flex-end"
        }
    },
    lg5Grid: {
        "@media (min-width: 1500px)": {
            flexGrow: 0,
            flexBasis: "auto",
            width: "calc(100%* 5 / 12)"
        }
    },
    lg6Grid: {
        "@media (min-width: 1500px)": {
            flexGrow: 0,
            flexBasis: "auto",
            width: "calc(100%* 6 / 12)"
        }
    },
    lg7Grid: {
        "@media (min-width: 1500px)": {
            flexGrow: 0,
            flexBasis: "auto",
            width: "calc(100%* 7 / 12)"
        }
    },
    md7Grid: {
        "@media (min-width: 730px) and (max-width: 1500px)": {
            flexGrow: 0,
            flexBasis: "auto",
            width: "calc(100%* 7 / 12)"
        }
    },
    md6Grid: {
        "@media (min-width: 730px) and (max-width: 1500px)": {
            flexGrow: 0,
            flexBasis: "auto",
            width: "calc(100%* 6 / 12)"
        }
    },
    md5Grid: {
        "@media (min-width: 730px) and (max-width: 1500px)": {
            flexGrow: 0,
            flexBasis: "auto",
            width: "calc(100%* 5 / 12)"
        }
    },
    mdxs12Grid: {
        "@media (max-width: 1040px) and (min-width: 730px)": {
            flexGrow: 0,
            flexBasis: "auto",
            width: "calc(100%* 12 / 12)",
            justifyContent: "flex-end"
        }
    },
    xs12Grid: {
        "@media (max-width: 730px) and (min-width: 0px)": {
            flexGrow: 0,
            flexBasis: "auto",
            width: "calc(100%* 12 / 12)"
        }
    },
}));

export default useStyles;
