import React, { useEffect } from "react";
import Typography from "@mui/material/Typography";
import { useEmbedStore } from "../../stores/embedStore";
import { useAuth } from "../../components/react-oidc-context";

const EmbedSignIn: React.FC = () => {
    const [isEmbed] = useEmbedStore((state) => [state.isInitialized]);

    const auth = useAuth();
    useEffect(() => {
        if (window.parent && auth.isAuthenticated) {
            window.parent.postMessage({ type: "embededUserSignedIn", user: auth.user }, "*");
        }
    }, [auth.isAuthenticated, auth.user]);

    useEffect(
        function signInSilient() {
            if (!isEmbed && !auth.isAuthenticated) {
                auth.signinSilent().catch((e) => {
                    if (window.parent) {
                        window.parent.postMessage({ type: "embedSignInSilentFailed" }, "*");
                    }
                });
            }
        },
        // Empty array as we only attempt sign in 1 time.
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    return <Typography>Signing In...</Typography>;
};

export default EmbedSignIn;
