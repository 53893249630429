import { CppOrganization, Application } from "@civicplus/preamble-ui/lib/Services/OrgService/Types/Organization";
import { ProductType } from "./ProductType";

export interface OrganizationOwner {
    user: {
        email: string;
        firstName: string;
        id: string;
        isValidated: boolean;
        lastName: string;
        phoneNumber: string | null;
        secondaryEmail: string | null;
    };
    userId: string;
}

export interface Organization extends Omit<CppOrganization, "applications"> {
    applications: OrgApplicationDto[];
    error?: string;
    friendlyName?: string;
    organizationOwners: OrganizationOwner[];
    userInOrganization: boolean;
    hasMassNotificationsApp: boolean;
    navigation: NavigationDictionary;
}

export interface PagedResult<T> {
    items: T[];
    count: number;
}

export interface OrgApplicationDto extends Omit<Application, "productType"> {
    additionalProductInformation?: string;
    customLabel?: string;
    isActive?: boolean;
    productType: ProductType;
    productUri: string;
}

export interface EnhancedApplication {
    additionalProductInformation?: string;
    applicationId: string;
    customLabel?: string;
    isActive: boolean;
    organizationId: string;
}

export interface EnhancedApplicationDto {
    additionalProductInformation?: string;
    customLabel?: string;
    isActive: boolean;
}

export interface NavigationItem {
    organizationId: string;
    type: NavigationOption;
    additionalProductInformation?: string;
    customLabel: string;
    isActive: boolean;
    disabled: boolean;
    defaultLabel: string;
    applications: OrgApplicationDto[];
    icon: string;
    publicUri?: string;
}

export enum NavigationOption {
    Dashboard = "Dashboard",
    Meetings = "Meetings",
    AlertsAndNotifications = "AlertsAndNotifications",
    Legislation = "Legislation",
    Forms = "Forms",
    Links = "Links",
    ParksAndRec = "ParksAndRec",
    Payments = "Payments",
    Permits = "Permits",
    RecordsRequest = "RecordsRequest",
    SubmitARequest = "SubmitARequest"
}

export type NavigationDictionary = {
    [key in NavigationOption]: NavigationItem;
};
