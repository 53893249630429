import React, { useEffect, useReducer } from "react";
import clsx from "clsx";
import Grid from "@civicplus/preamble-ui/lib/Grid";
import List from "@civicplus/preamble-ui/lib/List";
import ListItem from "@civicplus/preamble-ui/lib/ListItem";
import PortalListItems from "../../components/PortalListItems";
import SkeletonLoader from "@civicplus/preamble-ui/lib/SkeletonLoader";
import TablePagination from "@civicplus/preamble-ui/lib/TablePagination";
import { LoginNoticeDialog } from "../../components/Authentication/LoginNoticeDialog";
import { makeStyles } from "@civicplus/preamble-ui/lib/Utilities/ThemeHelper";
import { PagedResult, Organization } from "../../types/Organization";
import { RemoveFavoriteDialog } from "../../components/Favorites/RemoveFavoriteDialog";
import { SearchDocument } from "../../types/SearchDocument";
import { SubscriptionList } from "../../types/Subscription";
import { Typography } from "@civicplus/preamble-ui/lib/Typography";
import { useAuth } from "../../providers/AuthProvider";
import { useFavorites } from "../../shared/useFavorites";

const useStyles = makeStyles((theme) => ({
    pagination: {
        justifyContent: "center",
        padding: "10px"
    },
    paginationToolbar: {
        flexDirection: "row"
    }
}));

export interface PagedResultWithLoading<T> extends PagedResult<T> {
    isLoading?: boolean;
}

export interface SearchTableProps {
    /**
     * Whether subscriptions are being fetched.
     */
    isFetchingSubscriptions?: boolean;
    /**
     * Function to retrieve table results.
     * @param rowsPerPage number of rows to retrieve
     * @param currentPage current page of pagination
     * @param searchValue value to search for
     * @returns Results object with total count and array of results
     */
    getRows: (
        currentPage: number,
        rowsPerPage: number,
        searchValue?: string
    ) => Promise<PagedResultWithLoading<SearchDocument>>;
    /**
     * The current organization.
     */
    organization?: Organization;
    /**
     * Value to search for.
     */
    searchValue?: string;
    /**
     * Array of user's subscriptions.
     */
    subscriptionList: SubscriptionList[];
}

interface SearchResultsState {
    currentPage: number;
    currentSearchValue?: string;
    isLoading: boolean;
    results: SearchDocument[];
    rowsPerPage: number;
    total: number;
}

interface SearchResultsReducerAction {
    newState: Partial<SearchResultsState>;
    type: SearchResultsReducerActionType;
}

type SearchResultsReducerActionType = "update_page" | "update_rows_per_page" | "update_results";

function searchResultsReducer(state: SearchResultsState, action: SearchResultsReducerAction): SearchResultsState {
    if (action.type === "update_page")
        return {
            ...state,
            currentPage: action.newState.currentPage ?? 0,
            isLoading: true
        };
    else if (action.type === "update_rows_per_page") {
        let newCurrentPage = state.currentPage;
        const newRowsPerPage = action.newState.rowsPerPage ?? 10;

        if (state.rowsPerPage < newRowsPerPage)
            newCurrentPage = +(state.total - newRowsPerPage * (state.rowsPerPage - 1) > 0) ? state.currentPage : 0;

        return {
            ...state,
            currentPage: newCurrentPage,
            isLoading: true,
            rowsPerPage: action.newState.rowsPerPage ?? 10
        };
    } else if (action.type === "update_results")
        return {
            ...state,
            currentPage:
                action.newState.currentSearchValue === state.currentSearchValue
                    ? action.newState.currentPage ?? state.currentPage
                    : 0,
            currentSearchValue: action.newState.currentSearchValue ?? "",
            isLoading: action.newState.isLoading ?? false,
            results: action.newState.results ?? [],
            total: action.newState.total ?? 0
        };
    else {
        throw Error("Unknown action: " + action.type);
    }
}

export const SearchTable: React.FC<SearchTableProps> = (props) => {
    const { isFetchingSubscriptions, getRows: load, organization, searchValue, subscriptionList } = props;
    const classes = useStyles();
    const auth = useAuth();

    const [
        { favoriteMap: favorites, loginNoticeOpen, unfavoriteDialogSettings },
        {
            handleCloseLoginNotice,
            handleConfirmUnfavorite,
            handleFavoriteClick,
            handleLogin,
            setUnfavoriteDialogSettings,
            getAllFavorites
        }
    ] = useFavorites();

    const [{ currentPage, isLoading, results, rowsPerPage, total }, dispatch] = useReducer(searchResultsReducer, {
        currentPage: 0,
        currentSearchValue: "",
        isLoading: false,
        results: [],
        rowsPerPage: 10,
        total: 0
    });

    useEffect(() => {
        let isCancelled = false;

        async function loadTable() {
            const results = await load(currentPage, rowsPerPage, searchValue);

            if (!isCancelled) {
                dispatch({
                    type: "update_results",
                    newState: {
                        currentSearchValue: searchValue,
                        results: results.items,
                        total: results.count,
                        isLoading: results.isLoading
                    }
                });
            }
        }

        loadTable();

        return () => {
            isCancelled = true;
        };
    }, [currentPage, load, rowsPerPage, searchValue]);

    useEffect(() => {
        getAllFavorites();
    }, [getAllFavorites]);

    const handleChangePage = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
        dispatch({ type: "update_page", newState: { currentPage: newPage } });
    };

    const handleChangeRowsPerPage = async (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch({ type: "update_rows_per_page", newState: { rowsPerPage: parseInt(event.target.value, 10) || 10 } });
    };

    return (
        <>
            <List id="search-results-list" wrapperStyles={true}>
                {isLoading || isFetchingSubscriptions || !organization ? (
                    new Array(rowsPerPage).fill(null).map((_: unknown, index: number) => {
                        return (
                            <ListItem
                                key={index}
                                data-testid={`skeletonLoader-${index}`}
                                ListItemTextProps={{
                                    primary: (
                                        <Grid container={true} spacing={3}>
                                            <Grid xs={12}>
                                                <SkeletonLoader />
                                            </Grid>
                                            <Grid xs={12}>
                                                <SkeletonLoader />
                                            </Grid>
                                            <Grid xs={2}>
                                                <SkeletonLoader />
                                            </Grid>
                                            <Grid xs={2}>
                                                <SkeletonLoader />
                                            </Grid>
                                            <Grid xs={2}>
                                                <SkeletonLoader />
                                            </Grid>
                                        </Grid>
                                    )
                                }}
                                divider={true}
                            ></ListItem>
                        );
                    })
                ) : results.length > 0 ? (
                    <PortalListItems
                        authUser={auth.user}
                        items={results}
                        isEmbed={false}
                        organization={organization}
                        subscriptionList={subscriptionList}
                        favorites={favorites}
                        handlePendingSubscriptions={true}
                        handleFavoriteClick={handleFavoriteClick}
                    />
                ) : (
                    <ListItem
                        ListItemTextProps={{
                            primary: (
                                <Typography align="center">
                                    {searchValue ? "Sorry, no results found" : "Please enter a search term or phrase"}
                                </Typography>
                            )
                        }}
                    ></ListItem>
                )}
            </List>

            <TablePagination
                id="search-results-pagination"
                component="div"
                count={total}
                page={currentPage}
                className={clsx(classes.pagination, classes.paginationToolbar)}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[10, 25, 50, 100]}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />

            <LoginNoticeDialog isOpen={loginNoticeOpen} onClose={handleCloseLoginNotice} handleLogin={handleLogin} />

            <RemoveFavoriteDialog
                isOpen={unfavoriteDialogSettings.open}
                handleConfirm={handleConfirmUnfavorite}
                onClose={() => setUnfavoriteDialogSettings({ open: false, link: null })}
            />
        </>
    );
};
