export interface CivicClerkEvent {
    id: number;
    eventName: string;
    eventDescription: string;
    eventTemplateId: number | null;
    eventLocation: EventLocation;
    eventDate: string;
    startDateTime: string;
    createdOn: string;
    createdByUserId: string;
    isPublished: string;
    agendaId: number | null;
    agendaName: string | null;
    cutOffDateTime: string | null;
    categoryName: string | null;
    keywords: string | null;
    visibilityId: number | null;
    showInUpcomingEvents: boolean | null;
    isOnDemandEvent: boolean | null;
    isLiveEvent: boolean | null;
    automaticallyStart: boolean | null;
    thumbFileName: string | null;
    thumbFileData: string | null;
    thumbContentType: string | null;
    thumbFileSize: number | null;
    durationHrs: number | null;
    durationMin: number | null;
    mediaSourcePath: string | null;
    mediaStreamPath: string | null;
    mediaOrigFileName: string | null;
    publishStart: string | null;
    mediaTypeId: number | null;
    liveStartTime: string | null;
    liveEndTime: string | null;
    liveIsCurrentlyStreaming: boolean | null;
    livePublishingPointId: number | null;
    automaticallyStop: boolean | null;
    pauseMsgId: number | null;
    streamingStatus: number | null;
    youtubeVideoId: string | null;
    youtubeVideoThumbnailPath: string | null;
    youtubeVideoUploaded: number | null;
    youtubeVideoProcessing: number | null;
    mobileMediaSourcePath: string | null;
    mobileMediaStreamPath: string | null;
    mobileMediaOrigFileName: string | null;
    externalMediaUrl: string | null;
    mediaSourcePathMp4: string | null;
    mediaOrigFileNameMp4: string | null;
    mediaAwsKeyName: string | null;
    bookmarksAdded: number | null;
    displayMessage: string | null;
    mediaUploadedBy: string | null;
    mediaUploadedOn: string | null;
    mediaFileSize: number | null;
    mediaTotalPlay: number | null;
    closedCaptionFileName: string | null;
    closedCaptionFileType: string | null;
    closedCaptionUploadedBy: string | null;
    closedCaptionUploadedOn: string | null;
    closedCaptionSourcePath: string | null;
    closedCaptionBlobPath: string | null;
    eventAutoStartEndTime: string | null;
    showPauseMessage: boolean | null;
    pauseMessage: string | null;
    closedCaptionServiceType: number | null;
    closedCaptionStatus: string | null;
    closedCaptionOrderId: string | null;
    closedCaptionSeconds: number | null;
    closedCaptionCost: number | null;
    startEventAutomatically: boolean | null;
    actualStartTime: string | null;
    meetingEndTime: string | null;
    categoryId: number | null;
    eventCategoryId: number | null;
    eventCategoryName: string | null;
    eventTemplateName: string | null;
    hasAgenda: boolean | null;
    hasMedia: boolean | null;
    isLive: boolean | null;
    meetingIn: number | null;
    isDeleted: boolean | null;
    finalDeletedDate: string | null;
    mediaUploadedByName: string | null;
    streamId: number | null;
    zoomMeetingId: string | null;
    eventNotice: string | null;
    showEventNoticePreview: boolean | null;
    cpMediaOnly: boolean | null;
    defaultPublicPortalPage: number | null;
    updateEvent: boolean | null;
    meetingTypeId: number | null;
    meetingTypeName: string | null;
    publishedFiles: PublishedFile[];
    agendaFile: AgendaFile;
    minutesFile: MinutesFile;
}

export interface EventLocation {
    id: number | null;
    eventId: number | null;
    address1: string | null;
    address2: string | null;
    city: string | null;
    state: string | null;
    zipCode: string | null;
}

export interface AgendaFile {
    agendaId: number;
    fileName: string;
    dateUploaded: string | null;
    createdBy: string;
}

export interface MinutesFile {
    eventId: number;
    minutesId: number;
    fileName: string;
    createdOn: string | null;
    createdBy: string;
}

export interface PublishedFile {
    fileId: number;
    publishTo: string;
    type: string;
    publishOn: string | null;
    publishedBy: string;
    name: string;
    streamUrl: string;
    url: string;
    sort: number;
    fileType: number;
}

export enum EventFilesType {
    Agenda = 1,
    Agenda_Packet,
    Supporting_Documents,
    Minutes,
    Notices,
    Forward_Agenda,
    Other,
    Merged_Packet,
    Agenda_Script
}

export const EventFileTypeLabels = {
    [EventFilesType.Agenda]: "Agenda",
    [EventFilesType.Agenda_Packet]: "Agenda Packet",
    [EventFilesType.Notices]: "Public Notices",
    [EventFilesType.Minutes]: "Minutes",
    [EventFilesType.Supporting_Documents]: "Supporting Documents",
    [EventFilesType.Forward_Agenda]: "Forward Agenda",
    [EventFilesType.Other]: "Other Documents",
    [EventFilesType.Merged_Packet]: "Merged Packet",
    [EventFilesType.Agenda_Script]: "Agenda Script",
};
