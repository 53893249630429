import React, { useState, useEffect } from "react";
import ApiService from "../../../services/apiService";
import Button from "@civicplus/preamble-ui/lib/Button";
import Grid from "@civicplus/preamble-ui/lib/Grid";
import HiddenPageAlert from "../../../components/HiddenPageAlert";
import Layout from "@civicplus/preamble-ui/lib/Layout";
import Link from "@civicplus/preamble-ui/lib/Link";
import OpenInNewTab from "@mui/icons-material/OpenInNew";
import PublicationItem from "./components/publicationItem";
import Typography from "@civicplus/preamble-ui/lib/Typography";
import { ChangesType, LegislationType } from "./types";
import { HtmlDescription } from "../../../components/Layout/HtmlDescription";
import { Link as RouterLink } from "react-router-dom";
import { makeStyles } from "@civicplus/preamble-ui/lib/Utilities/ThemeHelper";
import { NavigationOption, PagedResult } from "../../../types/Organization";
import { SearchDocument, DocumentType } from "../../../types/SearchDocument";
import { useAuth } from "../../../providers/AuthProvider";
import { useOrganization } from "../../../stores/organizationStore";

const useStyles = makeStyles((theme) => ({
    link: {
        fontSize: theme.typography.body1.fontSize,
        verticalAlign: "middle"
    },
    card: {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2),
        backgroundColor: theme.palette.background.paper,
        borderRadius: theme.shape.borderRadius,
        boxShadow: theme.shadows[1]
    }
}));

export type MunicodeNextCode = {
    item: {
        id: string;
        title: string;
        url: string;
        ordinances: LegislationType[];
        recentChanges: ChangesType[];
    };
} & SearchDocument;

export const Legislation: React.FC = () => {
    const classes = useStyles();
    const auth = useAuth();
    const [codes, setCodes] = useState<MunicodeNextCode[]>([]);
    const [isLoading, setIsLoading] = useState(true);

    const [docs, setDocs] = useState<SearchDocument[]>();
    const [organization] = useOrganization((state) => [state.organization]);

    const orgName = organization?.friendlyName || organization?.name;

    useEffect(() => {
        const getPublications = async () => {
            setIsLoading(true);

            const results: PagedResult<SearchDocument> = await ApiService.get({
                url: `${organization?.name}/search/?documentType=Publication&documentType=PublicationDocument`,
                cache: false,
                authUser: auth.user
            });

            setCodes(results.items.filter((x) => x.type === DocumentType.Publication) as MunicodeNextCode[]);
            setDocs(results.items.filter((x) => x.type === DocumentType.PublicationDocument));

            setIsLoading(false);
        };

        if (organization != null) {
            getPublications();
        }
    }, [organization, auth.user]);

    const navigationItem =
        organization && organization.navigation && organization.navigation[NavigationOption.Legislation];

    return (
        <Layout
            TitlebarProps={{
                id: "legislation-titlebar",
                title: navigationItem ? navigationItem.customLabel ?? navigationItem.defaultLabel : "Legislation",
                breadcrumbs: [
                    <Link key="home" to={`/${organization?.name}`} component={RouterLink}>
                        Home
                    </Link>
                ],
                description: (
                    <HtmlDescription
                        description={
                            navigationItem?.additionalProductInformation ??
                            `Recent updates and adoptions made to the Code of Ordinances Publications of ${orgName}.`
                        }
                    />
                )
            }}
        >
            <HiddenPageAlert navOption={NavigationOption.Legislation} />

            <Grid container={true} spacing={3}>
                {isLoading ? (
                    <Grid xs={12} key="skeleton">
                        <PublicationItem />
                    </Grid>
                ) : codes.length === 0 ? (
                    <Grid xs={12}>
                        <Typography variant="h5" component="h3">
                            No legislation found
                        </Typography>
                    </Grid>
                ) : (
                    codes.map((code) => (
                        <Grid xs={12} key={code.id}>
                            <PublicationItem
                                {...code}
                                ordinances={code.item.ordinances}
                                recentChanges={code.item.recentChanges}
                            />
                        </Grid>
                    ))
                )}

                {docs === undefined ? (
                    <></>
                ) : (
                    docs.map((doc) => (
                        <Grid xs={12} key={doc.id}>
                            <div className={classes.card}>
                                <Grid container={true} justifyContent="space-between" alignItems="center">
                                    <Grid>
                                        <Typography variant="h5" component="h3">
                                            {orgName} Documents
                                        </Typography>

                                        <Typography variant="body1" color="textSecondary">
                                            Munidocs contains documents as designated by{" "}
                                            {orgName || "this organization"}
                                            {", "}
                                            follow the link to explore further.
                                        </Typography>
                                    </Grid>

                                    <Grid>
                                        <Button
                                            id="view-org-documents-btn"
                                            color="primary"
                                            endIcon={<OpenInNewTab />}
                                            onClick={() => {
                                                window.open(doc.url, "_blank");
                                            }}
                                        >
                                            VIEW ORGANIZATION DOCUMENTS
                                        </Button>
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                    ))
                )}
            </Grid>
        </Layout>
    );
};
