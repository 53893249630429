import React, { useEffect, useState } from "react";
import Button from "@civicplus/preamble-ui/lib/Button";
import ButtonGroup from "@civicplus/preamble-ui/lib/ButtonGroup";
import Grid from "@civicplus/preamble-ui/lib/Grid";
import Icon from "@mdi/react";
import Paper from "@mui/material/Paper";
import SkeletonLoader from "@civicplus/preamble-ui/lib/SkeletonLoader";
import Typography from "@civicplus/preamble-ui/lib/Typography";
import useStyles from "./styles";
import { getIconPathByName, getIconSetForNavigation } from "../Settings/Navigation/NavigationIcons";
import { NavigationItem, NavigationOption } from "../../types/Organization";
import { useFeatures } from "../../shared/useFeatureFlags";
import { useNavigate } from "react-router-dom";
import { useOrganization } from "../../stores/organizationStore";

export interface DashboardZoneProps {
    icon: string;
    title: string;
    url?: string;
    buttons?: React.ReactElement[] | React.ReactNode[];
    navigationType: NavigationOption;
    children: React.ReactElement;
}

const DashboardZone: React.FC<DashboardZoneProps> = (props) => {
    const { icon, title, url, buttons, navigationType, children } = props;
    const classes = useStyles();
    const navigate = useNavigate();
    const org = useOrganization((state) => state.organization);
    const [flags, _, orgFlagsLoaded] = useFeatures();
    const [zone, setZone] = useState<NavigationItem>();

    useEffect(() => {
        if (flags.showSettingsNavigation && org?.navigation && orgFlagsLoaded) {
            const getItem = org.navigation[navigationType];
            const icon = getIconPathByName(
                getItem?.icon ?? getIconSetForNavigation(navigationType)[0].path,
                navigationType
            );
            const isAlertAndNotifications = navigationType === NavigationOption.AlertsAndNotifications;
            const customLabel = isAlertAndNotifications ? `${getItem.customLabel} - ${title}` : getItem.customLabel;
            setZone({
                ...getItem,
                customLabel,
                icon
            });
        }
    }, [flags.showSettingsNavigation, navigationType, org, orgFlagsLoaded, title]);

    const id = title.toLowerCase().replace(" ", "-");
    const isLoading = orgFlagsLoaded === false || !org;

    if (flags.showSettingsNavigation && zone && !zone.isActive) {
        return null;
    }

    return (
        <Paper className={classes.root} id={`${id}-dashboard-zone`}>
            <Grid container={true} justifyContent="space-between" alignItems="center" spacing={2}>
                <Grid md="auto" xs={12}>
                    <div className={classes.zoneTitleWrapper}>
                        {isLoading ? (
                            <SkeletonLoader circle={true} width={34} height={34} />
                        ) : (
                            <Icon path={zone ? zone.icon : icon} className={classes.icon} />
                        )}

                        <Typography variant="h6" component="h2" className={classes.zoneTitle}>
                            {isLoading ? <SkeletonLoader width={50} /> : zone ? zone.customLabel : title}
                        </Typography>
                    </div>
                </Grid>

                {url && (
                    <Grid md="auto">
                        <Button
                            id={`view-all-${id}-btn`}
                            color="default"
                            onClick={() => navigate(url)}
                            disabled={isLoading}
                        >
                            View all {isLoading ? <SkeletonLoader width={10} /> : zone ? zone.customLabel : title}
                        </Button>
                    </Grid>
                )}

                {buttons && (
                    <Grid md="auto">
                        <ButtonGroup>{buttons}</ButtonGroup>
                    </Grid>
                )}

            </Grid>

            <div className={classes.content}>
                {children}
            </div>
        </Paper>
    );
};

export default DashboardZone;
