import React, { useMemo } from "react";
import AccessibilityMessage from "../AccessibilityMessage";
import AlertToggle from "../../components/AlertToggle";
import ChipPortal from "../Chip";
import clsx from "clsx";
import languages from "../../shared/languages";
import List from "@civicplus/preamble-ui/lib/List/List";
import ListItem from "@civicplus/preamble-ui/lib/ListItem";
import SkeletonLoader from "@civicplus/preamble-ui/lib/SkeletonLoader";
import Typography from "@civicplus/preamble-ui/lib/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { DocumentType } from "../../types/SearchDocument";
import { Theme, useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { NotificationsService } from "../../services/notificationsService";
import { Organization } from "../../types/Organization";
import { SubscriptionList } from "../../types/Subscription";
import { useAccountInfo } from "../../hooks/useAccountInfo";

const useStyles = makeStyles((theme: Theme) => ({
    toggle: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        gap: theme.spacing(1),
        [theme.breakpoints.up("md")]: {
            justifyContent: "center"
        },
        [theme.breakpoints.down("md")]: {
            flexDirection: "column"
        }
    },
    alignEnd: {
        justifyContent: "flex-end"
    },
    chipsContainer: {
        display: "flex",
        flexWrap: "wrap",
        gap: 5,
        margin: theme.spacing(1, 0, 0),
        overflowX: "auto"
    },
    externalChip: {
        "& svg": {
            marginLeft: theme.spacing(1) + "px !important"
        }
    },
    featured: {
        position: "relative",
        "&:after": {
            content: '""',
            position: "absolute",
            left: 0,
            top: 0,
            width: 5,
            height: "100%",
            backgroundColor: theme.palette.primary.main
        }
    },
    alertListing: {
        margin: theme.spacing(2, 0),
        "& > li": {
            borderBottom: `1px solid ${theme.palette.divider}`, //"1px solid rgba(0, 0, 0, 0.05)",
            [theme.breakpoints.down("md")]: {
                paddingBottom: 12,
                borderBottom: "1px solid rgba(0, 0, 0, 0.15)"
            }
        }
    },
    mobileListButton: {
        paddingRight: "15px !important"
    },
    mobileToggle: {
        display: "flex",
        position: "relative",
        transform: "none",
        justifyContent: "center"
    },
    textContent: {
        paddingRight: 0
    },
    mobileSubscribeLabel: {
        margin: "-5px 0 0"
    }
}));

export const enum ColumnMap {
    AggregateId,
    Name,
    Groups,
    Tags,
    Description,
    IsSubscribed,
    Featured,
    List,
    Pending
}

export interface AlertListProps {
    alerts: SubscriptionList[] | undefined;
    organization: Organization;
    searchText?: string;
    setRefreshRows?: React.Dispatch<React.SetStateAction<boolean>>;
    handleSubscriptionUpdate?: (subscription: SubscriptionList, subscribedToDefaultGroups: boolean) => void;
}

const AlertList: React.FC<AlertListProps> = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const isMobileView = useMediaQuery(theme.breakpoints.down("md"));

    const notificationService = useMemo(() => new NotificationsService(), []);
    const [isAccountInfoLoading, userAccountInfo, reloadAccountInfo] = useAccountInfo();
    const { alerts, handleSubscriptionUpdate, organization, searchText, setRefreshRows } = props;

    const renderedAlertRows = useMemo(() => {
        if (alerts === undefined) {
            return Array.from({ length: 5 }, (_, i) => (
                <ListItem
                    key={`skeleton-${i}`}
                    divider={true}
                    button={false}
                    ListItemTextProps={{
                        primary: <SkeletonLoader width={300} />
                    }}
                />
            ));
        } else if (alerts.length === 0) {
            return [
                <ListItem
                    key="empty-list"
                    ListItemTextProps={{
                        primary: (
                            <Typography align="center">
                                {searchText ? "No results found" : "No alerts found"}
                            </Typography>
                        )
                    }}
                />
            ];
        } else {
            if (!organization) {
                return null;
            }

            return alerts.map((alert) => {
                return (
                    <ListItem
                        key={alert.id}
                        classes={{
                            container: alert.isFeatured ? classes.featured : undefined
                        }}
                        className={clsx({ [classes.mobileListButton]: isMobileView })}
                        ListItemTextProps={{
                            primary: alert.name,
                            className: classes.textContent,
                            secondary: (
                                <div>
                                    <Typography variant="body2">
                                        {alert.description}
                                    </Typography>

                                    <div className={classes.chipsContainer}>
                                        {alert.tags?.map((tag) => <ChipPortal label={tag} key={tag} size="small" />)}
                                    </div>
                                </div>
                            )
                        }}
                        itemSecondaryAction={
                            <div className={clsx(classes.toggle, classes.alignEnd)}>
                                <AlertToggle
                                    id={`${alert.aggregateId}-alert-toggle`}
                                    alerts={alerts}
                                    documentType={DocumentType.AlertList}
                                    languages={languages}
                                    notificationService={notificationService}
                                    organization={organization}
                                    subscribed={alert.emailSubscription ?? false}
                                    subscription={alert}
                                    userAccountDetails={userAccountInfo}
                                    reloadAccountDetails={reloadAccountInfo}
                                    handlePendingSubscription={true}
                                    onChangeComplete={(success: boolean, subscribedToDefaultGroups: boolean) => {
                                        if (success) {
                                            alert.emailSubscription = !alert.emailSubscription;
                                            alert.smsSubscription = !alert.smsSubscription;
                                            if (alert.emailSubscription && subscribedToDefaultGroups) {
                                                setRefreshRows?.((prev) => !prev);
                                            } else {
                                                handleSubscriptionUpdate?.(alert, subscribedToDefaultGroups);
                                            }
                                        } else {
                                            handleSubscriptionUpdate?.(alert, subscribedToDefaultGroups);
                                        }
                                    }}
                                />

                                {isMobileView ? (
                                    <p className={classes.mobileSubscribeLabel}>
                                        <b>Subscribe</b>
                                    </p>
                                ) : null}
                            </div>
                        }
                        ListItemSecondaryActionProps={{
                            className: isMobileView ? classes.mobileToggle : undefined
                        }}
                    />
                );
            });
        }
    }, [
        alerts,
        handleSubscriptionUpdate,
        notificationService,
        organization,
        searchText,
        setRefreshRows,
        userAccountInfo,
        reloadAccountInfo,
        isMobileView,
        classes.alignEnd,
        classes.chipsContainer,
        classes.featured,
        classes.toggle,
        classes.textContent,
        classes.mobileToggle,
        classes.mobileListButton,
        classes.mobileSubscribeLabel
    ]);

    const isLoading = isAccountInfoLoading || alerts === undefined;

    return (
        <>
            <List
                className={classes.alertListing}
                id="alerts-list"
                data-testid="alerts-list"
                wrapperStyles={true}
                aria-busy={isLoading}
            >
                {renderedAlertRows}
            </List>

            <AccessibilityMessage
                message={isLoading ? "Loading Subscription Details" : "Subscription Details Loaded"}
            />
        </>
    );
};

export default AlertList;
