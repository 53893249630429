import React, { useCallback, useMemo } from "react";
import AdvancedTableWrapper from "@civicplus/preamble-ui/lib/AdvancedTable/AdvancedTableWrapper";
import ApiService from "../../services/apiService";
import Chip from "@civicplus/preamble-ui/lib/Chip";
import Close from "@mui/icons-material/Close";
import Grid from "@civicplus/preamble-ui/lib/Grid";
import IconCircleCheck from "@civicplus/preamble-ui/lib/Icons/IconCircleCheck";
import InfoIcon from "mdi-material-ui/Information";
import Menu from "@civicplus/preamble-ui/lib/Menu";
import Tooltip from "@civicplus/preamble-ui/lib/Tooltip";
import useStyles from "./styles";
import { AuthContextProps } from "../react-oidc-context/AuthContext";
import { convertSearchDocumentToLink, GetSectionFromDocumentType, Link } from "../../types/Link";
import { DocumentType, SearchDocument } from "../../types/SearchDocument";
import { getFormattedDateStringMonthDayYearWithTime } from "@civicplus/preamble-ui/lib/Utilities/DateHelper";
import { getProductTypeLabels, filterLogic } from "../../shared/functions";
import { isNullOrWhiteSpace } from "@civicplus/preamble-ui/lib/Utilities/StringHelper";
import {
    makeCustomSelectPicker,
    standardFilterLogic
} from "@civicplus/preamble-ui/lib/Utilities/CommonTableComponents";
import { OptionShape } from "@civicplus/preamble-ui/lib/Autocomplete";
import { Organization, PagedResult } from "../../types/Organization";
import { ProductType, getProductTypeOptions } from "../../types/ProductType";
import { RowStatus, TableData } from "@civicplus/preamble-ui/lib/AdvancedTable/AdvancedTableWrapperConstants";
import { useSnackbar } from "notistack";

const enum ColumnMap {
    AggregateId,
    Title,
    Tags,
    StartDate,
    IsHidden,
    IsFeatured,
    ProductType
}

interface ActionMenuProps {
    tableData: { rowData: unknown[]; rowIndex: number };
    items: PagedResult<SearchDocument>;
    organization: Organization;
    auth: AuthContextProps;
    setItemToBeEdited: React.Dispatch<React.SetStateAction<{ data: Link; index: number } | undefined>>;
    setItemToBeDeleted: React.Dispatch<React.SetStateAction<{ data: SearchDocument; index: number } | undefined>>;
    setIsEditDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setIsEditExternalDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setIsDeleteDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
    refContainer: any;
    showFeatureOption: boolean;
}

const ActionMenu: React.FC<ActionMenuProps> = ({
    tableData,
    items,
    setItemToBeEdited,
    setItemToBeDeleted,
    setIsEditDialogOpen,
    setIsEditExternalDialogOpen,
    setIsDeleteDialogOpen,
    organization,
    auth,
    refContainer,
    showFeatureOption
}) => {
    const { enqueueSnackbar } = useSnackbar();
    const index = items.items.findIndex((item) => item.id === tableData.rowData[ColumnMap.AggregateId]);
    const item = items.items[index];

    if (item === undefined) {
        return <></>;
    }

    const itemActions = [];

    itemActions.push({
        children: "Edit",
        onClick: () => {
            const section = GetSectionFromDocumentType(item.type);
            setItemToBeEdited({ data: convertSearchDocumentToLink(item, section), index: index });

            item.productType === ProductType.CivicPlusPortal
                ? setIsEditDialogOpen(true)
                : setIsEditExternalDialogOpen(true);
        }
    });

    itemActions.push({
        children: "Copy link",
        onClick: () => {
            navigator.clipboard.writeText(item.url);
            enqueueSnackbar("Link copied to clipboard.", {
                variant: "success"
            });
        }
    });

    itemActions.push({
        children: `${tableData.rowData[ColumnMap.IsHidden] === true ? "Show" : "Hide"} in Portal`,
        onClick: async () => {
            const result = await ApiService.put({
                url: `${organization.name}/links/toggle/visibility/${tableData.rowData[ColumnMap.AggregateId]}`,
                authUser: auth.user
            });

            const tableRef = refContainer && (refContainer.current as typeof AdvancedTableWrapper);
            tableData.rowData[ColumnMap.IsHidden] = result.isHidden;
            tableRef.reloadRow(tableData.rowIndex, tableData.rowData);
        }
    });

    if (showFeatureOption) {
        itemActions.push({
            children: `${tableData.rowData[ColumnMap.IsFeatured] === true ? "Unfeature" : "Feature"} in Portal`,
            onClick: async () => {
                const result = await ApiService.put({
                    url: `${organization.name}/links/toggle/featured/${tableData.rowData[ColumnMap.AggregateId]}`,
                    authUser: auth.user
                });

                tableData.rowData[ColumnMap.IsFeatured] = result.isFeatured;

                item.isFeatured = result.isFeatured;
                const section = GetSectionFromDocumentType(item.type);
                const newLinkToEdit = convertSearchDocumentToLink(item, section);
                setItemToBeEdited({ data: newLinkToEdit, index: index });

                refContainer.current.reloadRow(tableData.rowIndex, tableData.rowData);
            }
        });
    }

    if (item.productType === ProductType.CivicPlusPortal) {
        itemActions.push({
            children: "Delete",
            onClick: () => {
                setItemToBeDeleted({ data: item, index: index });
                setIsDeleteDialogOpen(true);
            }
        });
    }

    return <Menu type="action" items={itemActions} id={`actions-${item.id}`} key={`actions-${item.id}`} />;
};

interface SettingsTableProps {
    refContainer: React.MutableRefObject<any>;
    loading: boolean;
    items: PagedResult<SearchDocument>;
    tags: OptionShape[];
    tabName: string;
    type: DocumentType;
    timeZone: string;
    organization: Organization;
    auth: AuthContextProps;
    showFeatureOption: boolean;
    setItemToBeEdited: React.Dispatch<React.SetStateAction<{ data: Link; index: number } | undefined>>;
    setItemToBeDeleted: React.Dispatch<React.SetStateAction<{ data: SearchDocument; index: number } | undefined>>;
    setIsEditDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setIsEditExternalDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setIsDeleteDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const SettingsTable: React.FC<SettingsTableProps> = React.memo(function SettingsTableInternal({
    refContainer,
    loading,
    items,
    tags,
    tabName,
    type,
    timeZone,
    organization,
    auth,
    showFeatureOption,
    setItemToBeEdited,
    setItemToBeDeleted,
    setIsEditDialogOpen,
    setIsEditExternalDialogOpen,
    setIsDeleteDialogOpen
}) {
    const classes = useStyles();

    const mapData = useCallback((item: SearchDocument, _: number) => {
        // Ordering of columns is important. If order changes, ensure all spots that read the row data by index is updated.
        const res = [item.id, item.title, item.tags, item.startDate, item.isHidden, item.isFeatured, item.productType];
        return res;
    }, []);

    const getRows = useCallback(async (): Promise<TableData> => {
        if (loading) {
            return { count: 0, data: [], status: RowStatus.Loading };
        }

        const data = items.items
            .sort((a, b) => (a.title.toLowerCase() < b.title.toLowerCase() ? 1 : 0))
            .map((item, index) => mapData(item, index));

        return { count: items.count, data };
    }, [loading, items, mapData]);

    const customSearch = useCallback((searchQuery: string, currentRow: string[]) => {
        let isFound = false;

        currentRow.forEach((col) => {
            if (col) {
                if (col.toString().toLowerCase().indexOf(searchQuery.toLowerCase()) >= 0) {
                    isFound = true;
                }
            }
        });

        return isFound;
    }, []);

    const columns = useMemo(() => {
        return [
            {
                name: "id",
                label: "Id",
                options: {
                    sort: false,
                    display: "false"
                }
            },
            {
                name: "title",
                label: "Name",
                options: {
                    sort: true,
                    filter: false,
                    customBodyRender: (value: string) => {
                        const description = items.items.find((f) => f.title === value)?.description;
                        return (
                            <Grid container={true} alignItems="center">
                                <Grid>{value}</Grid>
                                <Grid xs={1}>
                                    {isNullOrWhiteSpace(description) ? null : (
                                        <Tooltip
                                            id={`${description}-tooltip`}
                                            delay="instant"
                                            title={description || ""}
                                            placement="bottom-end"
                                        >
                                            <InfoIcon className={classes.icon} aria-haspopup="true" />
                                        </Tooltip>
                                    )}
                                </Grid>
                            </Grid>
                        );
                    }
                }
            },
            {
                name: "tags",
                label: "Tags",
                options: {
                    sort: false,
                    filter: true,
                    customBodyRender: (values: string[]) => (
                        <Grid container={true}>
                            {values.map((category) => {
                                return (
                                    <Grid key={category}>
                                        <Chip id={category} label={category} className={classes.categoryChip} />
                                    </Grid>
                                );
                            })}
                        </Grid>
                    ),
                    ...makeCustomSelectPicker(
                        "Tags",
                        Array.from(new Set(tags)).map((t) => ({
                            label: t.label,
                            value: t.value
                        })),
                        standardFilterLogic
                    )
                }
            },
            {
                name: "startDate",
                label: "Date",
                options: {
                    sort: true,
                    filter: false,
                    display: type === DocumentType.Event ? "true" : "excluded",
                    customBodyRender: (value: string) => {
                        return getFormattedDateStringMonthDayYearWithTime(value, timeZone);
                    },
                    sortCompare: (order: string) => {
                        return (date1: { data: string }, date2: { data: string }) => {
                            return order === "asc"
                                ? new Date(date1.data).getTime() - new Date(date2.data).getTime()
                                : new Date(date2.data).getTime() - new Date(date1.data).getTime();
                        };
                    }
                }
            },
            {
                name: "isHidden",
                label: "Visible",
                options: {
                    sort: false,
                    filter: true,
                    customBodyRender: (value: boolean) => {
                        return (
                            <Tooltip
                                key={`tooltip-isHidden-${value}`}
                                title={value ? "Hidden in Portal" : "Visible in Portal"}
                            >
                                {value ? <Close color="disabled" /> : <IconCircleCheck />}
                            </Tooltip>
                        );
                    },
                    ...makeCustomSelectPicker(
                        "Hidden",
                        [
                            { label: "Hidden", value: true },
                            { label: "Visible", value: false }
                        ],
                        standardFilterLogic
                    )
                }
            },
            {
                name: "isFeatured",
                label: "Featured",
                options: {
                    sort: false,
                    filter: true,
                    customBodyRender: (value: boolean) => {
                        return (
                            <Tooltip
                                key={`tooltip-isFeatured-${value}`}
                                title={value ? "Featured in Portal" : "Not featured in Portal"}
                            >
                                {value ? <IconCircleCheck /> : <Close color="disabled" />}
                            </Tooltip>
                        );
                    },
                    ...makeCustomSelectPicker(
                        "Featured",
                        [
                            { label: "Featured", value: true },
                            { label: "Not Featured", value: false }
                        ],
                        standardFilterLogic
                    )
                }
            },
            {
                name: "productType",
                label: "Product Type",
                options: {
                    sort: false,
                    filter: type !== DocumentType.AlertList,
                    display: type !== DocumentType.AlertList ? "true" : "excluded",
                    customBodyRender: (value: string) => {
                        return (
                            <div className={classes.settingsColumn}>{getProductTypeLabels(value as ProductType)}</div>
                        );
                    },
                    ...makeCustomSelectPicker(
                        "Product Type",
                        Array.from(getProductTypeOptions(type)).map((p) => ({
                            label: getProductTypeLabels(p),
                            value: p
                        })),
                        filterLogic
                    )
                }
            },
            {
                name: "actions",
                label: "Actions",
                options: {
                    filter: false,
                    sort: false,
                    searchable: false,
                    viewColumns: false,
                    customBodyRender: (_: never, tableData: { rowData: unknown[]; rowIndex: number }) => (
                        <ActionMenu
                            tableData={tableData}
                            items={items}
                            setItemToBeEdited={setItemToBeEdited}
                            setItemToBeDeleted={setItemToBeDeleted}
                            setIsDeleteDialogOpen={setIsDeleteDialogOpen}
                            setIsEditDialogOpen={setIsEditDialogOpen}
                            setIsEditExternalDialogOpen={setIsEditExternalDialogOpen}
                            organization={organization}
                            auth={auth}
                            refContainer={refContainer}
                            showFeatureOption={showFeatureOption}
                        />
                    )
                }
            }
        ];
    }, [
        items,
        tags,
        organization,
        auth,
        type,
        timeZone,
        setItemToBeEdited,
        setItemToBeDeleted,
        setIsDeleteDialogOpen,
        setIsEditDialogOpen,
        setIsEditExternalDialogOpen,
        classes,
        refContainer,
        showFeatureOption
    ]);

    return (
        <AdvancedTableWrapper
            key={`${loading}-${JSON.stringify(items.items)}`}
            columns={columns}
            rows={getRows}
            scrollToTop={true}
            showFilter={true}
            showSearch={true}
            emptyMessage={`You currently have no ${tabName}.`}
            serverSide={false}
            customSearch={customSearch}
            ref={refContainer}
        />
    );
});

export default SettingsTable;
