import { createWithEqualityFn } from "zustand/traditional";
import { devtools } from "zustand/middleware";

export interface InitEmbedProps {
    organizationName?: string;
    baseUrl?: string;
    defaultSection?: string;
    startingPath?: string;
    isUserSignedIn: boolean;
    isInitialized?: boolean;
    hideNavigation?: boolean;
    backgroundColor?: string;
}

export interface EmbedStoreState extends InitEmbedProps {
    initEmbedStore: (props: InitEmbedProps) => void;
}

export const useEmbedStore = createWithEqualityFn<EmbedStoreState>()(
    devtools(
        (set, get) => ({
            organizationName: undefined,
            baseUrl: undefined,
            defaultSection: undefined,
            startingPath: undefined,
            isUserSignedIn: false,
            isInitialized: false,
            initEmbedStore: ({
                organizationName,
                defaultSection,
                baseUrl,
                startingPath,
                isUserSignedIn,
                hideNavigation,
                backgroundColor
            }) =>
                set({
                    organizationName,
                    baseUrl,
                    defaultSection:
                        defaultSection && defaultSection.toLowerCase() === "settings" ? "links" : defaultSection,
                    startingPath,
                    isUserSignedIn,
                    isInitialized: true,
                    hideNavigation,
                    backgroundColor
                })
        }),
        { name: "embedStore" }
    )
);
