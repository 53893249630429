import React, { useEffect, useMemo, useState } from "react";
import AccessibilityMessage from "../../../../components/AccessibilityMessage";
import AlertToggle from "../../../../components/AlertToggle";
import Autocomplete from "@civicplus/preamble-ui/lib/Autocomplete";
import Button from "@civicplus/preamble-ui/lib/Button";
import ButtonDropDown from "@civicplus/preamble-ui/lib/ButtonDropDown";
import ButtonGroup from "@civicplus/preamble-ui/lib/ButtonGroup";
import clsx from "clsx";
import FilterButton from "../../../../components/FilterButton";
import Grid from "@civicplus/preamble-ui/lib/Grid";
import languages from "../../../../shared/languages";
import List from "@civicplus/preamble-ui/lib/List";
import ListItem from "@civicplus/preamble-ui/lib/ListItem";
import SearchInput from "@civicplus/preamble-ui/lib/SearchInput";
import SkeletonLoader from "@civicplus/preamble-ui/lib/SkeletonLoader";
import AlertsSubscriptionDialog, { DataType } from "../../../../components/AlertsSubscriptionDialog";
import TablePagination from "@civicplus/preamble-ui/lib/TablePagination";
import Typography from "@civicplus/preamble-ui/lib/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import tabStyles from "../styles";
import { ApiError, parseErrorMessage } from "../../../../services/apiService";
import { DocumentType } from "../../../../types/SearchDocument";
import { makeStyles, useTheme } from "@civicplus/preamble-ui/lib/Utilities/ThemeHelper";
import { mdiEmailOutline, mdiPhoneInTalk, mdiCellphoneSound, mdiMessageProcessingOutline } from "@mdi/js";
import { NotificationsService, SubscriptionAction } from "../../../../services/notificationsService";
import { SubscriptionList, SubscriptionType, WeatherChannel } from "../../../../types/Subscription";
import { SubscriptionProfileInformation } from "../../../../types/Account";
import { useAccountInfo } from "../../../../hooks/useAccountInfo";
import { useApplicationPhoneNumbers } from "../../../../hooks/useApplicationPhoneNumbers";
import { useAuth } from "../../../../providers/AuthProvider";
import { useOrganization } from "../../../../stores/organizationStore";
import { setProfileInfo, validateKonexusInfo } from "../../../../shared/alertFunctions";
import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme) => ({
    flex: {
        display: "flex"
    },
    row: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        [theme.breakpoints.down("md")]: {
            flexDirection: "column"
        }
    },
    title: {
        flex: "1 1 auto",
        marginRight: theme.spacing(2)
    },
    toggleGroup: {
        display: "flex",
        gap: theme.spacing(2),
        [theme.breakpoints.up("md")]: {
            justifyContent: "center"
        }
    },
    toggle: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        gap: theme.spacing(1),
        [theme.breakpoints.up("md")]: {
            justifyContent: "center"
        },
        [theme.breakpoints.down("md")]: {
            flexDirection: "column"
        }
    },
    alignEnd: {
        justifyContent: "flex-end"
    },
    fullWidth: {
        [theme.breakpoints.down("md")]: {
            flex: "1"
        }
    },
    mobileSubscribeLabel: {
        margin: "-5px 0 0"
    }
}));

export type WeatherAlertsTabProps = {
    onShowContactDialog: () => void;
};

export const WeatherAlertsTab: React.FC<WeatherAlertsTabProps> = ({ onShowContactDialog }) => {
    const classes = useStyles();
    const tabsClasses = tabStyles();
    const auth = useAuth();
    const theme = useTheme();
    const isMobileView = useMediaQuery(theme.breakpoints.down("md"));

    const [organization] = useOrganization((state) => [state.organization]);
    const [alerts, setAlerts] = useState<SubscriptionList[]>();
    const [searchText, setSearchText] = useState("");
    const [isAccountInfoLoading, userAccountInfo, reloadAccountInfo] = useAccountInfo();
    const [isPhoneNumbersLoading, phoneNumbers] = useApplicationPhoneNumbers();
    const notificationService = useMemo(() => new NotificationsService(), []);
    const [currentPage, setCurrentPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [showSubscriptionDialog, setShowSubscriptionDialog] = React.useState(false);
    const [subscribeToAllSelectedChannels, setSubscribeToAllSelectedChannels] = React.useState<WeatherChannel[]>([]);
    const [refreshRows, setRefreshRows] = useState(false);
    const [subscribeToAllPending, setSubscribeToAllPending] = useState(false);
    const [unsubscribeToAllPending, setUnsubscribeToAllPending] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

    const isSubscriptionDialogLoading = useMemo(
        () => isPhoneNumbersLoading || isAccountInfoLoading,
        [isPhoneNumbersLoading, isAccountInfoLoading]
    );

    useEffect(() => {
        const controller = new AbortController();

        async function fetchAlerts(orgName: string) {
            try {
                const weatherResults = await notificationService.getSubscriptions({
                    orgName: orgName,
                    authUser: auth.user,
                    documentType: DocumentType.AlertWeather
                });

                if (controller && controller.signal.aborted) return;
                setAlerts(weatherResults);
                setSubscribeToAllPending(false);
                setUnsubscribeToAllPending(false);
            } catch (error) {
                if (controller?.signal.aborted) return;
                const message = parseErrorMessage(
                    error as ApiError,
                    "An error occurred while fetching weather alerts. Please try again."
                );

                enqueueSnackbar(message, {
                    variant: "error",
                    persist: true
                });
            }
        }

        if (organization?.name) {
            fetchAlerts(organization?.name);
        }

        return () => {
            controller.abort();
        };
    }, [auth.user, notificationService, organization?.name, refreshRows, enqueueSnackbar]);

    const handleSubscriptionUpdate = (subscription: SubscriptionList) => {
        setAlerts((prev) => {
            if (!prev) return prev;
            return prev.map((item) => {
                if (item.aggregateId === subscription.aggregateId) {
                    return subscription;
                }
                return item;
            });
        });
    };

    const handleChangePage = async (
        _event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
        newPage: number
    ) => {
        setCurrentPage(newPage);
    };

    const handleChangeRowsPerPage = async (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10) || 10);
    };

    const alertsFiltered = useMemo(() => {
        return alerts?.filter((item) => item.name.toLowerCase().includes(searchText.toLowerCase()));
    }, [alerts, searchText]);

    const alertsPaginated = useMemo(() => {
        return alertsFiltered?.slice(currentPage * rowsPerPage, currentPage * rowsPerPage + rowsPerPage);
    }, [alertsFiltered, currentPage, rowsPerPage]);

    const renderedWeatherAlerts = useMemo(() => {
        if (alertsPaginated === undefined) {
            return Array.from({ length: 5 }, (_, i) => (
                <ListItem
                    key={`skeleton-${i}`}
                    divider={true}
                    button={false}
                    ListItemTextProps={{
                        primary: <SkeletonLoader width={300} />
                    }}
                />
            ));
        } else if (alertsPaginated.length === 0) {
            return [
                <ListItem
                    key="empty-list"
                    ListItemTextProps={{
                        primary: (
                            <Typography align="center">
                                {searchText ? "No results found" : "No alerts found"}
                            </Typography>
                        )
                    }}
                />
            ];
        } else {
            return alertsPaginated.map((item) => {
                if (!organization) {
                    return null;
                }

                const shouldHideVoiceHome = !item.supportedChannels?.find((X) =>
                    [WeatherChannel.VoiceHome].includes(X)
                );
                const shouldHideVoiceCell = !item.supportedChannels?.find((X) =>
                    [WeatherChannel.VoiceCell].includes(X)
                );
                const shouldHideEmailText = !item.supportedChannels?.find((X) =>
                    [WeatherChannel.Email, WeatherChannel.Text].includes(X)
                );

                return (
                    <ListItem
                        key={item.id}
                        divider={true}
                        ListItemTextProps={{
                            primary: (
                                <div className={classes.row}>
                                    <Typography className={classes.title}>{item.name}</Typography>

                                    <div className={classes.toggleGroup}>
                                        {shouldHideVoiceHome ? null : (
                                            <div className={classes.toggle}>
                                                <AlertToggle
                                                    id={`Subscribe to Home Phone Voice Notifications - ${item.aggregateId}-alert-home-toggle`}
                                                    iconPath={mdiPhoneInTalk}
                                                    tooltipText="Receive Voice Notifications at Home"
                                                    subscribed={item.homeSubscription || false}
                                                    organization={organization}
                                                    subscription={item}
                                                    notificationService={notificationService}
                                                    languages={languages}
                                                    userAccountDetails={userAccountInfo}
                                                    alerts={alerts}
                                                    documentType={DocumentType.AlertWeather}
                                                    weatherChannels={[WeatherChannel.VoiceHome]}
                                                    handlePendingSubscription={true}
                                                    onChangeComplete={(success: boolean) => {
                                                        if (success) {
                                                            item.homeSubscription = !item.homeSubscription;
                                                            handleSubscriptionUpdate(item);
                                                        }
                                                    }}
                                                />

                                                {isMobileView ? (
                                                    <p className={classes.mobileSubscribeLabel}>
                                                        <b>Home</b>
                                                    </p>
                                                ) : null}
                                            </div>
                                        )}

                                        {shouldHideVoiceCell ? null : (
                                            <div className={classes.toggle}>
                                                <AlertToggle
                                                    id={`Subscribe to Cell Phone Voice Notifications - ${item.aggregateId}-alert-cell-toggle`}
                                                    iconPath={mdiCellphoneSound}
                                                    tooltipText="Receive Voice Notifications at Cell Phones"
                                                    subscribed={item.cellSubscription || false}
                                                    organization={organization}
                                                    subscription={item}
                                                    notificationService={notificationService}
                                                    languages={languages}
                                                    userAccountDetails={userAccountInfo}
                                                    alerts={alerts}
                                                    documentType={DocumentType.AlertWeather}
                                                    weatherChannels={[WeatherChannel.VoiceCell]}
                                                    handlePendingSubscription={true}
                                                    onChangeComplete={(success: boolean) => {
                                                        if (success) {
                                                            item.cellSubscription = !item.cellSubscription;
                                                            handleSubscriptionUpdate(item);
                                                        }
                                                    }}
                                                />

                                                {isMobileView ? (
                                                    <p className={classes.mobileSubscribeLabel}>
                                                        <b>Cell</b>
                                                    </p>
                                                ) : null}
                                            </div>
                                        )}

                                        {shouldHideEmailText ? null : (
                                            <div className={clsx(classes.toggle, classes.alignEnd)}>
                                                <AlertToggle
                                                    id={`Subscribe to Email and SMS notifications - ${item.aggregateId}-alert-email-sms-toggle`}
                                                    iconPath={[mdiEmailOutline, mdiMessageProcessingOutline]}
                                                    tooltipText="Receive Email and Text Notifications"
                                                    subscribed={
                                                        (item.emailSubscription && item.smsSubscription) || false
                                                    }
                                                    organization={organization}
                                                    subscription={item}
                                                    notificationService={notificationService}
                                                    languages={languages}
                                                    userAccountDetails={userAccountInfo}
                                                    alerts={alerts}
                                                    documentType={DocumentType.AlertWeather}
                                                    weatherChannels={[WeatherChannel.Email, WeatherChannel.Text]}
                                                    handlePendingSubscription={true}
                                                    onChangeComplete={(success: boolean) => {
                                                        if (success) {
                                                            item.emailSubscription = !item.emailSubscription;
                                                            item.smsSubscription = !item.smsSubscription;
                                                            handleSubscriptionUpdate(item);
                                                        }
                                                    }}
                                                />

                                                {isMobileView ? (
                                                    <p className={classes.mobileSubscribeLabel}>
                                                        <b>Email/SMS</b>
                                                    </p>
                                                ) : null}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )
                        }}
                    />
                );
            });
        }
    }, [
        alerts,
        organization,
        classes,
        notificationService,
        userAccountInfo,
        searchText,
        alertsPaginated,
        isMobileView
    ]);

    const handleSearch = (searchQuery: string) => {
        setSearchText(searchQuery);
    };

    const handleSubscribeAll = (channels: WeatherChannel[]) => {
        if (!organization || !alerts || alerts.length === 0) {
            return;
        }

        const applicationId = alerts[0].applicationId;

        const { isValid } = validateKonexusInfo(userAccountInfo, phoneNumbers, applicationId);

        setSubscribeToAllSelectedChannels(channels);

        if (!isValid) {
            setShowSubscriptionDialog(true);
            return;
        }

        handleSubscribeAllWithSignUpData(null, channels);
    };

    const handleSubscribeAllWithSignUpData = async (signUpData: DataType | null, channels: WeatherChannel[]) => {
        if (!organization || !auth.user) {
            return false;
        }

        try {
            setSubscribeToAllPending(true);
            const profileInfo: SubscriptionProfileInformation | null = setProfileInfo(signUpData);

            const response = await notificationService.subscribeToAllLists({
                organization,
                authUser: auth.user,
                documentType: DocumentType.AlertWeather,
                weatherChannels: channels,
                profileInfo
            } as SubscriptionAction);

            setRefreshRows((prev) => !prev);
            if (response.success) {
                enqueueSnackbar("Successfully subscribed to all lists.", {
                    variant: "success"
                });
                setShowSubscriptionDialog(false);
            } else {
                enqueueSnackbar(
                    response.error
                        ? response.error
                        : "An error occurred while subscribing to all lists. Please try again.",
                    {
                        variant: "error",
                        persist: true
                    }
                );
            }
            return response.success;
        } catch {
            enqueueSnackbar("An error occurred while subscribing to all lists. Please try again.", {
                variant: "error",
                persist: true
            });
        } finally {
            setSubscribeToAllPending(false);
        }
        return false;
    };

    const handleClose = () => {
        setShowSubscriptionDialog(false);
    };

    const handleUnsubscribeAll = async (channels: WeatherChannel[]) => {
        if (!organization || !alerts || alerts.length === 0) {
            return;
        }
        try {
            setUnsubscribeToAllPending(true);

            const response = await notificationService.unsubscribeFromAllLists({
                organization,
                authUser: auth.user,
                subscriptionType: SubscriptionType.None,
                documentType: DocumentType.AlertWeather,
                weatherChannels: channels
            });

            if (response.ok) {
                setRefreshRows((prev) => !prev);

                enqueueSnackbar("Unsubscribed successfully from all lists.", {
                    variant: "success"
                });
            } else {
                enqueueSnackbar(response.error, {
                    variant: "error",
                    persist: true
                });
            }
        } catch {
            enqueueSnackbar("Error while trying to unsubscribe user from all lists.", {
                variant: "error",
                persist: true
            });
        } finally {
            setUnsubscribeToAllPending(false);
        }
    };

    const isLoading = isAccountInfoLoading || alerts === undefined;

    const subscribeToAllButtonOptions = subscribeToAllPending
        ? [
              {
                  children: "Subscribe to all",
                  isLoading: true
              }
          ]
        : [
              {
                  children: "Subscribe to all",
                  className: tabsClasses.tabButton,
                  onClick: () =>
                      handleSubscribeAll([
                          WeatherChannel.Email,
                          WeatherChannel.Text,
                          WeatherChannel.VoiceHome,
                          WeatherChannel.VoiceCell
                      ]),
                  "aria-label": "Subscribe to all Weather alerts"
              },
              {
                  children: "Subscribe to Home Voice",
                  onClick: () => handleSubscribeAll([WeatherChannel.VoiceHome])
              },
              {
                  children: "Subscribe to Cell Voice",
                  onClick: () => handleSubscribeAll([WeatherChannel.VoiceCell])
              },
              {
                  children: "Subscribe to Email and SMS",
                  onClick: () => handleSubscribeAll([WeatherChannel.Email, WeatherChannel.Text])
              }
          ];

    const unsubscribeFromAllButtonOptions = unsubscribeToAllPending
        ? [
              {
                  children: "Unsubscribe from all",
                  isLoading: true
              }
          ]
        : [
              {
                  children: "Unsubscribe from all",
                  className: tabsClasses.tabButton,
                  onClick: () =>
                      handleUnsubscribeAll([
                          WeatherChannel.Email,
                          WeatherChannel.Text,
                          WeatherChannel.VoiceHome,
                          WeatherChannel.VoiceCell
                      ]),
                  "aria-label": "Unsubscribe from all Weather alerts"
              },
              {
                  children: "Only Voice Alerts for Home Phone",
                  onClick: () => handleUnsubscribeAll([WeatherChannel.VoiceHome])
              },
              {
                  children: "Only Voice Alerts for Cell Phone",
                  onClick: () => handleUnsubscribeAll([WeatherChannel.VoiceCell])
              },
              {
                  children: "Only Email and SMS Alerts",
                  onClick: () => handleUnsubscribeAll([WeatherChannel.Email, WeatherChannel.Text])
              }
          ];

    return (
        <Grid container={true} spacing={2} justifyContent="space-between">
            <Grid xs={12} className={tabsClasses.lg7Grid} alignItems="center">
                {auth.user && (
                    <ButtonGroup layout="right" className={tabsClasses.tabButtonGroup}>
                        <Button
                            id="manage-contact-btn"
                            onClick={onShowContactDialog}
                            className={tabsClasses.tabButton}
                            fullWidth={isMobileView}
                            isLoading={isSubscriptionDialogLoading}
                        >
                            Manage contact information
                        </Button>

                        <ButtonDropDown
                            color="primary"
                            options={subscribeToAllButtonOptions}
                            fullWidth={isMobileView}
                            className={tabsClasses.tabButton}
                            MenuProps={{ ButtonProps: { "aria-label": "Select specific subscribe options", className: tabsClasses.tabButton } }}
                        />

                        <ButtonDropDown
                            color="primary"
                            options={unsubscribeFromAllButtonOptions}
                            fullWidth={isMobileView}
                            className={tabsClasses.tabButton}
                            MenuProps={{ ButtonProps: { "aria-label": "Select specific unsubscribe options", className: tabsClasses.tabButton } }}
                        />
                    </ButtonGroup>
                )}
            </Grid>

            <Grid xs={12} className={tabsClasses.lg5Grid}>
                <Grid container={true} xs={12} alignItems="center" spacing={2}>
                    <Grid xs={true}>
                        <SearchInput
                            id="weather-search-bar"
                            value={searchText}
                            searchOnChange={true}
                            onSearch={handleSearch}
                            fullWidth={true}
                            margin={false}
                            style={{ minWidth: 220 }}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                handleSearch(e.target.value);
                            }}
                        />
                    </Grid>

                    <Grid width="auto">
                        <FilterButton id="weather-filter" onFilterReset={() => {}}>
                            <Autocomplete
                                id="tag-filter"
                                label="Tags"
                                options={[]}
                                multiSelect={true}
                                fullWidth={true}
                                isInDialog={true}
                            />
                        </FilterButton>
                    </Grid>
                </Grid>
            </Grid>

            <Grid xs={12}>
                <Typography variant="body2" style={{ textAlign: "justify" }}>
                    When subscribing to voice alerts for your home or cell phone, you will only receive a call
                    for that alert that you have subscribed to if it is a &quot;Warning&quot; status or severe
                    weather. All other Weather Alerts will be provided via SMS/Email.
                </Typography>
            </Grid>

            <Grid xs={12}>
                <List id="weather-list" data-testid="weather-list" wrapperStyles={true} aria-busy={isLoading}>
                    {renderedWeatherAlerts}
                </List>

                {alertsFiltered && alertsFiltered.length > 0 && (
                    <TablePagination
                        id="changes-results-pagination"
                        component="div"
                        count={alertsFiltered.length}
                        page={currentPage}
                        onPageChange={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        rowsPerPageOptions={[10, 25, 50, 100]}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                )}

                <AccessibilityMessage
                    message={isLoading ? "Loading Subscription Details" : "Subscription Details Loaded"}
                />
            </Grid>

            {alerts && alerts.length > 0 && showSubscriptionDialog && organization && userAccountInfo && (
                <AlertsSubscriptionDialog
                    open={showSubscriptionDialog}
                    onClose={handleClose}
                    languages={languages}
                    userAccountDetails={userAccountInfo}
                    reloadAccountDetails={reloadAccountInfo}
                    organization={organization}
                    applicationId={alerts[0].applicationId}
                    documentType={DocumentType.AlertWeather}
                    phoneNumbers={phoneNumbers[alerts[0].applicationId]}
                    onSubmit={async (signUpData: DataType) => {
                        const success = await handleSubscribeAllWithSignUpData(
                            signUpData,
                            subscribeToAllSelectedChannels
                        );
                        return success;
                    }}
                />
            )}
        </Grid>
    );
};

export default WeatherAlertsTab;
