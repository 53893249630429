import React, { useState, useRef } from "react";
import ApiService, { ApiError, parseErrorMessage } from "../../../services/apiService";
import Dialog from "@civicplus/preamble-ui/lib/Dialog";
import { DirtyStateDialog } from "../../DirtyState";
import Button from "@civicplus/preamble-ui/lib/Button";
import Checkbox from "@civicplus/preamble-ui/lib/Checkbox";
import enhanceWithValidation, { requiredValidation, ValidationResult } from "@civicplus/preamble-ui/lib/Validations";
import FormGroup from "@civicplus/preamble-ui/lib/FormGroup";
import FormControl from "@civicplus/preamble-ui/lib/FormControl";
import FormLabel from "@civicplus/preamble-ui/lib/FormLabel";
import FormHelperText from "@civicplus/preamble-ui/lib/ErrorHandlingFormHelperText";
import Grid from "@civicplus/preamble-ui/lib/Grid";
import PhoneNumberInput from "@civicplus/preamble-ui/lib/PhoneNumberInput";
import TextInput from "@civicplus/preamble-ui/lib/TextInput";
import { softMaxLengthValidation } from "../../../shared/customValidation";
import { useAuth } from "../../../providers/AuthProvider";
import { useSnackbar } from "notistack";
import { phoneNumberMask } from "./customValidation";
import {
    emptyPhone,
    isValidPhoneNumberValidation,
    PhoneNumber,
    cleanUpPhoneNumber,
    removeCountryCode
} from "./PhoneNumberUtils";

const EnhancedTextInput = enhanceWithValidation(TextInput);
const EnhancedPhoneNumberInput = enhanceWithValidation(PhoneNumberInput);
const EnhancedFormControl = enhanceWithValidation(FormControl);

interface AddPhoneNumberDialogProps {
    finishedAddPhoneNumberAction: (newNumber?: PhoneNumber) => void;
    existingNumberCount: number;
    show: boolean;
}

const AddPhoneNumberDialog: React.FC<AddPhoneNumberDialogProps> = (props) => {
    const { finishedAddPhoneNumberAction, existingNumberCount, show } = props;
    const auth = useAuth();
    const { enqueueSnackbar } = useSnackbar();

    const [phoneNumber, setPhoneNumber] = useState<PhoneNumber>({
        ...emptyPhone,
        label: `Phone Number ${existingNumberCount + 1}`,
        isDefaultNumber: existingNumberCount === 0
    });
    const [isLoading, setIsLoading] = useState(false);
    const phoneLabelRef = useRef<{ validate: () => ValidationResult }>();
    const phoneRef = useRef<{ validate: () => ValidationResult }>();
    const communicationOptionsRef = useRef<{ validate: () => ValidationResult }>();
    const [checkBoxError, setCheckBoxError] = useState(false);
    const [isLeavingWithoutSaving, setIsLeavingWithoutSaving] = useState<boolean>(false);
    const [isDirty, setIsDirty] = useState<boolean>(false);

    const handleSaveChanges = (key: string) => (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        setIsDirty(true);
        let value = checked === undefined ? e.currentTarget?.value : checked;
        if (key === "number") value = cleanUpPhoneNumber(e.currentTarget?.value);
        setPhoneNumber((prevPhone) => ({ ...prevPhone, [key]: value }));
    };

    const handleCancel = () => {
        if (isDirty) {
            setIsLeavingWithoutSaving(true);
        } else {
            setPhoneNumber(emptyPhone);
            finishedAddPhoneNumberAction();
        }
    };

    const handleSubmit = async () => {
        const validationResults: ValidationResult[] = await Promise.all([
            phoneRef.current!.validate(),
            phoneLabelRef.current!.validate(),
            communicationOptionsRef.current!.validate()
        ]);

        if (validationResults.some((x) => x.error)) {
            setCheckBoxError(validationResults[2]?.error ? true : false);
            enqueueSnackbar("Please select a communication option to proceed.", {
                variant: "error"
            });

            return;
        }

        try {
            setIsLoading(true);
            const promiseResult = await ApiService.post({
                url: "user/account/phoneNumber",
                authUser: auth.user,
                requestInit: {
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        number: removeCountryCode(phoneNumber.number),
                        allowSms: phoneNumber.allowSms,
                        allowVoice: phoneNumber.allowVoice,
                        countryCode: phoneNumber.countryCode,
                        label: phoneNumber.label,
                        isDefaultNumber: phoneNumber.isDefaultNumber
                    })
                }
            });
            enqueueSnackbar("Phone number saved successfully!", {
                variant: "success"
            });

            setPhoneNumber((prevPhone) => ({ ...prevPhone, id: promiseResult.id }));
            finishedAddPhoneNumberAction(promiseResult);
        } catch (error) {
            const message = parseErrorMessage(error as ApiError, "Failed to save a new phone number.");
            enqueueSnackbar(message, {
                variant: "error",
                persist: true
            });
            finishedAddPhoneNumberAction();
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <Dialog
                open={show}
                onClose={handleCancel}
                title="Add Phone Number"
                actions={[
                    <Button key="save-changes" color="primary" onClick={handleSubmit} isLoading={isLoading}>
                        Save Changes
                    </Button>,
                    <Button key="cancel" data-testid="cancel-add-phone" onClick={handleCancel}>
                        Cancel
                    </Button>
                ]}
            >
                <Grid container={true} spacing={2}>
                    <Grid xs={12} md="auto">
                        <EnhancedFormControl
                            ref={communicationOptionsRef}
                            required={true}
                            validations={[requiredValidation]}
                            value={phoneNumber.allowSms || phoneNumber.allowVoice ? "true" : undefined}
                            margin="none"
                        >
                            <FormLabel sx={{ color: "primary.main", fontSize: "caption.fontSize", fontWeight: "bold" }}>
                                Communication Options
                            </FormLabel>
                            <FormGroup row={true}>
                                <Checkbox
                                    id="allowSms"
                                    label="SMS / text messages"
                                    onChange={handleSaveChanges("allowSms")}
                                    checked={phoneNumber.allowSms}
                                    size="small"
                                />

                                <Checkbox
                                    id="allowVoice"
                                    label="Voice calls"
                                    onChange={handleSaveChanges("allowVoice")}
                                    checked={phoneNumber?.allowVoice}
                                    size="small"
                                />
                            </FormGroup>
                            <FormHelperText
                                error={Boolean(checkBoxError)}
                                errorMessage="You must select at least one communication option."
                            />
                        </EnhancedFormControl>
                    </Grid>

                    <Grid xs={12}>
                        <EnhancedTextInput
                            ref={phoneLabelRef}
                            id="phoneNumberLabel"
                            label="Phone Number Label"
                            value={phoneNumber.label}
                            onChange={handleSaveChanges("label")}
                            fullWidth={true}
                            softMaxLength={30}
                            validations={[softMaxLengthValidation]}
                            margin={false}
                        />
                    </Grid>

                    <Grid xs={12}>
                        <EnhancedPhoneNumberInput
                            ref={phoneRef}
                            id="phoneNumber"
                            label="Phone Number"
                            value={phoneNumber.number}
                            onChange={handleSaveChanges("number")}
                            fullWidth={true}
                            required={true}
                            maxLength={10}
                            autoComplete="tel-local"
                            placeholder="+1 (000) 000-0000"
                            maskTemplate={phoneNumberMask}
                            regExpPattern={"^\\+1\\s\\([1-9]\\d{2}\\)\\s\\d{3}-\\d{4}"}
                            validations={[requiredValidation, isValidPhoneNumberValidation]}
                            margin={false}
                        />
                    </Grid>

                    <Grid xs={12}>
                        <Checkbox
                            id="isDefaultPhoneNumber"
                            label="This is my Default Phone Number"
                            size="small"
                            color="primary"
                            onChange={handleSaveChanges("isDefaultNumber")}
                            disabled={existingNumberCount === 0}
                            checked={
                                existingNumberCount === 0 && phoneNumber.id === "" ? true : phoneNumber.isDefaultNumber
                            }
                        />
                    </Grid>
                </Grid>
            </Dialog>
            <DirtyStateDialog
                warningText="Are you sure you want to discard changes for Phone Number?"
                isOpen={isLeavingWithoutSaving}
                onReturnToContent={() => {
                    setIsLeavingWithoutSaving(false);
                }}
                onDiscardChanges={() => {
                    setIsLeavingWithoutSaving(false);
                    setIsDirty(false);
                    setPhoneNumber(emptyPhone);
                    finishedAddPhoneNumberAction();
                }}
            />
        </>
    );
};

export default AddPhoneNumberDialog;
