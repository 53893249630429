import { ValidationResult, errorMessage } from "@civicplus/preamble-ui/lib/Validations";

export enum PhoneNumberType {
    CellPhone = "CellPhone",
    CellPhoneSecondary = "CellPhoneSecondary",
    HomePhone = "HomePhone",
    WorkPhone = "WorkPhone"
}

export type PhoneNumber = {
    id: string;
    countryCode: string;
    number: string;
    konexusPhoneNumber: string;
    label: string;
    allowSms: boolean;
    allowVoice: boolean;
    isDefaultNumber: boolean;
    type: PhoneNumberType | "";
    isPhoneVerified?: boolean;
};

export interface PhoneNumberWithType extends PhoneNumber {
    type: PhoneNumberType;
}

interface softMaxLengthValidationProps {
    errorMessages: string;
    softMaxLength: number;
    label?: string;
}

export const softMaxLengthValidation = (value: string, props: softMaxLengthValidationProps) => {
    const validationName = "SoftMaxLength";
    const defaultErrorMessage = `${props.label ?? "Input"} length must be under ${props.softMaxLength} characters`;

    const isValid = value ? value.length < props.softMaxLength : true;

    const errormsg = { key: props.errorMessages };

    const result = {
        error: !isValid,
        message: errorMessage(validationName, errormsg, defaultErrorMessage),
        validationName: validationName
    };

    return result.error ? Promise.reject(result) : Promise.resolve(result);
};

export const isValidPhoneNumberValidation = (value: string): Promise<ValidationResult> => {
    const cleanedUpPhoneNumber = removeCountryCode(cleanUpPhoneNumber(value));

    const isValid = (value && RegExp(/^\d{10}$/).test(cleanedUpPhoneNumber)) || value === "";

    const result = {
        error: !isValid,
        message: "Phone Number must be 10 digits.",
        validationName: "isValidPhoneNumberValidation"
    };

    return result.error ? Promise.reject(result) : Promise.resolve(result);
};

export const emptyPhone: PhoneNumber = Object.freeze({
    id: "",
    label: "",
    allowSms: false,
    allowVoice: false,
    isDefaultNumber: false,
    countryCode: "1",
    number: "",
    konexusPhoneNumber: "",
    type: ""
});

export const areAllPhonesVerified = (phoneNumbers: PhoneNumber[]): boolean => {
    if (!phoneNumbers || phoneNumbers.length === 0) return false;

    const hasOnlyWorkHomeNumbers = phoneNumbers.every(
        (x) => x.type === PhoneNumberType.WorkPhone || x.type === PhoneNumberType.HomePhone
    );

    if (hasOnlyWorkHomeNumbers) {
        return true;
    } else {
        const cellPhoneNumbers = phoneNumbers.filter(
            (x) => x.type === PhoneNumberType.CellPhone || x.type === PhoneNumberType.CellPhoneSecondary
        );

        return cellPhoneNumbers.every((x) => x.isPhoneVerified);
    }
};

export const cleanUpPhoneNumber = (phoneNumber: string | undefined): string => {
    return phoneNumber ? phoneNumber.replace(/([^+\d_]|(?!^)\+)/g, "") : "";
};

export const removeCountryCode = (phoneNumber: string): string => {
    const countryCodeLength = phoneNumber.length - 10;
    return phoneNumber.substring(countryCodeLength > 0 ? countryCodeLength : 0);
};
