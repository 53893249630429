import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Button from "@civicplus/preamble-ui/lib/Button";
import ButtonGroup from "@civicplus/preamble-ui/lib/ButtonGroup";
import Loader from "@civicplus/preamble-ui/lib/Loader";
import Table from "@civicplus/preamble-ui/lib/Table";
import Toggle from "@civicplus/preamble-ui/lib/Toggle";
import Typography from "@civicplus/preamble-ui/lib/Typography";
import { makeStyles } from "@civicplus/preamble-ui/lib/Utilities/ThemeHelper";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import ApiService, { ApiError, parseErrorMessage } from "../../services/apiService";
import { useOrganization } from "../../stores/organizationStore";
import { useConfig } from "../../providers/ConfigProvider";
import { BaseSettingsSectionProps } from "./SettingsSection";
import { useSnackbar } from "notistack";
export type Permission = {
    id: number;
    groupId: string;
    groupName: string;
    isEnabled: boolean;
};

export function usePreviousErrors(value: string): string | undefined {
    const ref = useRef<string>();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

const useStyles = makeStyles((theme) => ({
    settingsColumn: {
        minWidth: "120px"
    },
    btnWrapper: {
        display: "flex",
        justifyContent: "end"
    },
    columnWrap: {
        overflowWrap: "anywhere"
    },
    tableHead: {
        "& th": {
            fontWeight: 700
        }
    },
    table: {
        "& #tableWrapper": {
            overflow: "initial"
        },
        "& .prmbl-tableHeader": {
            backgroundColor: theme.palette.background.default,
            position: "sticky",
            top: 0,
            zIndex: theme.zIndex.appBar
        }
    }
}));

const Permissions: React.FC<BaseSettingsSectionProps> = ({ org, auth }) => {
    const [loading, setLoading] = useState<boolean>(true);
    const [permissions, setPermissions] = useState<Permission[]>([]);
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(25);
    const [count, setCount] = useState<number>(0);
    const [areGroupsSyncing, setAreGroupsSyncing] = useState<boolean>(false);
    const [error, setError] = useState<string>("");
    const prevError = usePreviousErrors(error);
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();

    const config = useConfig();
    const organization = useOrganization((state) => state.organization);

    const load = useCallback(
        async (scrollToTop = false, resetCache = false): Promise<void> => {
            try {
                const permissions = await ApiService.get({
                    url: `${org.name}/permissions?$orderby=groupName asc&$count=true&$skip=${
                        page * rowsPerPage
                    }&$top=${rowsPerPage}`,
                    authUser: auth.user,
                    resetCache: resetCache
                });

                setPermissions(permissions.items);
                setCount(permissions.count);
                setLoading(false);
                if (scrollToTop) {
                    window.scroll(0, 0);
                }
            } catch (e) {
                const message = parseErrorMessage(e as ApiError, "An error occurred.");
                setError(message);
                setLoading(false);
            }
        },
        [auth.user, org.name, page, rowsPerPage]
    );

    useEffect(() => {
        async function init() {
            await load();
        }
        init();
    }, [load]);

    const changePage = async (page: number) => {
        setPage(page);
        await load(true);
    };

    const changeRowsPerPage = async (rows: number) => {
        setRowsPerPage(rows);
        setPage(0);
        await load(true);
    };

    const getPermission = (groupId: string): Permission | undefined => {
        const permission = permissions.find((p) => p.groupId === groupId);
        return permission;
    };

    const syncGroups = async () => {
        try {
            setAreGroupsSyncing(true);

            await ApiService.get({
                url: `${org.name}/permissions/syncGroups`,
                authUser: auth.user
            });

            await load(false, true);
        } catch (e) {
            const message = parseErrorMessage(e as ApiError, "An error occurred.");

            setError(message);
            setLoading(false);
        } finally {
            setAreGroupsSyncing(false);
        }
    };

    const onChange = async (e: React.ChangeEvent<HTMLInputElement>, groupId: string) => {
        const checked = e.currentTarget.checked;
        const permission = getPermission(groupId);

        if (permission) {
            permission.isEnabled = checked;

            ApiService.post({
                url: `${org.name}/permissions`,
                requestInit: {
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(permission)
                },
                authUser: auth.user
            })
                .then((p: Permission) => {
                    if (permission) {
                        setPermissions(
                            permissions.map((mappedPermission: Permission) => {
                                return mappedPermission.groupId === groupId
                                    ? { ...mappedPermission, id: p.id, isEnabled: checked }
                                    : mappedPermission;
                            })
                        );
                    }
                    enqueueSnackbar(
                        p.isEnabled
                            ? `The "${p.groupName}" group now has access to Portal.`
                            : `The "${p.groupName}" group no longer has access to Portal.`,
                        {
                            variant: "success"
                        }
                    );
                    return;
                })
                .catch((e) => {
                    const message = parseErrorMessage(e as ApiError, "An error occurred.");
                    setError(message);
                    setLoading(false);
                });
        }
    };

    useEffect(() => {
        if (error && error !== prevError) {
            enqueueSnackbar(error, {
                variant: "error",
                persist: true
            });
        }
    }, [enqueueSnackbar, error, prevError]);

    const handleRowClick = (_: React.MouseEvent, row: { isEnabled: boolean; groupId: string }) => {
        onChange({ currentTarget: { checked: !row.isEnabled } } as React.ChangeEvent<HTMLInputElement>, row.groupId);
    };

    const orgServiceBaseUrl = useMemo(() => {
        const orgServiceBaseUrl = config.getOrgServiceBaseUrl();

        const manageGroupsUrl = `${orgServiceBaseUrl}${organization?.name}/groups`;
        return manageGroupsUrl;
    }, [config, organization]);

    return (
        <>
            <div className={classes.btnWrapper}>
                <ButtonGroup>
                    <Button
                        id="sync-groups-button"
                        color="primary"
                        endIcon={<OpenInNewIcon fontSize="small" />}
                        onClick={syncGroups}
                        disabled={areGroupsSyncing}
                    >
                        Sync Groups
                    </Button>
                    <Button
                        id="manage-users-button"
                        openInNewWindow={true}
                        color="primary"
                        endIcon={<OpenInNewIcon fontSize="small" />}
                        href={orgServiceBaseUrl}
                    >
                        Manage Users and Groups
                    </Button>
                </ButtonGroup>
            </div>

            {loading ? (
                <Loader />
            ) : (
                <Table
                    id="permissions-view-table"
                    rows={permissions}
                    columns={[
                        {
                            columnHeader: "Group Name",
                            rowProperty: "groupName",
                            type: (subject: { value: string }) => (
                                <Typography variant="body1">{subject.value}</Typography>
                            )
                        },
                        {
                            columnHeader: "Has Access",
                            align: "right",
                            rowProperty: "groupId",
                            type: (groupId: { value: string }) => {
                                const permission = getPermission(groupId.value);
                                return (
                                    <Toggle
                                        id={`toggle${groupId.value}`}
                                        value={`toggle_${groupId.value}`}
                                        checked={permission && permission.isEnabled}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                            onChange(e, groupId.value)
                                        }
                                        stopPropagation={true}
                                    />
                                );
                            }
                        }
                    ]}
                    pagination={{
                        count: count,
                        page: page,
                        rowsPerPage: rowsPerPage,
                        rowsPerPageOptions: [25, 50, 100],
                        onPageChange: (_: unknown, page: number) => changePage(page),
                        onRowsPerPageChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => {
                            changeRowsPerPage(event.target.value as unknown as number);
                        }
                    }}
                    onRowClick={handleRowClick}
                    className={classes.table}
                />
            )}
        </>
    );
};

export default Permissions;
