import React from "react";
import Typography from "@civicplus/preamble-ui/lib/Typography";
import { sanitizeAndParseStringToHtml } from "../../shared/functions";

interface HtmlDescriptionProps {
    description?: string;
}

export const HtmlDescription: React.FC<HtmlDescriptionProps> = ({ description }) => {
    return (
        <Typography sx={{ wordBreak: "normal", textAlign: "justify" }}>
            {sanitizeAndParseStringToHtml(description)}
        </Typography>
    )
};
