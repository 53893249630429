import React, { LegacyRef, useEffect, useState } from "react";
import ApiService from "../../services/apiService";
import Button from "@civicplus/preamble-ui/lib/Button";
import ButtonGroup from "@civicplus/preamble-ui/lib/ButtonGroup";
import ChipPortal from "../../components/Chip";
import clsx from "clsx";
import Grid, { GridProps } from "@civicplus/preamble-ui/lib/Grid";
import Icon from "@mdi/react";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@civicplus/preamble-ui/lib/Typography";
import useStyles from "./styles";
import WebIcon from "mdi-material-ui/Web";
import { CreatePublicLinkDialog } from "../CreateNewLinkDialog/CreatePublicLinkDialog";
import { Dictionary } from "../../types/Content";
import { GAlinkSelected } from "../../services/googleAnalyticsService";
import { getIconByKey, SectionIconKey } from "../SectionsIcons/SectionsIcons";
import { isStaffUser } from "../../shared/functions";
import { Link, LinkUtilities, PublicLink } from "../../types/Link";
import { LoginNoticeDialog } from "../Authentication/LoginNoticeDialog";
import { mdiBookmark, mdiBookmarkOutline, mdiDelete, mdiPencil, mdiOpenInNew } from "@mdi/js";
import { ProductType } from "../../types/ProductType";
import { RemoveFavoriteDialog } from "../Favorites/RemoveFavoriteDialog";
import { setupAcrValue } from "../react-oidc-context";
import { SimpleDialog } from "../SimpleDialog/SimpleDialog";
import { SigninPopupArgs } from "oidc-client-ts";
import { useAuth } from "../../providers/AuthProvider";
import { useEmbedStore } from "../../stores/embedStore";
import { useLinks } from "../../stores/linksStore";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
export interface LinkCardProps extends Omit<React.HTMLProps<HTMLDivElement>, "ref"> {
    /**
     * The link id
     */
    id?: string;

    /**
     * The aggregate id for the link
     */
    aggregateId?: string;

    /**
     * The title to display for the link
     */
    title: string;

    /**
     * The description to display for the link
     */
    description?: string;

    /**
     * The URL to open when the link is activated
     */
    href?: string;

    /**
     * The click handler to pass to the rendered button
     */
    onClick?: (event: React.MouseEvent<HTMLElement>) => void;

    /**
     * The Icon to use
     */
    icon?: React.ReactNode | React.ReactElement | keyof SectionIconKey;

    /**
     * The image to display instead of an icon. If this URL is provided, the icon will not display. The color will still render behind the image
     */
    image?: string;

    /**
     * Whether this item is favorited or not
     */
    favorite?: boolean;

    /**
     * Whether to render a skeleton loader for this card
     */
    loading?: boolean;

    /**
     * Link's Organization name
     */
    orgName?: string;

    /**
     * Link's Organization Id
     */
    organizationId?: string;

    /**
     * Product Type
     */
    productType: ProductType;

    /**
     * List of tags
     */
    tags?: string[];

    /**
     * Links background color
     */
    linksBackgroundColor?: string;

    /**
     * Links UserId
     */
    userId?: string;

    /**
     * Delete Open Action
     */
    onDeleteAction?: (id?: string, title?: string) => Promise<void>;
    /**
     * Delete Close Action
     */
    onDeleteClose?: () => void;
    /**
     * Modify Open Action
     */
    onModifyAction?: () => void;
    /**
     * Modify Close Action
     */
    onModifyClose?: () => void;
    /**
     * A ref to the root element
     */
    ref?: LegacyRef<GridProps> | undefined;
    /**
     * Section link is displayed in.
     */
    section: string;
    /**
     * Boolean to verify if Link is featured or not
     */
    isFeatured?: boolean;
    /**
     * Link creation date
     */
    created?: string;
    /**
     * additionalInformation.
     */
    additionalInformation?: Dictionary[];
    /**
     * Boolean to verify if LinkCard is on LinksDashboard component
     */
    isOnLinksDashboard?: boolean;
    /**
     * Full link with all properties
     */
    fullLink: Link;
}

const LinkCard: React.FC<LinkCardProps> = (props) => {
    const auth = useAuth();
    const classes = useStyles();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const [isEmbed, baseUrl] = useEmbedStore((state) => [state.isInitialized, state.baseUrl]);
    const [loginNoticeOpen, setLoginNoticeOpen] = useState(false);
    const [isUnfavoriteDialogOpen, setIsUnfavoriteDialogOpen] = useState(false);
    const [setFavoriteLink, setLinkToFavorite] = useLinks((state) => [state.setFavoriteLink, state.setLinkToFavorite]);

    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
    const [linkToBeEdited, setLinkToBeEdited] = useState<PublicLink>();

    const {
        title,
        description,
        href,
        icon,
        id,
        aggregateId,
        favorite,
        onClick,
        loading,
        productType,
        userId,
        onDeleteAction,
        onDeleteClose,
        onModifyClose,
        orgName,
        organizationId,
        tags,
        additionalInformation,
        isOnLinksDashboard,
        fullLink
    } = props;

    const pathIcon = icon ? getIconByKey(icon as SectionIconKey) : undefined;

    const handleFavoriteClick = async (e: React.MouseEvent) => {
        e.preventDefault();

        const isSignedIn = auth.isAuthenticated;

        if (isSignedIn && id) {
            if (favorite) {
                setIsUnfavoriteDialogOpen(true);
            } else if (aggregateId !== undefined && auth.user) {
                await setFavoriteLink(aggregateId, fullLink, true, auth.user, enqueueSnackbar, organizationId);
            }
        } else if (!isSignedIn && aggregateId) {
            setLinkToFavorite(fullLink);
            setLoginNoticeOpen(true);
        }
    };

    useEffect(
        function userAuthCallback() {
            if (isEmbed && auth.isAuthenticated) {
                setLoginNoticeOpen(false);
            }
        },
        [isEmbed, baseUrl, auth.isAuthenticated]
    );

    const handleDeleteLink = async (e: React.MouseEvent) => {
        e.preventDefault();
        setIsDeleteDialogOpen(true);
    };

    const deleteAction = async () => {
        onDeleteAction && onDeleteAction(id, title);
        setIsDeleteDialogOpen(false);
    };

    const closeDeleteModal = async () => {
        onDeleteClose && onDeleteClose();
        setIsDeleteDialogOpen(false);
    };

    const handleModifyLink = async (e: React.MouseEvent) => {
        e.preventDefault();
        const link = await ApiService.get({ url: `publicLinks/${id}`, authUser: auth.user });
        setIsEditDialogOpen(true);
        setLinkToBeEdited(link);
    };

    const editAction = async () => {
        setIsEditDialogOpen(false);
    };

    const closeEditModal = () => {
        onModifyClose && onModifyClose();
        setIsEditDialogOpen(false);
        setLinkToBeEdited(undefined);
    };

    const handleCloseLoginNotice = () => {
        setLoginNoticeOpen(false);
        setLinkToFavorite(undefined);
    };

    const handleLogin = () => {
        const args = {
            state: { from: window.location.pathname }
        } as SigninPopupArgs;
        auth.signinPopup(setupAcrValue(auth, args, organizationId));
        setLoginNoticeOpen(false);
    };

    const handleConfirmUnfavorite = async () => {
        if (aggregateId !== undefined && auth.user) {
            await setFavoriteLink(aggregateId, fullLink, false, auth.user, enqueueSnackbar, organizationId);
            setIsUnfavoriteDialogOpen(false);
        }
    };

    return (
        <Grid xs={12} md={6} lg={4} xl={3} id={id} className={classes.root} role="listitem">
            <Button
                className={classes.button}
                variant="text"
                fullWidth={true}
                id={`${id}-main-link-card`}
                data-testid={`${id}-main-link-card`}
                onClick={(e: React.MouseEvent<HTMLElement>) => {
                    if (onClick) {
                        onClick(e);
                    }

                    if (!isStaffUser(auth.user)) {
                        GAlinkSelected(orgName || "Dashboard", id || "0", title, props.section);
                    }

                    const { url, isExternal } = LinkUtilities.navigateUrl(
                        productType,
                        orgName,
                        id,
                        href,
                        undefined,
                        isEmbed,
                        additionalInformation
                    );

                    if (isExternal) {
                        window.open(url);
                    } else {
                        navigate(url);
                    }
                }}
            >
                <figure className={classes.cardIcon}>
                    {pathIcon ? <Icon path={pathIcon.path} /> : <WebIcon fontSize="inherit" />}
                </figure>

                <div className={classes.content}>
                    <Typography
                        id={`${id}-link-card-title`}
                        className={classes.title}
                        component="h2"
                        variant="h5"
                        color="primary"
                    >
                        {loading ? <Skeleton variant="text" /> : title}
                    </Typography>

                    <Typography
                        id={`${id}-description`}
                        className={clsx(classes.description, { [classes.descriptionDashboard]: isOnLinksDashboard })}
                        variant="body1"
                    >
                        {loading ? <Skeleton variant="text" /> : description}
                    </Typography>

                    <div className={classes.chipsContainer}>
                        {LinkUtilities.opensInNewWindow(productType, isEmbed, additionalInformation) ? (
                            <ChipPortal
                                label="External"
                                size="small"
                                icon={<Icon path={mdiOpenInNew} size={0.75} aria-hidden="true" />}
                                className={classes.externalChip}
                            />
                        ) : null}

                        {tags?.map((tag) => <ChipPortal label={tag} key={tag} size="small" />)}
                    </div>
                </div>
            </Button>

            <div className={classes.buttonGroup}>
                {userId ? (
                    <ButtonGroup>
                        <Button
                            id={`edit-icon-btn-${id}`}
                            type="icon"
                            onClick={handleModifyLink}
                            stopPropagation={true}
                            title={`Modify ${title} link`}
                        >
                            <Icon path={mdiPencil} size={1} aria-hidden="true" />
                        </Button>

                        <Button
                            id={`delete-icon-btn-${id}`}
                            type="icon"
                            onClick={handleDeleteLink}
                            stopPropagation={true}
                            title={`Delete ${title} link`}
                        >
                            <Icon path={mdiDelete} size={1} aria-hidden="true" />
                        </Button>
                    </ButtonGroup>
                ) : (
                    <Button
                        id={`bookmark-icon-btn-${id}`}
                        type="icon"
                        onClick={handleFavoriteClick}
                        stopPropagation={true}
                        title={`${favorite ? `Remove ${title} from` : `Add ${title} to`} my bookmarks`}
                        data-testid={`bookmark-icon-btn-${id}`}
                        aria-pressed={favorite}
                    >
                        <Icon path={favorite ? mdiBookmark : mdiBookmarkOutline} size={1} aria-hidden="true" />
                    </Button>
                )}
            </div>

            <LoginNoticeDialog isOpen={loginNoticeOpen} onClose={handleCloseLoginNotice} handleLogin={handleLogin} />

            <RemoveFavoriteDialog
                isOpen={isUnfavoriteDialogOpen}
                handleConfirm={handleConfirmUnfavorite}
                onClose={() => setIsUnfavoriteDialogOpen(false)}
            />

            <CreatePublicLinkDialog
                isOpen={isEditDialogOpen}
                onClose={closeEditModal}
                onSave={editAction}
                linkToModify={linkToBeEdited}
                modifying={true}
                userId={userId}
            />

            <SimpleDialog
                isOpen={isDeleteDialogOpen}
                title="Delete"
                primaryAction={deleteAction}
                primaryBtnText="Confirm Deletion"
                onClose={closeDeleteModal}
            >
                <Typography>Are you sure you want to delete this link?</Typography>
            </SimpleDialog>
        </Grid>
    );
};

export default LinkCard;
