import React, { useState, useMemo, useEffect } from "react";
import AccessibilityMessage from "../../AccessibilityMessage";
import { mdiFormatListBulleted } from "@mdi/js";
import Link from "@civicplus/preamble-ui/lib/Link";
import Loader from "@civicplus/preamble-ui/lib/Loader";
import NotificationsList from "../../Notifications/NotificationsList";
import Typography from "@civicplus/preamble-ui/lib/Typography";
import useStyles from "../styles";
import { DocumentType } from "../../../types/SearchDocument";
import { LoginNoticeDialog } from "../../Authentication/LoginNoticeDialog";
import { NotificationsService } from "../../../services/notificationsService";
import { NavigationOption, Organization } from "../../../types/Organization";
import { shallow } from "zustand/shallow";
import { SubscriptionList, SubscriptionType } from "../../../types/Subscription";
import { useOrganization } from "../../../stores/organizationStore";
import { useFavorites } from "../../../shared/useFavorites";
import { useNavigate } from "react-router-dom";
import { User as AuthUser } from "oidc-client-ts";
import DashboardZone from "../DashboardZone";

const NUMBER_OF_NOTIFICATIONS_TO_DISPLAY = 3;

interface NotificationsDashboardProps {
    /**
     * The current user session
     */
    authUser: AuthUser | null | undefined;
    /**
     * The current organization
     */
    organization: Organization;
    /**
     * Array of organization subscriptions with user subscription info.
     */
    subscriptions: SubscriptionList[];
}

const NotificationsDashboard: React.FC<NotificationsDashboardProps> = ({
    authUser,
    organization,
    subscriptions: allSubscriptions
}) => {
    const classes = useStyles();
    const navigate = useNavigate();

    const [allNotifications, setAllNotifications] = useState<SubscriptionList[]>([]);
    const notificationService = useMemo(() => new NotificationsService(), []);
    const [subsAndUnsubs, fetchNotificationsStatus, availableNotifications] = useOrganization(
        (state) => [state.subsAndUnsubs, state.fetchNotificationsStatus, state.availableNotifications],
        shallow
    );

    const subscriptions = useMemo(
        () => allSubscriptions?.filter((s) => s.documentType === DocumentType.SubscriptionList),
        [allSubscriptions]
    );

    const [{ loginNoticeOpen }, { handleCloseLoginNotice, handleLogin }] = useFavorites();

    const retryActionLocalStorage = Object.keys({ ...localStorage }).find((s) => s.includes("retrySubscriptionAction"));

    const subscribedNotifications = useMemo(() => {
        return subscriptions?.filter(
            (s: SubscriptionList) =>
                s.emailSubscription === true ||
                (s.smsSubscription === true && s.availableSubscriptionType !== SubscriptionType.Email)
        );
    }, [subscriptions]);

    const featuredNotifications = useMemo(() => {
        return subscriptions?.filter((s: SubscriptionList) => s.isFeatured === true);
    }, [subscriptions]);

    useEffect(
        function getDashboardNotifications() {
            let joinedNotifications = [];

            if (authUser && subscribedNotifications?.length > 0 && !retryActionLocalStorage) {
                joinedNotifications = [...subscribedNotifications];
            } else {
                let tempItems: SubscriptionList[] = [];

                if (featuredNotifications?.length > 0) {
                    tempItems = [...featuredNotifications];
                }
                if (subscriptions?.length > 0) {
                    tempItems = [...tempItems, ...subscriptions];
                }

                joinedNotifications = tempItems.slice(0, 10);
            }

            const currentNotifications: SubscriptionList[] = [];

            if (joinedNotifications.length > 0) {
                joinedNotifications.forEach((notification) => {
                    if (!currentNotifications.find((c) => c.aggregateId === notification.aggregateId)) {
                        currentNotifications.push(notification);
                    }
                });
            }

            setAllNotifications(
                authUser && subscribedNotifications?.length > 0
                    ? currentNotifications
                    : currentNotifications.slice(0, NUMBER_OF_NOTIFICATIONS_TO_DISPLAY)
            );
        },
        [
            authUser,
            featuredNotifications,
            subscriptions,
            subscribedNotifications,
            retryActionLocalStorage,
            subsAndUnsubs
        ]
    );

    const zoneContent: React.ReactElement = useMemo(() => {
        if (allNotifications.length === 0) {
            return (
                <Typography className={classes.emptyMessage}>
                    At present, <b>{organization?.name || "This organization"}</b> does not have have any available
                    subscription lists.
                </Typography>
            );
        }

        if (authUser && subscribedNotifications?.length === 0 && !retryActionLocalStorage) {
            return (
                <div className={classes.emptyZone}>
                    <Typography component="h3" variant="h5">
                        You have not subscribed to any Notifications
                    </Typography>

                    <Typography>
                        <Link
                            key="view-all-notifications"
                            onClick={() => navigate(`/${organization?.name}/notifications`)}
                            underline="always"
                        >
                            View the Notifications List
                        </Link>{" "}
                        and use the subscription toggles to
                        <br /> subscribe and manage notifications to say informed.
                    </Typography>
                </div>
            );
        }

        return (
            <NotificationsList
                notifications={allNotifications}
                organization={organization}
                authUser={authUser}
                notificationService={notificationService}
            ></NotificationsList>
        );
    }, [
        authUser,
        organization,
        allNotifications,
        subscribedNotifications,
        notificationService,
        retryActionLocalStorage,
        navigate,
        classes
    ]);

    if (availableNotifications !== undefined && availableNotifications[DocumentType.SubscriptionList] === false) {
        return null;
    }

    return (
        <>
            <DashboardZone
                icon={mdiFormatListBulleted}
                title="Notifications"
                url={`/${organization?.name}/notifications`}
                navigationType={NavigationOption.AlertsAndNotifications}
            >
                <section id="notifications-dashboard-list">
                    {fetchNotificationsStatus !== "Complete" ? <Loader verticallyCenter={true} /> : <>{zoneContent} </>}

                    <AccessibilityMessage
                        message={
                            fetchNotificationsStatus !== "Complete"
                                ? "Loading Notification lists"
                                : "Dashboard Notification lists loaded"
                        }
                    />
                </section>
            </DashboardZone>
            <LoginNoticeDialog isOpen={loginNoticeOpen} onClose={handleCloseLoginNotice} handleLogin={handleLogin} />
        </>
    );
};

export default NotificationsDashboard;
