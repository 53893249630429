import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";

export interface RenderConfiguration {
    isEmbed?: boolean;
    rootHtmlId?: string;
}

export function renderPortal(config: RenderConfiguration = {}): void {
    const { rootHtmlId, ...others } = config;
    const root = ReactDOM.createRoot(document.getElementById(rootHtmlId ?? "root") as HTMLElement);

    root.render(
        <React.StrictMode>
            <App {...others} />
        </React.StrictMode>
    );
}

if ((window as any).CivicPlus === undefined) {
    (window as any).CivicPlus = { renderPortal };
}

if (((window as any).CivicPlus as any).renderPortal === undefined) {
    ((window as any).CivicPlus as any).renderPortal = renderPortal;
}
