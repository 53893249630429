import { AppIdsAndPhoneNumbers } from "../types/Subscription";
import { PhoneNumberWithType, PhoneNumberType } from "../components/AlertsSubscriptionDialog/PhoneNumber/PhoneNumberUtils";
import { PhysicalAddress } from "../components/AlertsSubscriptionDialog/PhysicalAddress/PhysicalAddressList";
import { SubscriptionProfileInformation, UserAccountDetails } from "../types/Account";
import { DataType } from "../components/AlertsSubscriptionDialog";
import languages from "../shared/languages";

const getVerifiedPhone = (
    userAccountInfo: UserAccountDetails,
    phoneNumbers: AppIdsAndPhoneNumbers,
    applicationId: string,
    phoneId?: string | null
) => {
    if (!userAccountInfo || !phoneId) return;
    const phone = userAccountInfo.phoneNumbers.find((x) => x.id === phoneId);
    if (!phone) return;
    const isPhoneVerified =
        phoneNumbers[applicationId] && phone
            ? phoneNumbers[applicationId].find((x) => x.phoneNumber === phone.konexusPhoneNumber)?.isValidated ?? false
            : false;

    return { phone, isPhoneVerified };
};

export const validateKonexusInfo = (
    userAccountInfo: UserAccountDetails | undefined,
    phoneNumbers: AppIdsAndPhoneNumbers,
    applicationId: string
): {
    isValid: boolean;
    isPhoneVerified: boolean;
    phoneNumbers: PhoneNumberWithType[];
    physicalAddress?: PhysicalAddress;
} => {
    if (userAccountInfo?.konexusUser === undefined || userAccountInfo.konexusUser === null) {
        return { isValid: false, isPhoneVerified: false, phoneNumbers: [] };
    }

    const accountCellPhone = getVerifiedPhone(
        userAccountInfo,
        phoneNumbers,
        applicationId,
        userAccountInfo.konexusUser?.accountServiceCellPhoneId
    );
    const accountCellPhoneSecondary = getVerifiedPhone(
        userAccountInfo,
        phoneNumbers,
        applicationId,
        userAccountInfo.konexusUser?.accountServiceCellPhoneSecondaryId
    );
    const accountHomePhone = getVerifiedPhone(
        userAccountInfo,
        phoneNumbers,
        applicationId,
        userAccountInfo.konexusUser?.accountServiceHomePhoneId
    );
    const accountWorkPhone = getVerifiedPhone(
        userAccountInfo,
        phoneNumbers,
        applicationId,
        userAccountInfo.konexusUser?.accountServiceWorkPhoneId
    );

    const phoneNumbersArr: PhoneNumberWithType[] = [];

    if (accountCellPhone) phoneNumbersArr.push({ ...accountCellPhone.phone, type: PhoneNumberType.CellPhone });
    if (accountCellPhoneSecondary)
        phoneNumbersArr.push({ ...accountCellPhoneSecondary.phone, type: PhoneNumberType.CellPhoneSecondary });
    if (accountHomePhone) phoneNumbersArr.push({ ...accountHomePhone.phone, type: PhoneNumberType.HomePhone });
    if (accountWorkPhone) phoneNumbersArr.push({ ...accountWorkPhone.phone, type: PhoneNumberType.WorkPhone });

    const isPhoneVerified =
        (accountCellPhone?.isPhoneVerified ?? true) && (accountCellPhoneSecondary?.isPhoneVerified ?? true);

    const addressIndex = userAccountInfo.physicalAddresses.findIndex(
        (x) => x.id === userAccountInfo.konexusUser?.accountServicePhysicalAddressId
    );

    return {
        isValid: isPhoneVerified && addressIndex > -1,
        phoneNumbers: phoneNumbersArr ? phoneNumbersArr.filter((x) => x !== undefined) : [],
        isPhoneVerified,
        physicalAddress: addressIndex > -1 ? userAccountInfo.physicalAddresses[addressIndex] : undefined
    };
};

export const setProfileInfo = (signUpData: DataType | null): SubscriptionProfileInformation | null => {
    let profileInfo: SubscriptionProfileInformation | null = null;
    if (signUpData) {
        profileInfo = {
            cellPhoneId: signUpData.phoneNumbers.find((x) => x.type === PhoneNumberType.CellPhone)?.id ?? null,
            cellPhoneSecondaryId:
                signUpData.phoneNumbers.find((x) => x.type === PhoneNumberType.CellPhoneSecondary)?.id ?? null,
            homePhoneId: signUpData.phoneNumbers.find((x) => x.type === PhoneNumberType.HomePhone)?.id ?? null,
            workPhoneId: signUpData.phoneNumbers.find((x) => x.type === PhoneNumberType.WorkPhone)?.id ?? null,
            physicalAddressId: signUpData.physicalAddress?.id ?? null,
            spokenLanguageOption: signUpData.language?.spoken?.value ?? languages.defaultSpoken.value,
            writtenLanguageOption: signUpData.language?.written?.value ?? languages.defaultWritten.value
        };
    }
    return profileInfo;
};
