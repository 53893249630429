import { makeStyles } from "@civicplus/preamble-ui/lib/Utilities/ThemeHelper";

const useStyles = makeStyles((theme) => ({
    settingsColumn: {
        minWidth: "120px"
    },
    categoryChip: {
        backgroundColor: theme.palette.grey[300],
        "& > span": {
            color: "#000"
        }
    },
    icon: {
        cursor: "pointer",
        color: theme.palette.grey[700],
        fontSize: theme.typography.subtitle1.fontSize,
        marginLeft: 6,
        marginTop: 5
    },
    syncMessage: {
        display: "flex",
        justifyContent: "end",
        alignItems: "center",
        marginRight: 15
    }
}));

export default useStyles;
